<template>
  <sliding-modal
    :title="videosTitle"
    :feed-name="currentFeedEdit.feedName"
    @close="close"
  >
    <div class="video-sliding-container">
      <Step2
        :is-bulk="true"
        :is-edit-mode="true"
        @close-videos-modal="close"
        @save-videos="saveVideos"
      />
    </div>
  </sliding-modal>
</template>

<script>
import SlidingModal from "@/components/shared/SlidingModal.vue";
import Step2 from "@/components/create-campaign-steps/CreateCampaignStep2.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    SlidingModal,
    Step2,
  },
  props: {
    currentFeedEditIndex: { type: Number, required: true },
  },
  data() {
    return {
      videosTitle: this.$t("create.bulk.videosModal.title"),
    };
  },
  computed: {
    ...mapGetters({
      currentFeedEdit: "campaign/currentFeedEdit",
      feedList: "campaign/feedList",
      getVideos: "video/getVideos",
    }),
  },
  methods: {
    close() {
      this.$emit("close-videos-modal");
    },
    saveVideos() {
      this.$store.commit("campaign/updateFeedList", {
        currentFeedEdit: this.currentFeedEdit,
        index: this.currentFeedEditIndex,
      });
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.video-sliding-container {
  position: relative;
  height: 100%;
}
.buttons {
  width: calc(25% + 12px);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  position: absolute;
  bottom: 80px;
  left: 0;
  height: 56px;
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.15));
}

.flex-button {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
