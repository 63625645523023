import axios from "axios";

async function signup(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/account/signup`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function appsumoSignup(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/appsumo/signup`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err;
  }
}



async function login(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/account/login`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function updatePassword(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };
  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/account/change-password`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function setPassword(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };
  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/account/set-password`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function resetPassword(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/account/reset-password`,
      requestOptions
    );
    
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function cancelSubscription(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };
  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/account/cancel`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function processShopifyAuth(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };
  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/shopify/process-auth`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function logShopifyUser(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };
  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/shopify/log-user`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function logWixUser(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };
  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/wix/log-user`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function logFromAuthToken(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };
  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/account/log-from-auth-token`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err;
  }
}

async function logFromLocalStorage(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };
  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/account/log-from-local-storage`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err;
  }
}

export default {
  signup,
  appsumoSignup,
  login,
  updatePassword,
  resetPassword,
  setPassword,
  cancelSubscription,
  processShopifyAuth,
  logShopifyUser,
  logWixUser,
  logFromAuthToken,
  logFromLocalStorage,
};
