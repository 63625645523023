<template>
  <full-screen-modal :show-close-button="false">
    <SignupLogin />
  </full-screen-modal>
</template>

<script>
import FullScreenModal from "./shared/Fullscreen-modal.vue";
import SignupLogin from "./shared/SignupLogin.vue";

export default {
  name: "Signup",
  components: {
    FullScreenModal,
    SignupLogin,
  },
};
</script>

<style></style>
