<template>
  <div
    class="banner-container"
    :class="[bannerType === 'warning' ? 'warning-container' : 'info-container']"
  >
    <div class="icon">
      <InfoIcon v-if="bannerType === 'info'"></InfoIcon>
      <WarningIcon v-else></WarningIcon>
    </div>
    <div class="content" :style="{ gap: contentGap }">
      <h2
        class="title"
        :style="{ ...titleStyle, 'font-weight': titleFontWeight }"
      >
        {{ title }}
      </h2>
      <p class="text" :style="titleStyle">{{ text }}<slot></slot></p>
    </div>
  </div>
</template>

<script>
import InfoIcon from "@/assets/svg/info-icon.svg?inline";
import WarningIcon from "@/assets/svg/banner-warning-icon.svg?inline";

export default {
  name: "BannerModal",
  components: {
    InfoIcon,
    WarningIcon,
  },
  props: {
    bannerType: {
      type: String,
      default: "info",
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: "",
    },
    titleFontWeight: {
      type: String,
      default: "600",
    },
    contentGap: {
      type: String,
      default: "8px",
    },
  },
  computed: {
    titleStyle() {
      if (this.bannerType === "warning") {
        return { color: "rgba(235, 0, 0, 1)" };
      } else {
        return { color: "rgba(123, 1, 181, 1)" };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$banner-background-purple: rgba(123, 1, 181, 0.15);
$warning-background-red: rgba(235, 0, 0, 0.15);

.banner-container {
  position: relative;
  border-radius: 2px;
  text-align: left;
  padding: 16px 16px 16px 32px;
  width: 100%;
  box-sizing: border-box;
}
.warning-container {
  background-color: $warning-background-red;
  border-left: 3px solid $error-red;
}

.info-container {
  background-color: $banner-background-purple;
  border-left: 3px solid $dark-purple;
}

.icon {
  position: absolute;
  top: 17px;
  left: 8px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  @include base-font;
  @include font-normal;
}

.text {
  @include base-font;
  @include font-normal;
}
</style>
