import axios from "axios";

async function getInvoices(siteId) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    params: {
      siteId,
    },
  };
  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/invoices`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

export default {
  getInvoices,
};
