<template>
  <small-modal :fit-content="true" @close="close">
    <div class="title-subtitle-banner">
      <h1 class="card-title">
        {{ $t("account.userInformation.addressModal.title") }}
      </h1>
      <h2>{{ $t("account.userInformation.addressModal.enterAddress") }}</h2>
      <div class="banner">
        <InfoIcon class="info-icon" />
        <div class="banner-information">
          <h2 class="banner-title">
            {{ $t("account.userInformation.addressModal.information") }}
          </h2>
          <p class="banner-text">
            {{ $t("account.userInformation.addressModal.informationText") }}
          </p>
        </div>
      </div>
    </div>
    <form class="form" @submit.prevent="updateSite">
      <ul class="address-form">
        <li>
          <label class="address-label country" for="country">{{
            $t("account.userInformation.addressModal.country")
          }}</label>
          <v-select
            id="country"
            v-model="country"
            class="vidjet-single-select address"
            :options="countries.map((country) => country.name)"
            :searchable="false"
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                :required="!country"
                v-bind="attributes"
                v-on="events"
              />
            </template>
          </v-select>
        </li>
        <li>
          <label class="address-label" for="street">{{
            $t("account.userInformation.addressModal.address")
          }}</label>
          <input id="street" v-model="line1" type="text" required />
        </li>
        <li class="zip-and-city">
          <label class="address-label align" for="zipcode">{{
            $t("account.userInformation.addressModal.zipcode")
          }}</label>
          <input id="zipcode" v-model="postalCode" type="text" required />
          <label class="address-label align" for="city">{{
            $t("account.userInformation.addressModal.city")
          }}</label>
          <input id="city" v-model="city" type="text" required />
        </li>

        <li>
          <label class="address-label" for="state-region">{{
            $t("account.userInformation.addressModal.state")
          }}</label>
          <input id="state-region" v-model="state" type="text" />
        </li>
      </ul>
      <div class="buttons">
        <input
          class="button-primary --grey"
          type="button"
          :value="$t('account.userInformation.addressModal.cancel')"
          @click.prevent="close"
        />
        <label class="button-primary flex-button">
          <input
            type="submit"
            :value="$t('account.userInformation.addressModal.save')"
            class="submit-button"
          />
          <ConfirmIcon />
        </label>
      </div>
    </form>
  </small-modal>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import countriesEn from "@/utils/countries-en.js";
import countriesFr from "@/utils/countries-fr.js";
import SmallModal from "../../shared/SmallModal";
import InfoIcon from "@/assets/svg/info-icon.svg?inline";
import ConfirmIcon from "@/assets/svg/confirm-icon.svg?inline";
import isEmpty from "lodash/isEmpty";

export default {
  components: {
    SmallModal,
    InfoIcon,
    ConfirmIcon,
  },
  data() {
    return {
      countries: [],
      countriesEn,
      countriesFr,
      country: "",
      line1: "",
      state: "",
      postalCode: "",
      city: "",
    };
  },

  computed: {
    ...mapState(["site"]),
    ...mapGetters({ address: "site/getAddress" }),
  },
  async created() {
    if (this.$i18n.locale == "fr") {
      this.countries = this.countriesFr;
    } else {
      this.countries = this.countriesEn;
    }
    await this.$store.dispatch("site/getSite", {
      siteId: this.$store.state.account.user.siteId,
    });

    if (!isEmpty(this.address)) {
      const {
          city,
          country: countryCode,
          line1,
          postal_code,
          state,
        } = await this.address,
        countryFound = this.countries.find(
          (country) => country.code.toUpperCase() === countryCode
        );

      this.country = countryFound ? countryFound.name : "";
      this.line1 = line1;
      this.state = state;
      this.postalCode = postal_code;
      this.city = city;
    }
  },

  methods: {
    close() {
      this.$emit("close");
    },

    async updateSite() {
      await this.$store.dispatch("site/updateSite", {
        siteId: this.site.site._id,
        dataToUpdate: {
          address: {
            country: this.countries
              .find((country) => country.name === this.country)
              .code.toUpperCase(),
            line1: this.line1,
            state: this.state,
            postal_code: this.postalCode,
            city: this.city,
          },
        },
      });
      this.$emit("close");
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("shared.toastMessage.addressUpdated"),
        type: "success",
      });
    },
  },
};
</script>

<style lang="scss">
.vs--searchable .vs__dropdown-toggle {
  height: 27px;
}

.vs__dropdown-option {
  color: $dark-grey !important;
}

.vs__dropdown-option--highlight {
  background-color: $light-pink !important;
  @include base-font;
}

#country {
  width: 60%;
  float: right;
}

.address {
  .vs__dropdown-menu {
    height: 200px;
  }
  .vs__selected {
    font-size: 14px;
  }
  .vs__dropdown-option {
    font-size: 14px;
  }
}
</style>

<style lang="scss" scoped>
@import "vue-select/src/scss/vue-select.scss";
$banner-purple: rgba(123, 1, 181, 1);
$banner-background-purple: rgba(123, 1, 181, 0.15);

.title-subtitle-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.card-title {
  @include font-big;
  font-weight: 600;
  text-align: center;
  color: #585858;
}

.card-subtitle {
  @include font-normal;
  font-weight: 400;
  margin-bottom: 24px;
}

.banner {
  width: 440px;
  min-height: 100px;
  background-color: $banner-background-purple;
  border-radius: 2px;
  border-left: 3px solid $banner-purple;
  position: relative;
  margin-bottom: 24px;
}

.banner-information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin: 16px 16px 0 40px;
}

.info-icon {
  position: absolute;
  top: 16px;
  left: 16px;
  transform: scale(130%);
}

.banner-title {
  @include font-normal;
  font-weight: 600;
  color: $banner-purple;
}

.banner-text {
  color: $banner-purple;
  @include font-normal;
}

.address-form {
  margin: 0 auto;
  height: 170px;
  min-width: 450px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 40px;
}

.zip-and-city {
  display: flex;
  justify-content: space-around;
  gap: 16px;
}

.address-label {
  vertical-align: -webkit-baseline-middle;
  @include font-normal;
  .country {
    line-height: 34px;
  }
}

.address-label.align {
  align-self: center;
}

#street,
#state-region {
  @include base-font;
  @include font-normal;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3px;
  margin-left: 4px;
  width: 60%;
  float: right;
  padding: 3px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 1px rgba(226, 124, 252, 0.7);
  }
}

#zipcode,
#city {
  @include base-font;
  @include font-normal;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3px;
  margin-left: 4px;
  width: 50%;
  height: 30px;
  padding: 3px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 1px rgba(226, 124, 252, 0.7);
  }
}

/*save and back button*/
.buttons {
  display: flex;
  justify-content: center;
  gap: 80px;
}

.flex-button {
  display: flex;
  align-items: center;
  gap: 8px;
}

.submit-button {
  @include base-font;
  @include font-small;
  font-weight: 500;
  background: transparent;
  border: none;
}

@include media(">tablet", "<=desktop") {
  .address-form {
    width: 100%;
  }
}

@include media(">phone", "<=tablet") {
  .card-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    @include font-normal;
  }

  .address-label,
  #street,
  #state-region,
  #zipcode,
  #city {
    @include font-small;
  }

  #card-number {
    width: 60%;
  }

  .buttons {
    margin-top: 16px;
  }
}

@include media("<=phone") {
  .card-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    @include font-normal;
  }

  .address-form {
    margin-bottom: 8px;
  }

  .address-label,
  #street,
  #state-region,
  #zipcode,
  #city {
    @include font-smaller;
  }
}
</style>
