<template>
  <div v-click-outside="closeOptions" class="search-bar">
    <input
      v-model="searchTerm"
      type="text"
      :placeholder="getPlaceholder()"
      @click="openOptions()"
    />
    <magnifying-glass class="search-icon"></magnifying-glass>
    <div
      v-if="showOptions && filteredItems && filteredItems.length > 0"
      class="options-search-items"
    >
      <div
        v-for="item in filteredItems"
        :key="item._id"
        class="option-search-item"
        @click="emitSearchToParent(item._id)"
      >
        <p>{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import MagnifyingGlass from "@/assets/svg/magnifying-glass.svg?inline";
import { normalizeString } from "@/utils/stringNormalize";

export default {
  components: {
    MagnifyingGlass,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: () => [],
    },
  },
  data() {
    return {
      searchTerm: "",
      showOptions: false,
    };
  },
  computed: {
    filteredItems() {
      if (!this.searchTerm || this.searchTerm.length < 2) return [];
      const normalizedSearchTerm = normalizeString(
        this.searchTerm.toLowerCase()
      );
      return this.items.filter((item) => {
        const itemName =
          item.name && typeof item.name === "string"
            ? normalizeString(item.name.toLowerCase())
            : "";
        return itemName.includes(normalizedSearchTerm);
      });
    },
  },
  methods: {
    emitSearchToParent(itemId) {
      this.searchTerm = "";
      this.$emit("search", { type: this.type, _id: itemId });
    },
    openOptions() {
      this.showOptions = true;
    },
    closeOptions() {
      this.showOptions = false;
    },
    getPlaceholder() {
      return `${this.$t("campaignManager.search")} ${this.type}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-bar {
  position: relative;
  width: 33%;

  input {
    padding: 3px 8px 3px 8px;
  }
}
.search-icon {
  position: absolute;
  right: 4px;
  top: 4px;
  transform: scale(85%);
}
input {
  width: 100%;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 12%);
  @include input-style;
}
.options-search-items {
  margin-top: 1px;
  border: 1px solid $light-grey;
  border-radius: 4px;
  position: absolute;
  z-index: 1000;
  background: white;
  width: 100%;
}

.option-search-item {
  cursor: pointer;
  @include base-font;
  @include font-smaller;
  color: $light-grey;
  padding: 4px 10px;
  &:hover {
    background: $settings-hover;
    color: $dark-grey;
  }
}
</style>
