<template>
  <div class="banner-position">
    <banner-locked v-if="bannerLocked" :warning-icon="true">
      <template #text="{ reason }">
        <h3>
          {{ reason }}
        </h3>
      </template>
      <template #button="{ buttonText }">
        {{ buttonText }}
      </template>
    </banner-locked>
    <div class="main-content-wrapper">
      <h1 class="settings">{{ $t("account.title") }}</h1>
      <nav id="nav">
        <router-link class="button-primary link" to="/settings/account">
          <AccountIcon class="tab-icon" />
          {{ $t("account.nav.account") }}
        </router-link>
        <router-link class="button-primary link" to="/settings/billing">
          <CreditCardIcon class="tab-icon" />
          {{ $t("account.nav.billing") }}
        </router-link>
        <router-link class="button-primary link" to="/settings/integration">
          <WrenchIcon class="tab-icon" />
          {{ $t("account.nav.integration") }}
        </router-link>
        <router-link class="button-primary link" to="/settings/users">
          <PeopleIcon class="tab-icon" />
          {{ $t("account.nav.users") }}
        </router-link>
      </nav>
      <router-view />
      <spinner-loader v-if="isLoading" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import BannerLocked from "../shared/BannerLocked.vue";
import SpinnerLoader from "../shared/SpinnerLoader.vue";
import AccountIcon from "@/assets/svg/account-icon.svg?inline";
import WrenchIcon from "@/assets/svg/wrench-icon.svg?inline";
import CreditCardIcon from "@/assets/svg/credit-card-icon.svg?inline";
import PeopleIcon from "@/assets/svg/people-icon.svg?inline";
export default {
  components: {
    BannerLocked,
    SpinnerLoader,
    AccountIcon,
    WrenchIcon,
    CreditCardIcon,
    PeopleIcon,
  },

  data() {
    return {
      isLoading: true,
    };
  },

  computed: {
    ...mapState({ plan: "plan" }),
    ...mapGetters({
      userIsTrial: "plan/userIsTrial",
      bannerLocked: "plan/bannerLocked",
      currentPlan: "plan/currentPlan"
    }),
  },

  async created() {
    await this.$store.dispatch("plan/getPlan", {
      siteId: this.$store.state.account.user.siteId,
    });
    await this.$store.dispatch("user/getUser", {
      userId: this.$store.state.account.user._id,
    });
    await this.$store.dispatch("site/getSite", {
      siteId: this.$store.state.account.user.siteId,
    });
    await this.$store.dispatch("user/getUsers", {
      siteId: this.$store.state.account.user.siteId,
    });
    await this.$store.dispatch("invoices/getInvoices", {
      siteId: this.$store.state.account.user.siteId,
    });
    this.isLoading = false;

    //If we do not have a plan and not in FT, no need for calculation
    if (!this.userIsTrial && !this.currentPlan) {
      return
    }

    let fromDate, toDate;


    if (this.userIsTrial) {
      toDate = this.$store.state.plan.plan.trialEndDate

      fromDate = new Date(this.$store.state.plan.plan.creationDate).setDate(
        new Date(this.$store.state.plan.plan.creationDate).getDate() - 14
      )
    } else {
      fromDate = this.$store.state.plan.plan.billingStartDate
        ? this.$store.state.plan.plan.billingCycleStartDate
        : this.$store.state.plan.plan.freemiumCycleStartDate

      toDate = this.$store.state.plan.plan.billingStartDate
        ? this.$store.state.plan.plan.billingCycleEndDate
        : this.$store.state.plan.plan.freemiumCycleEndDate;
    }

    await this.$store.dispatch("usage/getUsage", {
      siteId: this.$store.state.account.user.siteId,
      fromDate: new Date(fromDate).toISOString(),
      toDate: new Date(toDate).toISOString(),
    });

    await this.$store.dispatch("video/getVideoLibrary", {
      siteId: this.$store.state.account.user.siteId,
      query: {
        $or: [{ isPremade: { $exists: false } }, { isPremade: false }],
        $and: [
          {
            fileName: { $exists: true },
            height: { $exists: true },
            width: { $exists: true },
            videoDuration: { $exists: true },
            url: { $not: { $regex: "blob:" } },
          },
        ]
      },
    });
  },
};
</script>

<style lang="scss" scoped>
#nav {
  margin-top: 32px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: $dark-purple;
  height: 72px;
  border-radius: 12px;
}

.button-primary.link {
  text-decoration: none;
  @include font-normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.button-primary {
  background-color: $dark-purple;
  color: $light-grey;
  .tab-icon {
    path {
      fill: $light-grey;
    }
  }
}

.router-link-active {
  background-color: white;
  color: $dark-purple;
  .tab-icon {
    path {
      fill: $dark-purple;
    }
  }
}

@include media("<=phone") {
  .button-primary.link {
    width: 80px;
    height: 25px;
    @include font-small;
    line-height: 25px;
    text-align: center;
    padding: 4px;
  }

  .main-content-wrapper {
    margin: 0 auto;
    margin-left: 73px;
  }
}

@include media(">tablet", "<=desktop") {
  #nav {
    flex-wrap: wrap;
  }

  .button-primary.link {
    width: 25vw;
    height: 4vh;
    max-width: 100px;
    @include font-normal;
    padding: 5px;
    line-height: 4vh;
    text-align: center;
    justify-content: space-between;
  }

  .main-content-wrapper {
    margin-right: 16px;
    margin-left: calc(16px + 73px);
  }
}

@include media(">phone", "<=tablet") {
  .button-primary.link {
    width: 25vw;
    height: 4vh;
    max-width: 100px;
    @include font-small;
    line-height: 4vh;
    text-align: center;
    justify-content: space-between;
  }

  .main-content-wrapper {
    margin-right: 16px;
    margin-left: calc(16px + 73px);
  }
}
</style>
