var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"url-segmentation"},[_c('div',{staticClass:"page-display-input"},[_c('div',{staticClass:"and-or-button add-remove"},[_c('p',{staticClass:"and-or-text",on:{"click":_vm.toggleInclusion}},[_vm._v(" "+_vm._s(_vm.segmentation.inclusion.length ? _vm.$t("shared.urlSegmentationComponent.removeRule") : _vm.$t("shared.urlSegmentationComponent.addRule"))+" ")]),_c('img',{directives:[{name:"tippy",rawName:"v-tippy",value:({
          arrow: true,
          placement: 'right',
          a11y: false,
          maxWidth: 450,
          boundary: 'window',
        }),expression:"{\n          arrow: true,\n          placement: 'right',\n          a11y: false,\n          maxWidth: 450,\n          boundary: 'window',\n        }"}],staticClass:"tooltip-icon",attrs:{"src":require("@/assets/usecases_cards/tooltip-icon.png"),"alt":"tooltip-icon","content":_vm.$t('shared.urlSegmentationComponent.tooltip')}})])]),_vm._l((_vm.ruleTypes),function(ruleType){return _c('div',{key:ruleType.id,staticClass:"iteration-container"},[(ruleType === 'exclusion' && _vm.showExclusionButton)?_c('div',{staticClass:"and-or-button exclusion-btn"},[_c('p',{staticClass:"and-or-text",on:{"click":function($event){return _vm.toggleExclusion()}}},[_vm._v(" "+_vm._s(_vm.segmentation.exclusion.length > 0 ? _vm.$t("shared.urlSegmentationComponent.removeExclusions") : _vm.$t("shared.urlSegmentationComponent.addExclusion"))+" ")])]):_vm._e(),(_vm.segmentation[ruleType].length > 0)?_c('ul',{staticClass:"url-list"},[_c('section',_vm._l((_vm.segmentation[ruleType]),function(filter,index){return _c('li',{key:filter.id,staticClass:"segmentation-list-item"},[_c('label',{staticClass:"url-label",attrs:{"for":"comparisonType"}},[_vm._v(_vm._s(_vm.$t("shared.urlSegmentationComponent.URL")))]),_c('v-select',{staticClass:"vidjet-single-select",attrs:{"searchable":false,"clearable":false,"label":"name","options":_vm.comparisonOptions[ruleType],"reduce":(options) => options.value},on:{"input":function($event){return _vm.handleComparisonString()}},model:{value:(filter.comparison),callback:function ($$v) {_vm.$set(filter, "comparison", $$v)},expression:"filter.comparison"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(filter.string),expression:"filter.string"},{name:"tippy",rawName:"v-tippy",value:({
              placement: 'top',
              arrow: true,
              maxWidth: 450,
              trigger: filter.string.length < 50 ? 'manual' : 'mouseenter',
            }),expression:"{\n              placement: 'top',\n              arrow: true,\n              maxWidth: 450,\n              trigger: filter.string.length < 50 ? 'manual' : 'mouseenter',\n            }"}],ref:"input",refInFor:true,staticClass:"text-input --with-borders url-input",class:{ 'long-url': filter.string.length >= 50 },attrs:{"id":"url","content":filter.string,"type":"text","placeholder":_vm.$t('shared.urlSegmentationComponent.placeholder')},domProps:{"value":(filter.string)},on:{"blur":function($event){return _vm.handleComparisonString()},"input":function($event){if($event.target.composing)return;_vm.$set(filter, "string", $event.target.value)}}}),(index + 1 === _vm.segmentation[ruleType].length)?_c('button',{staticClass:"and-or-button",on:{"click":function($event){return _vm.addRule(ruleType)}}},[_vm._v(" "+_vm._s(_vm.$t("shared.urlSegmentationComponent.addURL"))+" ")]):_c('div'),(ruleType === 'inclusion' ? _vm.showButtonRemoveUrl : true)?_c('button',{staticClass:"close-button",on:{"click":function($event){return _vm.removeUrlSegmentation(ruleType, index)}}},[_c('CloseIcon')],1):_vm._e()],1)}),0)]):_vm._e()])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }