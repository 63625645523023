import enums from "@/enums";

const { FormatType } = enums;

let targetedPlayer =
    process.env.VUE_APP_API_URL === "https://dev-app-api.vidjet.io"
      ? "dev-player.vidjet.io"
      : "player.vidjet.io",
  campaign,
  ratio;

const storyEmbedCode = () => {
  const iframeCodeCarousel = new String(`
    <div class="vidjet-carousel-wrapper"
      style="position: relative;
      overflow: hidden;
      height: 140px;
      margin: 0px auto;">
      <iframe
        class="vidjet-embed-iframe"
        data-vidjet-campaign-id="${campaign._id}"
        src="https://${targetedPlayer}/${campaign.siteId}/${campaign._id}"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
        allow="clipboard-read; clipboard-write; fullscreen"
        frameborder="0">
      </iframe>
    </div>`);

  return iframeCodeCarousel.replace(/(\s+)/gm, " ");
};

const carouselEmbedCode = () => {
  const iframeCodeCarousel = new String(`
    <div class="vidjet-carousel-wrapper"
      style="padding-bottom: 65%;
      position: relative;
      overflow: hidden;
      height: auto;
      margin: 0px auto;">
      <iframe
        class="vidjet-embed-iframe"
        data-vidjet-campaign-id="${campaign._id}"
        src="https://${targetedPlayer}/${campaign.siteId}/${campaign._id}"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
        allow="clipboard-read; clipboard-write; fullscreen"
        frameborder="0">
      </iframe>
    </div>`);
  return iframeCodeCarousel.replace(/(\s+)/gm, " ");
};

const carouselInlineEmbedCode = () => {
  const iframeCodeCarousel = new String(`
    <div 
      class="vidjet-carousel-wrapper ${
        campaign?.formats[0].isFullScreen ? "" : "carousel-inline"
      }"
      style="max-height: 445px;
      margin: 0 16px;
      height: 100vw;
      position: relative;
      overflow: hidden;">
      <iframe
        class="vidjet-embed-iframe"
        data-vidjet-campaign-id="${campaign._id}"
        src="https://${targetedPlayer}/${campaign.siteId}/${campaign._id}"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
        allow="clipboard-read; clipboard-write; fullscreen"
        frameborder="0">
      </iframe>
    </div>`);
  return iframeCodeCarousel.replace(/(\s+)/gm, " ");
};

const responsiveEmbedCode = () => {
  const iframeCodeResponsive = new String(`
    <div
      class="vidjet-video-wrapper"
      style="
        padding-bottom: ${ratio}%;
        position: relative;
        overflow: hidden;
        height: auto;
        margin: 0px auto;
      "
    >
      <iframe
        class="vidjet-embed-iframe"
        data-vidjet-campaign-id="${campaign._id}"
        src="https://${targetedPlayer}/${campaign.siteId}/${campaign._id}"
        style="
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: ${String(campaign.formats[0].cornerShape ?? 10)}px;
        "
        allow="clipboard-read; clipboard-write; fullscreen"
        frameborder="0"
      ></iframe>
    </div>`);
  return iframeCodeResponsive.replace(/(\s+)/gm, " ");
};

const staticEmbedCode = () => {
  const iframeCodeStatic = new String(`
    <div
      class="vidjet-video-wrapper"
      style="
        position: relative;
        overflow: hidden;
        height: ${campaign.formats[0].embedSize?.height ?? 0}px;
        width: ${campaign.formats[0].embedSize?.width ?? 0}px;
        margin: 0px auto;
      "
    >
      <iframe
        class="vidjet-embed-iframe"
        data-vidjet-campaign-id="${campaign._id}"
        src="https://${targetedPlayer}/${campaign.siteId}/${campaign._id}"
        style="
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: ${String(campaign.formats[0].cornerShape ?? 10)}px;
        "
        allow="clipboard-read; clipboard-write; fullscreen"
        frameborder="0"
      ></iframe>
    </div>`);
  return iframeCodeStatic.replace(/(\s+)/gm, " ");
};

export default (currentCampaign) => {
  campaign = currentCampaign;
  ratio = currentCampaign.bulk
    ? (campaign.bulk[0]?.videos[0]?.height /
        campaign.bulk[0]?.videos[0]?.width) *
      100
    : (campaign.videos[0].height / campaign.videos[0].width) * 100;

  let format = campaign?.formats[0];

  if (
    format &&
    format &&
    format.formatType === FormatType.carousel &&
    format.isStory
  ) {
    return storyEmbedCode();
  } else if (
    format &&
    format &&
    format.formatType === FormatType.carousel &&
    format.isFullScreen &&
    format.carouselThumbnailAmount !== 1
  ) {
    return carouselEmbedCode();
  } else if (
    format &&
    format &&
    format.formatType === FormatType.carousel &&
    (!format.isFullScreen || format.carouselThumbnailAmount === 1)
  ) {
    return carouselInlineEmbedCode();
  } else if (format.responsive) {
    return responsiveEmbedCode();
  }
  return staticEmbedCode();
};
