export default {
  yellow: [
    "rgba(227, 181, 38, 0)",
    "rgba(227, 181, 38, 0.2)",
    "rgba(227, 181, 38, 0.4)",
  ],
  green: [
    "rgba(14, 186, 12, 0)",
    "rgba(14, 186, 12, 0.2)",
    "rgba(14, 186, 12, 0.4)",
  ],
  blue: [
    "rgba(0, 48, 211, 0)",
    "rgba(0, 48, 211, 0.2)",
    "rgba(0, 48, 211, 0.4)",
  ],
  red: [
    "rgba(181, 3, 14, 0)",
    "rgba(181, 3, 14, 0.2)",
    "rgba(181, 3, 14, 0.4)",
  ],
  purple: [
    "rgba(123, 1, 181, 0)",
    "rgba(123, 1, 181, 0.2)",
    "rgba(123, 1, 181, 0.4)",
  ],
  darkBlue: ["rgba(64,0,129, 0)", "rgba(64,0,129, 0.2)", "rgba(64,0,129, 0.4)"],
  darkPurlple: [
    "rgba(110, 21, 186, 0)",
    "rgba(110, 21, 186, 0.2)",
    "rgba(110, 21, 186, 0.4)",
  ],
  lightPink: [
    "rgba(163, 40, 193, 0)",
    "rgba(163, 40, 193, 0.2)",
    "rgba(163, 40, 193, 0.4)",
  ],
};
