<template>
  <div v-if="carousel && carousel.length > 0" class="carousel">
    <draggable
      v-model="localCarousel"
      class="cards-wrapper"
      :options="draggableOptions"
      @change="changeOrder"
    >
      <div
        v-for="(video, index) in localCarousel"
        :id="'video-' + index"
        :key="index"
        class="video-card"
      >
        <div
          class="main-img-container"
          :style="`background-image: url('${video.thumbnail || video.img}');`"
          @click="emitCarouselVideoSelected(video)"
        ></div>
        <div class="actions">
          <div class="drag-block">
            <DragDropIcon v-if="!hasThumbnailGrouping" />
            <input
              v-if="!hasThumbnailGrouping"
              class="editable-index"
              type="text"
              :value="index + 1"
              @change="changeCarouselOrderByInput(index, $event)"
            />
            <span v-else>{{ index + 1 }}</span>
          </div>
          <button
            class="delete-button"
            @click="emitCarouselVideoDeleted(index)"
          >
            <DeleteIcon class="trash-icon" />
          </button>
        </div>
      </div>
    </draggable>
    <div class="btn-group">
      <button class="btn" @click="scrollLeft">
        <BtnArrowBack />
      </button>
      <button class="btn" @click="scrollRight">
        <BtnArrowNext />
      </button>
    </div>
  </div>
</template>

<script>
import BtnArrowNext from "@/assets/svg/btn-arrow-next.svg?inline";
import BtnArrowBack from "@/assets/svg/btn-arrow-back.svg?inline";
import DeleteIcon from "@/assets/svg/delete-small.svg?inline";
import Draggable from "vuedraggable";
import DragDropIcon from "@/assets/svg/drag-drop-icon-small.svg?inline";

export default {
  name: "VideoCarouselPreview",
  components: {
    BtnArrowNext,
    BtnArrowBack,
    DeleteIcon,
    Draggable,
    DragDropIcon,
  },
  props: {
    carousel: { type: Array, default: () => [] },
  },
  data() {
    return {
      localCarousel: [],
    };
  },
  computed: {
    hasThumbnailGrouping() {
      return !!this.localCarousel[0]?.thumbnailGrouping;
    },
    draggableOptions() {
      return {
        handle: ".drag-block",
        disabled: this.hasThumbnailGrouping,
      };
    },
  },
  watch: {
    carousel() {
      this.localCarousel = [...this.carousel];
      this.scrollToLastVideo();
    },
  },
  created() {
    this.localCarousel = [...this.carousel];
  },
  methods: {
    emitCarouselVideoSelected(video) {
      this.$emit("carousel-video-selected", video);
    },
    emitCarouselVideoDeleted(index) {
      this.$emit("carousel-video-deleted", index);
    },
    scrollLeft() {
      let content = document.querySelector(".cards-wrapper");
      content.scrollLeft -= 150;
    },
    scrollRight() {
      let content = document.querySelector(".cards-wrapper");
      content.scrollLeft += 150;
    },
    scrollToLastVideo() {
      this.$nextTick(() => {
        if (!this.localCarousel.length) return;
        var video = this.$el.querySelector(
          "#video-" + (this.localCarousel.length - 1)
        );
        if (video) video.scrollIntoView({ behavior: "smooth" });
      });
    },
    changeOrder() {
      this.$emit("carousel-video-reorder", this.localCarousel);
    },
    changeCarouselOrderByInput(oldIndex, event) {
      const newIndex = event.target.value - 1;
      const removedElement = this.localCarousel.splice(oldIndex, 1);
      this.localCarousel.splice(newIndex, 0, removedElement[0]);
      this.changeOrder();
    },
  },
};
</script>

<style scoped lang="scss">
.carousel {
  max-width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  margin-bottom: 8px;
}
.cards-wrapper {
  display: inline-flex;
  overflow: hidden;
  gap: 12px;
  max-width: 97%;
  scroll-behavior: smooth;
  margin-bottom: 16px;
}

.video-card {
  margin: 0 auto;
  display: grid;
  background-color: white;
  border-radius: 3px;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.12);
  justify-items: center;
  &:hover {
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
  }
  .main-img-container {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: black;
    text-align: center;
    cursor: pointer;
    height: 58px;
    width: 113px;
    border-radius: 2px;
    margin: 8px 8px 2px 8px;
  }
  .main-img {
    cursor: pointer;
    height: 58px;
    width: 113px;
    border-radius: 10px;
    padding: 8px 8px 2px 8px;
  }
}
.btn-group {
  display: flex;
  width: 30%;
  margin: 0 auto;
  justify-content: center;
}
.btn {
  background: transparent;
}

.actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.drag-block {
  font-family: "Yeon Sung", "sans-serif";
  font-size: 24px;
  color: #757575;
  display: flex;
  align-items: center;
  margin: 4px;
  margin-left: 9px;
  gap: 5px;
  cursor: pointer;
}

.delete-button {
  cursor: pointer;
  background-color: white;
  padding: 6px 9px;
  max-width: 100px;
  border-radius: 2px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
  margin: 4px;
  margin-right: 9px;
  &:hover {
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
  }
}
.trash-icon {
  transform: scale(150%);
  path {
    fill: $dark-grey;
    fill-opacity: 1;
  }
}
.editable-index {
  font-family: "Yeon Sung", "sans-serif";
  font-size: 24px;
  color: #757575;
  text-align: start;
  max-width: 52px;
  border: 0;
  background: transparent;
}
</style>
