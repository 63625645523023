<template>
  <div
    class="dashboard-wrapper"
    :class="{ 'fixed-dashboard': showVideoLibrary }"
  >
    <banner-locked v-if="bannerLocked" :warning-icon="true">
      <template #text="{ reason }">
        <h3>
          {{ reason }}
        </h3>
      </template>
      <template #button="{ buttonText }">
        {{ buttonText }}
      </template>
    </banner-locked>
    <main class="main-content-wrapper">
      <vidjet-header
        class="title"
        :title="$t('dashboard.heading.title')"
        :show-create-button="true"
        :show-video-library-button="true"
        :video-library-creation="videoLibraryCreation"
        @show-video-library="showVideoLibrary = true"
        @close-video-library="closeVideoLibrary"
      />

      <section class="dashboard-filters">
        <div class="date-device-wrapper">
          <div class="date-device-filters">
            <dashboard-datepicker
              :first-init-done-prop="firstInitDone"
              :from-date-pick.sync="fromDate"
              :to-date-pick.sync="toDate"
              @dates-changed="updateData"
            />
          </div>
          <dashboard-device-selector
            :selected-device.sync="deviceSelectorPicked"
          />
        </div>
        <div class="campaign-select-wrapper">
          <v-select
            v-model="selectedCampaigns"
            class="campaign-select-input"
            :class="{ 'all-campaigns': isAllCampaignsSelected }"
            label="name"
            append-icon="unfold_more"
            append-outer-icon="unfold_more"
            :multiple="true"
            :filterable="true"
            :searchable="true"
            :clearable="true"
            :options="campaignOptions"
            :selectable="() => selectedCampaigns.length < 4"
            :close-on-select="false"
            :components="{ OpenIndicator, Deselect }"
            @input="handleInputChange"
          >
            <template slot="option" slot-scope="option">
              {{ option.name | truncate }}
              <CircleCheckedIcon class="circle-checked" />
            </template>
            <template #selected-option="{ name }">
              {{ name | truncate }}
            </template>
          </v-select>
          <p class="placeholder">
            {{ $t("dashboard.filters.campaignsSelector.placeholder") }}
          </p>
        </div>
      </section>
      <div
        v-show="
          dashboardMetricsLoading && chartMetricsLoading && tableMetricsLoading
        "
        class="frames-loader-wrapper"
      >
        <spinner-loader />
      </div>
      <dashboard-frames
        v-show="!dashboardMetricsLoading"
        :dashboard-data="dashboardGlobalData"
        :frames="frames"
        :currency="getCurrency"
        :selected-frame="selectedFrame"
        @selectedFrame="selectFrame($event)"
      />
      <section
        v-show="!chartMetricsLoading"
        class="chart-frame"
        :class="getChartColor"
      >
        <div class="chart-container">
          <canvas id="myChart"></canvas>
        </div>
        <div class="chart-bottom">
          <div class="metric-selector">
            <template v-for="(metric, index) in frames">
              <input
                :id="metric.chartValue"
                :key="metric.chartValue"
                v-model="metricSelectorPicked"
                :class="`button-radio ${chartColor}`"
                type="radio"
                name="metric-selector"
                :value="metric.chartValue"
              />
              <label
                :key="`${metric.chartName}-label`"
                :for="metric.chartValue"
                class="radio-label"
                @click="selectFrame(index)"
                >{{ $t(`${metric.chartName}`) }}</label
              >
            </template>
          </div>
        </div>
      </section>
      <section
        v-if="!tableMetricsLoading && !isAgency"
        class="data-table-frame"
      >
        <DashboardTable
          :data="dataTable.campaigns"
          @video-library-opened="showVideoLibrary = true"
          @video-library-closed="showVideoLibrary = false"
        ></DashboardTable>
      </section>
      <section class="data-table-frame">
        <DashboardVideosTable
          :data="filteredVideos"
          @video-library-opened="showVideoLibrary = true"
          @video-library-closed="showVideoLibrary = false"
        />
      </section>
    </main>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Chart from "chart.js";
import enums from "@/enums";
import throttle from "lodash/throttle";
import debounce from "lodash/debounce";
import CircleCheckedIcon from "@/assets/svg/dashboard/circle_checked.svg?inline";
import Deselect from "@/components/Deselect";
import BannerLocked from "@/components/shared/BannerLocked.vue";
import DashboardDatepicker from "@/components/dashboard/DashboardDatepicker.vue";
import DashboardDeviceSelector from "@/components/dashboard/DashboardDeviceSelector.vue";
import DashboardFrames from "@/components/dashboard/DashboardFrames";
import createGradients from "@/components/dashboard/createGradients";
import chartConfig from "@/components/dashboard/chartConfig";
import SpinnerLoader from "../shared/SpinnerLoader.vue";
import endOfToday from "date-fns/end_of_today";
import isEqual from "date-fns/is_equal";
import OpenIndicator from "@/assets/svg/magnifying-glass.svg?inline";
import DashboardTable from "./DashboardTable";
import metricFrames from "../../utils/metric-frames.js";
import VidjetHeader from "@/components/shared/Vidjet-header";
import DashboardVideosTable from "./DashboardVideosTable";

const { StateFlag } = enums;

export default {
  name: "Dashboard",
  components: {
    CircleCheckedIcon,
    BannerLocked,
    DashboardDatepicker,
    DashboardDeviceSelector,
    DashboardFrames,
    SpinnerLoader,
    DashboardTable,
    VidjetHeader,
    DashboardVideosTable,
  },
  props: {
    isAgency: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fromDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      toDate: endOfToday(),
      frames: [],
      selectedCampaigns: this.isAgency
        ? [{ name: this.$t("agencyPortal.allDomains") }]
        : [
            {
              name: this.$t("dashboard.filters.campaignsSelector.allCampaigns"),
            },
          ],
      deviceSelectorPicked: "allDevices",
      metricSelectorPicked: "campaignInteracted",
      firstInitDone: false,
      chart: {},
      chartData: [],
      campaigns: [],
      queryMetrics: {},
      dashboardMetricsLoading: true,
      chartMetricsLoading: true,
      tableMetricsLoading: true,
      borderColors: ["#E3B526", "#0EBA0C", "#0030D3", "#B5030E", "#7B01B5"],
      gradientsArray: [],
      allCampaignsGradientObject: {},
      StateFlag,
      Deselect,
      OpenIndicator,
      selectedFrame: 1,
      chartColor: "darkPurple",
      graphColor: {
        darkBlue: "#400081",
        darkPurple: "#6e15ba",
        lightPink: "#a328c1",
      },
      showVideoLibrary: false,
      videoLibraryCreation: false,
    };
  },
  computed: {
    ...mapState(["plan", "site"]),
    ...mapGetters({
      bannerLocked: "plan/bannerLocked",
      getCurrency: "site/getCurrency",
    }),
    ...mapState({
      graphData: (state) => state.dashboard.dashboardGraphMetrics,
      dashboardGlobalData: (state) => state.dashboard.dashboardGlobalMetrics,
      dataTable: (state) => state.dashboard.dashboardCampaignMetrics,
    }),
    campaignOptions() {
      if (this.isAgency) {
        return this.site.agencySites.map((site) => ({
          name: site.websiteUrl,
          _id: site.siteId,
        }));
      }
      return [
        { name: this.$t("dashboard.filters.campaignsSelector.allCampaigns") },
        ...this.campaigns,
      ];
    },
    isAllCampaignsSelected() {
      if (this.isAgency) {
        return !this.selectedCampaigns.some(
          (domain) => domain.name !== this.$t("agencyPortal.allDomains")
        );
      } else {
        return Boolean(
          this.selectedCampaigns.filter(
            (campaign) =>
              campaign.name ===
              this.$t("dashboard.filters.campaignsSelector.allCampaigns")
          ).length
        );
      }
    },
    getChartColor() {
      return `is-selected-${this.chartColor}`;
    },

    userIsShopify() {
      return (
        this.site.site.integration === "shopify" && !this.site.site.payByCard
      );
    },
    filteredVideos() {
      if (!this.dataTable || !this.dataTable.videos) {
        return [];
      }

      const uniqueVideos = {};
      return this.dataTable.videos
        .filter((video) => !video.isPremade)
        .filter((video) => {
          if (uniqueVideos[video.fileName]) {
            return false;
          }
          uniqueVideos[video.fileName] = true;
          return true;
        });
    },
  },

  watch: {
    // Only the change of campaigns or device triggers a refetch
    async deviceSelectorPicked() {
      await this.throttledFetch();
      this.updateChart(this.metricSelectorPicked);
    },
    async metricSelectorPicked() {
      this.updateChart(this.metricSelectorPicked);
    },
    async selectedCampaigns() {
      await this.debouncedFetch();
      this.updateChart(this.metricSelectorPicked);
    },
  },

  async mounted() {
    // Retreive all campaign informations
    await this.getAllCampaigns();
    // The chart elements needs to be present before we fetch the data
    this.displayChart();
    await this.fetchData();
  },
  async created() {
    //Make sure to clean all campaign state so we do not have issue on new create/edit
    this.$store.commit("campaign/resetCampaignState");
    this.$store.commit("video/resetVideos");

    // We load the properties from the locale file
    this.frames = metricFrames;
    await this.$store.dispatch("plan/getPlan", {
      siteId: this.$store.state.account.user.siteId,
    });
    await this.$store.dispatch("site/getSite", {
      siteId: this.$store.state.account.user.siteId,
    });
    if (this.userIsShopify && this.dashboardGlobalData.totalSales) {
      this.selectFrame(0);
    }
    if (this.$route.query.action === "videoLibraryCreation") {
      this.videoLibraryCreation = true;
    }
  },

  methods: {
    handleInputChange() {
      if (this.isAgency) {
        // Handle agencySites logic
        this.selectedCampaigns = this.selectedCampaigns.filter(
          (site) => site.name !== this.$t("agencyPortal.allDomains")
        );

        // Ensure at least one site is selected
        if (!this.selectedCampaigns.length) {
          this.selectedCampaigns.push({
            name: this.$t("agencyPortal.allDomains"),
          });
        }
      } else {
        // Handle campaigns logic
        this.selectedCampaigns = this.selectedCampaigns.filter(
          (campaign) =>
            campaign.name !==
            this.$t("dashboard.filters.campaignsSelector.allCampaigns")
        );

        // Ensure at least one campaign is selected
        if (!this.selectedCampaigns.length) {
          this.selectedCampaigns.push({
            name: this.$t("dashboard.filters.campaignsSelector.allCampaigns"),
          });
        }
      }
    },
    // This just sets up the foundation of the chart, we update it when data
    // changes with updateChart().
    displayChart() {
      const ctx = document.getElementById("myChart").getContext("2d");

      this.gradientsArray = createGradients.singleCampaignGradient(ctx);
      this.allCampaignsGradientObject =
        createGradients.allCampaignsGradient(ctx);

      this.chart = new Chart(ctx, chartConfig(this.chartData));
    },

    getName(id) {
      return this.selectedCampaigns.find(
        (campaignObject) => campaignObject._id === id
      ).name;
    },

    updateChart(selectedChartMetric) {
      // To avoid problems with "this" in JS
      let campaignDataToDraw = this.graphData;
      if (!this.chart.data || !campaignDataToDraw) return;

      if (this.isAllCampaignsSelected) {
        // This are all chart.js properties, refer to documentation if in doubt
        this.chart.data.labels = getDays(campaignDataToDraw);

        this.chartData = {
          label: this.$t("dashboard.filters.campaignsSelector.allCampaigns"),
          data: this.getMetricArray(this.graphData, selectedChartMetric),
          borderColor: this.graphColor[this.chartColor],
          borderWidth: 1,
          fill: true,
          backgroundColor: this.allCampaignsGradientObject[this.chartColor],
          pointRadius: 3,
          pointHitRadius: 0,
          pointBackgroundColor: this.graphColor[this.chartColor],
        };
      } else {
        let selectedIds = this.selectedCampaigns.map(
          (campaign) => campaign._id
        );

        // For the agency we need to take the siteId
        this.chartData = selectedIds.map((id, index) => {
          let filteredById = campaignDataToDraw.filter((campaignData) =>
            this.isAgency
              ? campaignData.siteId === id
              : campaignData.campaignId === id
          );

          this.chart.data.labels = getDays(filteredById);
          return {
            label: `${this.getName(id)}`,
            data: this.getMetricArray(filteredById, selectedChartMetric),
            borderColor: this.borderColors[index],
            borderWidth: 1,
            fill: true,
            backgroundColor: this.gradientsArray[index],
            pointRadius: 3,
            pointHitRadius: 0,
            pointBackgroundColor: this.borderColors[index],
          };
        });
      }

      function updateViewTimeTicks() {
        return function (value, index, values) {
          return selectedChartMetric === "avgViewTime" ? `${value}s` : value;
        };
      }

      function updateViewTimeTooltip() {
        return function (tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].label || "";

          if (label) {
            label += ": ";
          }
          label += Math.round(tooltipItem.yLabel * 100) / 100;
          label += selectedChartMetric === "avgViewTime" ? "s" : "";
          return label;
        };
      }

      function getDays(campaignArray) {
        return campaignArray.map((e) => e.day);
      }

      this.isAllCampaignsSelected
        ? (this.chart.data.datasets[0] = this.chartData)
        : (this.chart.data.datasets = this.chartData);
      this.chart.options.scales.yAxes[0].ticks.callback = updateViewTimeTicks();
      this.chart.options.tooltips.callbacks.label = updateViewTimeTooltip();
      this.chart.update();
    },

    getMetricArray(inputArray, metric) {
      return inputArray.map((element) => element[metric]);
    },

    debouncedFetch: debounce(async function () {
      await this.fetchData();
    }, 1000),

    throttledFetch: throttle(async function () {
      await this.fetchData();
    }, 1000),

    async updateData(from, to) {
      this.fromDate = from;
      this.toDate = to;
      this.debouncedFetch();
    },

    async getAllCampaigns() {
      this.campaigns = [
        ...(await this.$store.dispatch("campaign/getCampaigns", {
          siteId: this.$store.state.account.user.siteId,
        })),
      ];
    },
    async fetchData() {
      try {
        this.chartMetricsLoading = true;
        this.dashboardMetricsLoading = true;
        this.tableMetricsLoading = true;

        // We need to add a day to the end date, so it includes the full day
        // Until 23:59 at night
        let fullDayToDate = new Date(this.toDate);

        // We only add the 23:59 if the user selects a date.
        // Because we have an endOfToday() helper that doesn't work when we
        // select a date manually due to timezone offset.
        if (!isEqual(fullDayToDate, endOfToday())) {
          fullDayToDate.setHours(fullDayToDate.getHours() + 23);
          fullDayToDate.setMinutes(fullDayToDate.getMinutes() + 59);
        }

        // Create the query metrics
        // We only send the ids as params when some campaigns or domains are selected
        const specificQueryMetrics = this.isAgency
          ? {
              ...(!this.isAllCampaignsSelected && {
                siteIds: this.selectedCampaigns.map((site) => site._id),
              }),
              agencyId: this.$store.state.account.user.siteId,
            }
          : {
              ...(!this.isAllCampaignsSelected && {
                campaignId: this.selectedCampaigns.map(
                  (campaign) => campaign._id
                ),
              }),
              siteId: this.$store.state.account.user.siteId,
            };

        this.queryMetrics = {
          ...(this.deviceSelectorPicked !== "allDevices" && {
            device: this.deviceSelectorPicked,
          }),
          fromDate: new Date(this.fromDate).toISOString(),
          toDate: fullDayToDate.toISOString(),
          ...specificQueryMetrics,
        };

        // Retrieve global metrics
        await this.$store.dispatch("dashboard/dashboardGlobalMetrics", {
          query: this.queryMetrics,
          isAgency: this.isAgency,
        });
        this.dashboardMetricsLoading = false;

        // Retrieve Graph metrics
        await this.$store.dispatch("dashboard/dashboardGraphMetrics", {
          query: this.queryMetrics,
          isAgency: this.isAgency,
        });
        this.chartMetricsLoading = false;

        // Retrieve campaigns metrics
        // We don't need it for the agency view
        if (!this.isAgency) {
          await this.$store.dispatch(
            "dashboard/dashboardCampaignMetrics",
            this.queryMetrics
          );
          this.tableMetricsLoading = false;
        }

        this.firstInitDone = true;
        this.updateChart(this.metricSelectorPicked);
      } catch (err) {
        console.log(err);
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: err,
          type: "error",
        });
      }
    },

    async goToEditCampaign(campaignId) {
      await this.$store.dispatch("campaign/setCampaignSelected", campaignId);
      this.$router.push({ name: "editCampaign" });
    },
    getStatus(campaign) {
      return campaign.stateFlag === StateFlag.active ? "LIVE" : "INACTIVE";
    },
    selectFrame(index) {
      this.selectedFrame = index;
      this.metricSelectorPicked = this.frames[index].chartValue;
      this.chartColor = this.frames[index].color;
    },
    closeVideoLibrary() {
      this.showVideoLibrary = false;
      this.videoLibraryCreation = false;
    },
  },
};
</script>

<style lang="scss">
/* We need this additional tag to override the Multiselect component default styles */
.asd__action-buttons {
  button {
    &:nth-of-type(2) {
      color: #40916c !important;
    }
  }
}
.campaign-select-input {
  &.all-campaigns {
    .vs__selected {
      &:nth-of-type(1) {
        @include selected($light-grey);
      }
    }
  }
}
.vs--open .vs__dropdown-toggle {
  border-bottom: 1px solid #c4c4c4 !important;
  border-radius: 3px !important;
}
.main-content-wrapper {
  margin-right: 30px;
  margin-left: 100px;
}
.campaign-select-input {
  .vs__search::placeholder,
  .vs__dropdown-toggle,
  .vs__dropdown-menu {
    background: white;
    color: $light-grey;
    border: 1px solid $light-grey;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    border-radius: 3px;
    @include base-font;
    @include font-small;
  }

  .vs__dropdown-menu {
    position: absolute !important;
  }
  .vs__dropdown-toggle {
    height: 36px;
    position: relative;
  }
  .vs__actions {
    position: absolute;
    right: 0;
    top: 5px;
  }
  .vs__selected-options {
    @include media("<tablet") {
      left: 0;
      top: 32px;
    }
  }
  .vs__selected {
    @include font-smaller;
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 1px 3px;
    svg {
      path {
        fill: inherit;
      }
    }
    &:nth-of-type(1) {
      @include selected($yellow);
    }
    &:nth-of-type(2) {
      @include selected($green);
    }
    &:nth-of-type(3) {
      @include selected($blue);
    }
    &:nth-of-type(4) {
      @include selected($red);
    }
  }
  .vs__dropdown-menu {
    margin-top: 8px;
    padding: 12px 4px;
    .vs__dropdown-option {
      @include base-font;
      @include font-smaller;
      color: $light-grey;
      border-radius: 4px;
      padding: 4px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .circle-checked {
        opacity: 0;
        color: $dark-purple;
      }
      &--highlight {
        background: rgba(226, 124, 252, 0.1);
      }
      &--selected {
        .circle-checked {
          opacity: 1;
        }
      }
    }
  }
  .vs__deselect {
    margin-left: 7px;
    svg {
      height: 14px;
      width: 14px;
    }
  }
  .vs__open-indicator {
    transform: none !important;
  }
}
.placeholder {
  @include font-small;
  color: $light-grey;
  margin-top: 3px;
}
</style>
<style lang="scss" scoped>
.fixed-dashboard {
  position: fixed;
}
.frames-loader-wrapper {
  position: relative;
  width: 100%;
  min-height: 500px;
}

svg {
  path {
    fill: currentColor;
  }
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  .button-primary {
    &.create-button {
      position: absolute;
      right: 5%;
      background-color: $dark-purple;
      color: white;
      z-index: 1;
      &:hover {
        background: $light-pink;
        color: black;
      }
      @include media("<=tablet") {
        display: none;
      }
    }
  }
}
.dashboard-filters {
  display: flex;
  @include media("<=desktop") {
    display: block;
  }
  .campaign-select-wrapper {
    margin-left: 50px;
    min-width: 50%;
    width: fit-content;
    @include media("<=desktop") {
      margin: 32px 0;
    }
  }
}

.chart-frame {
  @include frame;
  margin-bottom: 32px;
  .chart-selectors {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 17px;
    margin-bottom: 8px;
    margin-left: 120px;
  }
  .stacked-average-selector {
    .radio-label:first-of-type {
      padding-right: 18px;
    }
  }
  .chart-bottom {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 14px;

    .metric-selector {
      @include flex-centered;
      .button-radio {
        @include font-supersmall;
        margin-right: 35px;
        & + *::before {
          font-weight: 600;
          background: $light-grey;
          border-color: $light-grey;
          width: 5px;
          height: 5px;
        }
        &.darkPurple:checked + *::before {
          background: $graph-purple;
          border-color: $graph-purple;
        }
        &.darkBlue:checked + *::before {
          background: $graph-blue;
          border-color: $graph-blue;
        }
        &.lightPink:checked + *::before {
          background: $graph-pink;
          border-color: $graph-pink;
        }

        &.darkPurple:checked + * {
          color: $graph-purple;
        }
        &.darkBlue:checked + * {
          color: $graph-blue;
        }
        &.lightPink:checked + * {
          color: $graph-pink;
        }
      }
      .radio-label {
        font-weight: 600;
        line-height: 7px;
        margin-right: 42px;
        @include media(">=tablet", "<desktop") {
          margin-right: 22px;
        }
        @include media("<tablet") {
          margin-right: 0px;
        }
      }
    }
  }
  .chart-container {
    height: 300px;
    width: 100%;
    @include media("<desktop") {
      height: 300px;
    }
  }
}
.is-selected-darkBlue {
  border: 6px solid $graph-blue;
}
.is-selected-darkPurple {
  border: 6px solid $graph-purple;
}
.is-selected-lightPink {
  border: 6px solid $graph-pink;
}
.search-icon {
  align-self: flex-end;
}
.data-table-frame {
  @include frame;
  padding: 20px;
  margin-bottom: 40px;
}
</style>
