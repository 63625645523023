<template>
  <div class="conversions-block">
    <div v-if="dashboardData.totalSalesCount" class="conversions">
      <p>
        😍
        {{ dashboardData.totalSalesCount }}
        {{ $t("billing.conversionsBlock.title1") }} ({{ getCurrency }}
        {{ dashboardData.totalSales | round }})
        {{ $t("billing.conversionsBlock.title2") }}
      </p>
    </div>
    <div v-else class="no-conversions">
      <p>
        🤔
        {{ $t("billing.conversionsBlock.noConversions1") }}
        <a
          href="https://help.vidjet.io/en/articles/5568648-how-to-send-orders-and-conversions"
          target="_blank"
          >{{ $t("billing.conversionsBlock.linkText") }}</a
        >
        {{ $t("billing.conversionsBlock.noConversions2") }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import endOfToday from "date-fns/end_of_today";

export default {
  name: "ConversionBlock",
  computed: {
    ...mapGetters({
      getCurrency: "site/getCurrency",
      siteId: "account/getSiteId"
    }),
    ...mapState({
      dashboardData: (state) => state.dashboard.dashboardGlobalMetrics,
    }),
  },
  created() {
    this.getDashboardGlobalMetrics();
  },
  methods: {
    async getDashboardGlobalMetrics() {
      await this.$store.dispatch("dashboard/dashboardGlobalMetrics", { 
        query: {
          fromDate: this.getLast30DaysDate(),
          toDate: endOfToday(),
          siteId: this.siteId,
        }
      });
    },
    getLast30DaysDate() {
      var todayDate = new Date(),
        fromDate = new Date();
      fromDate.setTime(todayDate.getTime() - 30 * 24 * 3600000);
      return fromDate;
    },
  },
};
</script>

<style lang="scss" scoped>
.conversions-block {
  font-weight: 500;
  text-align: center;
  margin-top: 16px;
  padding-top: 4px;
}
.no-conversions,
.conversions {
  font-size: 12px;
  display: inline-flex;
  .icon {
    height: 14px;
    margin-right: 5px;
  }
}
</style>
