import gradients from "@/utils/gradients";

const singleCampaignGradient = (ctx) => {
  const gradientFillYellow = ctx.createLinearGradient(0, 300, 0, 50);
  gradientFillYellow.addColorStop(0, gradients.yellow[0]);
  gradientFillYellow.addColorStop(0.5, gradients.yellow[1]);
  gradientFillYellow.addColorStop(1, gradients.yellow[2]);

  const gradientFillGreen = ctx.createLinearGradient(0, 300, 0, 50);
  gradientFillGreen.addColorStop(0, gradients.green[0]);
  gradientFillGreen.addColorStop(0.5, gradients.green[1]);
  gradientFillGreen.addColorStop(1, gradients.green[2]);

  const gradientFillBlue = ctx.createLinearGradient(0, 300, 0, 50);
  gradientFillBlue.addColorStop(0, gradients.blue[0]);
  gradientFillBlue.addColorStop(0.5, gradients.blue[1]);
  gradientFillBlue.addColorStop(1, gradients.blue[2]);

  const gradientFillRed = ctx.createLinearGradient(0, 300, 0, 50);
  gradientFillRed.addColorStop(0, gradients.red[0]);
  gradientFillRed.addColorStop(0.5, gradients.red[1]);
  gradientFillRed.addColorStop(1, gradients.red[2]);

  const gradientFillPurple = ctx.createLinearGradient(0, 300, 0, 50);
  gradientFillPurple.addColorStop(0, gradients.purple[0]);
  gradientFillPurple.addColorStop(0.5, gradients.purple[1]);
  gradientFillPurple.addColorStop(1, gradients.purple[2]);

  return [
    gradientFillYellow,
    gradientFillGreen,
    gradientFillBlue,
    gradientFillRed,
    gradientFillPurple,
  ];
};

const allCampaignsGradient = (ctx) => {
  const gradientFillDarkPurple = ctx.createLinearGradient(0, 300, 0, 50);
  gradientFillDarkPurple.addColorStop(0, gradients.darkPurlple[0]);
  gradientFillDarkPurple.addColorStop(0.5, gradients.darkPurlple[1]);
  gradientFillDarkPurple.addColorStop(1, gradients.darkPurlple[2]);

  const gradientFillDarkBlue = ctx.createLinearGradient(0, 300, 0, 50);
  gradientFillDarkBlue.addColorStop(0, gradients.darkBlue[0]);
  gradientFillDarkBlue.addColorStop(0.5, gradients.darkBlue[1]);
  gradientFillDarkBlue.addColorStop(1, gradients.darkBlue[2]);

  const gradientFillLightPink = ctx.createLinearGradient(0, 300, 0, 50);
  gradientFillLightPink.addColorStop(0, gradients.lightPink[0]);
  gradientFillLightPink.addColorStop(0.5, gradients.lightPink[1]);
  gradientFillLightPink.addColorStop(1, gradients.lightPink[2]);

  return {
    darkPurple: gradientFillDarkPurple,
    darkBlue: gradientFillDarkBlue,
    lightPink: gradientFillLightPink,
  };
};

export default { singleCampaignGradient, allCampaignsGradient };
