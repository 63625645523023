<template>
  <div>
    <FullscreenModal v-if="isLoading" :show-close-button="false">
      <spinner-loader></spinner-loader>
    </FullscreenModal>
    <button class="button" @click="initPicker()">
      <img :src="require('../../assets/g-drive-logo.svg')" alt="logo gdrive" />
      <span>Drive</span>
    </button>
  </div>
</template>

<script>
import FullscreenModal from "./Fullscreen-modal.vue";
import SpinnerLoader from "./SpinnerLoader.vue";
import { mapGetters } from "vuex";

export default {
  name: "GooglePicker",
  components: {
    FullscreenModal,
    SpinnerLoader,
  },
  beforeRouteEnter(t, f, next) {
    next(async (vm) => {
      vm.init();
    });
  },
  data() {
    return {
      pickerLoaded: false,
      scope: ["https://www.googleapis.com/auth/drive.readonly"],
      token: null,
      client: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      isShopifyEmbed: "account/getIsShopifyEmbed",
    }),
  },
  watch: {
    token() {
      this.initPicker();
    },
  },
  methods: {
    async init() {
      try {
        this.isLoading = false;
      } catch (err) {
        console.log(`Err Gdrive: ${err}`);
      }
    },
    initGoogleAuth() {
      this.client = window.google.accounts.oauth2.initTokenClient({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        scope: "https://www.googleapis.com/auth/drive.readonly",
        prompt: "",
        callback: (response) => {
          this.token = response.access_token;
        },
      });
      if (!this.token) {
        this.client.requestAccessToken();
      } else {
        this.pickerLoaded = true;
        this.createPicker();
      }
    },
    revokeToken() {
      window.google.accounts.oauth2.revokeToken(this.token);
    },
    initPicker() {
      if (this.isShopifyEmbed) {
        window.open(
          window.location.href + "&redirectionForGdrive=true",
          "_blank",
          "noreferrer"
        );
        return;
      }
      window.gapi.load("picker");
      window.gapi.load("client", () => {
        window.gapi.client.load("drive", "v2", this.initGoogleAuth());
      });
    },
    createPicker() {
      if (this.pickerLoaded && this.token) {
        var view = new window.google.picker.View(
          window.google.picker.ViewId.DOCS
        );
        view.setMimeTypes("video/mp4");
        var picker = new window.google.picker.PickerBuilder()
          .enableFeature(window.google.picker.Feature.NAV_HIDDEN)
          .setOAuthToken(this.token)
          .addView(view)
          .addView(
            new window.google.picker.DocsUploadView().setIncludeFolders(true)
          )
          .setCallback(this.pickerCallback)
          .build();
        picker.setVisible(true);
      }
    },

    pickerCallback(data) {
      if (data.action == window.google.picker.Action.PICKED) {
        this.isLoading = true;
        const doc = data[window.google.picker.Response.DOCUMENTS][0];
        window.gapi.client.drive.files
          .get({
            fileId: doc.id,
            alt: "media",
          })
          .then((res) => {
            const dataUrl = `data:${res.headers["Content-Type"]};base64,${btoa(
              res.body
            )}`;
            fetch(dataUrl)
              .then((s) => s.blob())
              .then((blob) => {
                const file = new File([blob], doc.name, { type: doc.mimeType });
                this.isLoading = false;
                var payload = {
                  file,
                  type: "quick-import",
                };
                this.$emit("fileInput", payload);
              });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  @include base-font;
  @include font-small;
  border-radius: 4px;
  display: flex;
  align-content: center;
  align-items: center;
  gap: 8px;
  background-color: inherit;
  padding: 0 5px;
  width: 100%;
  text-align: left;
  box-sizing: content-box;
  &:hover {
    background: $light-pink;
  }
}

.bigger-font-size {
  @include font-small;
}
</style>
