import api from "../api";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    products: [],
  },
  getters: {
    getProductById: (state) => (id) => {
      return state.products.find((product) => product._id === id);
    },
  },
  mutations: {
    setProducts(state, products) {
      state.products = products;
    },
    updateProduct(state, product) {
      const indexOfProduct = state.products.findIndex(
        (item) => item._id === product._id
      );

      Vue.set(state.products, indexOfProduct, product);
    },
    deleteProduct(state, productId) {
      state.products.splice(
        state.products.findIndex((item) => item._id === productId),
        1
      );
    },
    createProduct(state, productCreated) {
      state.products.push(productCreated);
    },
  },
  actions: {
    async getProducts({ commit, state }, { siteId, isProductListView }) {
      try {
        if (
          !isProductListView &&
          state.products.length &&
          state.products[0].siteId === siteId
        ) {
          return state.products;
        }
        const products = await api.getProductsAddToCart(
          siteId,
          isProductListView
        );
        commit("setProducts", products);
        return products;
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    async createProduct({ commit }, productData) {
      try {
        const productCreated = await api.createProduct(productData);
        // Fetch the updated list of products after creating a new product
        commit("createProduct", productCreated);
      } catch (error) {
        console.error("Error creating product:", error);
      }
    },
    async deleteProduct({ commit }, { productId }) {
      try {
        await api.deleteProduct(productId);
        commit("deleteProduct", productId);
      } catch (error) {
        console.error("Error deleting product:", error);
      }
    },
    async updateProduct({ commit }, { productId, productData }) {
      try {
        const product = await api.updateProduct(productId, productData);

        commit("updateProduct", product);
      } catch (error) {
        console.error("Error updating product:", error);
      }
    },
  },
};
