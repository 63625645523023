<template>
  <div>
    <div class="library-modal-content">
      <div class="video-library-header">
        <h1 class="video-library-title">
          {{ getVideoLibrary.length }}
          {{ $t("create.step2.videoLibraryModal.title") }}
          <span v-if="selectedVideos.length">
            -
            {{ selectedVideos.length }}
            {{ $t("create.step2.videoLibraryModal.selected") }}
          </span>
        </h1>
        <SearchBar
          class="library-search-bar"
          :preselected-query="preselectedQuery"
          @query="getQuery"
        />
        <div class="sorting-options">
          <div class="sorting" @click="sortByField('creationDate')">
            <p
              class="sort-name"
              :class="{ 'selected-sort': sortingField === 'creationDate' }"
            >
              {{ $t("create.step2.videoLibraryModal.sortByImportDate") }}
            </p>
            <div class="sorting-arrows">
              <SortArrow
                class="sort-arrow"
                :class="{
                  'arrow-selected':
                    sortingField === 'creationDate' && sortingOrder === 'asc',
                }"
              />
              <SortArrow
                class="sort-arrow reverse-arrow"
                :class="{
                  'arrow-selected':
                    sortingField === 'creationDate' && sortingOrder === 'desc',
                }"
              />
            </div>
          </div>
          <div class="sorting" @click="sortByField('inCampaigns')">
            <p
              class="sort-name"
              :class="{ 'selected-sort': sortingField === 'inCampaigns' }"
            >
              {{ $t("create.step2.videoLibraryModal.sortByNumberOfCampaigns") }}
            </p>
            <div class="sorting-arrows">
              <SortArrow
                class="sort-arrow"
                :class="{
                  'arrow-selected':
                    sortingField === 'inCampaigns' && sortingOrder === 'asc',
                }"
              />
              <SortArrow
                class="sort-arrow reverse-arrow"
                :class="{
                  'arrow-selected':
                    sortingField === 'inCampaigns' && sortingOrder === 'desc',
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="video-library-cards">
        <div
          v-for="video in sortedVideoLibrary"
          :key="video._id"
          class="video-card"
          :class="{ selected: selectedVideos.includes(video._id) }"
          @click="toggleSelectedVideo(video._id)"
        >
          <div class="video-image">
            <div
              v-if="selectedVideos.includes(video._id)"
              class="selection-overlay"
            ></div>
            <div
              v-if="selectedVideos.includes(video._id)"
              class="purple-circle"
            ></div>
            <img
              v-if="video.thumbnail"
              :src="video.thumbnail"
              alt="thumbnail"
            />
            <div v-else class="no-thumbnail">
              <p>Thumbnail not available 😢</p>
            </div>
            <PlayIcon
              id="play-icon"
              v-tippy="{ placement: 'top', arrow: true }"
              class="play-icon"
              content="Preview video"
              @click.stop="$emit('play-video-in-preview', video)"
            />
            <div
              v-tippy="{
                arrow: true,
                placement: 'top',
                a11y: false,
              }"
              class="video-details products-tagged"
              :content="$t('create.step2.videoLibraryModal.seeProductsTagged')"
              @click="openProductListModal(video)"
            >
              <SmallTag />
              <span>{{ productIdsLength(video._id) }}</span>
            </div>
            <div
              v-tippy="{
                arrow: true,
                placement: 'top',
                a11y: false,
              }"
              class="video-details video-views"
              :content="$t('campaignManager.sortOptions.last7DaysViews')"
            >
              <Visibility />
              <span>{{ video.views }}</span>
            </div>
          </div>
          <div class="video-card-text">
            <div
              v-if="editingVideoId === video._id"
              class="file-name-container"
            >
              <textarea
                ref="videoFilenameTextarea"
                :key="video._id"
                v-model="editedFileName"
                class="video-filename-textarea"
                @blur="handleFileNameUpdate(video._id)"
                @keydown.enter.stop="handleEnterKey($event, video._id)"
              ></textarea>
            </div>
            <div v-else class="file-name-container">
              <h2
                class="video-card-title"
                @click.stop="startEditing(video._id)"
              >
                {{ displayedFileName(video) }}
              </h2>
            </div>
            <p class="video-card-indication">
              {{ $t("create.step2.videoLibraryModal.imported") }}
              {{ videoLibraryDate(video.creationDate) }}
            </p>
            <p class="video-card-indication">
              {{ $t("create.step2.videoLibraryModal.duration") }}
              {{ formatDuration(video.videoDuration) }}
            </p>
            <p class="video-card-indication">
              {{ $t("create.step2.videoLibraryModal.campaigns") }}
              {{ video.inCampaigns }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="showButtons" class="buttons">
        <button class="button-primary --grey" @click="$emit('close-library')">
          {{ $t("shared.buttons.back") }}
        </button>
        <button class="button-primary" @click="handleImport">
          {{ $t("create.step2.videoLibraryModal.import") }}
        </button>
      </div>
    </div>
    <SpinnerLoader v-if="isLoading" />
  </div>
</template>

<script>
import PlayIcon from "@/assets/svg/play-icon.svg?inline";
import SortArrow from "@/assets/svg/sort-arrow.svg?inline";
import { mapGetters } from "vuex";
import SearchBar from "@/components/shared/SearchBar.vue";
import SpinnerLoader from "@/components/shared/SpinnerLoader.vue";
import Visibility from "@/assets/svg/visibility-reverse.svg?inline";
import SmallTag from "@/assets/svg/small-tag.svg?inline";
import enums from "../../enums";
import { normalizeString } from "@/utils/stringNormalize";

const { FormatType } = enums;

export default {
  components: {
    PlayIcon,
    SearchBar,
    SpinnerLoader,
    SortArrow,
    Visibility,
    SmallTag,
  },
  props: {
    showButtons: {
      type: Boolean,
      default: true,
    },
    preselectedQuery: {
      type: String,
      default: "",
    },
    selectedVideosProp: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      videoList: [],
      query: "",
      selectedVideos: [],
      FormatType,
      isLoading: false,
      slideDirection: "right",
      sortingField: "creationDate",
      sortingOrder: "desc",
      editedFileName: "",
      editingVideoId: null,
      originalFileNameExtension: "",
    };
  },
  computed: {
    ...mapGetters({
      getSiteId: "account/getSiteId",
      getVideoLibrary: "video/getVideoLibrary",
      videoObject: "campaign/videoObject",
    }),
    videoLibrary() {
      let result = this.query ? this.videoList : this.getVideoLibrary;
      return result;
    },
    sortedVideoLibrary() {
      const sortedLibrary = [...this.videoLibrary];

      sortedLibrary.sort((a, b) => {
        const fieldA = a[this.sortingField];
        const fieldB = b[this.sortingField];

        if (fieldA < fieldB) {
          return this.sortingOrder === "asc" ? -1 : 1;
        }
        if (fieldA > fieldB) {
          return this.sortingOrder === "asc" ? 1 : -1;
        }
        return 0;
      });

      return sortedLibrary;
    },
    productIdsLength() {
      return (videoId) => {
        const video = this.getVideoLibrary.find((v) => v._id === videoId);
        return video?.productIds?.length || 0;
      };
    },
  },
  watch: {
    selectedVideos: {
      handler(newVal) {
        this.$emit("are-videos-selected", newVal.length > 0);
      },
      deep: true,
    },
    preselectedQuery(newVal) {
      this.query = newVal;
      this.filterListOfVideos();
    },
    selectedVideosProp: {
      handler(newVal) {
        this.selectedVideos = newVal;
      },
      immediate: true,
    },
    getVideoLibrary: {
      handler(newVal) {
        this.filterListOfVideos();
      },
      deep: true,
    },
  },
  async created() {
    this.isLoading = true;
    await this.$store.dispatch("video/getVideoLibrary", {
      siteId: this.getSiteId,
      query: {
        $or: [{ isPremade: { $exists: false } }, { isPremade: false }],
        $and: [
          {
            fileName: { $exists: true },
            height: { $exists: true },
            width: { $exists: true },
            videoDuration: { $exists: true },
            url: { $not: { $regex: "blob:" } },
          },
        ],
        getAnalytics: true,
      },
    });
    this.query = this.preselectedQuery;
    this.filterListOfVideos();
    this.isLoading = false;
  },
  methods: {
    videoLibraryDate(date) {
      return (
        date.slice(8, 10) + "/" + date.slice(5, 7) + "/" + date.slice(2, 4)
      );
    },

    formatDuration(duration) {
      const minutes = Math.floor(duration / 60);
      const seconds = Math.trunc(duration - minutes * 60);
      return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
    },
    getQuery(payload) {
      this.query = payload;
      this.filterListOfVideos();
    },
    filterListOfVideos() {
      const normalizedQuery = normalizeString(this.query.toLowerCase());
      this.videoList = this.getVideoLibrary.filter((video) => {
        const normalizedFileName = normalizeString(
          video.fileName.toLowerCase()
        );
        return normalizedFileName.includes(normalizedQuery);
      });
    },
    toggleSelectedVideo(videoId) {
      if (!this.selectedVideos.includes(videoId)) {
        const selectedVideo = this.getVideoLibrary.find(
          (video) => video._id === videoId
        );
        this.selectedVideos.push(videoId);
        this.$emit("play-video-in-preview", selectedVideo);
      } else {
        const index = this.selectedVideos.indexOf(videoId);
        this.selectedVideos.splice(index, 1);
      }
      this.$emit("update:selectedVideos", this.selectedVideos);
    },

    handleImport() {
      const videosToImport = [];
      this.selectedVideos.forEach((videoId) => {
        const foundVideo = this.videoLibrary.find(
          (video) => video._id === videoId
        );
        videosToImport.push(foundVideo);
      });
      this.$emit("import-videos", structuredClone(videosToImport));
    },
    closeLibrary() {
      this.$emit("close-library");
    },
    sortByField(field) {
      if (field === this.sortingField) {
        this.sortingOrder = this.sortingOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortingField = field;
        this.sortingOrder = "asc";
      }
    },
    startEditing(videoId) {
      const video = this.getVideoLibrary.find((video) => video._id === videoId);
      const fileName = video.fileName;
      const extensionIndex = fileName.lastIndexOf(".");
      const extension =
        extensionIndex !== -1 ? fileName.substring(extensionIndex) : "";
      this.editedFileName =
        extensionIndex !== -1
          ? fileName.substring(0, extensionIndex)
          : fileName;
      this.editingVideoId = videoId;
      this.originalFileNameExtension = extension;
      this.$nextTick(() => {
        const textarea = document.querySelector(".video-filename-textarea");
        if (textarea) {
          textarea.focus();
        }
      });
    },
    async handleFileNameUpdate(videoId) {
      const video = this.getVideoLibrary.find((v) => v._id === videoId);

      try {
        if (
          this.editedFileName.trim() === "" ||
          this.editedFileName.trim() === video.fileName
        ) {
          this.editedFileName = video.fileName;
        } else {
          const newFileName =
            this.editedFileName.trim() + this.originalFileNameExtension;
          await this.$store.dispatch("video/updateVideoLibraryFileName", {
            videoId,
            fileName: newFileName,
          });

          this.$notify({
            title: this.$t("shared.toastMessage.success"),
            text: this.$t("create.step2.fileNameUpdated"),
            type: "success",
          });
        }

        this.editingVideoId = null;
      } catch (error) {
        console.error("Error updating file name:", error);
      }
    },
    displayedFileName(video) {
      if (video && video.fileName) {
        if (this.editingVideoId === video._id) {
          return this.editedFileName;
        } else {
          return video.fileName.replace(/\.\w+$/, "");
        }
      }
      return "";
    },
    handleEnterKey(event, videoId) {
      if (event.key === "Enter") {
        event.preventDefault();
        this.handleFileNameUpdate(videoId);
      }
    },
    getProductIdLength(video) {
      return (video.productIds && video.productIds.length) || 0;
    },
    openProductListModal(video) {
      this.$emit("open-product-list-modal", video);
    },
  },
};
</script>

<style lang="scss">
.video-library-modal .sliding-modal {
  min-width: 410px;
  padding: 20px 0;
}
</style>

<style lang="scss" scoped>
.library-modal-content {
  height: calc(80%);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.video-library-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 20px;
}

.video-library-title {
  @include base-font;
  @include font-big;
  text-align: start;
  max-width: 380px;
}

.library-search-bar {
  width: 410px;
  margin: 0 !important;
}

.sorting-options {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.sorting {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.sort-name {
  @include base-font;
  @include font-small;
}

.selected-sort {
  color: $dark-purple;
}

.sorting-arrows {
  display: flex;
  flex-direction: column;
  gap: 4px;
  transform: scale(70%);
  .sort-arrow {
    &:hover {
      cursor: pointer;
    }
    path {
      fill: $light-grey;
    }
  }
  .reverse-arrow {
    transform: translateX(-0.9px);
    path {
      transform: rotate(180deg);
      transform-origin: 50% 50%;
    }
  }
  .arrow-selected {
    path {
      fill: $dark-purple;
    }
  }
}
.video-library-cards {
  display: grid;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: repeat(3, 120px);
  justify-content: center;
  overflow-y: auto;
  gap: 24px;
  height: 100vh;
  padding: 0 2px;
  padding-bottom: 120px;
  flex-grow: 1;
  scrollbar-width: none;
  .video-card {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 4px;
    &:hover {
      cursor: pointer;
    }
    &.selected {
      .video-image {
        border: 1px solid $dark-purple;
        img {
          background-color: rgba(255, 182, 193, 0.5);
        }
      }
    }
  }
  .video-image {
    height: 213.33px;
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    position: relative;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      border-radius: 4px;
    }
  }
  .no-thumbnail {
    background-color: #c4c4c4;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 12px;
      text-align: center;
    }
  }
  .selection-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(249, 186, 255, 0.3) 0%,
      rgba(249, 186, 255, 0.3) 100%
    );
    z-index: 100;
  }

  .purple-circle {
    border-radius: 50%;
    height: 12px;
    width: 12px;
    background-color: $light-purple;
    z-index: 150;
    position: absolute;
    top: 8px;
    right: 8px;
    border: 1px solid $dark-purple;
  }

  .video-details {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 2px 6px;
    border-radius: 2px;
    background-color: rgba(129, 129, 129, 0.8);
    position: absolute;
    left: 4px;
    z-index: 101;
    span {
      font-size: 10px;
      font-weight: 700;
      color: white;
    }
  }
  .products-tagged {
    bottom: 22px;
    &:hover {
      background-color: #7b01b5;
    }
  }

  .video-views {
    bottom: 4px;
  }
  .video-card-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .video-card-title {
    @include font-smaller;
    gap: 16px;
    width: 120px;
    height: 30px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
  }
  .video-card-indication {
    @include font-smallest;

    color: $light-grey;
  }
  .play-icon {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 101;
    &:focus {
      outline: none;
    }
    &:hover .icon-path {
      fill: #7b01b5;
    }
    .icon-path {
      fill: rgba(129, 129, 129, 0.8);
    }
  }
  scrollbar-width: none;
}

.video-library-cards::-webkit-scrollbar {
  width: 0;
}

.video-library-cards::-webkit-scrollbar-track {
  background-color: transparent;
}

.video-library-cards::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.buttons {
  z-index: 102;
  position: fixed;
  bottom: 0;
  background-color: white;
  min-width: 415px;
  display: flex;
  justify-content: center;
  height: fit-content;
  gap: 32px;
  padding: 16px 0;
  justify-self: flex-end;
}

@media (max-width: 1250px) {
  .modal-background {
    width: 45%;
  }
  .video-library-cards {
    gap: 16px;
  }
  .library-search-bar {
    width: 392px;
  }
}

.file-name-container {
  width: 120px;
  height: 28px;
}

.video-filename-textarea {
  @include base-font;
  @include font-smaller;
  font-weight: 500;
  width: 120px;
  height: 28px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  border: none;
  resize: none;
  box-sizing: border-box;
  outline: 1px solid $light-grey;
  border-radius: 2px;
}
</style>
