<template>
  <div class="section-container">
    <!-- possible to refactor with dynamic classes and computed properties -->
    <section
      v-if="isLoginVariant"
      v-lazy:background-image="!isModal"
      :class="isModal ? 'for-modal' : ''"
      class="identification-page login"
    >
      <form class="login-signup-form-container" @submit.prevent="login">
        <div class="login-signup-form">
          <h1 data-cy="login-header" class="signup-header">
            {{ $t("signUpLogin.headerLogIn") }}
          </h1>
          <div class="signup-inputs-wrapper">
            <label for="email">{{ $t("signUpLogin.email") }}</label>
            <input
              id="email"
              v-model="email"
              data-cy="login-email-input"
              class="text-input signup-input"
              type="email"
              required
            />
            <label for="password">{{ $t("signUpLogin.password") }}</label>
            <input
              id="password"
              v-model="password"
              data-cy="login-password-input"
              maxlength="256"
              class="text-input signup-input"
              type="password"
              required
            />
          </div>
          <p class="login-text reset-password">
            <button class="login-link" type="button" @click="resetPassword()">
              {{ $t("signUpLogin.resetPassword") }}
            </button>
          </p>
          <button
            data-cy="login-button"
            type="submit"
            class="button-primary button-login"
          >
            {{ $t("signUpLogin.logInLong") }}
          </button>
          <p class="login-text">
            {{ $t("signUpLogin.newUser") }}
            <button
              data-cy="login-switcher"
              class="login-link"
              @click="switchLoginSignup()"
            >
              {{ $t("signUpLogin.signUp") }}
            </button>
          </p>
        </div>
      </form>
      <div class="features-signup-container">
        <img
          class="logo"
          src="../../assets/vidjet-logo-signup.png"
          alt="vidjet-logo"
        />
        <div class="features">
          <h1>{{ $t("signUpLogin.delighting") }}</h1>
          <h3>{{ $t("signUpLogin.inspired") }}</h3>
        </div>
        <div class="carousel-container">
          <div ref="carousel" class="slide">
            <img
              v-for="(image, index) in slidingCarouselImages"
              :key="index"
              :src="image"
              alt="carousel-image"
            />
          </div>
        </div>
      </div>
    </section>
    <div v-else>
      <section
        v-if="isTrustedShopsSignup"
        v-lazy:background-image="!isModal"
        class="identification-page signup signup-trusted-shops"
        :class="isModal ? 'for-modal' : ''"
      >
        <form class="login-signup-form-container" @submit.prevent="signUp">
          <div class="free-onboarding">
            <Flame />
            <span>Simplified onboarding for Trusted Shops members</span>
          </div>
          <div class="login-signup-form">
            <h1 class="signup-header">
              {{ $t("signUpLogin.create") }}
            </h1>
            <div class="signup-inputs-wrapper">
              <label for="email">{{ $t("signUpLogin.workEmail") }}</label>
              <input
                id="email"
                v-model="email"
                class="text-input signup-input"
                type="email"
                required
              />
              <label for="websiteUrl">{{ $t("signUpLogin.websiteUrl") }}</label>
              <input
                id="websiteUrl"
                v-model="websiteUrl"
                maxlength="256"
                class="text-input signup-input"
                type="text"
                required
              />
              <label for="password">{{ $t("signUpLogin.passwordLong") }}</label>
              <input
                id="password"
                v-model="password"
                maxlength="256"
                class="text-input signup-input"
                type="password"
                required
              />
            </div>
            <button
              type="submit"
              :disabled="isSignupDone"
              class="button-primary button-signup"
              data-cy="signup-button"
            >
              {{ $t("signUpLogin.getStartedFree") }}
            </button>
          </div>
          <p class="login-text">
            {{ $t("signUpLogin.already")
            }}<button class="login-link" @click="switchLoginSignup()">
              {{ $t("signUpLogin.logIn") }}
            </button>
          </p>
          <p class="conditions">
            {{ $t("signUpLogin.submiting") }}
            <a href="https://www.vidjet.io/terms-of-service" target="_blank">
              {{ $t("signUpLogin.terms") }}</a
            >
            {{ $t("signUpLogin.and") }}
            <a href="https://www.vidjet.io/privacy-policy" target="_blank">
              {{ $t("signUpLogin.privacy") }}</a
            >
          </p>
        </form>
        <div class="features-signup-container features-container-trusted-shops">
          <div class="collaboration-logos">
            <img src="../../assets/ts-logo.png" alt="vidjet" />
            <XIcon />
            <img src="../../assets/vidjet-logo.png" alt="trusted-shops" />
          </div>
          <div class="features pull-top features-trusted-shops">
            <h1>Thank you for your trust!</h1>
            <h3>
              Just a few more steps and you will be ready to turn your video
              content into more sales!
            </h3>
          </div>
          <div class="carousel-container">
            <div ref="carousel" class="slide">
              <img
                v-for="(image, index) in slidingCarouselImages"
                :key="index"
                :src="image"
                alt="carousel-image"
              />
            </div>
          </div>
        </div>
      </section>
      <section
        v-else
        v-lazy:background-image="!isModal"
        class="identification-page signup"
        :class="isModal ? 'for-modal' : ''"
      >
        <div class="features-signup-container">
          <img
            class="logo"
            src="../../assets/vidjet-logo-signup.png"
            alt="vidjet-logo"
          />
          <div class="features pull-top">
            <h1>{{ $t("signUpLogin.getStarted") }}</h1>
            <h3>{{ $t("signUpLogin.deliver") }}</h3>
            <ul class="features-list">
              <li class="feature">
                <img src="../../assets/checkmark.png" alt="checkmark" />
                <p>
                  {{ $t("signUpLogin.reels") }}
                  <span class="emphasized">{{ $t("signUpLogin.cro") }}</span>
                </p>
              </li>
              <li class="feature">
                <img src="../../assets/checkmark.png" alt="checkmark" />
                <p>
                  {{ $t("signUpLogin.beautifulAlternative") }}
                  <span class="emphasized">{{ $t("signUpLogin.design") }}</span>
                </p>
              </li>
              <li class="feature">
                <img src="../../assets/checkmark.png" alt="checkmark" />
                <p>
                  {{ $t("signUpLogin.trigger") }}
                  <span class="emphasized">{{
                    $t("signUpLogin.automation")
                  }}</span>
                </p>
              </li>
            </ul>
          </div>
          <div class="carousel-container carousel-container-sign-up">
            <div ref="carousel" class="slide-sign-up">
              <img
                v-for="(image, index) in slidingCarouselImages"
                :key="index"
                :src="image"
                alt="carousel-image"
              />
            </div>
          </div>
        </div>
        <form class="login-signup-form-container" @submit.prevent="signUp">
          <div class="login-signup-form">
            <h1 class="signup-header">
              {{ $t("signUpLogin.create") }}
            </h1>
            <div class="signup-inputs-wrapper">
              <label for="email">{{ $t("signUpLogin.workEmail") }}</label>
              <input
                id="email"
                v-model="email"
                class="text-input signup-input"
                type="email"
                required
              />
              <label for="websiteUrl">{{ $t("signUpLogin.websiteUrl") }}</label>
              <input
                id="websiteUrl"
                v-model="websiteUrl"
                maxlength="256"
                class="text-input signup-input"
                type="text"
                required
              />
              <label for="password">{{ $t("signUpLogin.passwordLong") }}</label>
              <input
                id="password"
                v-model="password"
                maxlength="256"
                class="text-input signup-input"
                type="password"
                required
              />
            </div>
            <button
              type="submit"
              :disabled="isSignupDone"
              class="button-primary button-signup"
              data-cy="signup-button"
            >
              {{ $t("signUpLogin.getStartedFree") }}
            </button>
          </div>
          <p class="login-text">
            {{ $t("signUpLogin.already")
            }}<button class="login-link" @click="switchLoginSignup()">
              {{ $t("signUpLogin.logIn") }}
            </button>
          </p>
          <p class="conditions">
            {{ $t("signUpLogin.submiting") }}
            <a href="https://www.vidjet.io/terms-of-service" target="_blank">
              {{ $t("signUpLogin.terms") }}</a
            >
            {{ $t("signUpLogin.and") }}
            <a href="https://www.vidjet.io/privacy-policy" target="_blank">
              {{ $t("signUpLogin.privacy") }}</a
            >
          </p>
        </form>
      </section>
    </div>
  </div>
</template>
<script>
import api from "../../api";
import fullscreen from "../../assets/auto-sliding-carousel-fullscreen.png";
import bubble from "../../assets/auto-sliding-carousel-bubble.png";
import carousel from "../../assets/auto-sliding-carousel-carousel.png";
import stories from "../../assets/auto-sliding-carousel-stories.png";
import popup from "../../assets/auto-sliding-carousel-popup.png";
import fullscreen2 from "../../assets/auto-sliding-carousel-fullscreen2.png";
import XIcon from "@/assets/svg/x-icon.svg?inline";
import Flame from "@/assets/svg/flame.svg?inline";
import { mapGetters } from "vuex";

export default {
  name: "SignupLogin",
  components: { XIcon, Flame },
  props: {
    isModal: { type: Boolean, default: false },
  },
  data() {
    return {
      email: "",
      password: "",
      websiteUrl: "",
      isSignupDone: false,
      isLoginDone: false,
      isLoginVariant: false,
      preferredLanguage: "en",
      slidingCarouselImages: [
        fullscreen,
        bubble,
        carousel,
        stories,
        popup,
        fullscreen2,
        fullscreen,
        bubble,
        carousel,
        stories,
        popup,
        fullscreen2,
      ],
      isTrustedShops: false,
    };
  },

  computed: {
    ...mapGetters({
      isOnboardingComplete: "site/isOnboardingComplete",
    }),
    isLogin() {
      return this.$route.name === "login";
    },
    isSignupPage() {
      return this.$route.name === "signup";
    },
    isTrustedShopsSignup() {
      const currentPath = window.location.pathname;
      return currentPath.includes("/signup/trusted-shops");
    },
  },
  created() {
    this.setPreferredLanguage();
    if (this.isLogin) {
      this.isLoginVariant = true;
    }
    if (this.isTrustedShopsSignup) {
      this.isTrustedShops = true;
    }
  },
  methods: {
    switchLoginSignup() {
      if (
        this.$route.name === "signup" ||
        this.$route.name === "signup/trusted-shops"
      ) {
        this.$router.push("login");
      } else if (this.$route.name === "login") {
        this.$router.push("signup");
      }
      this.isLoginVariant = !this.isLoginVariant;
    },
    async resetPassword() {
      try {
        if (!this.email) {
          return this.$notify({
            title: this.$t("shared.toastMessage.error"),
            text: this.$t("shared.toastMessage.report.enterEmail"),
            type: "error",
          });
        }

        await api.resetPassword({
          email: this.email,
        });

        this.$notify({
          title: this.$t("shared.toastMessage.success"),
          text: this.$t("shared.toastMessage.passwordResetSuccess"),
          type: "success",
        });
      } catch (err) {
        console.log(err);
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: err.response.data,
          type: "error",
        });
      }
    },
    setPreferredLanguage() {
      if (navigator.language.includes("fr")) {
        this.preferredLanguage = "fr";
      }
      if (navigator.language.includes("es")) {
        this.preferredLanguage = "es";
      }
    },
    async signUp() {
      const regex =
        /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
      if (!regex.test(this.websiteUrl)) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.missingData.url"),
          type: "error",
        });
      } else {
        try {
          await this.$store.dispatch("account/signup", {
            email: this.email.toLowerCase(),
            password: this.password,
            websiteUrl: this.websiteUrl,
            preferredLanguage: this.preferredLanguage,
            isTrustedShops: this.isTrustedShops,
          });
          // check if successful
          // show success message and close modal
          if (this.$store.state.account.loggedIn) {
            this.$notify({
              title: this.$t("shared.toastMessage.success"),
              text: this.$t("shared.toastMessage.signUpSuccessful"),
              type: "success",
            });
            this.isSignupDone = true;
            this.$emit("signup-done");
            this.isSignupPage && this.$router.push({ name: "onboarding" });
            return;
          }
        } catch (err) {
          this.$notify({
            title: this.$t("shared.toastMessage.error"),
            text: err.response.data,
            type: "error",
          });
        }
      }
    },
    async login() {
      try {
        await this.$store.dispatch("account/login", {
          email: this.email.toLowerCase(),
          password: this.password,
        });

        if (this.$store.state.account.loggedIn) {
          this.$notify({
            title: this.$t("shared.toastMessage.success"),
            text: this.$t("shared.toastMessage.logInSuccessful"),
            type: "success",
          });

          if (!this.isOnboardingComplete) {
            this.$router.push({ name: "onboarding" });
          } else {
            this.$router.push({ name: "dashboard" });
          }

          this.isLoginDone = true;
          this.$emit("login-done");
          return;
        }
      } catch (err) {
        console.log(err);
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: err.response.data,
          type: "error",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.section-container {
  height: 100%;
}
.login-signup-form-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.login-signup-form {
  width: 60%;
}

.features-signup-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 32px;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background: $dark-purple;
  color: white;

  .logo {
    margin-top: 32px;
    margin-left: 32px;
    align-self: flex-start;
  }

  .carousel-container {
    margin-top: 16px;
    width: 100%;
    overflow: hidden;
    height: 425px;
    white-space: nowrap;
    display: flex;
  }

  .carousel-container-sign-up {
    margin-bottom: 35px;
    height: 280px;
  }

  .slide {
    display: flex;
    animation: 10s slide infinite linear;
    height: 100%;
    img {
      height: 100%;
      object-fit: cover;
      margin: 0 8px;
    }
  }

  .slide-sign-up {
    display: flex;
    animation: 10s slide-small infinite linear;
    height: 100%;
    img {
      height: 100%;
      object-fit: cover;
      margin: 0 8px;
    }
  }
  @keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-1320.54px);
    }
  }

  @keyframes slide-small {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-857.16px);
    }
  }
}

.features {
  margin-left: 32px;
  align-self: flex-start;
  width: 90%;
  .features-list {
    margin-top: 40px;
    margin-right: 32px;
  }
  .feature {
    margin-top: 20px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    gap: 10px;
  }
  h1 {
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
  }
  h3 {
    margin-top: 10px;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
  }
  .emphasized {
    font-style: italic;
    font-weight: 500;
  }
}

.identification-page {
  &.signup {
    background-position: 10% center, 5% center;
  }
  &.login {
    background-position: 90% center, 5% center;
  }
  &.signup,
  &.login {
    @include media("<=tablet") {
      background-image: none;
    }
    &.for-modal {
      width: 95%;
      background-image: url("~@/assets/signup-person.png"),
        url("~@/assets/svg/signup-engage.svg") !important;
      background-repeat: no-repeat;
      background-position: right, left;
      @include media("<=desktop") {
        background: none;
      }
    }
  }
}

.button-login {
  width: fit-content;
  align-self: center;
  width: 100%;
  background-color: $dark-purple;
  color: white;
  margin-top: 80px;
}
.button-signup {
  width: fit-content;
  align-self: center;
  width: 100%;
  background-color: $dark-purple;
  color: white;
}

.identification-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 100vh;
  background: no-repeat;
  @include media("<=desktop") {
    background-position: top, bottom;
  }

  .reset-password {
    text-align: right !important;
    margin-right: 16px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .login-text {
    @include font-smaller;
    margin-top: 20px;
    margin-bottom: 30px;
    color: $light-grey;
    text-align: center;

    .login-link {
      @include base-font;
      @include font-smaller;
      background: white;
      color: $dark-purple;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .signup-header {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin: 28px auto 35px auto;
  }
  .signup-completed {
    margin: 8px auto;
  }
  .signup-inputs-wrapper {
    display: flex;
    flex-direction: column;
    label {
      @include font-normal;
      margin-bottom: 6px;
    }
    input {
      margin-bottom: 20px;
    }
  }
  .signup-input {
    @include base-font;
    @include font-normal;
    border: 1px solid #c4c4c4;
    border-radius: 3px;
    margin-right: 0px;
    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 1px rgba(226, 124, 252, 0.7);
    }
  }
}
.logo-carousel-wrapper {
  width: 80%;
}
.logo-carousel {
  display: flex;
  gap: 5px;
  margin-bottom: -50px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.conditions {
  text-align: center;
  @include font-smallest;
  margin-top: 16px;
  color: $light-grey;
}
.collaboration-logos {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  margin-left: 32px;
}
.and {
  object-fit: contain;
}
.features-container-trusted-shops {
  background-color: #f2f0fe;
}

.features-trusted-shops {
  color: black;
}

.button-login,
.button-signup {
  border-radius: 4px;
}

.signup-trusted-shops {
  position: relative;
}

.free-onboarding {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0px 0px 115.5px 0px;
  background: #ffdc0f;
  padding: 12px 32px;
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
