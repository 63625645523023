<template>
  <small-modal v-if="showModal" :fit-content="true" @close="toggleModal">
    <h2 class="card-title">{{ $t("create.step2.browserErrorTitle") }}</h2>
    <h3 class="subtitle">
      {{ $t("create.step2.browserError") }}
      <a href="https://convertio.co/" target="_blank">
        {{ $t("create.step2.browserErrorLinkText") }}</a
      >
    </h3>
    <div class="images">
      <div class="image" v-for="image in images" :key="image.path">
        <img :src="image.path" :alt="image.title" />
        <p>{{ image.title }}</p>
      </div>
    </div>
  </small-modal>
</template>
<script>
import SmallModal from "../shared/SmallModal";

export default {
  name: "PlayErrorModal",
  components: {
    SmallModal,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      images: [
        {
          path: require("@/assets/play-error-img-1.png"),
          title: "1. Import file",
        },
        {
          path: require("@/assets/play-error-img-2.png"),
          title: "2. Select “MP4”",
        },
        {
          path: require("@/assets/play-error-img-3.png"),
          title: "3. Click “Convert”",
        },
        {
          path: require("@/assets/play-error-img-4.png"),
          title: "4. Download file, and import in Vidjet",
        },
      ],
    };
  },
  methods: {
    toggleModal() {
      this.$emit("toggleModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  @include font-big;
  padding: 20px 0 20px 0;
}

.subtitle {
  padding-bottom: 20px;
}

.images {
  display: flex;
  margin-bottom: 30px;
  grid-gap: 10px;
  .image {
    height: 220px;
    width: 220px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  p {
    @include font-small;
    color: red;
  }
}
</style>
