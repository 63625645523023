<template>
  <div class="url-segmentation">
    <div class="page-display-input">
      <div class="and-or-button add-remove">
        <p class="and-or-text" @click="toggleInclusion">
          {{
            segmentation.inclusion.length
              ? $t("shared.urlSegmentationComponent.removeRule")
              : $t("shared.urlSegmentationComponent.addRule")
          }}
        </p>
        <img
          v-tippy="{
            arrow: true,
            placement: 'right',
            a11y: false,
            maxWidth: 450,
            boundary: 'window',
          }"
          src="@/assets/usecases_cards/tooltip-icon.png"
          alt="tooltip-icon"
          class="tooltip-icon"
          :content="$t('shared.urlSegmentationComponent.tooltip')"
        />
      </div>
    </div>
    <div
      v-for="ruleType in ruleTypes"
      :key="ruleType.id"
      class="iteration-container"
    >
      <div
        v-if="ruleType === 'exclusion' && showExclusionButton"
        class="and-or-button exclusion-btn"
      >
        <p class="and-or-text" @click="toggleExclusion()">
          {{
            segmentation.exclusion.length > 0
              ? $t("shared.urlSegmentationComponent.removeExclusions")
              : $t("shared.urlSegmentationComponent.addExclusion")
          }}
        </p>
      </div>
      <ul v-if="segmentation[ruleType].length > 0" class="url-list">
        <section>
          <li
            v-for="(filter, index) in segmentation[ruleType]"
            :key="filter.id"
            class="segmentation-list-item"
          >
            <label class="url-label" for="comparisonType">{{
              $t("shared.urlSegmentationComponent.URL")
            }}</label>
            <v-select
              v-model="filter.comparison"
              class="vidjet-single-select"
              :searchable="false"
              :clearable="false"
              label="name"
              :options="comparisonOptions[ruleType]"
              :reduce="(options) => options.value"
              @input="handleComparisonString()"
            />
            <input
              id="url"
              ref="input"
              v-model="filter.string"
              v-tippy="{
                placement: 'top',
                arrow: true,
                maxWidth: 450,
                trigger: filter.string.length < 50 ? 'manual' : 'mouseenter',
              }"
              :content="filter.string"
              :class="{ 'long-url': filter.string.length >= 50 }"
              class="text-input --with-borders url-input"
              type="text"
              :placeholder="$t('shared.urlSegmentationComponent.placeholder')"
              @blur="handleComparisonString()"
            />

            <button
              v-if="index + 1 === segmentation[ruleType].length"
              class="and-or-button"
              @click="addRule(ruleType)"
            >
              {{ $t("shared.urlSegmentationComponent.addURL") }}
            </button>
            <!-- empty div for grid consistency -->
            <div v-else></div>
            <button
              v-if="ruleType === 'inclusion' ? showButtonRemoveUrl : true"
              class="close-button"
              @click="removeUrlSegmentation(ruleType, index)"
            >
              <CloseIcon />
            </button>
          </li>
        </section>
      </ul>
    </div>
  </div>
</template>

<script>
import CloseIcon from "@/assets/svg/close.svg?inline";
import enums from "@/enums";

const { ComparaisonType } = enums;
export default {
  name: "UrlSegmentation",
  components: {
    CloseIcon,
  },
  props: {
    urlSchema: {
      type: Array,
      default: () => [],
    },

    addInclusion: {
      type: Boolean,
      default: false,
    },
    showExclusionButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      segmentation: {
        inclusion: [],
        exclusion: [],
      },
      ComparaisonType,
      baseInput: [
        {
          comparison: "not_contains",
          string: "",
        },
      ],
      comparisonOptions: [],
      longUrl: false,
      firstUrlSchemaWatch: true,
    };
  },
  computed: {
    showButtonRemoveUrl() {
      return this.segmentation.inclusion.length > 1;
    },

    ruleTypes() {
      return Object.keys(this.segmentation);
    },

    filterEqualTriggers() {
      const eqTriggers = [];
      this.ruleTypes.forEach((ruleType) => {
        this.segmentation[ruleType].forEach((filter) => {
          if (
            filter.comparison === ComparaisonType.equalTo ||
            filter.comparison === ComparaisonType.notEqualTo
          ) {
            eqTriggers.push(filter);
          }
        });
      });
      return eqTriggers;
    },

    equalTriggersHaveValidUrls() {
      return this.isValidUrl(this.filterEqualTriggers);
    },

    areAllInputsFilled() {
      return this.ruleTypes.every((ruleTypeArray) => {
        return this.segmentation[ruleTypeArray].every((filter) => {
          return filter.string !== "";
        });
      });
    },
  },

  watch: {
    // to hide or show inclusion input
    addInclusion(newValue) {
      newValue ? this.addRule("inclusion") : (this.segmentation.inclusion = []);
      this.handleComparisonString();
    },
    //Because of reactivity, urlSchema is not ready on created to put a watcher
    urlSchema: {
      async handler(newVal, oldVal) {
        if (this.firstUrlSchemaWatch && this.urlSchema?.length) {
          this.formatDataFromBackEnd();
          this.firstUrlSchemaWatch = false;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    // Comes from locales, check en.json
    this.comparisonOptions = this.$t("shared.urlSegmentationComponent.options");

    // show end of strings for all inputs
    this.$refs.input &&
      this.$nextTick(() => {
        this.$refs.input.forEach((input) => {
          input.scrollLeft = input.scrollWidth;
        });
      });
  },

  methods: {
    removeUrlSegmentation(ruleType, index) {
      if (this.segmentation[ruleType].length === 1) {
        this.segmentation[ruleType] = [];
      } else {
        this.segmentation[ruleType].splice(index, 1);
      }
      this.handleComparisonString();
      this.formatDataForBackEnd();
    },

    toggleInclusion() {
      //This is done that that we do not call format data again when urlSchema was empty at the beginning
      this.firstUrlSchemaWatch = false;

      this.segmentation.inclusion.length > 0
        ? (this.segmentation.inclusion = [])
        : this.addRule("inclusion");
      this.handleComparisonString();
    },

    toggleExclusion() {
      //This is done that that we do not call format data again when urlSchema was empty at the beginning
      this.firstUrlSchemaWatch = false;

      this.segmentation.exclusion.length > 0
        ? (this.segmentation.exclusion = [])
        : this.addRule("exclusion");
      this.handleComparisonString();
    },

    handleComparisonString() {
      const dataToSend = {
        url: this.formatDataForBackEnd(),
        equalTriggersHaveValidUrls: this.equalTriggersHaveValidUrls,
        areAllInputsFilled: this.areAllInputsFilled,
      };
      this.$emit("input", dataToSend);
    },

    addRule(ruleType) {
      const comparison =
        ruleType === "inclusion"
          ? ComparaisonType.contains
          : ComparaisonType.notEqualTo;

      this.segmentation[ruleType].push({
        comparison: comparison,
        string: "",
      });
      this.handleComparisonString();
    },

    isValidUrl(urls) {
      return urls.every((url) => url.string.includes("http"));
    },

    // this is a data washing machine
    formatDataFromBackEnd() {
      if (this.urlSchema.length === 0) return;
      // we have to create a deep copy in order to use v-model
      const urlDeepCopy = JSON.parse(JSON.stringify(this.urlSchema))[0];
      //If filters is not set, it means we have only one exclusion rule
      if (!urlDeepCopy.filters) {
        // if comparison includes not then it is exclusion
        this.segmentation[
          urlDeepCopy.comparison.includes("not") ? "exclusion" : "inclusion"
        ].push(urlDeepCopy);
        // if type or is present in urlCopy then it's only inclusion
      } else if (urlDeepCopy.type === "or") {
        urlDeepCopy.filters.forEach((filter) => {
          this.segmentation.inclusion.push(filter);
        });
      } else {
        // check if there is a filter type to add the filters in the correct array
        urlDeepCopy.filters.forEach((filter) => {
          filter.type
            ? filter.filters.forEach((rule) => {
                filter.type === "or"
                  ? this.segmentation.inclusion.push(rule)
                  : this.segmentation.exclusion.push(rule);
              })
            : // if not it means it is type and => exclusion
              this.segmentation[
                filter.comparison.includes("not") ? "exclusion" : "inclusion"
              ].push(filter);
        });
      }
    },

    formatDataForBackEnd() {
      const finalData = [];
      // all pages with only one page/rule
      this.ruleTypes.forEach((ruleType) => {
        if (this.segmentation[ruleType].length > 1) {
          finalData.push({
            type: ruleType === "inclusion" ? "or" : "and",
            filters: this.segmentation[ruleType],
          });
        } else if (this.segmentation[ruleType].length === 1) {
          finalData.push(this.segmentation[ruleType][0]);
        }
      });

      if (!finalData.length) return [];

      // if finalData length is 1 it means we have only one rule
      return finalData.length === 2
        ? [{ filters: finalData, type: "and" }]
        : [{ ...finalData[0] }];
    },
  },
};
</script>

<style>
.url-segmentation .vs__dropdown-menu {
  min-width: none !important;
  position: absolute !important;
  width: 125px !important;
  min-width: 125px !important;
}

.url-segmentation .vs__dropdown-toggle {
  border-radius: 2px;
  width: 125px !important;
}
</style>

<style lang="scss" scoped>
.page-display-input {
  display: flex;
  flex-direction: column;
}

.and-or-button {
  @include base-font;
  @include font-small;
  font-weight: 600;
  color: $dark-purple;
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
}

.and-or-text {
  cursor: pointer;
}

.tooltip-icon {
  width: 15px;
  &:hover {
    cursor: pointer;
  }
}
.indication {
  @include font-smallest;
  margin-bottom: 5px;
  font-style: italic;
}

#url {
  @include font-small;
}

.url-list {
  padding: 8px;
  border: 1px solid $light-grey;
  border-radius: 5px;
  margin-bottom: 16px;
}

.segmentation-list-item {
  @include font-smallest;
  display: grid;
  grid-template-columns: auto auto 3fr 0.7fr 0.1fr;
  align-items: center;
  text-align: center;

  grid-gap: 16px;
  margin: 0 auto;
  margin-top: 8px;

  &:nth-child(1) {
    margin-top: 0px;
  }
}

.vidjet-single-select {
  margin-right: 7px;
  flex: 1;
}

.url-label {
  @include font-small;
}

.and-text {
  margin-bottom: 16px;
}

.exclusion-btn {
  margin-bottom: 12px;
}

.close-button {
  transform: scale(1.5);
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.url-input::placeholder {
  color: $light-grey;
}
.add-remove {
  margin-bottom: 12px;
}
</style>
