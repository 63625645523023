<template>
  <header class="header">
    <div class="left-part">
      <h1>{{ title }}</h1>
    </div>
    <div class="right-part">
      <button
        v-if="showVideoLibraryButton"
        class="button-primary button-no-shadows"
        @click="openVideoLibrary"
      >
        {{ $t("dashboard.heading.videoLibrary") }}
      </button>
      <button
        v-if="showCreateButton"
        class="button-primary create-button"
        @click="addCampaign"
      >
        {{ $t("dashboard.heading.createButton") }}
      </button>
    </div>
    <!-- if proposed plan has a value then user needs to upgrade -->
    <PlanRegistration
      v-if="showPlanRegistration"
      :new-plan="proposedPlan"
      :show-arrows="currentPlan !== 'scale'"
      @close="showPlanRegistration = false"
    />
    <transition name="modal-slide">
      <VideoLibrarySlidingModal
        v-if="showVideoLibrary || videoLibraryCreation"
        :video-library-creation="videoLibraryCreation"
        @close-video-library="closeVideoLibrary"
      />
    </transition>
  </header>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import PlanRegistration from "@/components/upgrade-plan/PlanRegistration.vue";
import VideoLibrarySlidingModal from "@/components/shared/VideoLibrarySlidingModal.vue";
export default {
  name: "VidjetHeader",

  components: {
    PlanRegistration,
    VideoLibrarySlidingModal,
  },
  props: {
    title: {
      type: String,
    },
    showCreateButton: {
      default: false,
      type: Boolean,
    },
    showVideoLibraryButton: {
      default: false,
      type: Boolean,
    },
    videoLibraryCreation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      proposedPlan: "",
      showPlanRegistration: false,
      showVideoLibrary: false,
    };
  },
  computed: {
    ...mapState(["plan"]),
    ...mapGetters({
      currentPlan: "plan/currentPlan",
      numberOfVideosInCampaigns: "campaign/numberOfVideosInCampaigns",
      videosInCampaignsLimit: "plan/videosInCampaignsLimit",
      siteId: "site/getSiteId",
    }),
  },

  methods: {
    async addCampaign() {
      //Video limit is based on the number of videos in librairy so we allow user to use those videos in campaign again
      // if (
      //   this.videosInCampaignsLimit &&
      //   this.numberOfVideosInCampaigns >= this.videosInCampaignsLimit
      // ) {
      //   if (this.currentPlan === "pro") {
      //     window.Intercom &&
      //       window.Intercom("trackEvent", "plan_enterprise_clicked");
      //     this.notifyTooManyVideos();
      //     return;
      //   }
      //   this.proposedPlan = await this.$store.dispatch("plan/getProposedPlan", {
      //     siteId: this.siteId,
      //   });
      //   this.showPlanRegistration = true;
      //   this.notifyTooManyVideos();
      //   return;
      // }
      this.$router.push({ name: "createCampaignStep1" });
    },
    notifyTooManyVideos() {
      this.$notify({
        text: this.$t("shared.toastMessage.tooManyVideos"),
        type: "error",
      });
    },
    openVideoLibrary() {
      this.showVideoLibrary = true;
      this.$emit("show-video-library");
    },
    closeVideoLibrary() {
      this.showVideoLibrary = false;
      this.$emit("close-video-library");
    },
  },
};
</script>

<style lang="scss">
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  .left-part {
    display: flex;
    align-items: center;
  }

  .right-part {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .button-primary {
    &.create-button {
      background-color: $dark-purple;
      color: white;
      z-index: 1;
      &:hover {
        background: $light-pink;
        color: black;
      }
      @include media("<=tablet") {
        display: none;
      }
    }

    .create-campaign-text {
      padding: 10px;
      @include font-normal;
      @include base-font;
    }

    .plus-icon {
      padding-left: 20px;
      height: 16px;
      width: 16px;
    }
  }
}

@include media(">phone", "<=tablet") {
  .header-create-campaign {
    top: 13%;
    left: 30%;
  }
}
@include media("<=phone") {
  .header-create-campaign {
    top: 13%;
    left: 30%;
  }
}
</style>
