<template>
  <div class="play-icon-settings">
    <div v-if="!formatObject.hideStoryPlay" class="icon-type-container">
      <h3
        class="title"
        :class="{
          'disabled-text': formatObject.hideStoryPlay,
        }"
      >
        {{ $t("create.step3.buttonSelector.buttonStyle") }}
        <div class="btn-group">
          <button class="navigation-arrow">
            <BtnArrow class="left" @click="scrollLeft" />
          </button>
          <button class="navigation-arrow">
            <BtnArrow class="right" @click="scrollRight" />
          </button>
        </div>
      </h3>
      <div
        class="icon-type-boxes play-icon-box"
        :class="{ 'bulk-carousel': isBulk }"
      >
        <button
          v-for="icon in icons"
          :key="icon"
          class="icon-type"
          :class="{
            selected: iconType === icon,
            bulk: isBulk,
            'not-allowed': formatObject.hideStoryPlay,
          }"
          :disabled="formatObject.hideStoryPlay"
          @click="
            onIconSettingsChange({
              inputFor: 'iconType',
              value: icon,
            })
          "
        >
          <PlayMinimal v-if="icon === 'minimal'" />
          <PlayRound v-if="icon === 'round'" />
          <PlayOutline v-if="icon === 'outline'" />
          <PlaySquare v-if="icon === 'square'" />
          <h3
            :class="{
              'disabled-text': formatObject.hideStoryPlay,
            }"
          >
            {{ icon | capitalize }}
          </h3>
        </button>
      </div>
    </div>
    <div v-if="!formatObject.hideStoryPlay" class="icon-customization">
      <div class="input-color">
        <h3 class="box-title">Icon color</h3>
        <button
          class="value-color"
          :disabled="formatObject.hideStoryPlay"
          :style="`background-color:${
            formatObject?.iconSettings?.color || 'white'
          }`"
          @click="showPicker(`color`)"
        />
      </div>
      <div
        class="input-color"
        :class="{
          disabled: iconType === 'minimal',
        }"
      >
        <h3 class="box-title">
          {{ $t("create.step3.buttonSelector.backgroundColor") }}
        </h3>
        <div class="buttons-color">
          <button
            class="value-color"
            :disabled="iconType === 'minimal'"
            :style="`background-color:${
              formatObject.iconSettings?.secondaryColor || 'grey'
            }`"
            @click="showPicker(`secondaryColor`)"
          />
        </div>
      </div>
      <toggle-box
        class="toggle-box"
        :options="[
          { text: $t('shared.toggleBox.none'), value: 'none' },
          { text: $t('shared.toggleBox.shadow'), value: 'wave' },
          { text: $t('shared.toggleBox.scale'), value: 'scale-down' },
        ]"
        :header="$t('shared.toggleBox.animation')"
        input-for="animation"
        :selected="formatObject.iconSettings?.animation"
        @option-selected="onIconSettingsChange($event)"
      ></toggle-box>
      <div
        v-if="displayPicker"
        key="color-picker"
        v-click-outside="hidePicker"
        class="vidjet-color-picker"
      >
        <colorpicker
          :value="
            formatObject?.iconSettings && formatObject?.iconSettings[pickerType]
          "
          @input="onPickerChange($event, pickerType)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ToggleBox from "../../shared/ToggleBox.vue";

import { Chrome } from "vue-color";
import PlayMinimal from "@/assets/svg/play-minimal.svg?inline";
import PlayOutline from "@/assets/svg/play-outline.svg?inline";
import PlayRound from "@/assets/svg/play-round.svg?inline";
import PlaySquare from "@/assets/svg/play-square.svg?inline";
import BtnArrow from "@/assets/svg/new-carousel-arrow.svg?inline";

export default {
  name: "PlayIconSettings",

  components: {
    ToggleBox,
    colorpicker: Chrome,
    PlayMinimal,
    PlayOutline,
    PlayRound,
    PlaySquare,
    BtnArrow,
  },

  props: {
    campaignFormatOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isBulk: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      icons: ["minimal", "round", "outline", "square"],
      displayPicker: false,
      pickerType: "",
    };
  },

  computed: {
    formatObject() {
      return this.campaignFormatOptions;
    },
    iconType() {
      return this.formatObject.iconSettings?.iconType;
    },
  },
  methods: {
    onPickerChange(e, name) {
      this.$emit("change-format-object", {
        inputFor: "iconSettings",
        value: { ...this.formatObject.iconSettings, [name]: e.hex8 },
      });
    },
    hidePicker() {
      this.displayPicker = false;
    },
    showPicker(type) {
      this.pickerType = type;
      this.displayPicker = true;
    },
    onIconSettingsChange(e) {
      this.$emit("change-format-object", {
        inputFor: "iconSettings",
        value: { ...this.formatObject.iconSettings, [e.inputFor]: e.value },
      });
    },
    scrollLeft() {
      let content = document.querySelector(".play-icon-box");
      content.scrollLeft -= 76;
    },
    scrollRight() {
      let content = document.querySelector(".play-icon-box");
      content.scrollLeft += 76;
    },
  },
};
</script>
<style lang="scss" scoped>
.value-color {
  margin-right: 0;
}
.icon-type-container:hover {
  background-color: $settings-hover;
}
.title {
  display: flex;
  justify-content: space-between;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  margin-bottom: 12px;
  .right {
    transform: rotate(180deg);
  }
}
.box-title {
  font-weight: 400;
}
.navigation-arrow {
  background: transparent;
  padding: 0;
  &:hover {
    rect {
      fill: $dark-purple;
    }
  }
}
.btn-group {
  @media (min-width: 1450px) {
    display: none;
  }
}

.bulk-carousel {
  gap: 8px;
  @media (max-width: 1350px) {
    max-width: 250px;
  }
  @media (max-width: 1250px) {
    max-width: 230px;
  }
}
.not-allowed {
  cursor: not-allowed;
}
.disabled-text {
  color: $light-grey;
}
button:disabled {
  cursor: not-allowed;
}
</style>
