<template>
  <small-modal
    :title="modalTitle"
    class="see-products-modal"
    :fit-content="true"
    @close="close"
  >
    <h2 class="subtitle">
      {{ $t("create.step2.seeProductsModal.subtitle") }}
      <span>{{ selectedVideo.fileName }}</span>
    </h2>
    <div
      v-if="!selectedVideo.productIds || selectedVideo.productIds.length === 0"
      class="empty-products-list"
    >
      <img
        class="sitting-man"
        src="@/assets/sitting-man.png"
        alt="sitting-man"
      />
      <div class="explanation">
        <p>{{ $t("create.step2.seeProductsModal.info1") }}</p>
        <p class="bold">{{ $t("create.step2.seeProductsModal.info2") }}</p>
        <div class="icons"></div>
        <FloatingButtons />
      </div>
    </div>
    <div v-else>
      <div v-for="product in filteredProducts" class="product-list">
        <div class="product-row">
          <div
            v-tippy="{ placement: 'top', arrow: true }"
            class="image-name"
            :content="product.name"
          >
            <div
              class="product-img-wrapper"
              @mouseenter="handleMouseEnter(product._id)"
              @mouseleave="handleMouseLeave"
            >
              <img
                v-lazy="product.imageUrl"
                class="product-img"
                alt="product-img"
              />
              <div
                v-if="product.productUrl"
                :class="[
                  'product-link',
                  { visible: hoveredProductId === product._id },
                ]"
              >
                <a :href="product.productUrl" target="_blank"
                  ><NewTabIcon class="new-tab-icon"
                /></a>
              </div>
            </div>
            <div class="name-info">
              <h2 class="product-name">{{ truncateName(product.name) }}</h2>
              <div>
                <p v-if="isShopify" class="product-info">
                  {{ firstVariantPrice(product.variants) }} -
                  {{ calculateTotalQty(product.variants) }}
                </p>
                <p v-else class="product-info">
                  {{ firstVariantPrice(product.variants) }}
                </p>
              </div>
            </div>
          </div>
          <div class="status">
            <p
              class="number-of-videos"
              :class="{ 'there-are-videos': product.videosAttached }"
            >
              <span v-if="product.videosAttached">{{
                product.videosAttached.length
              }}</span>
              <span v-else>0</span>
              {{ $t("account.usage.videos") }}
            </p>
          </div>
          <div class="widgets">
            <div
              v-if="
                product.formatsAttached && product.formatsAttached.length > 0
              "
              class="widgets-images"
            >
              <template v-for="format in product.formatsAttached">
                <tippy
                  :key="format.campaignId"
                  arrow="true"
                  placement="top"
                  a11y="false"
                  interactive="true"
                >
                  <template #trigger>
                    <component
                      :is="getWidgetComponent(format.formatName)"
                      class="widget-icon"
                    />
                  </template>
                  <div class="tippy-content">
                    <p>{{ getWidgetFormat(format.formatName) }}</p>
                    <p class="tooltip-campaign-name">
                      "{{ format.campaignName }}"
                    </p>
                  </div>
                </tippy>
              </template>
            </div>
            <div
              v-if="
                !product.formatsAttached ||
                product.formatsAttached.length < bulkCampaignCount.length
              "
              class="no-widget"
              @click="
                addWidget(
                  product.productId,
                  product.formatsAttached,
                  product.videosAttached
                )
              "
            >
              <p>+</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </small-modal>
</template>

<script>
import SmallModal from "@/components/shared/SmallModal";
import FloatingButtons from "@/assets/svg/floating-buttons-wrapper.svg?inline";
import BubbleWidget from "@/assets/svg/bubble-widget.svg?inline";
import CarouselWidget from "@/assets/svg/carousel-widget.svg?inline";
import StoriesWidget from "@/assets/svg/stories-widget.svg?inline";
import NewTabIcon from "@/assets/svg/open_new_tab.svg?inline";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    SmallModal,
    FloatingButtons,
    CarouselWidget,
    StoriesWidget,
    BubbleWidget,
    NewTabIcon,
  },
  props: {
    selectedVideo: {
      type: Object,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
    campaigns: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      hoveredProductId: null,
    };
  },
  computed: {
    ...mapState(["site"]),
    ...mapGetters({
      getCurrency: "site/getCurrency",
    }),
    modalTitle() {
      if (
        this.selectedVideo.productIds &&
        this.selectedVideo.productIds.length === 1
      ) {
        return 1 + " " + this.$t("create.step2.seeProductsModal.titleSingular");
      } else if (this.selectedVideo.productIds) {
        return (
          this.selectedVideo.productIds.length +
          " " +
          this.$t("create.step2.seeProductsModal.titlePlural")
        );
      } else {
        return 0 + " " + this.$t("create.step2.seeProductsModal.titlePlural");
      }
    },
    filteredProducts() {
      return this.products.filter((product) =>
        this.selectedVideo.productIds.includes(product._id)
      );
    },
    isShopify() {
      return this.site.site.integration === "shopify";
    },
    bulkCampaignCount() {
      return this.campaigns.filter((campaign) => Array.isArray(campaign.bulk));
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    firstVariantPrice(variants) {
      if (variants && variants.length > 0) {
        const firstVariant = variants[0];
        const price = firstVariant ? firstVariant.price : 0;

        if (this.getCurrency === "$") {
          return `$${price}`;
        } else if (this.getCurrency === "€") {
          return `${price}€`;
        } else {
          return `${price}${this.getCurrency}`;
        }
      } else {
        return "";
      }
    },
    calculateTotalQty(variants) {
      if (variants) {
        let totalQty = variants.reduce(
          (total, variant) => total + variant.qty,
          0
        );
        let hasUnlimited = variants.some((variant) => variant.sellWithoutStock);

        if (hasUnlimited) {
          return this.$t("create.bulk.pagesModal.stock.unlimited");
        } else if (totalQty === 0) {
          return this.$t("create.bulk.pagesModal.stock.outStock");
        } else if (!variants[0].qty) {
          return "No info";
        } else {
          return totalQty + " " + this.$t("create.bulk.pagesModal.stock.stock");
        }
      }

      return this.$t("create.bulk.pagesModal.stock.outStock");
    },
    truncateName(name) {
      return name.length > 23 ? name.substring(0, 20) + "..." : name;
    },
    getWidgetComponent(formatName) {
      switch (formatName) {
        case "carousel":
          return "CarouselWidget";
        case "story":
          return "StoriesWidget";
        case "bubble":
          return "BubbleWidget";
        default:
          return "";
      }
    },
    getWidgetFormat(formatName) {
      switch (formatName) {
        case "carousel":
          return "Carousel";
        case "story":
          return "Stories";
        case "bubble":
          return "Bubble";
        default:
          return "";
      }
    },
    addWidget(productId, formatsAttached) {
      if (this.bulkCampaignCount.length === 0) {
        let action = "";
        const path = this.$route.path;

        if (path.includes("campaign-manager")) {
          action = "VideoLibraryCampaignManagerWidgetCreation";
        } else if (path.includes("dashboard")) {
          action = "VideoLibraryDashboardWidgetCreation";
        } else if (path.includes("product-pages")) {
          action = "VideoLibraryProductPagesWidgetCreation";
        }

        this.$router.push({
          name: "Step1FromProductPages",
          query: {
            action: action,
            productId: productId,
          },
        });
      } else {
        const attachedCampaignIds = (formatsAttached || []).map(
          (format) => format.campaignId
        );

        const filteredBulkCampaignCount = this.bulkCampaignCount.filter(
          (campaign) => !attachedCampaignIds.includes(campaign._id)
        );
        this.$emit(
          "open-select-widget-modal",
          productId,
          filteredBulkCampaignCount
        );
      }
    },
    handleMouseEnter(productId) {
      this.hoveredProductId = productId;
    },
    handleMouseLeave() {
      this.hoveredProductId = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  @include base-font;
  font-weight: 400;
  margin-bottom: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  min-width: 700px;
  span {
    font-weight: 500;
  }
}
.empty-products-list {
  display: flex;
  align-items: center;
  gap: 36px;
  background-color: rgba(226, 124, 252, 0.1);
  padding: 24px;
  border-radius: 8px;
}
.explanation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.explanation {
  @include base-font;
  @include font-big;
  font-weight: 400;
  .bold {
    font-weight: 500;
  }
}

.product-row {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  gap: 24px;
  padding: 8px 2px;
  align-items: center;
  border-bottom: 1px solid $light-grey;
  &:hover {
    background-color: #c4c4c420;
  }
}
.product-list:first-child {
  border-top: 1px solid $light-grey;
}
.image-name {
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-img-wrapper {
  width: 38px;
  height: 38px;
  position: relative;
}
.product-img {
  width: 36px;
  height: 36px;
  border-radius: 2px;
  border: 1px solid $light-grey;
}
.product-link {
  display: none;
  position: absolute;
  width: 38px;
  height: 38px;
  top: 0;
  left: 0;
  background-color: rgba(6, 0, 7, 0.452);
  justify-content: center;
  align-items: center;
}
.product-link.visible {
  display: flex;
}
.new-tab-icon {
  path {
    fill: white;
  }
}
.name-info {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.product-name {
  @include font-small;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-info {
  @include font-smaller;
  font-weight: 400;
  color: #7f7f7f;
}

.status {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.number-of-videos {
  @include font-smaller;
  font-weight: 400;
  background-color: #e2e2e2;
  width: fit-content;
  border-radius: 20px;
  padding: 2px 12px;
  span {
    font-weight: 600;
  }
}

.there-are-videos {
  background-color: #d4f8d3;
}
.widgets {
  display: flex;
  align-items: center;
  gap: 8px;
}
.widgets-images {
  display: flex;
  gap: 8px;
  align-items: center;
}

.tippy-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  padding: 4px 8px;
}

.tooltip-campaign-name {
  font-style: italic;
}
.no-widget {
  width: 58.5px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fae8ffad;
  border: 2px dashed $dark-purple;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 2px;
  p {
    font-weight: 600;
    color: $dark-purple;
    border: 2px solid $dark-purple;
    border-radius: 2px;
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  &:hover {
    background-color: #fae8ff;
    cursor: pointer;
  }
}
</style>
