import { mapGetters } from "vuex";
export default {
  data() {
    return {
      showUploadProgress: false,
    };
  },
  computed: {
    ...mapGetters({
      getIsLoggedIn: "account/getIsLoggedIn",
      getSiteId: "account/getSiteId",
      getCampaignCreation: "campaign/campaignCreation",
      getUploadVideos: "video/getUploadVideos",
    }),
  },
  methods: {
    async init() {
      // Return to step one if no campaign creation has been started
      if (!this.isEditMode) {
        this.$nextTick(async function () {
          if (
            !this.$store.state.campaign.campaignCreation &&
            !this.$route.params.campaignId
          ) {
            return this.$router.push({ name: "campaignManager" });
          }
        });
      }
    },

    stopCreation() {
      this.$store.commit("campaign/stopCampaignCreation");
    },

    redirectToCampaignManager() {
      if (this.isEditMode) {
        return this.$router.push({
          name: "editCampaign",
        });
      } else {
        return this.$router.push({ name: "campaignManager" });
      }
    },

    async closeIfLoggedIn() {
      await this.stopCreation();
      this.redirectToCampaignManager();
    },
    handleCloseModal() {
      this.getIsLoggedIn
        ? this.closeIfLoggedIn()
        : (this.showSignupModal = true);
    },

    addSiteIdToCampaignCreation() {
      this.$store.commit("campaign/campaignCreationSetSiteId", this.getSiteId);
    },

    async saveCampaignToDb() {
      if (this.getIsLoggedIn && this.getSiteId) {
        try {
          this.addSiteIdToCampaignCreation();
          const campaign = await this.$store.dispatch(
            "campaign/createCampaign"
          );
          this.$router.push({
            params: { campaignId: campaign.id },
            name:
              this.isEmbed || this.isCarousel
                ? "embedStep4"
                : "createCampaignStep5",
          });
        } catch (err) {
          this.isLoading = false;
          this.$notify({
            title: this.$t("shared.toastMessage.error"),
            text: err,
            type: "error",
          });
        }
      } else {
        this.showSignupModal = true;
      }
    },
    async saveCampaignName(idCampaign) {
      await this.$store.dispatch("campaign/updateCampaign", {
        campaignId: idCampaign,
        dataToUpdate: { name: this.campaignName },
      });
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        ignoreDuplicates: true,
        text: this.$t("shared.toastMessage.campaignName"),
        type: "success",
      });
    },
  },
};
