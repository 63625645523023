const enums = {
  StateFlag: {
    deleted: -1,
    paused: 0,
    active: 1,
  },

  VideoType: {
    default: 0,
    customized: 1,
  },

  FormatType: {
    popup: 0,
    bubble: 1,
    embed: 2,
    carousel: 3,
  },

  DeviceType: {
    mobile: 0,
    desktop: 1,
  },

  CtaType: {
    link: 0,
    copy: 1,
    email: 2,
    addToCart: 3,
  },

  Position: {
    left: 0,
    right: 1,
  },

  TriggerType: {
    landing: 0,
    exit: 1,
    scroll: 2,
    delay: 3,
  },

  ComparaisonType: {
    equalTo: "eq",
    notEqualTo: "not_eq",
    contains: "contains",
    notContains: "not_contains",
  },

  UserPermissionType: {
    admin: 0,
    customer: 1,
  },

  PluginType: {
    shopify: "shopify",
    manual: "manual",
  },

  LockReason: {
    pageViewLimitReached: 'Page View Limit Reached',
    videoViewLimitReached: 'Video View Limit Reached',
    paymentFailure: 'Payment Failure',
    subscriptionCanceled: 'Subscription Canceled',
    shopifyChargeNotAccepted: 'Shopify Charge Not Accepted',
    freeTrialEnded: 'Free Trial Ended',
  },

  InvoiceState: {
    waiting: 0,
    paid: 1,
    failed: 2,
    refunded: 3,
  },

  CtaAction: {
    addtoCart: "addtoCart",
    buyNow: "buyNow",
  },
};

export default enums;
