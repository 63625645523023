<template>
  <fullscreen-modal @close-modal="handleCloseModal">
    <section
      v-lazy:background-image="require('@/assets/step5-shapes.svg')"
      class="launchStep"
    >
      <h1 class="embed-step-title">
        {{ $t("create.step4Embed.heading") }} <br />
        <span class="bold">{{ $t("create.step4Embed.subHeading") }}</span>
      </h1>
      <div class="container-center">
        <div class="campaign-name-frame">
          <h3 class="campaign-name-title">
            {{ $t("create.step5.name") }}
          </h3>
          <input
            v-model="campaignName"
            class="campaign-name-input"
            type="text"
            @change="saveCampaignName(campaign._id)"
          />
        </div>
        <div v-if="campaign.formats" class="script-to-copy">
          <p class="iframe-code-to-copy">
            {{ getEmbedCode(campaign) }}
          </p>
          <button
            v-clipboard="
              () => getEmbedCode(campaign).replace(/(\r\n|\n|\r)/gm, '')
            "
            v-clipboard:success="clipboardSuccessHandler"
            v-clipboard:error="clipboardErrorHandler"
            class="action-button"
          >
            {{ $t("create.step5.copyCode") }}
          </button>
        </div>
        <p class="back-to-campaign-manager" @click="backToCampaignManager()">
          {{ $t("create.step5.backToCampaignManager") }}
        </p>
        <div v-if="campaign.formats" class="embed-video-container">
          <div
            class="vidjet-video-wrapper"
            style="
              padding-bottom: 57.46714456391876%;
              position: relative;
              overflow: hidden;
              height: auto;
              margin: 0px auto;
            "
          >
            <iframe
              class="vidjet-embed-iframe"
              :src="getEmbedTutorialSrc()"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 10px;
              "
              allow="clipboard-read; clipboard-write; fullscreen"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
    <IntegrationPopUp
      v-if="showIntegrationPopup"
      @closePopUp="closeIntegrationPopup"
    />
  </fullscreen-modal>
</template>

<script>
import FullscreenModal from "../shared/Fullscreen-modal.vue";
import campaignCreationMixin from "./campaignCreationMixin";
import getEmbedCode from "@/utils/get-embed-code.js";
import IntegrationPopUp from "@/components/shared/IntegrationPopUp.vue";
import enums from "@/enums";
import { mapGetters, mapState } from "vuex";
const { FormatType } = enums;

export default {
  name: "EmbedStep4",
  components: {
    FullscreenModal,
    IntegrationPopUp,
  },

  mixins: [campaignCreationMixin],

  beforeRouteEnter(t, f, next) {
    next(async (vm) => {
      vm.init();
    });
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      campaignName: "",
      campaign: { name: "" },
      getEmbedCode,
      FormatType,
      showIntegrationPopup: false,
    };
  },
  computed: {
    ...mapState(["site"]),
    ...mapGetters({
      getSiteId: "account/getSiteId",
      singleCampaign: "campaign/getSingle",
    }),
  },
  async created() {
    await this.$store.dispatch("campaign/getSingle", {
      campaignId: this.$route.params.campaignId,
      siteId: this.getSiteId,
    });
    await this.$store.dispatch("site/getSite", {
      siteId: this.$store.state.account.user.siteId,
    });
    this.campaign = this.singleCampaign();

    this.campaignName = this.campaign.name;
  },

  methods: {
    clipboardSuccessHandler() {
      this.$notify({
        title: this.$t("shared.toastMessage.copied"),
        type: "success",
      });
    },
    clipboardErrorHandler() {
      this.$notify({
        title: this.$t("shared.toastMessage.failedToCopy"),
        type: "error",
      });
    },

    backToCampaignManager() {
      this.$store.commit("video/resetVideos");
      if (!this.site.site.integration) {
        this.showIntegrationPopup = true;
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("integration.toastMessage"),
          type: "error",
        });
        return;
      }
      this.$router.push({ name: "campaignManager" });
      if (this.isEditMode) {
        this.$notify({
          title: this.$t("shared.toastMessage.embedVideoUpdated"),
          text: this.$t("shared.toastMessage.forgetPaste"),
          type: "success",
        });
      } else {
        this.$notify({
          title: this.$t("shared.toastMessage.embedVideoCreated"),
          text: this.$t("shared.toastMessage.forgetPaste"),
          type: "success",
        });
      }
    },

    getEmbedTutorialSrc() {
      if (
        this.campaign &&
        this.campaign.formats &&
        this.campaign.formats[0] &&
        this.campaign?.formats[0].formatType === FormatType.carousel
      ) {
        return "https://player.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/f4f8e638-6c48-4b36-9b91-70a61dfd5a92";
      }

      return "https://player.vidjet.io/embed/ea450a97-86d5-47c2-8dde-5cbed280663b/82071710-0a20-4a8a-b801-1bd070300bc1";
    },
    closeIntegrationPopup() {
      this.showIntegrationPopup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.launchStep {
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  height: 100%;
  @include base-font;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  overflow: auto;
}

.embed-step-title {
  @include font-big;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  .bold {
    font-weight: 800;
  }
}

.container-center {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;
  align-items: center;
}

.campaign-name-frame {
  background: white;
  border-radius: 10px;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  width: 450px;
}

.campaign-name-title {
  @include font-normal;
  color: $dark-grey;
  margin-bottom: 16px;
}
.campaign-name-input {
  @include input-style;
}

.svg-copy {
  transform: scale(1.5);
}

.script-to-copy {
  position: relative;
  border: 1px solid $dark-purple;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: $card-border-radius;
  margin: 0 auto;
  overflow: auto;
  padding: 16px 24px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 24px;
  place-items: center;
  width: 60%;
  background-color: white;
  @include font-small;
}

.back-to-campaign-manager {
  @include base-font;
  @include font-normal;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.embed-video-container {
  width: 60%;
  margin: 0 auto 72px;
}
</style>
