<template>
  <nav class="navbar">
    <ul class="list-buttons">
      <router-link v-if="hideLogoIfAgencySite" to="/home">
        <li>
          <header class="navbar-header">
            <component
              :is="isHomeActive ? 'VidjetLogoDark' : 'VidjetLogoLight'"
              class="icon-logo"
              :class="{ 'active-logo': isHomeActive }"
            ></component>
          </header>
        </li>
      </router-link>
      <div v-for="tab in navigationTabs" :key="tab.name" class="tabs-tippy">
        <router-link
          :is="
            tab.name === 'Agency' && !isAgency && !isAdmin
              ? 'span'
              : 'router-link'
          "
          :to="tab.link"
          class="navbar-list"
        >
          <tippy arrow="true" placement="right" a11y="false" interactive="true">
            <template #trigger>
              <li
                class="navbar-list-item"
                :class="[
                  'navbar-list-item',
                  { active: isTabActive(tab) },
                  { 'invisible-tab': tab.name === 'Admin' },
                ]"
              >
                <component :is="tab.iconComponent"></component>
              </li>
            </template>
            <div>
              {{ tab.tooltip }}
            </div>
          </tippy>
        </router-link>
      </div>
      <div class="navbar-footer navbar-list">
        <div v-for="tab in footerTabs" :key="tab.name" class="tabs-tippy">
          <router-link
            :is="
              tab.name === 'Agency' && !isAgency && !isAdmin
                ? 'span'
                : 'router-link'
            "
            :to="tab.link"
            class="navbar-list"
          >
            <tippy
              arrow="true"
              placement="right"
              a11y="false"
              interactive="true"
            >
              <template #trigger>
                <li
                  class="navbar-list-item"
                  :class="[
                    'navbar-list-item',
                    { active: isTabActive(tab) },
                    { 'invisible-tab': tab.name === 'Admin' },
                  ]"
                >
                  <component :is="tab.iconComponent"></component>
                </li>
              </template>
              <div v-if="!isAgency && !isAdmin && tab.name === 'Agency'">
                <div class="tooltip-wrapper">
                  <div class="tooltip-left">
                    <div class="tooltip-title">
                      <LockedIcon class="locked-icon" />
                      <h1>{{ $t("agencyPortal.tooltip.title") }}</h1>
                    </div>
                    <p class="tooltip-text">
                      {{ $t("agencyPortal.tooltip.text") }}
                    </p>
                  </div>
                  <button
                    class="button-primary tooltip-button"
                    @click="openPlans"
                  >
                    {{ $t("agencyPortal.tooltip.button") }}
                  </button>
                </div>
              </div>
              <div v-else>
                {{ tab.tooltip }}
              </div>
            </tippy>
          </router-link>
        </div>
        <li class="navbar-list-item">
          <button
            v-tippy="{
              arrow: true,
              distance: 35,
              placement: 'right',
              a11y: false,
            }"
            class="navbar-list-item"
            :content="$t('shared.tooltip.help')"
            @click="toggleReportIssueModal"
          >
            <SupportIcon />
          </button>
        </li>
      </div>
    </ul>
  </nav>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BarChart from "@/assets/svg/sidebar/bar-chart.svg?inline";
import HamburgerMenu from "@/assets/svg/sidebar/hamburger-menu.svg?inline";
import VidjetLogo from "@/assets/svg/sidebar/vidjet-logo.svg?inline";
import LogoutIcon from "@/assets/svg/sidebar/logout.svg?inline";
import User from "@/assets/svg/sidebar/user.svg?inline";
import SupportIcon from "@/assets/svg/support-icon.svg?inline";
import ReportIssue from "@/components/shared/ReportIssue";
import Admin from "@/assets/svg/sidebar/admin.svg?inline";
import Agency from "@/assets/svg/sidebar/agency.svg?inline";
import BellIcon from "@/assets/svg/sidebar/bell-icon.svg?inline";
import BellIconActive from "@/assets/svg/sidebar/bell-icon-active.svg?inline";
import LockedIcon from "@/assets/svg/lock-icon-small.svg?inline";
import ProductIcon from "@/assets/svg/sidebar/product-icon.svg?inline";
import VidjetLogoDark from "@/assets/svg/sidebar/vidjet-logo-dark.svg?inline";
import VidjetLogoLight from "@/assets/svg/sidebar/vidjet-logo-light.svg?inline";

export default {
  name: "Navbar",
  components: {
    VidjetLogo,
    LogoutIcon,
    BarChart,
    HamburgerMenu,
    User,
    SupportIcon,
    ReportIssue,
    Admin,
    Agency,
    BellIcon,
    BellIconActive,
    LockedIcon,
    ProductIcon,
    VidjetLogoDark,
    VidjetLogoLight,
  },

  computed: {
    ...mapState(["site", "user"]),
    ...mapGetters({
      isAdmin: "account/getIsAdmin",
      isAgency: "account/getIsAgency",
      getSiteId: "account/getSiteId",
    }),
    hideLogoIfAgencySite() {
      return (
        this.site.site.agencyId !==
        ("8d2387b6-2039-440f-b937-2df2df8c3960" ||
          "e4c0ddcf-0559-4cbc-b802-773b25df3208")
      );
    },
    navigationTabs() {
      if (this.isAdmin) {
        return this.tabsTop;
      } else {
        const tabsCopy = this.tabsTop.slice();
        tabsCopy.pop();
        return tabsCopy;
      }
    },
    isHomeActive() {
      return this.$route.path === "/home";
    },
    tabsTop() {
      return [
        {
          name: "Dashboard",
          link: "/dashboard",
          iconComponent: "BarChart",
          tooltip: this.$t("shared.tooltip.dashboard"),
        },
        {
          name: "CampaignManager",
          link: "/campaign-manager",
          iconComponent: "HamburgerMenu",
          tooltip: this.$t("shared.tooltip.campaignManager"),
        },
        {
          name: "Products",
          link: "/product-pages",
          iconComponent: "ProductIcon",
          tooltip: this.$t("shared.tooltip.products"),
        },
        {
          name: "Admin",
          link: "/admin",
          iconComponent: "Admin",
          tooltip: "Admin",
        },
      ];
    },
    footerTabs() {
      return [
        {
          name: "Agency",
          link: "/agency",
          iconComponent: "Agency",
          tooltip: "Multi-account portal",
        },
        {
          name: "Settings",
          link: "/settings",
          iconComponent: "User",
          tooltip: this.$t("shared.tooltip.settings"),
        },
      ];
    },
  },
  async created() {
    await this.$store.dispatch("site/getIntegrations", {
      siteId: this.getSiteId,
    });
  },
  methods: {
    toggleReportIssueModal() {
      this.$emit("toggle-report-modal");
    },
    openPlans() {
      this.$router.push({ name: "billing" });
    },
    isTabActive(tab) {
      if (this.$route.path.startsWith(tab.link)) {
        return true;
      }
      return this.$route.matched.some((route) => {
        return route.path.startsWith(tab.link);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 100%;
  position: fixed;
  left: 0;
  width: $navbar-width;
  background: linear-gradient(179.38deg, #7b00b5 25%, #e47efd 100%);
  border-radius: 0px 15px 15px 0px;
  .navbar-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .navbar-list-item {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      &.active {
        color: $dark-purple;
        background-image: url("~@/assets/tab-bg.png");
        background-position: right;
        background-size: 90%;
        background-repeat: no-repeat;
      }
      button {
        background: none;
        color: white;
      }
    }
    svg {
      padding: 26px 0;
      path {
        fill: currentColor;
      }
    }
  }
  .list-buttons {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
  .navbar-list-item.invisible-tab {
    opacity: 0;
  }
  .navbar-header {
    display: flex;
    justify-content: center;
    align-content: center;
    svg {
      padding: 26px 0;
    }
  }
  .navbar-footer {
    margin-top: auto;
    margin-bottom: 20px;
  }
}
.router-link-active > .navbar-list-item {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEEAAABICAYAAACp+JiNAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHxSURBVHgB7ZrRUcJAEIb/0ICWcB2IFZgOxAqkA+0AO9AO0A60ArAC7AA6wA7O3WSTiTGHwIOD9+83c7OPcF/uNnfZBRzHcWpijOcjOGOXAFyAHdkOKzAjAkoZEczI/NfUEmTuD9EAIzLvaewANmTO97EHWJC5BhmLOAByRk+DMiapyTcUOBL9AQkl6sNGsHEqhF7cyUESOhO/s5gFe0vQhCJhJuMcmfGrBJn8WMJcxhiZsvMCZU9fz9bZClCSEvREJeERBAxuBxMwAwk/JLAJUL5JsCRId7/uS1jjtA49f0KbGG0bBBBSSdDLhYRbkNKshBKkq0CpcgJrLmgY2RshgBjdDlcgRyWUIEclBJDjEoSC6mtrAi/IwiVUuAS4hAqVsAE5KuED5PhKQC3hDeQ02+ETxIyKolABryCmeUW+gJhKgqyGpYQlSGk/ubPWHJT2xCirQRPkEwgZKsNlX4XuM3R3uAHZASpVlQ4SFiD56pTsVGESkbxKS6LcSLgEQbLcq3FLu8FR9y0FZMihLXxT1IXbEhlxVDOn5YtrGRPUr9N/3dZ3dEdrF2vyDDLOcDrof9IHVMKrbG1z9zzV2wwmTMaaWoJine8raglKXwRYsa2xpZagyPxnLqHeFlvqMpx9ZH72WiTwDnbsCuA4juN0+QInDmRL2XtAkgAAAABJRU5ErkJggg==);
  background-position: right;
  background-size: 90%;
  background-repeat: no-repeat;
  svg {
    color: $dark-purple;
  }
}

.icon-logo {
  width: 100%;
  margin-left: 8px;
}
.active-logo {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEEAAABICAYAAACp+JiNAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHxSURBVHgB7ZrRUcJAEIb/0ICWcB2IFZgOxAqkA+0AO9AO0A60ArAC7AA6wA7O3WSTiTGHwIOD9+83c7OPcF/uNnfZBRzHcWpijOcjOGOXAFyAHdkOKzAjAkoZEczI/NfUEmTuD9EAIzLvaewANmTO97EHWJC5BhmLOAByRk+DMiapyTcUOBL9AQkl6sNGsHEqhF7cyUESOhO/s5gFe0vQhCJhJuMcmfGrBJn8WMJcxhiZsvMCZU9fz9bZClCSEvREJeERBAxuBxMwAwk/JLAJUL5JsCRId7/uS1jjtA49f0KbGG0bBBBSSdDLhYRbkNKshBKkq0CpcgJrLmgY2RshgBjdDlcgRyWUIEclBJDjEoSC6mtrAi/IwiVUuAS4hAqVsAE5KuED5PhKQC3hDeQ02+ETxIyKolABryCmeUW+gJhKgqyGpYQlSGk/ubPWHJT2xCirQRPkEwgZKsNlX4XuM3R3uAHZASpVlQ4SFiD56pTsVGESkbxKS6LcSLgEQbLcq3FLu8FR9y0FZMihLXxT1IXbEhlxVDOn5YtrGRPUr9N/3dZ3dEdrF2vyDDLOcDrof9IHVMKrbG1z9zzV2wwmTMaaWoJine8raglKXwRYsa2xpZagyPxnLqHeFlvqMpx9ZH72WiTwDnbsCuA4juN0+QInDmRL2XtAkgAAAABJRU5ErkJggg==);
  background-position: right;
  background-size: 100%;
  background-repeat: no-repeat;
}

.tooltip-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px 16px;
}

.tooltip-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
}

.tooltip-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  h1 {
    @include base-font;
    @include font-small;
    font-weight: 700;
    color: white;
  }
}

.tooltip-text {
  @include base-font;
  @include font-small;
  font-weight: 400;
  color: white;
  text-align: start;
}

.tooltip-button {
  width: fit-content;
  @include font-small;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
}
</style>
