<template>
  <div class="pages-segmentation-nav">
    <label class="segmentation-label">
      <input
        v-model="localSelectedOption"
        type="radio"
        value="productPages"
        class="segmentation-radio"
        :disabled="!isProductSegAllowed"
        @change="optionSelected('productPages')"
      />
      <span class="segmentation-text">
        <TagIcon class="icon" />
        {{ $t("create.bulk.segmentationNav.product") }} ({{
          numberOfProductsInSeg
        }})
      </span>
      <tippy
        :trigger="!isProductSegAllowed ? 'mouseenter' : 'manual'"
        arrow="true"
        placement="top"
        a11y="false"
        interactive="true"
      >
        <div class="tooltip">
          <h1 class="tooltip-text">
            {{ $t("create.bulk.segmentationNav.tooltip") }}
          </h1>
          <button class="tooltip-button">
            <a
              href="https://help.vidjet.io/en/articles/8446441-add-product-catalog-to-vidjet-not-available-yet"
              target="_blank"
              >{{ $t("create.bulk.segmentationNav.tooltipButton") }}</a
            >
          </button>
        </div>
      </tippy>
    </label>
    <label class="segmentation-label">
      <input
        v-model="localSelectedOption"
        type="radio"
        value="pageUrls"
        class="segmentation-radio"
        @change="optionSelected('pageUrls')"
      />
      <span class="segmentation-text">
        <LinkIcon class="icon" />{{ $t("create.bulk.segmentationNav.url") }} ({{
          numberOfUrlsInSeg
        }})
      </span>
    </label>
  </div>
</template>

<script>
import LinkIcon from "@/assets/svg/link-icon.svg?inline";
import TagIcon from "@/assets/svg/tag-icon.svg?inline";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    LinkIcon,
    TagIcon,
  },
  props: {
    selectedOption: String,
    numberOfProducts: Number,
    urlSegmentation: {
      type: [Array, Object],
      default: () => {},
    },
  },
  data() {
    return {
      localSelectedOption: "",
    };
  },
  computed: {
    ...mapState({ site: "site" }),
    ...mapGetters({
      getSiteId: "account/getSiteId",
    }),
    isProductSegAllowed() {
      return (
        this.site.site.integration === "shopify" ||
        this.site.site.integration === "woocommerce" ||
        this.site.site.integration === "prestashop"
      );
    },
    numberOfProductsInSeg() {
      if (!this.numberOfProducts) {
        return 0;
      }

      return this.numberOfProducts;
    },
    numberOfUrlsInSeg() {
      if (!this.urlSegmentation || this.urlSegmentation.length === 0) {
        return 0;
      }

      const firstFilter = this.urlSegmentation[0];

      if (this.isFilterEmpty(firstFilter)) {
        return 0;
      }

      if (firstFilter.filters && firstFilter.filters.length > 0) {
        return this.calculateSumOfStringInFilters(firstFilter.filters);
      }

      return 1;
    },
  },
  watch: {
    selectedOption(newValue) {
      this.localSelectedOption = newValue;
    },
  },
  async mounted() {
    await this.$store.dispatch("site/getSite", {
      siteId: this.$store.state.account.user.siteId,
    });

    //Props are not 100% ready on mounted
    this.$nextTick(() => {
      if (this.numberOfProductsInSeg > 0) {
        this.localSelectedOption = "productPages";
      } else if (this.urlSegmentation && this.urlSegmentation.length > 0) {
        this.localSelectedOption = "pageUrls";
      } else {
        this.localSelectedOption = "productPages";
      }
      this.$emit("optionSelected", this.localSelectedOption);
    });
  },
  methods: {
    optionSelected(option) {
      this.localSelectedOption = option;
      this.$emit("optionSelected", option);
    },
    calculateSumOfStringInFilters(filters) {
      let sum = 0;

      filters.forEach((filter) => {
        if (filter.string !== "" && filter.string !== undefined) {
          sum += 1;
        }

        if (filter.filters && filter.filters.length > 0) {
          sum += this.calculateSumOfStringInFilters(filter.filters);
        }
      });

      return sum;
    },
    isFilterEmpty(filter) {
      if (filter.string) return filter.string === "";

      return filter.filters && filter.filters.every(this.isFilterEmpty);
    },
  },
};
</script>

<style lang="scss" scoped>
.pages-segmentation-nav {
  width: 100%;
  min-height: 64px;
  height: 64px;
  background-color: $dark-purple;
  border-radius: 4px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.segmentation-label {
  @include base-font;
  @include font-normal;
  font-weight: 500;
  color: white;
  display: flex;
  gap: 12px;
  align-items: center;
}

.segmentation-radio {
  display: none;
}

.segmentation-text {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.segmentation-radio:checked + .segmentation-text {
  background-color: white;
  color: $dark-purple;
  .icon {
    path {
      fill: $dark-purple;
    }
  }
}

.tooltip {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px 16px;
  background-color: transparent;
}
.tooltip-text {
  @include base-font;
  @include font-small;
  font-weight: 500;
  color: white;
}
.tooltip-button {
  padding: 8px 16px;
  border-radius: 20px;
  a {
    @include base-font;
    @include font-small;
    font-weight: 500;
    text-decoration: none;
    white-space: nowrap;
  }
}
</style>
