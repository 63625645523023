<template>
  <div class="main-content-wrapper">
    <div class="flex">
      <h1>Admin page</h1>
      <button class="button-primary" @click="refreshCache">
        Refresh cache
      </button>
    </div>
    <h3>
      <strong>Logged as</strong>: {{ site.site.companyName }} /
      {{ site.site.websiteUrl }} /
      {{ site.site._id }}
    </h3>
    <div class="buttons">
      <button
        v-for="(field, index) in searchFields"
        :key="index"
        :class="{ searchField: true, active: index === isActive }"
        @click="selectedField(index)"
      >
        {{ field }}
      </button>
    </div>
    <div class="search-bar">
      <input
        v-model="typedInput"
        type="text"
        @keyup.enter="getSearchResult()"
      />
      <button class="button-primary" @click="getSearchResult()">Search</button>
    </div>
    <div class="result">
      <div
        v-for="(result, index) in searchResult"
        :key="index"
        class="search-result"
      >
        <p>Company Name: {{ result.companyName }}</p>
        <p>siteId: {{ result._id }}</p>
        <p>URL: {{ result.websiteUrl }}</p>
        <p>country: {{ result.country }}</p>
        <p>Integration: {{ result.integration }}</p>
        <button class="button-primary" @click="logAs(index)">Log as</button>
      </div>
      <div class="flex">
        <button class="button-primary" @click="logOut()">Log out</button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../api";
import { mapState } from "vuex";

export default {
  data() {
    return {
      searchFields: ["Site id", "URL", "Company name"],
      isActive: null,
      typedInput: "",
      searchInput: null,
      searchResult: "",
      loggedAs: "",
    };
  },

  computed: {
    ...mapState(["site", "user"]),
  },

  async created() {
    await this.$store.dispatch("site/getSite", {
      siteId: this.$store.state.account.user.siteId,
    });
    await this.$store.dispatch("user/getUser", {
      userId: this.$store.state.account.user._id,
    });
    if (!this.user.user.isAdmin) {
      this.$router.push("dashboard");
    }
    this.$store.dispatch("site/saveAgencyId", "");
  },
  methods: {
    async refreshCache() {
      try {
        await api.refreshCache(this.site.site._id)

        this.$notify({
          title: this.$t("shared.toastMessage.cacheRefreshed"),
          type: "success",
        });
      } catch (err) {
        this.$notify({
          title: this.$t("shared.toastMessage.cacheError"),
          type: "error",
        });
        throw err;
      }
    },
    selectedField(index) {
      this.isActive = index;
      this.searchInput = this.searchFields[index];
    },

    getSearchResult() {
      if (this.searchInput === "Site id") {
        const query = { siteId: this.typedInput };
        this.retrieveSite(query);
      } else if (this.searchInput === "URL") {
        const query = { websiteUrl: this.typedInput };
        this.retrieveSite(query);
      } else if (this.searchInput === "Company name") {
        const query = { companyName: this.typedInput };
        this.retrieveSite(query);
      }
    },
    async retrieveSite(query) {
      try {
        const result = await api.adminSearch(query);
        this.searchResult = result;
        return result;
      } catch (err) {
        console.log(err);
      }
    },
    async logAs(index) {
      const resultId = this.searchResult[index]._id;

      await this.$store.dispatch("account/logAs", {
        siteId: resultId,
      });

      await this.$store.dispatch("site/getSite", {
        siteId: this.$store.state.account.user.siteId,
      });

      this.$store.dispatch("site/isLoggedAs", true);

      this.$notify({
        title: this.$t("shared.toastMessage.loggedAs"),
        type: "success",
      });
    },

    async logOut() {
      try {
        const originalUser = await api.getUser(
          this.$store.state.account.user._id
        );

        await this.$store.dispatch("account/logAs", {
          siteId: originalUser.siteId,
        });

        await this.$store.dispatch("site/getSite", {
          siteId: this.$store.state.account.user.siteId,
        });
        this.$store.dispatch("site/isLoggedAs", false);

        this.$notify({
          title: this.$t("shared.toastMessage.loggedOut"),
          type: "success",
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.buttons {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 32px;
  margin-top: 32px;
}

.searchField {
  @include base-font;
  color: $dark-purple;
  background-color: white;
  border: 1px solid $dark-purple;
  border-radius: $card-border-radius;
  font-weight: bold;
  padding: 4px 12px;
}

.searchField.active {
  color: white;
  background-color: $dark-purple;
}

.search-bar {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 32px;
  input {
    height: 100%;
    align-self: center;
    width: 30vw;
    @include input-style;
  }
}

.search-result {
  p {
    margin-bottom: 16px;
  }
}

.flex {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 16px;
}
</style>
