<template>
  <div class="content">
    <toggle-box
      class="toggle-box"
      :options="[
        { text: $t('shared.toggleBox.left'), value: 0 },
        { text: $t('shared.toggleBox.right'), value: 1 },
      ]"
      :header="$t('create.step3.bubblePositionHeader')"
      input-for="position"
      :selected="formatObject.position"
      @option-selected="handleFormatEvent"
    ></toggle-box>

    <toggle-box
      class="toggle-box"
      :options="[
        { text: $t('shared.toggleBox.round'), value: 'round' },
        { text: $t('shared.toggleBox.square'), value: 'square' },
        { text: $t('shared.toggleBox.original'), value: 'original' },
      ]"
      :header="$t('shared.toggleBox.bubbleShape')"
      input-for="bubbleShape"
      :selected="formatObject.bubbleShape"
      @option-selected="handleFormatEvent"
    ></toggle-box>
    <toggle-box
      class="toggle-box"
      :options="[
        { text: $t('shared.toggleBox.small'), value: 60 },
        { text: $t('shared.toggleBox.medium'), value: 90 },
        { text: $t('shared.toggleBox.large'), value: 120 },
      ]"
      :header="$t('create.step3.bubbleSize')"
      input-for="bubbleSize"
      :selected="formatObject.bubbleSize"
      @option-selected="handleFormatEvent"
    ></toggle-box>
    <div class="bubble-placement-container">
      <TextWithSlider
        :label-text="$t('create.step3.sideMargin')"
        input-for="moveX"
        :max-value="1000"
        :step="5"
        :preset-value="formatObject.moveX"
        @toggle-button="handleFormatEvent"
      />
      <TextWithSlider
        :label-text="$t('create.step3.bottomMargin')"
        input-for="moveY"
        :max-value="500"
        :step="5"
        :preset-value="formatObject.moveY"
        @toggle-button="handleFormatEvent"
      />
      <text-with-btn-and-color
        :label-text="$t('create.step3.bubbleBorder')"
        input-for="bubbleBorder"
        :preset-value="bubbleBorder"
        :color="formatObject.bubbleBorderColor"
        @toggle-button="handleFormatEvent"
        @picker-change="onPickerChange"
      />
      <TextWithBtnToggle
        :label-text="$t('create.step3.hideBubble')"
        input-for="hideBubble"
        :preset-value="
          formatObject.bubbleShape === 'original' || formatObject.hideBubble
        "
        :disabled="isHideBubbleDisabled"
        @toggle-button="handleFormatEvent"
      />
    </div>
  </div>
</template>

<script>
import TextWithBtnAndColor from "./TextWithBtnAndColor.vue";
import TextWithSlider from "./TextWithSlider.vue";
import ToggleBox from "../../shared/ToggleBox.vue";
import TextWithBtnToggle from "./TextWithBtnToggle.vue";

export default {
  name: "BubbleAppearance",

  components: {
    TextWithSlider,
    TextWithBtnAndColor,
    ToggleBox,
    TextWithBtnToggle,
  },

  props: {
    formatObject: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isHideBubbleDisabled() {
      return (
        this.chosenDevice === "mobile" ||
        this.isMultiple ||
        this.formatObject.bubbleShape === "original"
      );
    },
    bubbleBorder() {
      if (!this.formatObject.bubbleBorder) return false;
      return true;
    },
  },
  methods: {
    handleFormatEvent(payload) {
      this.$emit("change-format-object", payload);
    },
    onPickerChange(e) {
      const payload = {
        inputFor: "bubbleBorderColor",
        value: e.hex8,
      };
      this.handleFormatEvent(payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.bubble-placement-container {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.box-title {
  @include base-font;
  @include font-small;
  text-align: start;
}
.value-color {
  margin-right: 0;
}
.color-setting-label {
  @include font-small;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
}
</style>
