import axios from "axios";

export default {
  async getVideo(videoId) {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    try {
      const res = await axios(
        `${process.env.VUE_APP_API_URL}/video/${videoId}`,
        requestOptions
      );
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  },

  async getVideos(data) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: data.query,
    };

    try {
      const res = await axios(
        `${process.env.VUE_APP_API_URL}/videos/${data.siteId}`,
        requestOptions
      );
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  },

  async getInstagramVideos(id, token, paginationUrl) {
    const url = paginationUrl
      ? paginationUrl
      : `https://graph.facebook.com/v16.0/${id}/media?fields=media_url,username,thumbnail_url,caption,timestamp&access_token=${token}&limit=8`;
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    try {
      const res = await axios(url, requestOptions);
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  },

  async getInstagramAccountInformation(token) {
    const url = `https://graph.facebook.com/v16.0/me/accounts?fields=id,name,instagram_business_account&access_token=${token}`;
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    try {
      const res = await axios(url, requestOptions);
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  },

  async createVideo(data) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data,
    };

    try {
      const res = await axios(
        `${process.env.VUE_APP_API_URL}/video`,
        requestOptions
      );
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  },
  async updateVideo(videoId, data) {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data,
    };

    try {
      const res = await axios(
        `${process.env.VUE_APP_API_URL}/video/${videoId}`,
        requestOptions
      );
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  },
  async deleteVideo(videoId) {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    try {
      const res = await axios(
        `${process.env.VUE_APP_API_URL}/video/${videoId}`,
        requestOptions
      );
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  },
};
