<template>
  <div class="google-integration-wrapper">
    <div v-if="!short">
      <h2 class="google-integ-subtitle">
        {{ $t("integration.modal.tool.gsheet.subtitle") }}
      </h2>
    </div>
    <a
      v-if="!googleIntegration"
      class="action-button sign-up-google"
      @click="getGoogleAuthUrl()"
    >
      <img
        v-lazy="require(`@/assets/google-logo.png`)"
        class="google-logo-img"
        alt="google-logo"
      />
      {{ $t("integration.modal.tool.gsheet.signUp") }}
      <spinner-loader v-if="isLoading" />
    </a>
    <a
      v-else
      :href="googleIntegration.emailSheet.url"
      class="action-button sign-up-google"
      target="_blank"
    >
      <img
        v-lazy="require(`@/assets/google-sheet-logo.png`)"
        class="google-logo-img"
        alt="google-sheets-logo"
      />
      {{ $t("integration.modal.tool.gsheet.openGoogleSheet") }}
    </a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../../../api";
import SpinnerLoader from "../../../components/shared/SpinnerLoader.vue";

export default {
  components: {
    SpinnerLoader,
  },
  props: {
    short: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      interval: null,
      isLoading: false,
      attempts: 0,
      intervalDuration: 1000,
      maxAttempts: 20,
    };
  },
  computed: {
    ...mapGetters({
      siteId: "account/getSiteId",
    }),
    googleIntegration() {
      return this.$store.state.site.toolIntegrations.find(
        (integration) => integration.type == "google"
      );
    },
  },
  beforeDestroy() {
    if (this.interval) this.clearInterval();
  },
  async created() {
    await this.$store.dispatch("site/getIntegration", {
      siteId: this.siteId,
      type: "google",
    });
  },
  methods: {
    async getGoogleAuthUrl() {
      this.isLoading = true;
      let res = await api.getIntegrationUrlGoogle(this.siteId);
      window.open(res.authUrl, "_blank");
      this.triggerIntegrationSearch();
    },
    loadIntegrations() {
      if (!this.googleIntegration) {
        this.$store.dispatch("site/getIntegrations", {
          siteId: this.siteId,
        });
        this.attempts++;
      }

      if (this.googleIntegration) {
        this.$notify({
          title: this.$t("integration.modal.tool.gsheet.integrationSuccess"),
          type: "success",
        });
        this.isLoading = false;
        this.clearInterval();
      } else if (this.attempts >= this.maxAttempts) {
        this.$notify({
          title: this.$t("integration.modal.tool.gsheet.integrationFailure"),
          type: "error",
        });
        this.isLoading = false;
        this.clearInterval();
      }
    },
    triggerIntegrationSearch() {
      this.interval = setInterval(this.loadIntegrations, this.intervalDuration);
    },
    clearInterval() {
      clearInterval(this.interval);
      this.attempts = 0;
    },
  },
};
</script>

<style lang="scss">
.google-integration-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.google-integ-title {
  text-align: center;
  @include font-big;
}

.google-integ-subtitle {
  text-align: center;
  margin-bottom: 32px;
}

.sign-up-google {
  min-width: 180px;
  max-width: 200px;
  height: 24px;
  text-decoration: none;
}
.google-logo-img {
  width: 16px;
}
</style>
