import api from "../api";

const state = {
  dashboardGlobalMetrics: {},
  dashboardGlobalMetricsforLast7Days: {},
  dashboardGraphMetrics: {},
  dashboardCampaignMetrics: [],
};

const mutations = {
  dashboardGraphMetrics(state, dashboardGraphMetrics) {
    state.dashboardGraphMetrics = dashboardGraphMetrics;
  },
  dashboardGlobalMetricsforLast7Days(state, dashboardGlobalMetrics) {
    state.dashboardGlobalMetricsforLast7Days = dashboardGlobalMetrics;
  },
  dashboardCampaignMetrics(state, dashboardCampaignMetrics) {
    state.dashboardCampaignMetrics = dashboardCampaignMetrics;
  },
  dashboardGlobalMetrics(state, dashboardGlobalMetrics) {
    state.dashboardGlobalMetrics = dashboardGlobalMetrics;
  },
};

const actions = {
  async dashboardGraphMetrics({ dispatch, commit }, {query, isAgency}) {
    try {
      const dashboardGraphMetrics = isAgency
        ? await api.getAgencyDashboardGraphMetrics(query)
        : await api.getDashboardGraphMetrics(query);
      commit("dashboardGraphMetrics", dashboardGraphMetrics);
    } catch (err) {
      throw err;
    }
  },

  async dashboardGlobalMetrics({ dispatch, commit }, {query, isAgency}) {
    try {
      const dashboardGlobalMetrics = isAgency
        ? await api.getAgencyDashboardGlobalMetrics(query)
        : await api.getDashboardGlobalMetrics(query);
      commit("dashboardGlobalMetrics", dashboardGlobalMetrics);
    } catch (err) {
      throw err;
    }
  },

  async dashboardGlobalMetricsforLast7Days({ dispatch, commit }, query) {
    try {
      const dashboardGlobalMetrics = await api.getDashboardGlobalMetrics(query);
      commit("dashboardGlobalMetricsforLast7Days", dashboardGlobalMetrics);
    } catch (err) {
      throw err;
    }
  },
  async dashboardCampaignMetrics({ dispatch, commit }, query) {
    try {
      const dashboardCampaignMetrics = await api.getDashboardCampaignMetrics(
        query
      );
      commit("dashboardCampaignMetrics", dashboardCampaignMetrics);
    } catch (err) {
      throw err;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
