<template>
  <div class="default-open-input">
    <span class="open-text" :class="{ disabled: disabled }">{{
      labelText
    }}</span>
    <div class="buttons">
      <div v-if="value === true" class="color-settings">
        <button
          class="value-color"
          :style="`background-color:${color}`"
          @click="displayPicker = true"
        />
      </div>
      <vidjet-toggle-btn
        v-model="value"
        :checked="value"
        :text-after="labelText"
        :is-disabled="disabled"
      />
    </div>
    <div
      v-if="displayPicker"
      key="color-picker"
      v-click-outside="hidePicker"
      class="vidjet-color-picker"
    >
      <colorpicker :value="color" @input="onPickerChange" />
    </div>
  </div>
</template>

<script>
import VidjetToggleBtn from "@/components/shared/Vidjet-toggle-btn.vue";
import { Chrome } from "vue-color";
export default {
  components: {
    VidjetToggleBtn,
    colorpicker: Chrome,
  },

  props: {
    inputFor: {
      type: String,
      default: "",
    },
    presetValue: {
      type: Boolean,
      default: true,
    },
    labelText: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#000000",
    },
  },

  data() {
    return {
      value: true,
      displayPicker: false,
    };
  },

  watch: {
    value() {
      this.$emit("toggle-button", {
        inputFor: this.inputFor,
        value: this.value,
      });
    },
    presetValue() {
      this.value = this.presetValue;
    },
  },

  created() {
    this.value = this.presetValue;
  },
  methods: {
    onPickerChange(payload) {
      this.$emit("picker-change", payload);
    },
    hidePicker() {
      this.displayPicker = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.default-open-input {
  @include font-small;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  &:hover {
    background: $settings-hover;
  }
}

.open-text {
  align-self: center;
  text-align: left;
}
.disabled {
  color: $light-grey;
}
.value-color {
  height: 20px;
  width: 20px;
  border-radius: 1px;
}
.buttons {
  display: flex;
  gap: 16px;
}
</style>
