<template>
  <div :class="['format-block']">
    <div class="button-and-toggle">
      <h3 class="format-block-title">
        {{ $t("create.step3.button") }}
      </h3>
      <vidjet-toggle-btn
        class="toggle-btn-cta"
        :checked="showCtaOptions"
        @change="toggleCta"
      />
    </div>
    <div v-if="showCtaOptions" key="enable-cta" class="format-block-body">
      <div>
        <CtaVSelect
          v-model="ctaObject.ctaType"
          :placeholder="placeholderForVSelect"
          :options="ctaOptions"
          :is-disabled="ctaObject.hasOwnProperty('deviceType')"
        />
      </div>

      <div
        v-if="displayPicker"
        key="color-picker"
        v-click-outside="hidePicker"
        class="vidjet-color-picker"
      >
        <colorpicker :value="colorChosen" @input="onPickerChange" />
      </div>
      <div class="accordion-container">
        <vidjet-accordion
          v-show="!isCtaEmail && !isCtaAddToCart"
          id="accordion-text"
          :is-open-by-default="true"
        >
          <template #title>
            <span>{{
              $t("create.step3.buttonSelector.placeholder.buttonText")
            }}</span>
          </template>
          <template #content>
            <div class="button-inputs">
              <input
                v-show="!isCtaEmail && !isCtaAddToCart"
                :id="`${chosenDevice}-cta-value`"
                v-model="ctaObject.value"
                :name="`${chosenDevice}-cta-value`"
                class="text-input --with-borders smallInput"
                type="text"
                :placeholder="`${
                  ctaObject.ctaType === CtaType.copy
                    ? $t('create.step3.buttonSelector.placeholder.coupon')
                    : $t('create.step3.buttonSelector.placeholder.URL')
                }`"
                :disabled="ctaObject.hasOwnProperty('deviceType')"
                @input="formatUrl"
              />
              <input
                v-show="!isCtaEmail && !isCtaAddToCart"
                :id="`${chosenDevice}-cta-text`"
                v-model="ctaObject.text"
                :name="`${chosenDevice}-cta-text`"
                maxlength="17"
                class="text-input --with-borders smallInput"
                type="text"
                :placeholder="
                  $t('create.step3.buttonSelector.placeholder.buttonText')
                "
                :disabled="ctaObject.hasOwnProperty('deviceType')"
                @input="onCtaTextChange($event)"
              />
            </div>
          </template>
        </vidjet-accordion>
        <vidjet-accordion
          v-if="ctaObject.ctaType == CtaType.email"
          id="accordion-email"
          :is-open-by-default="true"
        >
          <template #title>
            <span>
              {{ $t("create.step3.buttonSelector.emailIntegration") }}</span
            >
          </template>
          <template #content>
            <cta-site-integration-block
              :integrations="integrations"
              @openModal="toggleToolsModal()"
            ></cta-site-integration-block>
          </template>
        </vidjet-accordion>

        <vidjet-accordion
          v-if="isCtaAddToCart"
          id="accordion-products"
          :is-open-by-default="true"
        >
          <template #title>
            <span> {{ $t("create.step3.buttonSelector.products") }}</span>
          </template>
          <template #content>
            <button class="action-button" @click="$emit('open-add-to-cart')">
              <CartIcon />
              <h3>{{ $t("create.step3.buttonSelector.addProductsBtn") }}</h3>
            </button>
            <div class="product-cards">
              <draggable
                :list="products"
                v-bind="{ handle: '.drag-number' }"
                @change="changeOrder"
              >
                <div
                  v-for="({ imageUrl, name, _id }, index) in products"
                  v-if="_id !== ''"
                  :key="_id"
                  class="product-card"
                >
                  <div class="img-container">
                    <div
                      v-lazy:background-image="imageUrl"
                      class="product-image"
                    ></div>
                    <p class="product-name">
                      {{
                        name.length > 28 ? name.substring(0, 28) + "..." : name
                      }}
                    </p>
                  </div>
                  <div class="drag-number">
                    <DragDropIcon class="drag-icon" />
                    <h3>{{ index + 1 }}</h3>
                  </div>
                </div>
              </draggable>
            </div>
          </template>
        </vidjet-accordion>
        <vidjet-accordion id="accordion-buttons">
          <template #title>
            <span> {{ $t("create.step3.buttonSelector.buttonTypes") }}</span>
          </template>
          <template #content>
            <div v-if="isCtaAddToCart" class="atc-type-container">
              <div class="atc-type-container">
                <toggle-box
                  :options="[
                    {
                      text: $t('shared.toggleBox.addToCart'),
                      value: CtaAction.addtoCart,
                    },
                    {
                      text: $t('shared.toggleBox.buyNow'),
                      value: CtaAction.buyNow,
                    },
                  ]"
                  input-for="ctaAction"
                  :header="$t('shared.toggleBox.mainButtonType')"
                  :selected="ctaObject.ctaAction || 'addToCart'"
                  @option-selected="ctaObject.ctaAction = $event.value"
                ></toggle-box>
              </div>
            </div>
            <h3 v-if="isCtaAddToCart" class="colors-title">
              {{ $t("create.step3.colorButtons") }}
            </h3>
            <toggle-box
              v-if="!isCtaAddToCart"
              :header="$t('shared.toggleBox.buttonShape')"
              input-for="buttonShape"
              :options="[
                { text: $t('shared.toggleBox.round'), value: 'round' },
                { text: $t('shared.toggleBox.semi'), value: 'semi' },
                { text: $t('shared.toggleBox.square'), value: 'square' },
              ]"
              :selected="ctaObject.buttonShape || 'round'"
              @option-selected="ctaObject.buttonShape = $event.value"
            ></toggle-box>
            <div class="color-settings">
              <div v-if="!isCtaEmail" class="color-setting">
                <button
                  class="value-color"
                  :style="`background-color:${btnColors.textColor}`"
                  :disabled="ctaObject.hasOwnProperty('deviceType')"
                  @click="showPicker(`textColor`)"
                />
                <div class="cta-title-format">
                  {{ $t("create.step3.buttonSelector.textColor") }}
                </div>
              </div>
              <div class="color-setting">
                <button
                  class="value-color"
                  :style="`background-color:${btnColors.buttonColor}`"
                  :disabled="ctaObject.hasOwnProperty('deviceType')"
                  @click="showPicker(`buttonColor`)"
                />
                <div class="cta-title-format">
                  {{ $t("create.step3.buttonSelector.buttonColor") }}
                </div>
              </div>
            </div>
          </template>
        </vidjet-accordion>
        <vidjet-accordion id="accordion-placement">
          <template #title>
            <span>
              {{ $t("create.step3.buttonSelector.buttonPlacement") }}</span
            >
          </template>
          <template #content>
            <TextWithSlider
              :type="'%'"
              :label-text="$t('create.step3.buttonSelector.moveUpDown')"
              :max-value="100"
              :step="1"
              :preset-value="ctaObject.bottomMarginPercentage"
              :input-width="'100px'"
              :is-bulk="isBulk"
              @toggle-button="ctaObject.bottomMarginPercentage = $event.value"
            />
            <banner-modal
              v-if="isFullScreen && isCtaAddToCart"
              :title="$t('shared.toastMessage.information')"
              :text="$t('create.step3.buttonSelector.mobilePreview')"
            />
          </template>
        </vidjet-accordion>
        <vidjet-accordion id="accordion-timeframe">
          <template #title>
            <span>
              {{ $t("create.step3.buttonSelector.adjustTimeframe") }}</span
            >
          </template>
          <template #content>
            <cta-time-adjuster
              :label-text="$t('create.step3.buttonSelector.displayBetween')"
              :preset-value="ctaObject.timeframe"
              @toggle-button="ctaObject.timeframe = $event"
            ></cta-time-adjuster>
          </template>
        </vidjet-accordion>
        <button
          v-if="showCtaButton"
          class="copy-cta-button"
          @click="showCtaChangesModal = true"
        >
          <FlashIcon></FlashIcon>
          <div>{{ $t("create.step3.applyChanges") }}</div>
        </button>
      </div>
    </div>
    <email-integration-modal
      v-if="modalTools"
      :integrations="integrations"
      @close="toggleToolsModal"
    >
    </email-integration-modal>
    <apply-changes-modal-vue
      v-if="showCtaChangesModal"
      :is-bulk="isBulk"
      @close-apply-changes-modal="showCtaChangesModal = false"
      @submit-changes="onApplyChangesModalSubmit"
    ></apply-changes-modal-vue>
  </div>
</template>

<script>
import VidjetToggleBtn from "@/components/shared/Vidjet-toggle-btn.vue";
import VidjetAccordion from "@/components/shared/VidjetAccordion.vue";
import CtaVSelect from "@/components/shared/CtaVSelect.vue";
import CartIcon from "@/assets/svg/campaign-manager/add-to-cart.svg?inline";
import { mapState, mapGetters } from "vuex";

import { Chrome } from "vue-color";
import enums from "../../../enums.js";
import EmailIntegrationModal from "./EmailIntegrationModal.vue";
import ToggleBox from "../../shared/ToggleBox.vue";
import CtaSiteIntegrationBlock from "./CtaSiteIntegrationBlock";
import FlashIcon from "@/assets/svg/flash-icon.svg?inline";
import ApplyChangesModalVue from "./ApplyChangesModal.vue";
import TextWithSlider from "./TextWithSlider.vue";
import CtaTimeAdjuster from "./CtaTimeAdjuster.vue";
import BannerModal from "../../shared/BannerModal.vue";
import DragDropIcon from "@/assets/svg/drag-drop-icon-small.svg?inline";
import Draggable from "vuedraggable";

const { CtaType, CtaAction } = enums;
export default {
  components: {
    VidjetToggleBtn,
    colorpicker: Chrome,
    EmailIntegrationModal,
    CartIcon,
    CtaSiteIntegrationBlock,
    VidjetAccordion,
    ApplyChangesModalVue,
    TextWithSlider,
    CtaTimeAdjuster,
    BannerModal,
    ToggleBox,
    FlashIcon,
    CtaVSelect,
    Draggable,
    DragDropIcon,
  },

  props: {
    campaignCtaOption: {
      type: Object,
      default: () => {
        return {};
      },
    },
    chosenDevice: {
      type: String,
      default: "desktop",
    },
    products: {
      type: Array,
      default: () => {
        return [];
      },
    },
    showCtaButton: {
      type: Boolean,
      default: false,
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    },
    isBulk: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    campaign: {
      type: Object,
      default: () => {
        return {};
      },
    },
    videoIndex: {
      type: Number,
      default: null,
    },
    currentFeedEditIndex: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      ctaObject: {
        text: "",
        textColor: "",
        value: "",
        buttonColor: "",
        ctaAction: CtaAction.buyNow,
        ctaType: null,
        products: [
          {
            _id: "",
            productId: null,
            name: "",
            status: "",
            imageUrl: "",
            options: [{ name: "", values: [], position: null }],
            variants: [
              {
                price: null,
                id: null,
                name: "",
                imageUrl: "",
                qty: null,
                sellWithoutStock: null,
              },
            ],
            siteId: "",
            creationDate: "",
            modificationDate: "",
          },
        ],
      },
      buttonOptions: [],
      defaultCtaObject: {
        text: "Text",
        textColor: "#000000",
        value: "",
        buttonColor: "#E5E5E5",
        ctaType: 0,
        ctaAction: CtaAction.buyNow,
        bottomMarginPercentage: 10,
        timeframe: {
          fromSec: 0,
          toSec: 0,
        },
        buttonShape: "semi",
      },
      defaultProduct: {
        _id: "",
        productId: null,
        name: "",
        status: "",
        imageUrl: "",
        options: [{ name: "", values: [], position: null }],
        variants: [
          {
            price: null,
            id: null,
            name: "",
            imageUrl: "",
            qty: null,
            sellWithoutStock: null,
          },
        ],
        siteId: "",
        creationDate: "",
        modificationDate: "",
      },
      displayPicker: false,
      colorChosen: "",
      pickerType: "",
      CtaType,
      modalTools: false,
      showCtaOptions: true,
      CtaAction,
      showCtaChangesModal: false,
      placeholderForVSelect: "Select button type",
    };
  },
  computed: {
    ...mapState(["site"]),
    ...mapGetters({
      siteId: "account/getSiteId",
    }),
    ...mapGetters({
      feedList: "campaign/feedList",
    }),
    isCtaEmpty() {
      return Object.keys(this.campaignCtaOption).length === 0;
    },
    integrations() {
      return this.$store.state.site.toolIntegrations;
    },
    isCtaEmail() {
      return this.ctaObject.ctaType === CtaType.email;
    },
    isCtaAddToCart() {
      return this.ctaObject.ctaType === CtaType.addToCart;
    },

    ctaOptions() {
      let options = [...this.buttonOptions];
      // if not shopify don't display ATC option
      // if (this.site.site.integration !== "shopify") {
      //   let index = options.findIndex((o) => o.value === CtaType.addToCart);
      //   if (index > -1) options.splice(index, 1);
      // }
      return options;
    },

    btnColors() {
      let { textColor, buttonColor } = this.ctaObject;
      if (!textColor) textColor = this.defaultCtaObject.textColor;
      if (
        this.isCtaAddToCart &&
        textColor === "#000000" &&
        buttonColor === "#E5E5E5"
      ) {
        return { buttonColor: "#000000", textColor: textColor };
      } else if (!this.isCtaAddToCart && textColor === buttonColor) {
        return {
          textColor: "#000000",
          buttonColor: "#E5E5E5",
        };
      } else if (buttonColor === "#E5E5E5" && this.isCtaEmail) {
        return {
          textColor: "#000000",
          buttonColor: "#FF0000",
        };
      } else {
        return { textColor, buttonColor };
      }
    },
  },

  watch: {
    ctaObject: {
      handler: function (oldValue) {
        if (Object.keys(oldValue).length > 0) {
          this.setBtnDefaultColors();
        }
        if (
          this.ctaObject.ctaType === CtaType.addToCart &&
          (!this.ctaObject.products || this.ctaObject.products.length === 0)
        ) {
          this.ctaObject.products = [{ ...this.defaultProduct }];
        }
        if (oldValue?.ctaType !== CtaType.addToCart) {
          delete this.ctaObject.products;
        }
        if (
          !this.ctaObject.text &&
          (this.ctaObject.ctaType === CtaType.copy ||
            this.ctaObject.ctaType === CtaType.link)
        ) {
          this.ctaObject = Object.assign({}, this.defaultCtaObject);
          this.ctaObject.ctaType = oldValue.ctaType;
        }
        const ctaToSend = this.formatCtaObjectOnChange(oldValue);
        this.$emit("change-cta-object", ctaToSend);
      },
      deep: true,
    },
  },
  // should be broken down to pieces
  async mounted() {
    // These come from the locales files, check en.json
    this.buttonOptions = this.$t("create.step3.buttonSelector.buttonOptions");
    await this.$store.dispatch("site/getSite", {
      siteId: this.siteId,
    });
    // if (this.site.site.integration === "shopify") {
    this.defaultCtaObject.ctaType = CtaType.addToCart;
    // }
    if (Object.keys(this.campaignCtaOption).length > 0) {
      this.ctaObject = structuredClone({
        bottomMarginPercentage: 8,
        buttonShape: "semi",
        timeframe: {
          fromSec: 0,
          toSec: 0,
        },
        ...this.campaignCtaOption,
      });
    }
    // if ctaObject is empty, set default values
    if (this.isCtaEmpty) {
      this.ctaObject = { ...this.defaultCtaObject };
    }
    if (this.isEdit && this.isCtaEmpty) {
      this.showCtaOptions = false;
      this.ctaObject = {};
    }
  },

  methods: {
    addOrRemoveProductFromCtaObject(oldValue) {
      // pass product to ctaObject
      if (oldValue?.ctaType !== CtaType.addToCart) {
        delete this.ctaObject.products;
      } else if (this.campaignCtaOption?.products ?? []) {
        this.ctaObject.products = this.campaignCtaOption.products;
      }
    },
    // atc btn by default is full black
    setBtnDefaultColors() {
      const prevVideoCtaObject = this.getPreviousVideoCtaObject();
      // Apply text color
      if (
        !this.ctaObject.textColor ||
        this.ctaObject.textColor === this.defaultCtaObject.textColor
      ) {
        this.ctaObject.textColor =
          prevVideoCtaObject?.textColor || this.defaultCtaObject.textColor;
      }

      // Apply button color
      if (
        !this.ctaObject.buttonColor ||
        this.ctaObject.buttonColor === this.defaultCtaObject.buttonColor
      ) {
        this.ctaObject.buttonColor =
          prevVideoCtaObject?.buttonColor || this.defaultCtaObject.buttonColor;
      }
    },

    toggleCta() {
      if (this.showCtaOptions) {
        this.ctaObject = {};
        this.showCtaOptions = false;
      } else {
        this.ctaObject = { ...this.defaultCtaObject };
        this.showCtaOptions = true;
      }
    },

    formatCtaObjectOnChange(ctaObject) {
      if (ctaObject?.ctaType !== CtaType.addToCart) {
        delete ctaObject.products;
      }
      // deep copy ctaObject
      const ctaToSend = { ...ctaObject };
      if (
        this.isCtaAddToCart &&
        (!ctaObject.products || ctaObject.products.length == 0)
      ) {
        ctaToSend.products = [{ ...this.defaultProduct }];
      }
      if (!this.ctaObject.textColor && Object.keys(ctaToSend).length > 0) {
        ctaToSend.textColor = "#000000";
      }
      if (this.isCtaAddToCart || this.isCtaEmail) {
        "value" in ctaToSend && delete ctaToSend.value;
        "text" in ctaToSend && delete ctaToSend.text;
      }
      return ctaToSend;
    },

    showPicker(type) {
      this.pickerType = type;
      this.displayPicker = true;
      this.colorChosen = this.ctaObject[type];
    },

    onPickerChange(e) {
      this.ctaObject[this.pickerType] = e.hex8;
    },

    hidePicker() {
      this.displayPicker = false;
    },

    formatUrl() {
      if (
        this.ctaObject.ctaType === 0 &&
        !this.ctaObject.value.includes("http")
      ) {
        this.ctaObject.value = "https://" + this.ctaObject.value;
      }
    },
    toggleToolsModal() {
      this.modalTools = !this.modalTools;
    },
    onCtaTextChange(event) {
      this.ctaObject.text = event.target.value;
    },
    onApplyChangesModalSubmit() {
      this.showCtaChangesModal = false;
      this.$emit("copy-cta-button-clicked");
    },
    changeOrder() {
      this.$emit("product-order-change", this.products);
    },
    getPreviousVideoCtaObject() {
      let prevVideoCtaObject = null;

      // Check if it's the first video in bulk and not the first feed
      if (
        this.isBulk &&
        this.videoIndex === 0 &&
        this.currentFeedEditIndex !== 0
      ) {
        const prevFeedIndex = this.currentFeedEditIndex - 1;
        if (
          prevFeedIndex >= 0 &&
          this.feedList[prevFeedIndex].videos.length > 0
        ) {
          const lastVideoIndex = this.feedList[prevFeedIndex].videos.length - 1;
          const ctaObject =
            this.feedList[prevFeedIndex].videos[lastVideoIndex].cta;
          if (ctaObject) {
            prevVideoCtaObject = {
              textColor: ctaObject.textColor,
              buttonColor: ctaObject.buttonColor,
            };
          }
        }
      } else {
        const prevIndex = this.videoIndex - 1;
        if (prevIndex >= 0 && this.campaign.videos[prevIndex].cta) {
          const ctaObject = this.campaign.videos[prevIndex].cta;
          prevVideoCtaObject = {
            textColor: ctaObject.textColor,
            buttonColor: ctaObject.buttonColor,
          };
        }
      }
      return prevVideoCtaObject;
    },
  },
};
</script>
<style lang="scss">
.toggle-cta {
  .vs__search {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.button-and-toggle {
  width: 100%;
}
.toggle-cta {
  width: 100%;
  margin: auto;
  .vs__search {
    display: none;
  }
}

.accordion-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.device-input {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 75%;
}

.button-inputs {
  width: 100%;
  text-align: left;
  display: grid;
  gap: 8px;
}

.color-setting {
  width: 50%;
  text-align: center;
}

.text-input {
  @include font-smaller;
}

.cta-title-format {
  margin-top: 5px;
  @include font-smallest;
  font-weight: 500;
}

.toggle-btn-cta {
  position: absolute;
  top: 16px;
  right: 10px;
}

.colors-title {
  @include font-small;
  @include base-font;
  text-align: left;
}
.copy-cta-button {
  background: white;
  @include font-smaller;
  @include base-font;
  text-decoration-line: underline;
  color: $medium-grey;
  padding: 0;
  text-align: left;
  width: 100%;
  display: flex;
  gap: 6px;
  margin: 0 auto;
}

.product-cards {
  .product-card {
    display: flex;
    gap: 4px;
    margin: 14px 0;
    justify-content: space-between;
    align-items: center;
  }

  h3 {
    font-family: "Yeon Sung";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: $dark-grey;
  }
  .product-image {
    height: 40px;
    width: 40px;
    background-size: cover;
  }
  .img-container {
    display: flex;
    grid-template-columns: 1fr 3fr;
    gap: 12px;
    align-items: center;
  }

  .product-name {
    @include font-small;
    width: 75%;
    text-align: left;
  }
}
.drag-number {
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
}

.drag-icon {
  ellipse {
    fill: $dark-grey;
  }
}
</style>
