<template>
  <small-modal
    :fit-content="true"
    :show-close-button="false"
    class="filter-modal"
    @close="$emit('close-filter-modal')"
  >
    <div class="campaign-filter-container">
      <h1 class="filter">{{ $t("campaignManager.filter.filters") }}</h1>
      <span class="reset" @click="resetFilters">{{
        $t("campaignManager.filter.reset")
      }}</span>
      <form class="filter-wrapper">
        <div>
          <label>
            <h2 class="filter-type-title">
              {{ $t("campaignManager.filter.formats") }}
            </h2>
          </label>
          <v-select
            v-model="formatSelectedFilters"
            class="dropdown format-dropdown"
            :options="formatOptions"
            :reduce="(formatOptions) => formatOptions.value"
            label="name"
            :searchable="false"
            multiple
            :placeholder="placeholder"
            @input="applyFormatFilter"
          ></v-select>
        </div>
        <div>
          <label>
            <h2 class="filter-type-title">
              {{ $t("campaignManager.filter.currentlyPublished") }}
            </h2>
          </label>
          <v-select
            v-model="selectedFilters.published"
            class="dropdown"
            :options="publishedOptions"
            label="title"
            :placeholder="placeholder"
            @input="applyPublishedFilter"
          ></v-select>
        </div>
        <div>
          <label>
            <h2 class="filter-type-title">
              {{ $t("campaignManager.filter.lastActivity") }}
            </h2>
          </label>
          <v-select
            v-model="selectedFilters.activity"
            class="dropdown"
            :options="activityOptions"
            label="title"
            :placeholder="placeholder"
            @input="applyActivityFilter"
          ></v-select>
        </div>
        <div>
          <label>
            <h2 class="filter-type-title">Tags</h2>
          </label>
          <v-select
            v-model="tagsSelectedFilters"
            class="dropdown tags-dropdown"
            :options="filteredTagsArray"
            label="name"
            multiple
            :placeholder="placeholder"
            @input="applyTagFilter"
          >
          </v-select>
        </div>
      </form>
    </div>
  </small-modal>
</template>

<script>
import SmallModal from "@/components/shared/SmallModal.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    SmallModal,
  },
  props: {
    formatOptions: {
      type: Array,
      required: true,
    },
    publishedOptions: {
      type: Array,
      required: true,
    },
    activityOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedFilters: {
        published: null,
        activity: null,
      },
      formatSelectedFilters: [],
      tagsSelectedFilters: [],
      placeholder: this.$t("campaignManager.filter.select"),
    };
  },
  computed: {
    ...mapGetters({
      tagsArray: "tag/getTags",
    }),
    filteredTagsArray() {
      return this.tagsArray
        .filter((tag) => "_id" in tag)
        .map((tag) => ({ ...tag }));
    },
  },
  methods: {
    applyFormatFilter(event) {
      this.$emit("filter", { formats: event });
    },
    applyPublishedFilter(event) {
      this.$emit("filter", {
        published: event,
      });
    },
    applyActivityFilter(event) {
      this.$emit("filter", {
        activity: event,
      });
    },
    applyTagFilter(event) {
      this.$emit("filter", {
        tags: event,
      });
    },
    resetFilters() {
      this.formatSelectedFilters = [];
      this.selectedFilters.published = null;
      this.selectedFilters.activity = null;
      this.tagsSelectedFilters = [];
      this.$emit("reset");
    },
  },
};
</script>

<style lang="scss">
.filter-modal .small-modal.fit-modal {
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.25);
  padding: 16px;
  top: 130px;
  left: 32%;
  transform: translate(0, 0);
  width: 210px;
}
.dropdown {
  .vs__dropdown-toggle {
    min-height: 48px;
  }
  .vs__search {
    font-family: Montserrat;
    color: $light-grey;
    font-size: 14px;
    font-weight: 400;
  }
  .vs__selected-options {
    flex-wrap: nowrap;
  }
  .vs__dropdown-option {
    padding-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .vs__dropdown-menu {
    position: absolute !important;
  }
}
.format-dropdown {
  .vs__selected-options {
    flex-wrap: wrap;
  }
}

.tags-dropdown {
  .vs__dropdown-menu {
    min-height: 30px !important;
    overflow: auto !important;
    .vs__dropdown-option:nth-child(9n + 1) {
      color: rgba(0, 48, 211, 1) !important;
      &:hover {
        background-color: rgba(0, 48, 211, 0.2) !important;
      }
    }

    .vs__dropdown-option:nth-child(9n + 2) {
      color: rgba(181, 3, 14, 1) !important;
      &:hover {
        background-color: rgba(181, 3, 14, 0.2) !important;
      }
    }

    .vs__dropdown-option:nth-child(9n + 3) {
      color: rgba(2, 162, 0, 1) !important;
      &:hover {
        background-color: rgba(2, 162, 0, 0.2) !important;
      }
    }

    .vs__dropdown-option:nth-child(9n + 4) {
      color: rgba(236, 128, 0, 1) !important;
      &:hover {
        background-color: rgba(236, 128, 0, 0.2) !important;
      }
    }

    .vs__dropdown-option:nth-child(9n + 5) {
      color: rgba(211, 0, 203, 1) !important;
      &:hover {
        background-color: rgba(211, 0, 203, 0.2) !important;
      }
    }

    .vs__dropdown-option:nth-child(9n + 6) {
      color: rgba(154, 91, 35, 1) !important;
      &:hover {
        background-color: rgba(154, 91, 35, 0.2) !important;
      }
    }

    .vs__dropdown-option:nth-child(9n + 7) {
      color: rgba(0, 188, 188, 1) !important;
      &:hover {
        background-color: rgba(0, 188, 188, 0.2) !important;
      }
    }

    .vs__dropdown-option:nth-child(9n + 8) {
      color: rgba(207, 156, 0, 1) !important;
      &:hover {
        background-color: rgba(207, 156, 0, 0.2) !important;
      }
    }

    .vs__dropdown-option:nth-child(9n) {
      color: rgba(88, 88, 88, 1) !important;
      &:hover {
        background-color: rgba(88, 88, 88, 0.2) !important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.modal-background {
  background: transparent;
}

.filter {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  cursor: default;
  margin-bottom: 14px;
}
.reset {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: $light-grey;
  position: fixed;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.filter-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.filter-type-title {
  margin-bottom: 8px;
}
</style>
