<template>
  <small-modal @close="close">
    <h1 class="card-title">{{ $t("account.deleteModal.title") }}</h1>
    <h3 class="subtitle">
      {{ $t("account.deleteModal.sorry") }}
      <strong> {{ $t("account.deleteModal.letUsKnow") }}</strong>
    </h3>
    <form class="form" @submit.prevent="deleteSite">
      <label class="delete-label" for="reason"></label>
      <v-select
        id="reason"
        v-model="selected"
        :placeholder="$t('account.deleteModal.placeholder')"
        class="vidjet-single-select"
        label="name"
        :options="reasons"
        :searchable="false"
        :required="!selected"
        :reduce="(option) => option.key"
        name="issue-select"
      >
      </v-select>
      <div class="delete-icon">
        <DeleteAccount />
      </div>
      <div class="buttons">
        <input
          class="button-primary --grey"
          type="button"
          :value="$t('shared.buttons.back')"
          @click.prevent="close"
        />
        <input
          class="button-primary"
          type="submit"
          :value="$t('account.deleteModal.confirm')"
        />
      </div>
    </form>
  </small-modal>
</template>

<script>
import DeleteAccount from "@/assets/svg/settings/delete-account.svg?inline";
import SmallModal from "../../shared/SmallModal";
import { mapState } from "vuex";

export default {
  components: {
    DeleteAccount,
    SmallModal,
  },

  data() {
    return {
      selected: "",
      reasons: [],
    };
  },

  computed: {
    ...mapState(["site"]),
  },
  created() {
    this.reasons = this.$t("account.deleteModal.reasons");
  },

  methods: {
    close() {
      this.$emit("close");
    },

    async deleteSite() {
      await this.$store.dispatch("account/cancelSubscription", {
        data: {
          siteId: this.$store.state.account.user.siteId,
          cancelReason: this.selected,
        },
      });
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "vue-select/src/scss/vue-select.scss";

.modal {
  background-color: $modal-bg;
}

.delete-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: map-get($zindex, modal);

  width: 80vw;
  max-width: 870px;
  height: 460px;
  background-color: #fff;
  box-shadow: 0px 0px 250px 100px rgba(0, 0, 0, 0.2),
    inset 0px 0px 10px 3px #dfdfdf;
  border-radius: 7px;
  @include base-font;
}

.card-title {
  margin: 2rem 0;
  @include font-big;
  text-align: center;
  color: #585858;
}

.subtitle {
  margin-bottom: 2rem;
  text-align: center;
  @include font-normal;
  strong {
    font-weight: bold;
  }
}
#reason {
  margin: auto;
  width: 35%;
  align-content: center;
  margin-bottom: 70px;
}

.delete-icon svg {
  display: block;
  margin: auto;
  margin-bottom: 4rem;
}
.delete-label {
  @include font-normal;
}

/*save and back button*/
.buttons {
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin: 0 auto;
}

@include media(">phone", "<=tablet") {
  .delete-modal {
    width: 60vw;
    min-width: 320px;
    height: 400px;
    align-items: center;
  }

  .delete-form {
    width: 90%;
    margin-bottom: 8px;
  }

  .card-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    @include font-normal;
  }

  .subtitle {
    @include font-small;
  }

  .delete-label {
    @include font-small;
  }

  #reason {
    width: 70%;
    min-width: 300px;
    @include font-small;
    margin-bottom: 32px;
  }

  .vs__selected {
    @include font-small;
  }

  .buttons {
    width: 100%;
  }

  .save,
  .button-back {
    @include font-small;
    height: 30px;
    width: 75px;
    padding: 0;
  }
}

@include media("<=phone") {
  .delete-modal {
    width: 300px;
    height: 350px;
    align-items: center;
  }

  .card-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    @include font-normal;
  }

  .delete-form {
    width: 90%;
    margin-bottom: 8px;
  }
  #reason {
    //  width: 30%;
    min-width: 280px;
    @include font-smaller;
    margin-bottom: 32px;
  }

  .vs__selected {
    @include font-smaller;
  }

  .buttons {
    width: 100%;
  }
  .delete-label {
    @include font-smaller;
  }

  .delete-icon svg {
    margin-bottom: 2rem;
  }

  .save,
  .button-back {
    @include font-smaller;
    height: 25px;
    width: 55px;
    padding: 0;
  }
}
</style>
