var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"arrow-icon-settings"},[_c('TextWithBtnToggle',{attrs:{"label-text":_vm.$t('create.step3.hideCarouselArrows'),"input-for":"hideCarouselArrows","preset-value":_vm.formatObject.hideCarouselArrows},on:{"toggle-button":_vm.toggleCarouselArrows}}),_c('div',{staticClass:"arrows-icon-container"},[_c('h3',{staticClass:"title",class:{ 'disable-title': _vm.formatObject.hideCarouselArrows }},[_vm._v(" "+_vm._s(_vm.$t("create.step3.buttonSelector.buttonStyle"))+" "),_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"navigation-arrow"},[_c('BtnArrow',{staticClass:"left",on:{"click":_vm.scrollLeft}})],1),_c('button',{staticClass:"navigation-arrow"},[_c('BtnArrow',{staticClass:"right",on:{"click":_vm.scrollRight}})],1)])]),_c('div',{staticClass:"icon-type-boxes arrow-icon-box",class:{
        'bulk-icon-type-boxes': _vm.isBulk,
        disabled: _vm.formatObject.hideCarouselArrows,
      }},_vm._l((_vm.icons),function(icon){return _c('button',{key:icon.name,staticClass:"icon-type",class:{
          selected: _vm.iconType === icon.name,
          'not-allowed': _vm.formatObject.hideCarouselArrows,
        },attrs:{"disabled":_vm.formatObject.hideCarouselArrows},on:{"click":function($event){return _vm.onArrowSettingsChange({
            inputFor: 'iconType',
            value: icon.name,
          })}}},[_c(icon.icon,{tag:"component"}),_c('h3',[_vm._v(_vm._s(_vm._f("capitalize")(icon.label)))])],1)}),0)]),_c('div',{staticClass:"icon-customization"},[_c('div',{staticClass:"input-color",class:{ disabled: _vm.formatObject.hideCarouselArrows }},[_c('h3',{staticClass:"box-title"},[_vm._v("Icon color")]),_c('button',{staticClass:"value-color",class:{ 'not-allowed': _vm.formatObject.hideCarouselArrows },style:(`background-color:${
          _vm.formatObject?.arrowSettings?.color || 'white'
        }`),attrs:{"disabled":_vm.formatObject.hideCarouselArrows},on:{"click":function($event){return _vm.showPicker(`color`)}}})]),_c('div',{staticClass:"input-color",class:{
        disabled: _vm.isMinimal || _vm.formatObject.hideCarouselArrows,
      }},[_c('h3',{staticClass:"box-title"},[_vm._v(" "+_vm._s(_vm.$t("create.step3.buttonSelector.backgroundColor"))+" ")]),_c('div',{staticClass:"buttons-color"},[_c('button',{staticClass:"value-color",class:{ 'not-allowed': _vm.formatObject.hideCarouselArrows },style:(`background-color:${
            _vm.formatObject.arrowSettings?.secondaryColor || 'grey'
          }`),attrs:{"disabled":_vm.isMinimal || _vm.formatObject.hideCarouselArrows},on:{"click":function($event){return _vm.showPicker(`secondaryColor`)}}})])]),(_vm.displayPicker)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hidePicker),expression:"hidePicker"}],key:"color-picker",staticClass:"vidjet-color-picker"},[_c('colorpicker',{attrs:{"value":_vm.formatObject?.arrowSettings &&
          _vm.formatObject?.arrowSettings[_vm.pickerType]},on:{"input":function($event){return _vm.onPickerChange($event, _vm.pickerType)}}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }