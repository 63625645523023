<template>
  <div class="youtube-importer">
    <div class="youtube-importer-title">
      <img
        :src="require('../../assets/youtube-logo.svg')"
        class="youtube-logo-big"
        alt="logo youtube"
      />
      <h2 class="youtube-modal-title">
        {{ $t("create.step2.quickImportModal.youtubeTitle") }}
      </h2>
    </div>
    <h3 class="importer-modal-title">
      {{ $t("create.step2.quickImportModal.pasteYoutube") }}
    </h3>
    <form class="importer-form">
      <div v-for="(url, index) in youtubeUrls" :key="index" class="inputs">
        <div class="input-number">
          <h4 class="video-number">Video {{ index + 1 }}</h4>
          <div class="input-field">
            <input
              id="importUrl"
              v-model="url.url"
              class="input-importer"
              :class="url.url ? 'input-importer-content' : ''"
              type="text"
              required
              @input="handleInput(index)"
            />
            <div v-if="isValidUrl(url.url)" class="loading-icon">
              <div v-if="url.isLoading" class="lds-dual-ring"></div>
              <CircleCheckedIcon v-else class="checked-icon-input" />
            </div>
          </div>
        </div>
      </div>
      <button v-if="areAllInputsFilled" class="add-input" @click="addInput">
        {{ $t("create.step2.quickImportModal.addInput") }}
      </button>
      <div class="buttons">
        <input
          class="button-primary --grey"
          type="button"
          :value="$t('shared.buttons.cancel')"
          @click="removeVideos"
        />
        <template v-if="isLoading || anyUrlsLoading">
          <label
            v-tippy="{
              placement: 'top',
              arrow: true,
              a11y: false,
              interactive: true,
            }"
            class="button-primary button-flex"
            :class="{ 'is-disabled': isLoading || anyUrlsLoading }"
            :content="$t('create.step2.quickImportModal.tooltip')"
          >
            <input
              class="submit-button"
              :class="{ 'is-disabled': isLoading || anyUrlsLoading }"
              type="button"
              :value="$t('shared.buttons.confirm')"
              :disabled="isLoading || anyUrlsLoading"
              @click="close"
            />
            <CircleCheckedIcon class="checked-icon-button" />
          </label>
        </template>
        <template v-else>
          <label class="button-primary button-flex">
            <input
              class="submit-button"
              type="button"
              :value="$t('shared.buttons.confirm')"
              @click="close"
            />
            <CircleCheckedIcon class="checked-icon-button" />
          </label>
        </template>
      </div>
    </form>
  </div>
</template>

<script>
import CircleCheckedIcon from "@/assets/svg/dashboard/circle_checked.svg?inline";
import api from "../../api";
export default {
  components: {
    CircleCheckedIcon,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      youtubeUrls: [{ url: "", isLoading: false }],
    };
  },
  computed: {
    areAllInputsFilled() {
      if (this.youtubeUrls.length === 0) {
        return true;
      }
      return this.isValidUrl(this.youtubeUrls[this.youtubeUrls.length - 1].url);
    },
    anyUrlsLoading() {
      return this.youtubeUrls.some((url) => url.isLoading);
    },
  },
  methods: {
    addInput() {
      this.youtubeUrls.push({ url: "", isLoading: false });
    },
    close() {
      this.$emit("close");
    },
    isValidUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    handleInput(index) {
      const url = this.youtubeUrls[index].url;
      if (this.isValidUrl(url)) {
        this.importVideo(url, index);
      }
    },
    async importVideo(url, index) {
      if (this.isValidUrl(url)) {
        this.youtubeUrls[index].isLoading = true;
        try {
          const result = await api.getVideoImport(url, "youtube");
          const dataUrl = Buffer.from(result.body, "base64");
          let importMetadata = result.metadata;
          const file = new File(
            [dataUrl],
            importMetadata.name ? importMetadata.name + ".mp4" : "doc.mp4",
            { type: "video/mp4" }
          );
          var payload = {
            file,
            type: "quick-import",
            source: "youtube",
          };
          this.youtubeUrls[index].isLoading = false;
          this.$emit("import-video", payload);
        } catch (err) {
          this.youtubeUrls[index].isLoading = false;
          const errorText = err.text
            ? err.text
            : this.$t("create.step2.quickImportError");
          this.$notify({
            title: this.$t("shared.toastMessage.error"),
            text: errorText,
            type: "error",
          });
        }
      }
    },
    removeVideos() {
      this.$emit("remove-videos");
    },
  },
};
</script>

<style lang="scss" scoped>
.youtube-importer-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.youtube-modal-title {
  @include base-font;
  @include font-big;
  font-weight: 600;
}
.youtube-logo-big {
  width: 33px;
  display: inline;
}
.importer-modal-title {
  @include base-font;
  @include font-normal;
  font-weight: 400;
  text-align: center;
  margin: 12px auto 24px;
}
.video-number {
  @include base-font;
  @include font-small;
  font-weight: 500;
}
.input-number {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 8px;
}
.importer-form {
  margin: 10px;
}
.add-input {
  @include base-font;
  @include font-smaller;
  font-weight: 600;
  color: $dark-purple;
  padding: 0;
  background: none;
  margin-top: 4px;
}
.input-importer {
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  min-width: 450px;
  height: 30px;
  border-radius: 3px;
  outline: none;
  border: 1px solid grey;
}
.input-importer-content {
  border: 1.5px solid rgba(226, 124, 252, 0.7);
}
.buttons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 24px;
}
.checked-icon-button {
  path {
    fill: $dark-grey;
  }
}
.button-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.submit-button {
  @include base-font;
  @include font-small;
  font-weight: 500;
  background: none;
  border: none;
}

.input-field {
  display: flex;
  align-items: center;
  gap: 4px;
}

.checked-icon-input {
  margin-left: 4px;
  transform: scale(1.71429);
  path {
    fill: $dark-purple;
  }
}
.lds-dual-ring {
  display: inline-block;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  margin-top: 6px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 3px solid $dark-purple;
  border-color: $dark-purple transparent #fff $dark-purple;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.is-disabled {
  background-color: $medium-grey;
  cursor: not-allowed;
}
</style>
