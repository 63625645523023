<template>
  <small-modal :show-close-button="showCloseButton" @close="onCloseModal">
    <div class="plan-registration">
      <div class="modal-header">
        <h2 class="title">
          {{
            userIsTrial
              ? $t("planRegistration.trialTitle")
              : $t("planRegistration.title")
          }}
        </h2>
        <h3 class="subtitle">
          {{
            userIsTrial
              ? $t("planRegistration.trialSubtitle") +
                daysLeftTrial +
                $t("planRegistration.days")
              : $t("planRegistration.subtitle")
          }}
        </h3>
      </div>
      <div class="grid">
        <div
          v-if="site.site.integration === 'shopify' && !site.site.payByCard"
          class="left"
        >
          <form
            id="shopify-form"
            class="form"
            @submit.prevent="setUserInformation"
          >
            <ul class="big-inputs">
              <h3 class="fill-information">
                {{ $t("planRegistration.fillInformation") }}
              </h3>
              <div class="company-name-vat">
                <li
                  class="form-input"
                  :class="{
                    'company-name-intra-community': !isIntraCommunity,
                  }"
                >
                  <label class="registration-label" for="company-name"
                    >{{ $t("planRegistration.companyName") }}
                  </label>
                  <input
                    id="company-name"
                    v-model="newCompanyName"
                    type="text"
                    required
                  />
                </li>
                <li v-if="isIntraCommunity" class="form-input">
                  <label class="registration-label" for="company-vat"
                    >{{ $t("planRegistration.vatFull") }}
                  </label>
                  <input id="company-vat" v-model="vat" type="text" required />
                </li>
              </div>
              <li class="form-input" @click="toggleAddressModal">
                <label class="registration-label" for="company-address">{{
                  $t("planRegistration.companyAddress")
                }}</label>
                <input
                  id="company-address"
                  type="text"
                  :value="getAddressString"
                  required
                />
              </li>
            </ul>
            <div class="shopify-instruction">
              <div class="shopify-header">
                <h3 class="fill-information">
                  {{ $t("planRegistration.payment") }}
                </h3>
                <img
                  class="shopify-logo"
                  src="@/assets/shopify-logo-small.png"
                  alt="shopify-logo"
                />
              </div>
              <img
                class="shopify-card"
                src="@/assets/shopify-card.png"
                alt="shopify-card"
              />
              <p class="shopify-text">
                {{ $t("planRegistration.shopify") }}
              </p>
            </div>
          </form>
          <AddressModal v-show="showAddressModal" @close="toggleAddressModal" />
        </div>
        <!-- change or set payment method -->
        <div v-else class="left">
          <form
            id="payment-form"
            class="form"
            @submit.prevent="setPaymentMethod"
          >
            <ul class="big-inputs">
              <h3 class="fill-information">
                {{ $t("planRegistration.fillInformation") }}
              </h3>
              <div class="company-name-vat">
                <li
                  class="form-input"
                  :class="{
                    'company-name-intra-community': !isIntraCommunity,
                  }"
                >
                  <label class="registration-label" for="company-name"
                    >{{ $t("planRegistration.companyName") }}
                  </label>
                  <input
                    id="company-name"
                    v-model="newCompanyName"
                    type="text"
                    required
                  />
                </li>
                <li v-if="isIntraCommunity" class="form-input">
                  <label class="registration-label" for="company-vat"
                    >{{ $t("planRegistration.vatFull") }}
                  </label>
                  <input id="company-vat" v-model="vat" type="text" required />
                </li>
              </div>
              <li class="form-input" @click="toggleAddressModal">
                <label class="registration-label" for="company-address">{{
                  $t("planRegistration.companyAddress")
                }}</label>
                <input
                  id="company-address"
                  type="text"
                  :value="getAddressString"
                  required
                />
              </li>
              <div class="card-header">
                <h3 class="add-payment">
                  {{ $t("planRegistration.paymentMethod") }}
                </h3>
                <StripeLogo class="stripe-logo"></StripeLogo>
              </div>
              <CreditCardForm
                :input-value="creditCardInputValue"
                :disabled="userHasPaymentMethod"
                @input-change="updateCreditCardInputValue"
              />
            </ul>
          </form>
          <AddressModal v-show="showAddressModal" @close="toggleAddressModal" />
          <a
            v-if="userHasPaymentMethod"
            class="link"
            href="#"
            @click="changeCreditCard()"
          >
            {{ $t("planRegistration.changePayment") }}
          </a>
        </div>

        <div class="right">
          <p class="new-plan">{{ $t("planRegistration.newPlan") }}</p>
          <div class="plan-arrows">
            <div v-if="showArrows" @click="decreaseIndex">
              <ArrowLeft />
            </div>
            <div class="card-plan">
              <plan-card :subscription="displayedPricing"></plan-card>
            </div>
            <div v-if="showArrows" @click="increaseIndex">
              <ArrowRight />
            </div>
          </div>
          <p class="indication">{{ $t("planRegistration.indication") }}</p>
        </div>
      </div>
      <div class="btns">
        <!-- form binding to allow require input -->
        <button class="button-primary cancel-button" @click="onCloseModal">
          {{ $t("planRegistration.cancel") }}
        </button>
        <button
          v-if="userHasPaymentMethod"
          class="button-primary button-confirm-flex"
          @click="setUserInformation"
        >
          {{ $t("planRegistration.upgradeBtn") }}
          <ConfirmIcon />
        </button>
        <label
          v-else-if="
            site.site.integration === 'shopify' && !site.site.payByCard
          "
          class="button-primary button-confirm-flex"
        >
          <input
            type="submit"
            class="confirm-button"
            :value="
              userIsTrial
                ? $t('planRegistration.unlock')
                : $t('planRegistration.upgradeBtn')
            "
            form="shopify-form"
          />
          <ConfirmIcon />
        </label>
        <label v-else class="button-primary button-confirm-flex">
          <input
            class="confirm-button"
            type="submit"
            form="payment-form"
            :value="$t('planRegistration.upgradeBtn')"
          />
          <ConfirmIcon />
        </label>
      </div>
    </div>

    <spinner-loader
      v-if="isLoading"
      :loading-text="$t('planRegistration.loadingText')"
    />
  </small-modal>
</template>

<script>
import SpinnerLoader from "../shared/SpinnerLoader.vue";
import SmallModal from "../shared/SmallModal.vue";
import PlanCard from "../shared/PlanCard.vue";
import CreditCardForm from "@/components/settings/settings-components/CreditCardForm.vue";
import AddressModal from "@/components/settings/settings-components/AddressModal.vue";
import { mapState, mapGetters } from "vuex";
import StripeLogo from "@/assets/svg/stripe-logo.svg?inline";
import VatCheck from "@/utils/intraCommunityVatCheck.js";
import ConfirmIcon from "@/assets/svg/confirm-icon.svg?inline";
import ArrowLeft from "@/assets/svg/arrow-left.svg?inline";
import ArrowRight from "@/assets/svg/arrow-right.svg?inline";

export default {
  components: {
    SpinnerLoader,
    SmallModal,
    PlanCard,
    AddressModal,
    StripeLogo,
    ConfirmIcon,
    CreditCardForm,
    ArrowLeft,
    ArrowRight,
  },
  props: {
    newPlan: {
      type: Object,
      default: () => {
        return {};
      },
      isTrial: {
        type: Boolean,
        default: false,
      },
    },
    showArrows: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showAddressModal: false,
      creditCardInputValue: {
        cardHolderName: "",
        cardNumber: "",
        expDate: "",
        cvc: "",
      },
      vat: "",
      newCompanyName: "",
      changePaymentMethod: false,
      showCloseButton: true,
      isLoading: false,
      currentIndex: 0,
    };
  },
  computed: {
    ...mapState(["plan", "site", "user", "campaign"]),
    ...mapGetters({
      numberOfCampaigns: "campaign/numberOfCampaigns",
      currentPlan: "plan/currentPlan",
      cardExpDate: "plan/cardExpDate",
      campaignLimit: "plan/campaignLimit",
      hasPaymentMethod: "plan/hasPaymentMethod",
      siteIntegration: "site/siteIntegration",
      isPayByCard: "site/isPayByCard",
      getAddress: "site/getAddress",
      getAddressString: "site/getAddressString",
      userIsTrial: "plan/userIsTrial",
      daysLeftTrial: "plan/daysLeftTrial",
    }),
    companyName() {
      return this.site.site.companyName ? this.site.site.companyName : "";
    },
    userHasPaymentMethod() {
      if (
        (this.siteIntegration !== "shopify" &&
          this.hasPaymentMethod &&
          !this.changePaymentMethod) ||
        (this.isPayByCard && !this.changePaymentMethod && this.hasPaymentMethod)
      ) {
        return true;
      } else {
        return false;
      }
    },
    isIntraCommunity() {
      if (!this.getAddress || !this.getAddress.country) {
        return false;
      }
      return VatCheck(this.getAddress.country);
    },
    matchingPricingDetails() {
      const matchingIndex = this.plan.plan.pricings.findIndex(
        (pricing) => pricing.name === this.newPlan.name
      );

      const matchingPricing =
        matchingIndex !== -1 ? this.plan.plan.pricings[matchingIndex] : null;

      return { matchingIndex, matchingPricing };
    },
    displayedPricing() {
      const { matchingIndex, matchingPricing } = this.matchingPricingDetails;

      const indexToDisplay =
        typeof this.currentIndex === "number" &&
        this.currentIndex >= 0 &&
        this.currentIndex < this.plan.plan.pricings.length
          ? this.currentIndex
          : matchingIndex;

      return indexToDisplay !== -1
        ? this.plan.plan.pricings[indexToDisplay]
        : matchingPricing || this.newPlan;
    },
  },
  async created() {
    this.newCompanyName = this.site.site.companyName;
    this.vat = this.site.site.vatNumber;
    if (this.hasPaymentMethod) {
      this.creditCardInputValue.cardHolderName = this.plan.plan.cardHolderName;
      this.creditCardInputValue.cardNumber = `**** **** **** ${this.plan.plan.cardLastFourDigits}`;
      this.creditCardInputValue.expDate = this.cardExpDate;
      this.creditCardInputValue.cvc = "***";
    }
    this.currentIndex = this.matchingPricingDetails.matchingIndex;
  },
  methods: {
    onCloseModal() {
      this.$emit("close");
      this.changePaymentMethod = false;
    },
    toggleAddressModal() {
      this.showAddressModal = !this.showAddressModal;
      this.showCloseButton = !this.showCloseButton;
    },

    // if user changes payment method we reset the credit card values
    changeCreditCard() {
      this.changePaymentMethod = true;
      this.creditCardInputValue.cardHolderName = "";
      this.creditCardInputValue.cardNumber = "";
      this.creditCardInputValue.expDate = "";
      this.creditCardInputValue.cvc = "";
    },

    // setPaymentMethod calls setUserInformation which calls updatePlan
    async setPaymentMethod() {
      this.isLoading = true;
      const dataToAdd = {
        userId: this.user.user._id,
        siteId: this.site.site._id,
        holderName: this.creditCardInputValue.cardHolderName,
        cvc: this.creditCardInputValue.cvc,
        number: this.creditCardInputValue.cardNumber.replace(/ /g, ""),
        expMonth: parseInt(
          this.creditCardInputValue.expDate.substring(0, 2),
          10
        ),
        expYear:
          parseInt(this.creditCardInputValue.expDate.slice(-2), 10) + 2000,
      };
      const isValidCardNumber =
        this.creditCardInputValue.cvc.length >= 3 &&
        dataToAdd.number.length > 14 &&
        dataToAdd.number.length < 17;

      if (isValidCardNumber) {
        try {
          await this.$store.dispatch("plan/addCreditCard", { dataToAdd });
          this.setUserInformation();
        } catch (err) {
          console.log(err);
          this.$notify({
            title: err,
            type: "error",
          });
          this.isLoading = false;
        }
      } else {
        this.$notify({
          title: this.$t("shared.toastMessage.wrongCardNumber"),
          type: "error",
        });
        this.isLoading = false;
      }
    },

    async setUserInformation() {
      this.isLoading = true;
      if (this.newCompanyName !== "") {
        try {
          await this.$store.dispatch("site/updateSite", {
            siteId: this.site.site._id,
            dataToUpdate: {
              companyName: this.newCompanyName,
              vatNumber: this.vat,
            },
          });
          await this.updatePlan();
        } catch (err) {
          this.$notify({
            title: err,
            type: "error",
          });

          this.isLoading = false;
        }
      }
    },

    async updatePlan() {
      this.isLoading = true;
      if (this.numberOfCampaigns.length > this.campaignLimit) {
        this.$notify({
          text:
            this.$t("planRegistration.youHave") +
            (this.numberOfCampaigns.length - this.campaignLimit) +
            this.$t("planRegistration.above"),
          type: "error",
        });
        this.isLoading = false;
        return;
      }
      const data = {
        siteId: this.site.site._id,
        chosenPlan: this.displayedPricing.name,
      };
      await this.$store.dispatch("plan/updatePlan", { data });
      this.$notify({
        title: this.$t("shared.toastMessage.planUpdated"),
        type: "success",
      });

      if (
        this.siteIntegration === "shopify" &&
        this.plan.plan.shopifyChargeConfirmationUrl &&
        this.currentPlan !== "free"
      ) {
        window.open(this.plan.plan.shopifyChargeConfirmationUrl, "_blank");
      }
      this.onCloseModal();
      this.isLoading = false;
    },
    updateCreditCardInputValue(updatedValue) {
      this.creditCardInputValue.cardNumber = updatedValue.cardNumber;
      this.creditCardInputValue.expDate = updatedValue.expDate;
      this.creditCardInputValue.cvc = updatedValue.cvc;
      this.creditCardInputValue.cardHolderName = updatedValue.cardHolderName;
    },
    decreaseIndex() {
      const currentPlanIndex = this.plan.plan.pricings.findIndex(
        (pricing) => pricing.name === this.currentPlan
      );
      // Calculate the new index in a circular manner to loop through the pricings
      if (currentPlanIndex !== -1) {
        this.currentIndex =
          (this.currentIndex - 1 + this.plan.plan.pricings.length) %
          this.plan.plan.pricings.length;
        // Ensure the new index is not the same as the current plan or less than the current plan's index
        while (
          this.currentIndex === currentPlanIndex ||
          this.currentIndex < currentPlanIndex
        ) {
          this.currentIndex =
            (this.currentIndex - 1 + this.plan.plan.pricings.length) %
            this.plan.plan.pricings.length;
        }
        // If the current plan is not found, simply decrement the index in a circular manner
      } else {
        this.currentIndex =
          (this.currentIndex - 1 + this.plan.plan.pricings.length) %
          this.plan.plan.pricings.length;
      }
    },

    increaseIndex() {
      const currentPlanIndex = this.plan.plan.pricings.findIndex(
        (pricing) => pricing.name === this.currentPlan
      );
      // Calculate the new index in a circular manner to loop through the pricings
      if (currentPlanIndex !== -1) {
        this.currentIndex =
          (this.currentIndex + 1) % this.plan.plan.pricings.length;
        // Ensure the new index is not the same as the current plan or less than/equal to the current plan's index
        while (
          this.currentIndex === currentPlanIndex ||
          this.currentIndex <= currentPlanIndex
        ) {
          this.currentIndex =
            (this.currentIndex + 1) % this.plan.plan.pricings.length;
        }
        // If the current plan is not found, simply increment the index in a circular manner
      } else {
        this.currentIndex =
          (this.currentIndex + 1) % this.plan.plan.pricings.length;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.plan-registration {
  width: 100%;
  height: 100%;
  position: relative;
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 40px 0 24px 0;
}
.title {
  @include base-font;
  @include font-big;
  font-weight: 600;
}

.subtitle {
  @include base-font;
  @include font-normal;
  font-weight: 400;
}

.fill-information {
  @include base-font;
  @include font-normal;
  font-weight: 600;
  margin-bottom: 6px;
}

.grid {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  .left {
    width: 70%;
  }
  label {
    margin-right: 30px;
  }
}
.form-input {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.big-inputs {
  margin-left: 48px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  span {
    margin-left: 16px;
    font-weight: bold;
  }
  li {
    margin: 6px 0 6px 0;
  }
}

.add-payment {
  @include font-normal;
  font-weight: 600;
  margin-bottom: 6px;
}

.card-header {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.link {
  float: right;
  color: $dark-grey;
  text-decoration: underline;
  @include font-small;
}

.registration-label {
  @include font-small;
  font-weight: 500;
  vertical-align: -webkit-baseline-middle;
}

.company-name-vat {
  display: flex;
  gap: 16px;
  #company-name {
    width: 261px;
  }
}
.company-name-intra-community {
  width: 100%;
  input {
    width: 100% !important;
  }
}

#name,
#card-number,
#company-name,
#company-vat,
#company-address {
  @include base-font;
  @include font-normal;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  float: right;
  padding: 3px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 1px rgba(226, 124, 252, 0.7);
  }
}

#exp-date,
#cvc {
  @include base-font;
  @include font-normal;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3px;
  margin: 0;
  width: 100%;
  padding: 3px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 1px rgba(226, 124, 252, 0.7);
  }
}

#name,
#card-number,
#company-name,
#company-vat,
#company-address,
#exp-date,
#cvc {
  &:disabled {
    background-color: $light-grey;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.right {
  background-color: #f6f6f6;
  width: 30%;
  height: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  border-radius: 6px;
  margin: 0 48px 0 28px;
  padding: 24px;
}
.new-plan {
  @include base-font;
  @include font-small;
  font-weight: 500;
}

.card-plan {
  @include flex-centered;
  position: relative;
}

.indication {
  @include font-smaller;
  text-align: center;
  color: $medium-grey;
  width: 200px;
}

.btns {
  margin: 20px auto 30px auto;
  width: 50%;
  display: flex;
  justify-content: center;
  gap: 74px;
}

.cancel-button {
  box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.18);
  &:hover {
    background-color: rgba(0, 0, 0, 0.18);
  }
}

.button-confirm-flex {
  display: flex;
  gap: 8px;
  align-items: center;
}

.confirm-button {
  @include base-font;
  @include font-small;
  font-weight: 500;
  outline: none;
  background: none;
  border: none;
}
.shopify-instruction {
  margin-top: 24px;
  margin-left: 48px;
  width: 80%;
}
.shopify-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shopify-logo {
  padding: 4px 16px;
  background-color: #f6f6f6;
}
.shopify-card {
  margin-top: 10px;
  margin-bottom: 16px;
}
.shopify-text {
  @include base-font;
  @include font-small;
  color: $medium-grey;
}
@include media(">tablet", "<=desktop") {
  .registration-label {
    @include font-small;
    margin-right: 10px !important;
  }

  .big-inputs {
    width: 85%;
  }
  #name,
  #card-number,
  #company-name,
  #company-vat,
  company-vat #company-address {
    width: 55%;
    @include font-small;
  }
  #exp-date,
  #cvc {
    @include font-small;
  }
}

@include media(">phone", "<=tablet") {
  .grid {
    @include font-small;
    .left {
      width: 100%;
    }
    .right {
      display: none;
      width: 0%;
    }
  }

  .registration-label {
    margin-right: 10px;
    @include font-smaller;
  }

  #name,
  #card-number,
  #company-name,
  #company-vat,
  #company-address {
    width: 45%;
    @include font-small;
  }
  #exp-date,
  #cvc {
    @include font-small;
  }
  .big-inputs {
    width: 90%;
    margin: auto;
  }

  .btns {
    width: 100%;
  }
}

@include media("<=phone") {
  .grid {
    @include font-small;
    .left {
      width: 100%;
    }
    .right {
      display: none;
      width: 0%;
    }
  }

  .registration-label {
    margin-right: 10px;
    @include font-smaller;
  }

  #name,
  #card-number,
  #company-name,
  #company-vat,
  #company-address {
    width: 45%;
    @include font-small;
  }
  #exp-date,
  #cvc {
    @include font-small;
  }
  .big-inputs {
    width: 90%;
    margin-left: 8px;
  }

  .btns {
    width: 100%;
  }
}
.plan-arrows {
  display: flex;
  align-items: center;
  gap: 12px;
  svg {
    cursor: pointer;
  }
}
</style>
