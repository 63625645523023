export default {
  all: [
    {
      name: {
        en: "Product tutorial",
        fr: "Tutoriel",
        es: "Tutorial de producto",
      },
      text: {
        en: "Product tutorial",
        fr: "Tutoriel produit",
        es: "Tutorial de producto",
      },
      image: require("@/assets/premade-campaigns/how-to-use.png"),
      videoObject: {
        isPremade: true,
        url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/8ea4ed1e-dd89-4909-9326-bd78eab96bfb/video_sample___how_to_use.mp4-compressed.mp4",
        trimmedUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/8ea4ed1e-dd89-4909-9326-bd78eab96bfb/video_sample___how_to_use.mp4-compressed-short.mp4",
        streamingUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/8ea4ed1e-dd89-4909-9326-bd78eab96bfb/video_sample___how_to_use.mp4.m3u8",
        height: 720,
        width: 1280,
        videoDuration: 23.4,
        fileName: "Product tutorial",
        thumbnail:
          "https://image-compression-pipeline-destination.s3.eu-west-1.amazonaws.com/premade-campaign-thumbnails/how-to-use.png",
      },
    },
    {
      name: {
        en: "Welcome visitors 1",
        fr: "Message de bienvenue 1",
        es: "Mensaje de bienvenida 1",
      },
      image: require("@/assets/premade-campaigns/andreea-welcome.png"),
      text: {
        en: "Welcome",
        fr: "Bienvenue",
        es: "Bienvenidos",
      },
      example: "e0b92a9c-e5b8-4f16-9942-c38b42db8320",
      videoObject: {
        isPremade: true,
        url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/d5df463d-c43a-402d-a4a9-dd54d6b2777a/andiwelcomegeneric.mov-compressed.mp4",
        trimmedUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/d5df463d-c43a-402d-a4a9-dd54d6b2777a/andiwelcomegeneric.mov-compressed-short.mp4",
        streamingUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/d5df463d-c43a-402d-a4a9-dd54d6b2777a/andiwelcomegeneric.mov.m3u8",
        height: 1920,
        width: 1080,
        videoDuration: 14.7,
        fileName: "Welcome visitors 1",
        thumbnail:
          "https://image-compression-pipeline-destination.s3.eu-west-1.amazonaws.com/premade-campaign-thumbnails/andreea-welcome.png",
      },
    },
  ],
  accordion: [
    {
      name: {
        en: "Collect emails 1",
        fr: "Collect d'emails 1",
        es: "Recopilador de emails 1",
      },
      image: require("@/assets/premade-campaigns/andrew-collect-emails.png"),
      text: {
        en: "Collect emails",
        fr: "Collect d'emails",
        es: "Recopilar emails",
      },
      videoObject: {
        isPremade: true,
        url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/286d96aa-78c8-4ef3-a461-4d7d028207b8/welcome_email.mov-compressed.mp4",
        trimmedUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/286d96aa-78c8-4ef3-a461-4d7d028207b8/welcome_email.mov-compressed-short.mp4",

        streamingUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/286d96aa-78c8-4ef3-a461-4d7d028207b8/welcome_email.mov.m3u8",
        height: 1920,
        width: 1080,
        videoDuration: 14,
        fileName: "Collect emails 1",
        thumbnail:
          "https://image-compression-pipeline-destination.s3.eu-west-1.amazonaws.com/premade-campaign-thumbnails/andrew-collect-emails.png",
      },
    },
    {
      name: {
        en: "Welcome visitors 2",
        fr: "Message de bienvenue 2",
        es: "Mensaje de bienvenida 2",
      },
      image: require("@/assets/premade-campaigns/andrew-welcome.png"),
      text: {
        en: "Welcome",
        fr: "Bienvenue",
        es: "Bienvenidos",
      },
      videoObject: {
        isPremade: true,
        url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/e6a6393b-5b56-4b1e-9d42-b1effa4d7ecc/welcome_generic.mov-compressed.mp4",
        trimmedUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/e6a6393b-5b56-4b1e-9d42-b1effa4d7ecc/welcome_generic.mov-compressed-short.mp4",
        streamingUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/e6a6393b-5b56-4b1e-9d42-b1effa4d7ecc/welcome_generic.mov.m3u8",
        height: 1920,
        width: 1080,
        videoDuration: 10.2,
        fileName: "Welcome visitors 2",
        thumbnail:
          "https://image-compression-pipeline-destination.s3.eu-west-1.amazonaws.com/premade-campaign-thumbnails/andrew-welcome.png",
      },
    },
    {
      name: {
        en: "Collect emails 2",
        fr: "Collect d'emails 2",
        es: "Recopilador de emails 2",
      },
      image: require("@/assets/premade-campaigns/andreea-welcome-email.png"),
      text: {
        en: "Collect emails",
        fr: "Collect d'emails",
        es: "Recopilar emails",
      },
      example: "11d3de6c-603b-49d5-a97c-311141416b9b",
      videoObject: {
        isPremade: true,
        url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/d5df463d-c43a-402d-a4a9-dd54d6b2777a/andiwelcomegeneric.mov-compressed.mp4",
        trimmedUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/d5df463d-c43a-402d-a4a9-dd54d6b2777a/andiwelcomegeneric.mov-compressed-short.mp4",
        streamingUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/d5df463d-c43a-402d-a4a9-dd54d6b2777a/andiwelcomegeneric.mov.m3u8",
        height: 1920,
        width: 1080,
        videoDuration: 14.7,
        fileName: "Welcome visitors 1",
        thumbnail:
          "https://image-compression-pipeline-destination.s3.eu-west-1.amazonaws.com/premade-campaign-thumbnails/andreea-welcome.png",
      },
    },
    {
      name: {
        en: "Thank buyers 1",
        fr: "Remercier les acheteurs 1",
        es: "Agradecer clientes 1",
      },
      image: require("@/assets/premade-campaigns/andreea-thank-you.png"),
      text: {
        en: "Thank You",
        fr: "Merci!",
        es: "Gracias",
      },
      example: "6eae0115-358f-49ba-be40-5d99b9951fc9",
      videoObject: {
        isPremade: true,
        url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/2673cb47-ca3d-4946-afe4-5c2922d25bec/andithankugeneric.mov-compressed.mp4",
        trimmedUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/2673cb47-ca3d-4946-afe4-5c2922d25bec/andithankugeneric.mov-compressed-short.mp4",
        streamingUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/2673cb47-ca3d-4946-afe4-5c2922d25bec/andithankugeneric.mov.m3u8",
        height: 1920,
        width: 1080,
        videoDuration: 20.4,
        fileName: "Thank buyers 1",
        thumbnail:
          "https://image-compression-pipeline-destination.s3.eu-west-1.amazonaws.com/premade-campaign-thumbnails/andreea-thank-you.png",
      },
    },
    {
      name: {
        en: "Thank buyers 2",
        fr: "Remercier les acheteurs 2",
        es: "Agradecer clientes 2",
      },
      image: require("@/assets/premade-campaigns/andrew-thank-you.png"),
      text: {
        en: "Thank You",
        fr: "Merci",
        es: "Gracias",
      },
      example: "6eae0115-358f-49ba-be40-5d99b9951fc9",
      videoObject: {
        isPremade: true,
        url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/1b5d0d61-bd46-419c-85d6-18ae04295737/thank_you_generic.mov-compressed.mp4",
        trimmedUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/1b5d0d61-bd46-419c-85d6-18ae04295737/thank_you_generic.mov-compressed-short.mp4",
        streamingUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/1b5d0d61-bd46-419c-85d6-18ae04295737/thank_you_generic.mov.m3u8",
        height: 1920,
        width: 1080,
        videoDuration: 13.4,
        fileName: "Thank buyers 2",
        thumbnail:
          "https://image-compression-pipeline-destination.s3.eu-west-1.amazonaws.com/premade-campaign-thumbnails/andrew-thank-you.png",
      },
    },
    {
      name: {
        en: "Thank buyers + CTA 1",
        fr: "Remercier les acheteurs + CTA 1",
        es: "Agradecer clientes + CTA 1",
      },
      image: require("@/assets/premade-campaigns/andrew-thank-you-cta.png"),
      text: {
        en: "Thank You Coupon",
        fr: "Merci Coupon",
        es: "Gracias Cupón",
      },
      example: "642b3a15-e0ff-47aa-a0aa-7dc2e130f1fe",
      videoObject: {
        isPremade: true,
        url: "https://video-compression-pipeline-source-czz220hqvww8.s3-eu-west-1.amazonaws.com/ea450a97-86d5-47c2-8dde-5cbed280663b/d5c66232-18a6-4dd4-8ed3-257bf0963af7/thank_you_cta.mov",
        trimmedUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/d5c66232-18a6-4dd4-8ed3-257bf0963af7/thank_you_cta.mov-compressed-short.mp4",
        streamingUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/d5c66232-18a6-4dd4-8ed3-257bf0963af7/thank_you_cta.mov.m3u8",
        height: 1920,
        width: 1080,
        videoDuration: 12.5,
        fileName: "Thank buyers + CTA 1",
        thumbnail:
          "https://image-compression-pipeline-destination.s3.eu-west-1.amazonaws.com/premade-campaign-thumbnails/andrew-thank-you-cta.png",
      },
    },
    {
      name: {
        en: "Thank buyers + CTA 2",
        fr: "Remercier les acheteurs + CTA 2",
        es: "Agradecer clientes + CTA 2",
      },
      text: {
        en: "Thank You Coupon",
        fr: "Merci Coupon",
        es: "Gracias Cupón",
      },
      example: "642b3a15-e0ff-47aa-a0aa-7dc2e130f1fe",
      image: require("@/assets/premade-campaigns/andreea-thank-you-cta.png"),
      videoObject: {
        isPremade: true,
        url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/05d886c4-de16-4a3e-b1ae-7f29da576c11/andithankuwcoupon.mov-compressed.mp4",
        trimmedUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/05d886c4-de16-4a3e-b1ae-7f29da576c11/andithankuwcoupon.mov-compressed-short.mp4",
        streamingUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/05d886c4-de16-4a3e-b1ae-7f29da576c11/andithankuwcoupon.mov.m3u8",
        height: 1920,
        width: 1080,
        videoDuration: 18.7,
        fileName: "Thank buyers + CTA 2",
        thumbnail:
          "https://image-compression-pipeline-destination.s3.eu-west-1.amazonaws.com/premade-campaign-thumbnails/andreea-thank-you-cta.png",
      },
    },
    {
      name: {
        en: "Social Proof 1",
        fr: "Preuve Sociale 1",
        es: "Prueba social 1",
      },
      text: {
        en: "Social Proof",
        fr: "Social Proof",
        es: "Prueba Social",
      },
      image: require("@/assets/premade-campaigns/andreea-social-proof-cta.png"),
      videoObject: {
        isPremade: true,
        url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/628223b4-5fea-4fc5-9932-967bfb588d17/andisocialproof.mov-compressed.mp4",
        trimmedUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/628223b4-5fea-4fc5-9932-967bfb588d17/andisocialproof.mov-compressed-short.mp4",
        streamingUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/628223b4-5fea-4fc5-9932-967bfb588d17/andisocialproof.mov.m3u8",
        height: 1920,
        width: 1080,
        videoDuration: 13.4,
        fileName: "Social Proof 1",
        thumbnail:
          "https://image-compression-pipeline-destination.s3.eu-west-1.amazonaws.com/premade-campaign-thumbnails/andreea-social-proof-cta.png",
      },
    },

    {
      name: {
        en: "Social Proof 2",
        fr: "Preuve Sociale 2",
        es: "Prueba social 2",
      },
      text: {
        en: "Social proof",
        fr: "Social proof",
        es: "Prueba Social",
      },
      image: require("@/assets/premade-campaigns/andrew-social-proof-cta.png"),
      videoObject: {
        isPremade: true,
        url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/8eae0732-e75b-42bf-bef5-5a18ef948819/social_proof.mov-compressed.mp4",
        trimmedUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/8eae0732-e75b-42bf-bef5-5a18ef948819/social_proof.mov-compressed-short.mp4",
        streamingUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/8eae0732-e75b-42bf-bef5-5a18ef948819/social_proof.mov.m3u8",
        height: 1920,
        width: 1080,
        videoDuration: 10.7,
        fileName: "Social Proof 2",
        thumbnail:
          "https://image-compression-pipeline-destination.s3.eu-west-1.amazonaws.com/premade-campaign-thumbnails/andrew-social-proof-cta.png",
      },
    },
    {
      name: { en: "Flash Sale 1", fr: "Vente Flash 1", es: "Venta Flash 1" },
      image: require("@/assets/premade-campaigns/andreea-flash-sale.png"),
      text: {
        en: "Flash Sale",
        fr: "Vente Flash",
        es: "Venta Flash",
      },
      example: "d7e8b134-0b2b-4f54-9eca-ff97dd4aebf7",
      videoObject: {
        isPremade: true,
        url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/e8fd23a1-0ed4-4a46-b7ed-625ee125abd3/andiflashsalegeneric.mov-compressed.mp4",
        trimmedUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/e8fd23a1-0ed4-4a46-b7ed-625ee125abd3/andiflashsalegeneric.mov-compressed-short.mp4",
        streamingUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/e8fd23a1-0ed4-4a46-b7ed-625ee125abd3/andiflashsalegeneric.mov.m3u8",
        height: 1920,
        width: 1080,
        videoDuration: 12.7,
        fileName: "Flash Sale 1",
        thumbnail:
          "https://image-compression-pipeline-destination.s3.eu-west-1.amazonaws.com/premade-campaign-thumbnails/andreea-flash-sale.png",
      },
    },
    {
      name: { en: "Flash sale 2", fr: "Vente flash 2", es: "Venta Flash 2" },
      image: require("@/assets/premade-campaigns/andrew-flash-sale.png"),
      text: {
        en: "Flash Sale",
        fr: "Vente Flash",
        es: "Venta Flash",
      },
      example: "98b437aa-0f11-4156-ab70-56682a73ffd0",
      videoObject: {
        isPremade: true,
        url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/2ca9e616-7e7c-4197-9841-9598816b4f75/flash_generic.mov-compressed.mp4",
        trimmedUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/2ca9e616-7e7c-4197-9841-9598816b4f75/flash_generic.mov-compressed-short.mp4",
        streamingUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/2ca9e616-7e7c-4197-9841-9598816b4f75/flash_generic.mov.m3u8",
        height: 1920,
        width: 1080,
        videoDuration: 13.1,
        fileName: "Flash sale 2",
        thumbnail:
          "https://image-compression-pipeline-destination.s3.eu-west-1.amazonaws.com/premade-campaign-thumbnails/andrew-flash-sale.png",
      },
    },
    {
      name: { en: "Cross Sell", fr: "Cross Sale", es: "Venta adicional" },
      image: require("@/assets/premade-campaigns/andreea-cross-sale-cta.png"),
      text: {
        en: "Cross Sell",
        fr: "Cross Sell",
        es: "Venta Adicional",
      },
      videoObject: {
        isPremade: true,
        url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/9e10b6b3-a218-452e-82a4-23e97054a223/andiproductcrosssell.mov-compressed.mp4",
        trimmedUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/9e10b6b3-a218-452e-82a4-23e97054a223/andiproductcrosssell.mov-compressed-short.mp4",
        streamingUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/9e10b6b3-a218-452e-82a4-23e97054a223/andiproductcrosssell.mov.m3u8",
        height: 1920,
        width: 1080,
        videoDuration: 13.4,
        fileName: "Cross Sell",
        thumbnail:
          "https://image-compression-pipeline-destination.s3.eu-west-1.amazonaws.com/premade-campaign-thumbnails/andreea-cross-sale-cta.png",
      },
    },
    {
      name: {
        en: "Cross Sell + CTA",
        fr: "Cross Sale + CTA",
        es: "Venta adicional + CTA",
      },
      type: "crossSellCta",
      image: require("@/assets/premade-campaigns/andrew-cross-sale-cta.png"),
      text: {
        en: "Cross Sell CTA",
        fr: "Cross Sell CTA",
        es: "Venta adicional CTA",
      },
      videoObject: {
        isPremade: true,
        url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/4a992122-78ad-4c89-88b8-1e05cfb09725/product_cross_sell.mov-compressed.mp4",
        trimmedUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/4a992122-78ad-4c89-88b8-1e05cfb09725/product_cross_sell.mov-compressed-short.mp4",
        streamingUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/4a992122-78ad-4c89-88b8-1e05cfb09725/product_cross_sell.mov.m3u8",
        height: 1920,
        width: 1080,
        videoDuration: 12.8,
        fileName: "Cross Sell + CTA",
        thumbnail:
          "https://image-compression-pipeline-destination.s3.eu-west-1.amazonaws.com/premade-campaign-thumbnails/andrew-cross-sale-cta.png",
      },
    },
    {
      name: {
        en: "Leaving Visitors (on exit) 1",
        fr: "Retenir les visiteurs 1",
        es: "Visitantes se van 1",
      },
      image: require("@/assets/premade-campaigns/andrew-retain-visitors.png"),
      text: {
        en: "Retain Visitors",
        fr: "Visiteurs Sortants",
        es: "Visitantes se van",
      },
      videoObject: {
        isPremade: true,
        url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/f8060998-9064-41b1-8736-c4ed00be9eb8/retain_leaving_visitors.mov-compressed.mp4",
        trimmedUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/f8060998-9064-41b1-8736-c4ed00be9eb8/retain_leaving_visitors.mov-compressed-short.mp4",
        streamingUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/f8060998-9064-41b1-8736-c4ed00be9eb8/retain_leaving_visitors.mov.m3u8",
        height: 1920,
        width: 1080,
        videoDuration: 7.5,
        fileName: "Leaving Visitors (on exit) 1",
        thumbnail:
          "https://image-compression-pipeline-destination.s3.eu-west-1.amazonaws.com/premade-campaign-thumbnails/andrew-retain-visitors.png",
      },
    },
    {
      name: {
        en: "Leaving Visitors (on exit) 2",
        fr: "Retenir les visiteurs 2",
        es: "Visitantes se van 2",
      },
      image: require("@/assets/premade-campaigns/andreea-retain-visitors.png"),
      text: {
        en: "Retain Visitors",
        fr: "Visiteurs Sortants",
        es: "Visitantes se van",
      },
      videoObject: {
        isPremade: true,
        url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/524b37ae-7fa0-4e2b-b035-4021fe0c735e/17___leaving_visitors__on_exit_.mov-compressed.mp4",
        trimmedUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/524b37ae-7fa0-4e2b-b035-4021fe0c735e/17___leaving_visitors__on_exit_.mov-compressed-short.mp4",
        streamingUrl:
          "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/524b37ae-7fa0-4e2b-b035-4021fe0c735e/17___leaving_visitors__on_exit_.mov-compressed.mov.m3u8",
        height: 1920,
        width: 1080,
        videoDuration: 9.3,
        fileName: "Leaving Visitors (on exit) 2",
        thumbnail:
          "https://image-compression-pipeline-destination.s3.eu-west-1.amazonaws.com/premade-campaign-thumbnails/andreea-retain-visitors.png",
      },
    },
  ],
};
// {
//   name: { en: "Cross Sell + CTA", fr: "Cross Sale + CTA" },
//   type: "crossSellCta",
//   image: require("@/assets/premade-campaigns/andrew-cross-sale-cta.png"),
//   text: {
//     en: "Cross Sell CTA",
//     fr: "Cross Sell CTA",
//   },
//   url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/4a992122-78ad-4c89-88b8-1e05cfb09725/product_cross_sell.mov-compressed.mp4",
//   streamingUrl:
//     "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/4a992122-78ad-4c89-88b8-1e05cfb09725/product_cross_sell.mov.m3u8",
// },
// {
//   name: { en: "Flash sale + CTA", fr: "Vente flash + CTA" },
//   type: "flashSaleCta",
//   image: require("@/assets/premade-campaigns/andrew-flash-sale-cta.png"),
//   text: {
//     en: "Flash Sale CTA",
//     fr: "Vente Flash CTA",
//   },
//   example: "d7e8b134-0b2b-4f54-9eca-ff97dd4aebf7",
//   url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/2a92b66e-2221-4df4-8cfe-7538031e2f8c/flash_cta.mov-compressed.mp4",
//   streamingUrl:
//     "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/2a92b66e-2221-4df4-8cfe-7538031e2f8c/flash_cta.mov.m3u8",
// },
// {
//   image: require("@/assets/premade-campaigns/andreea-welcome-email.png"),
//   text: {
//     en: "Welcome",
//     fr: "Bienvenue",
//   },
// example: "11d3de6c-603b-49d5-a97c-311141416b9b",
//   url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/09fbfb70-f615-4928-bb39-19c4c908646e/andiwelcomeemailcoll.mov-compressed.mp4",
//   streamingUrl:
//     "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/09fbfb70-f615-4928-bb39-19c4c908646e/andiwelcomeemailcoll.mov.m3u8",
// },

// {
//   image: require("@/assets/premade-campaigns/andrew-welcome-email-2.png"),
//   text: {
//     en: "Welcome",
//     fr: "Bienvenue",
//   },
// example: "11d3de6c-603b-49d5-a97c-311141416b9b",
//   url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/f9e97772-16a9-4d9d-a062-93f0d9ccbdc2/welcome_email_2.mov-compressed.mp4",
//   streamingUrl:
//     "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/f9e97772-16a9-4d9d-a062-93f0d9ccbdc2/welcome_email_2.mov.m3u8",
// },
// {
//   name: { en: "Flash Sale + CTA", fr: "Vente Flash + CTA" },
//   type: "flashSaleCta",
//   image: require("@/assets/premade-campaigns/andreea-flash-sale-cta.png"),
//   text: {
//     en: "Flash Sale CTA",
//     fr: "Vente Flash CTA",
//   },
//   example: "98b437aa-0f11-4156-ab70-56682a73ffd0",
//   url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/8bdc8cfc-ff5f-4ec2-914b-a926197e3e33/andiflashsalewithcta.mov-compressed.mp4",
//   streamingUrl:
//     "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/8bdc8cfc-ff5f-4ec2-914b-a926197e3e33/andiflashsalewithcta.mov.m3u8",
// },
//   blackFriday: {
//     andreea: [
//       {
//         name: { en: "Before Black Friday", fr: "Before Black Friday" },
//         type: "preBf",
//         image: require("@/assets/premade-campaigns/bf/andreea-pre-bf.png"),
//         text: {
//           en: "Before Black Friday",
//           fr: "Avant Black Friday",
//         },
//         url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/1d4b3ae4-a552-4218-b59c-b5a4eadca27f/prebf_informal.mov-compressed.mp4",
//         streamingUrl:
//           "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/1d4b3ae4-a552-4218-b59c-b5a4eadca27f/prebf_informal.mov.m3u8",
//       },
//       {
//         name: { en: "On Black Friday", fr: "On Black Friday" },
//         type: "onBf",
//         image: require("@/assets/premade-campaigns/bf/andreea-bf.png"),
//         text: {
//           en: "Black Friday",
//           fr: "Black Friday",
//         },
//         url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/ee5e6398-6531-4abc-9798-6b92b709073d/onbf_informal.mov-compressed.mp4",
//         streamingUrl:
//           "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/ee5e6398-6531-4abc-9798-6b92b709073d/onbf_informal.mov.m3u8",
//       },
//       {
//         name: {
//           en: "Post BF, Pre Cyber Monday",
//           fr: "Post BF, Pre Cyber Monday",
//         },
//         type: "postPre",
//         image: require("@/assets/premade-campaigns/bf/andreea-post-pre.png"),
//         text: {
//           en: "Before Cyber Monday",
//           fr: "Avant Cyber Monday",
//         },
//         url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/4c621e61-3a14-4e77-afe3-473035fcfac2/postbfprecm_informal.mov-compressed.mp4",
//         streamingUrl:
//           "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/4c621e61-3a14-4e77-afe3-473035fcfac2/postbfprecm_informal.mov.m3u8",
//       },
//       {
//         name: { en: "On Cyber Monday", fr: "On Cyber Monday" },
//         type: "onCm",
//         image: require("@/assets/premade-campaigns/bf/andreea-cm.png"),
//         text: {
//           en: "Cyber Monday",
//           fr: "Cyber Monday",
//         },
//         url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/93ce91cf-8a87-44f1-80a2-b4f002155f84/oncm_informal.mov-compressed.mp4",
//         streamingUrl:
//           "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/93ce91cf-8a87-44f1-80a2-b4f002155f84/oncm_informal.mov.m3u8",
//       },
//       {
//         name: { en: "Post BF & CM", fr: "Post BF & CM" },
//         type: "postBfcm",
//         image: require("@/assets/premade-campaigns/bf/andreea-post-bfcm.png"),
//         text: {
//           en: "Post Cyber Monday",
//           fr: "Post Cyber Monday",
//         },
//         url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/bd35b7e8-096e-4bc6-9403-408a363c44e2/postbfcm_informal.mov-compressed.mp4",
//         streamingUrl:
//           "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/bd35b7e8-096e-4bc6-9403-408a363c44e2/postbfcm_informal.mov.m3u8",
//       },
//     ],
//     andrew: [
//       {
//         name: { en: "Before Black Friday", fr: "Before Black Friday" },
//         type: "preBf",
//         image: require("@/assets/premade-campaigns/bf/andrew-pre-bf.png"),
//         text: {
//           en: "Before Black Friday",
//           fr: "Avant Black Friday",
//         },
//         url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/2fce1738-3061-4559-9475-a64704d873cf/prebf_formal.mov-compressed.mp4",
//         streamingUrl:
//           "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/2fce1738-3061-4559-9475-a64704d873cf/prebf_formal.mov.m3u8",
//       },
//       {
//         name: { en: "On Black Friday", fr: "On Black Friday" },
//         type: "onBf",
//         image: require("@/assets/premade-campaigns/bf/andrew-bf.png"),
//         text: {
//           en: "Black Friday",
//           fr: "Black Friday",
//         },
//         url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/39d9faf0-0297-4ae0-ae9f-2b184bb2198e/onbf_formal.mov-compressed.mp4",
//         streamingUrl:
//           "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/39d9faf0-0297-4ae0-ae9f-2b184bb2198e/onbf_formal.mov.m3u8",
//       },
//       {
//         name: {
//           en: "Post BF, Pre Cyber Monday",
//           fr: "Post BF, Pre Cyber Monday",
//         },
//         type: "postPre",
//         image: require("@/assets/premade-campaigns/bf/andrew-post-pre.png"),
//         text: {
//           en: "Before Cyber Monday",
//           fr: "Avant Cyber Monday",
//         },
//         url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/31cc3a8c-af2d-49e8-bb64-309f575fa6f3/postbfprecm_formal.mov-compressed.mp4",
//         streamingUrl:
//           "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/31cc3a8c-af2d-49e8-bb64-309f575fa6f3/postbfprecm_formal.mov.m3u8",
//       },
//       {
//         name: { en: "On Cyber Monday", fr: "On Cyber Monday" },
//         type: "onCm",
//         image: require("@/assets/premade-campaigns/bf/andrew-cm.png"),
//         text: {
//           en: "Cyber Monday",
//           fr: "Cyber Monday",
//         },
//         url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/06808536-b3e7-4f64-b903-c1041dff287d/oncm_formal.mov-compressed.mp4",
//         streamingUrl:
//           "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/06808536-b3e7-4f64-b903-c1041dff287d/oncm_formal.mov.m3u8",
//       },
//       {
//         name: { en: "Post BF & CM", fr: "Post BF & CM" },
//         type: "postBfcm",
//         image: require("@/assets/premade-campaigns/bf/andrew-post-bfcm.png"),
//         text: {
//           en: "Post Cyber Monday",
//           fr: "Post Cyber Monday",
//         },
//         url: "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/e5e4b446-6b5a-4b51-a143-09aca9c9d2b2/postbfcm_formal.mov-compressed.mp4",
//         streamingUrl:
//           "https://video.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/e5e4b446-6b5a-4b51-a143-09aca9c9d2b2/postbfcm_formal.mov.m3u8",
//       },
//     ],
//   },
// };
