<template>
  <div>
    <div class="billing flex-container">
      <div class="big-card">
        <subscription-card :is-appsumo="isAppsumoUser"></subscription-card>
      </div>
      <div class="credit-card-payment-wrapper">
        <credit-card
          v-if="!isAppsumoUser"
          class="credit-card"
          @openCreditCardModal="openModal"
        ></credit-card>
        <payment-history></payment-history>
      </div>
    </div>
    <credit-card-modal v-if="modalOpen" @close="openModal"></credit-card-modal>
  </div>
</template>

<script>
import CreditCard from "./settings-components/CreditCard";
import SubscriptionCard from "./settings-components/SubscriptionCard";
import CreditCardModal from "./settings-components/CreditCardModal";
import PaymentHistory from "./settings-components/PaymentHistory";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    CreditCard,
    SubscriptionCard,
    PaymentHistory,
    CreditCardModal,
  },
  data() {
    return {
      modalOpen: false,
    };
  },
  computed: {
    ...mapState(["plan"]),
    ...mapGetters({
      currentPlan: "plan/currentPlan",
    }),
    isAppsumoUser() {
      if (this.currentPlan) {
        return this.currentPlan.includes("tier");
      } else return false;
    },
  },

  async created() {
    await this.$store.dispatch("plan/getPlan", {
      siteId: this.$store.state.account.user.siteId,
    });
  },

  methods: {
    openModal() {
      this.modalOpen = !this.modalOpen;
    },
  },
};
</script>
<style lang="scss" scoped>
.flex-container {
  justify-content: center;
  grid-template-columns: 1.2fr 0.8fr;
  grid-template-rows: 2fr 0.7fr;
  justify-items: center;
  grid-gap: 32px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  grid-template-areas:
    ". big-card"
    ". big-card";
  margin-bottom: 16px;
}

.credit-card-payment-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.credit-card {
  max-height: 100px;
}

.big-card {
  grid-area: big-card;
  width: 100%;
}

.billing {
  @include base-font;
}

@include media(">tablet", "<=desktop") {
  .credit-card {
    width: 45vw;
    max-width: 450px;
  }
}

@include media(">phone", "<=tablet") {
  .grid-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .big-card {
    margin-top: 16px;
  }
  .credit-card {
    width: 75vw;
    max-width: 450px;
  }
}

@include media("<=phone") {
  .grid-container {
    display: flex;
    flex-direction: column;
  }

  .big-card {
    margin-top: 16px;
  }

  .credit-card {
    width: 300px;
  }

  .button-pen {
    padding: 4px 4px 1px 4px;
    position: absolute;
    top: 25px;
    right: 8px;
  }
}
</style>
