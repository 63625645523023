<template>
  <div class="product-pages-component">
    <list-items
      class="product-list-header"
      :header-elements="productListHeaders"
    >
    </list-items>
    <div class="product-cards-container">
      <div
        v-for="productId in productIds"
        :key="productId"
        class="product-card"
      >
        <div class="image-name">
          <img
            :src="getImageUrl(productId)"
            alt="product-image"
            class="product-image"
          />
          <div class="product-name-icon">
            <a
              v-if="getUrl(productId)"
              class="new-tab"
              :href="getUrl(productId)"
              target="_blank"
              ><NewTabIcon
            /></a>
            <h3 class="product-name">{{ getName(productId) }}</h3>
          </div>
        </div>
        <p class="stock">{{ calculateTotalQty(productId) }}</p>
        <p class="price">{{ firstVariantPrice(getProductById(productId)) }}</p>
        <CloseIcon class="delete-icon" @click="removeProduct(productId)" />
      </div>
    </div>
    <button
      class="action-button add-product-button"
      @click="toggleProductsModal"
    >
      {{ $t("create.bulk.pagesModal.addProduct") }}
    </button>
    <AddToCartModal
      v-if="showProductsModal"
      :is-full-screen="true"
      :selected-products-edit="getSelectedProducts(productIds)"
      @close-add-to-cart="showProductsModal = false"
      @submit-add-to-cart-products="submitAddToCartProducts"
    />
  </div>
</template>

<script>
import ListItems from "@/components/shared/ListItems.vue";
import CloseIcon from "@/assets/svg/settings/close.svg?inline";
import { mapGetters, mapState } from "vuex";
import AddToCartModal from "@/components/create-campaign-steps/step3/AddToCartModal.vue";
import NewTabIcon from "@/assets/svg/open_new_tab.svg?inline";

export default {
  components: {
    ListItems,
    CloseIcon,
    AddToCartModal,
    NewTabIcon,
  },
  props: {
    productIds: { type: Array, default: () => [] },
  },
  data() {
    return {
      showProductsModal: false,
    };
  },
  computed: {
    ...mapState({
      products: (state) => state.product.products,
    }),
    ...mapGetters({
      getCurrency: "site/getCurrency",
      siteId: "account/getSiteId",
      currentFeedEdit: "campaign/currentFeedEdit",
      feedList: "campaign/feedList",
    }),
    productListHeaders() {
      return [
        this.$t("create.bulk.pagesModal.listItems.productPages") +
          this.productIds.length,
        this.$t(""),
        this.$t(""),
        this.$t(""),
        this.$t("create.bulk.pagesModal.listItems.inventory"),
        this.$t("create.bulk.pagesModal.listItems.price"),
      ];
    },
  },
  async created() {
    await this.$store.dispatch("product/getProducts", {
      siteId: this.siteId,
      isProductListView: false,
    });
  },
  methods: {
    calculateTotalQty(productId) {
      const product = this.getProductById(productId);
      if (product && product.variants) {
        let totalQty = product.variants.reduce(
          (total, variant) => total + variant.qty,
          0
        );
        let hasUnlimited = product.variants.some(
          (variant) => variant.sellWithoutStock
        );

        if (hasUnlimited) {
          return this.$t("create.bulk.pagesModal.stock.unlimited");
        } else if (totalQty === 0) {
          return this.$t("create.bulk.pagesModal.stock.outStock");
        } else {
          return totalQty + " " + this.$t("create.bulk.pagesModal.stock.stock");
        }
      }
      return this.$t("create.bulk.pagesModal.stock.outStock");
    },
    firstVariantPrice(product) {
      if (product && product.variants && product.variants.length > 0) {
        const firstVariant = product.variants[0];
        const price = firstVariant ? firstVariant.price : 0;

        if (this.getCurrency === "$") {
          return `$${price}`;
        } else if (this.getCurrency === "€") {
          return `${price}€`;
        } else {
          return `${price}${this.getCurrency}`;
        }
      } else {
        return "";
      }
    },
    getProductById(productId) {
      return this.products.find((product) => product.productId === productId);
    },
    removeProduct(productId) {
      this.$emit(
        "update-product-segmentation",
        this.productIds.filter((id) => id !== productId)
      );
    },
    getImageUrl(productId) {
      const product = this.getProductById(productId);
      return product && product.imageUrl ? product.imageUrl : "";
    },
    getName(productId) {
      const product = this.getProductById(productId);
      return product && product.name ? product.name : "";
    },
    getUrl(productId) {
      const product = this.getProductById(productId);
      return product && product.productUrl ? product.productUrl : "";
    },
    toggleProductsModal() {
      this.showProductsModal = !this.showProductsModal;
    },
    submitAddToCartProducts(payload) {
      const productIds = payload.map((product) => product.productId);

      this.$emit("update-product-segmentation", productIds);

      this.showProductsModal = false;

      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("create.bulk.pagesModal.productAdded"),
        type: "success",
      });
    },
    getSelectedProducts(productIds) {
      const selectedProducts = this.products.filter((product) =>
        productIds.includes(product.productId)
      );

      return selectedProducts;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-list-header {
  margin-bottom: 12px;
}

.add-product-button {
  width: 200px;
}

.product-card {
  position: relative;
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  border: 1px solid $light-grey;
  border-radius: 5px;
  height: 68px;
  box-sizing: border-box;
  align-items: center;
  margin-bottom: 8px;
  padding: 8px 16px;
  p {
    @include base-font;
    @include font-normal;
    font-weight: 500;
  }
}

.delete-icon {
  position: absolute;
  right: 16px;
  cursor: pointer;
}

.image-name {
  display: flex;
  align-items: center;
  gap: 8px;
  h3 {
    @include base-font;
    @include font-normal;
  }
}

.product-image {
  width: 52px;
  height: 52px;
  border-radius: 2px;
}
.product-name-icon {
  display: flex;
  align-items: center;
  gap: 4px;
}
</style>
