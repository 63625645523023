<template>
  <section class="data-table">
    <div class="title-wrapper">
      <h2 class="title">
        <SlideIcon class="slide-icon" />
        {{ $t("dashboard.dashboardTable.videoList") }}
      </h2>
    </div>
    <table class="table">
      <thead class="table-header">
        <tr>
          <th class="video-name">
            {{ $t("dashboard.dashboardTable.fileName") }}
          </th>
          <th class="column align-right">
            {{ $t("dashboard.dashboardTable.impressions") }}
          </th>
          <th class="column align-right">
            {{ $t("dashboard.dashboardTable.plays") }}
          </th>
          <th class="column align-right">
            {{ $t("dashboard.dashboardTable.avgViewDuration") }}
          </th>
          <th class="column align-right">
            {{ $t("dashboard.dashboardTable.clicksShare") }}
          </th>
          <th class="column align-right">
            {{ $t("dashboard.dashboardTable.salesCount") }}
          </th>
          <th class="column align-right">
            {{ $t("dashboard.dashboardTable.salesAmount") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(video, index) in data" :key="index" class="row">
          <td
            v-tippy="{ arrow: true, placement: 'top', a11y: false }"
            class="video-name"
            :content="video.fileName"
          >
            <OpenIcon
              class="open-icon"
              @click="handleOpenVideoLibrarySlidingModal(video)"
            />
            {{ video.fileName }}
          </td>
          <td class="align-right">{{ video.display }}</td>
          <td class="align-right">{{ video.viewTimeCount }}</td>
          <td class="align-right">{{ avgViewDurationPercentage(video) }}%</td>
          <td class="align-right">{{ video.videoShared }}</td>
          <td class="align-right">{{ video.conversionCount }}</td>
          <td class="align-right">
            {{ currencyDisplay(video.conversionAmount) }}
          </td>
        </tr>
      </tbody>
    </table>
    <VideoLibrarySlidingModal
      v-if="modalData.openVideoLibrarySlidingModal"
      :preselected-query="modalData.fileName"
      @close-video-library="closeVideoLibrary"
    />
  </section>
</template>

<script>
import SlideIcon from "@/assets/svg/slide-icon.svg?inline";
import OpenIcon from "@/assets/svg/cta/open-icon.svg?inline";
import VideoLibrarySlidingModal from "@/components/shared/VideoLibrarySlidingModal.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    SlideIcon,
    OpenIcon,
    VideoLibrarySlidingModal,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      videos: [],
      modalData: {
        openVideoLibrarySlidingModal: false,
        fileName: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      getSiteId: "account/getSiteId",
      getVideoLibrary: "video/getVideoLibrary",
      getCurrency: "site/getCurrency",
    }),
  },
  async created() {
    await this.$store.dispatch("video/getVideoLibrary", {
      siteId: this.getSiteId,
      query: {
        $or: [{ isPremade: { $exists: false } }, { isPremade: false }],
        $and: [
          {
            fileName: { $exists: true },
            height: { $exists: true },
            width: { $exists: true },
            videoDuration: { $exists: true },
            url: { $not: { $regex: "blob:" } },
          },
        ],
        getAnalytics: true,
      },
    });
    this.videos = this.getVideoLibrary;
  },
  methods: {
    currencyDisplay(amount) {
      if (this.getCurrency === "$") {
        return `${this.getCurrency}${amount}`;
      } else {
        return `${amount}${this.getCurrency}`;
      }
    },
    avgViewDurationPercentage(video) {
      return Math.ceil((video.avgViewDuration / video.videoDuration) * 100);
    },
    handleOpenVideoLibrarySlidingModal(row) {
      this.modalData = {
        openVideoLibrarySlidingModal: true,
        fileName: row.fileName,
      };
      this.$emit("video-library-opened");
    },
    closeVideoLibrary() {
      this.modalData = {
        openVideoLibrarySlidingModal: false,
        fileName: "",
      };
      this.$emit("video-library-closed");
    },
  },
};
</script>

<style lang="scss" scoped>
.data-table {
  width: 100%;
}
.table {
  width: 100%;
}

.title-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .title {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .slide-icon {
    transform: scale(150%);
  }
}
.table-header tr {
  background-color: #ececec;
  display: grid;
  grid-template-columns: 20% 12.5% 12.5% 12.5% 12.5% 15% 15%;
  border-radius: 4px !important;
}

.table-header {
  padding: 8px 16px;
  border-radius: 4px !important;

  th {
    font-weight: 500;
    vertical-align: middle;
    white-space: nowrap;
    padding: 8px 16px;
  }
  th.align-right {
    text-align: end;
  }

  th.video-name {
    text-align: left;
    padding-left: 16px;
  }
}

tbody {
  display: grid;
}

tr {
  max-height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 20% 12.5% 12.5% 12.5% 12.5% 15% 15%;
}

td {
  padding: 16px;
  text-align: right;
  box-sizing: border-box;
  font-weight: 500;
}

.video-name {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.align-right {
  text-align: right;
}

.row:hover {
  background: linear-gradient(
    270deg,
    #f2d9fc 11.86%,
    rgba(242, 217, 252, 0) 100%
  );
  cursor: default;
  outline: 1px solid #dcdbdb;
  border-radius: 5px;
}
.open-icon {
  cursor: pointer;
  margin-right: 4px;
}
@media (max-width: 1200px) {
  .table-header tr {
    grid-template-columns: 16.25% 13.75% 10% 16.25% 16.25% 13.75% 13.75%;
  }
  tr {
    grid-template-columns: 16.25% 13.75% 10% 16.25% 16.25% 13.75% 13.75%;
  }
}
</style>
