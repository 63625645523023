import { render, staticRenderFns } from "./TitleBlock.vue?vue&type=template&id=73ef57fe&scoped=true&"
import script from "./TitleBlock.vue?vue&type=script&lang=js&"
export * from "./TitleBlock.vue?vue&type=script&lang=js&"
import style0 from "./TitleBlock.vue?vue&type=style&index=0&id=73ef57fe&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73ef57fe",
  null
  
)

export default component.exports