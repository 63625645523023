<template>
  <v-select
    :value="selectedValue"
    class="vidjet-single-select cta-dropdown"
    :placeholder="placeholder"
    :searchable="false"
    :clearable="false"
    :options="options"
    :reduce="(buttonOption) => buttonOption.value"
    :is-disabled="isDisabled"
    @input="updateSelectedValue"
  >
    <template #open-indicator>
      <AngleDown></AngleDown>
    </template>
    <template #option="option">
      <OpenIconSelect v-if="option.value === 0"></OpenIconSelect>
      <CopyIconSelect v-if="option.value === 1"></CopyIconSelect>
      <EmailIconSelect v-if="option.value === 2"></EmailIconSelect>
      <CartIconSelect v-if="option.value === 3"></CartIconSelect>
      {{ option.label }}
    </template>
    <template #selected-option="option">
      <OpenIconSelect v-if="option.value === 0"></OpenIconSelect>
      <CopyIconSelect v-if="option.value === 1"></CopyIconSelect>
      <EmailIconSelect
        v-if="option.value === 2"
        class="email-icon"
      ></EmailIconSelect>
      <CartIconSelect v-if="option.value === 3"></CartIconSelect>
      {{ option.label }}
    </template>
  </v-select>
</template>

<script>
import CartIconSelect from "@/assets/svg/cta/cart-icon.svg?inline";
import OpenIconSelect from "@/assets/svg/cta/open-icon.svg?inline";
import CopyIconSelect from "@/assets/svg/cta/copy-icon.svg?inline";
import EmailIconSelect from "@/assets/svg/cta/email-icon.svg?inline";
import AngleDown from "@/assets/svg/cta/icon-angle-down.svg?inline";
export default {
  components: {
    CartIconSelect,
    OpenIconSelect,
    CopyIconSelect,
    EmailIconSelect,
    AngleDown,
  },
  props: {
    value: {
      type: [Object, Number, String],
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    updateSelectedValue(newValue) {
      this.selectedValue = newValue;
    },
  },
};
</script>

<style lang="scss">
.cta-dropdown.vidjet-single-select {
  .vs--open .vs__dropdown-toggle {
    border-bottom: 1px solid #c4c4c4;
  }

  .vs__selected {
    color: $dark-purple;
    display: flex;
    gap: 10px;
    font-size: 14px;

    path {
      fill: $dark-purple;
    }
  }

  .vs__dropdown-menu {
    position: absolute !important;
  }

  .vs__dropdown-toggle,
  .vs__dropdown-menu {
    font-size: 14px;
    border-radius: 2px;

    .vs__dropdown-option {
      font-size: 14px;
      display: flex;
      gap: 10px;
      justify-content: left;
      padding: 6px 12px;
    }
  }

  .vs__actions {
    path {
      fill: #c4c4c4;
    }
  }
  .email-icon {
    path {
      stroke: $dark-purple;
      fill: none;
    }
  }
}
</style>
