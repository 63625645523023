import api from "../api";

const state = {
  suggestion: {},
};

const actions = {
  async postSuggestion({ commit }, data) {
    try {
      await api.sendSuggestion(data);
    } catch (err) {
      throw err;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
};
