<template>
  <div class="default-open-input">
    <span class="open-text" :class="{ 'bulk-open-text': isBulk }">{{
      labelText
    }}</span>
    <range-slider
      :input-name="inputFor"
      :slider-value.sync="value"
      :max="maxValue"
      :input-width="inputWidth"
      :step="step"
      :type="type"
    />
  </div>
</template>

<script>
import RangeSlider from "@/components/shared/RangeSlider.vue";
export default {
  components: {
    RangeSlider,
  },

  props: {
    inputFor: {
      type: String,
      default: "",
    },
    presetValue: {
      type: Number,
      default: 0,
    },
    labelText: {
      type: String,
      default: "",
    },
    maxValue: {
      type: Number,
      default: 100,
    },
    inputWidth: {
      type: String,
      default: "50px",
    },
    step: {
      type: Number,
      default: 5,
    },
    type: {
      type: String,
      default: "px",
    },
    isBulk: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      value: true,
    };
  },

  watch: {
    value() {
      this.$emit("toggle-button", {
        inputFor: this.inputFor,
        value: this.value,
      });
    },
    presetValue() {
      this.value = this.presetValue;
    },
  },

  created() {
    this.value = this.presetValue;
  },
};
</script>

<style lang="scss" scoped>
.default-open-input {
  @include font-small;
  display: flex;
  justify-content: space-between;
  &:hover {
    background: $settings-hover;
  }
}
.bulk-open-text {
  text-align: left;
}
</style>
