<template>
  <base-card>
    <template #icon>
      <CreditCardIcon />
    </template>
    <template #title>
      {{ $t("billing.creditCardInformation.title") }}
    </template>
    <button-pen
      v-show="
        site.site.integration !== 'shopify' ||
        (site.site.payByCard && !vatNumber)
      "
      class="button-pen"
      @click.native="openCreditCardModal"
    ></button-pen>

    <section v-if="!vatNumber">
      <div
        v-if="userIsShopify || userHasNoPaymentMethod"
        class="credit-card-user"
      >
        <p v-if="userIsShopify" class="shopify-info">
          {{ $t("billing.creditCardInformation.isShopify") }}
        </p>
        <p v-else-if="userHasNoPaymentMethod">
          {{ $t("billing.creditCardInformation.hasNoCard") }}
        </p>
      </div>
      <div v-else class="credit-card-information">
        <p class="card-info">{{ plan.plan.cardBrand }}</p>
        <p>{{ plan.plan.cardLastFourDigits }}</p>
        <p class="card-info">
          {{ $t("billing.creditCardInformation.isNotShopify.expDate") }}
        </p>
        <p>{{ cardExpDate }}</p>
      </div>
      <div class="billing-block">
        <p v-if="billingDate" class="billing-date">
          {{ $t("billing.creditCardInformation.billingDate1") }}
          {{ billingDate }}
          {{ $t("billing.creditCardInformation.billingDate2") }}
        </p>
        <p
          v-if="!vatNumber && isIntraCommunity"
          class="add-vat-number"
          @click="toggleVatNumber"
        >
          {{
            Boolean(site.site.vatNumber)
              ? $t("billing.creditCardInformation.vat.updateVat")
              : $t("billing.creditCardInformation.vat.addVat")
          }}
        </p>
      </div>
    </section>
    <section v-else>
      <form class="form" @submit.prevent="updateVat">
        <label class="add-vat-label" for="vat">{{
          $t("planRegistration.vat")
        }}</label>
        <input
          id="vat"
          type="text"
          :value="site.site.vatNumber ? site.site.vatNumber : ''"
          @input="onVatUpdate"
        />
        <div class="buttons">
          <input
            class="button-primary-small --grey"
            type="button"
            :value="$t('shared.buttons.back')"
            @click="toggleVatNumber"
          />
          <input
            class="button-primary-small"
            type="submit"
            :value="$t('shared.buttons.save')"
          />
        </div>
      </form>
    </section>
  </base-card>
</template>

<script>
import CreditCardIcon from "@/assets/svg/settings/credit-card.svg?inline";
import BaseCard from "../../shared/BaseCard.vue";
import ButtonPen from "../../shared/Vidjet-pen-btn.vue";
import { mapState, mapGetters } from "vuex";
import VatCheck from "@/utils/intraCommunityVatCheck.js";

export default {
  components: {
    CreditCardIcon,
    ButtonPen,
    BaseCard,
  },
  data() {
    return {
      vatNumber: false,
      allInfoChecked: false,
      vat: "",
    };
  },

  computed: {
    ...mapState(["plan", "site", "user"]),
    ...mapGetters({
      cardExpDate: "plan/cardExpDate",
      getAddress: "site/getAddress",
    }),

    userIsShopify() {
      return (
        this.site.site.integration === "shopify" && !this.site.site.payByCard
      );
    },
    userHasNoPaymentMethod() {
      return !this.plan.plan.hasPaymentMethod && !this.vatNumber;
    },
    billingDate() {
      if (!this.$store.state.plan.plan.billingCycleEndDate) return;
      let date = new Date(this.$store.state.plan.plan.billingCycleEndDate);
      return date.getDate();
    },
    isIntraCommunity() {
      if (!this.getAddress || !this.getAddress.country) {
        return false;
      }
      return VatCheck(this.getAddress.country);
    },
  },
  async created() {
    await this.$store.dispatch("user/getUser", {
      userId: this.$store.state.account.user._id,
    });
    await this.$store.dispatch("site/getSite", {
      siteId: this.$store.state.account.user.siteId,
    });
    // checks wether or not the user has entered all his information to add payment
    if (
      Boolean(this.user.user.firstname) &&
      Boolean(this.user.user.lastname) &&
      Boolean(this.user.user.email) &&
      this.site.site.address &&
      this.site.site.address.line1 != "" &&
      Boolean(this.site.site.companyName)
    ) {
      this.allInfoChecked = true;
    } else {
      this.allInfoChecked = false;
    }
  },
  methods: {
    onVatUpdate(e) {
      this.vat = e.target.value;
    },
    async updateVat() {
      await this.$store.dispatch("site/updateSite", {
        siteId: this.site.site._id,
        dataToUpdate: { vatNumber: this.vat.toUpperCase() },
      });
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        ignoreDuplicates: true,
        text: this.$t("shared.toastMessage.vatAdded"),
        type: "success",
      });
      this.vatNumber = false;
    },
    toggleVatNumber() {
      this.vatNumber = !this.vatNumber;
    },
    // if user hasn't added all his infos user is redirected to account page
    openCreditCardModal() {
      if (this.allInfoChecked) {
        this.$emit("openCreditCardModal");
      } else {
        this.$router.push({ name: "account" });
        this.$notify({
          title: this.$t("shared.toastMessage.fillInformation"),
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.credit-card-user {
  @include font-small;
  margin-bottom: 12px;
}

.credit-card-information {
  @include font-small;
  display: flex;
  justify-content: space-evenly;
  margin-right: 50px;
}

.card-info {
  color: black;
  font-weight: 600;
}

.button-pen {
  position: absolute;
  top: 16px;
  right: 24px;
}

.form {
  display: flex;
  justify-content: space-evenly;
}

.add-vat-label {
  @include font-small;
  align-self: center;
}

.billing-block {
  @include font-small;
  display: flex;
  margin-top: 12px;
  justify-content: space-between;
}

.billing-date,
.shopify-info {
  color: $light-grey;
}

.add-vat-number {
  text-decoration: underline;
  cursor: pointer;
}

#vat {
  height: 21px;
  @include input-style;
  align-self: center;
}

.buttons {
  display: flex;
  width: 25%;
  justify-content: space-evenly;
  grid-gap: 8px;
}

@include media(">tablet", "<=desktop") {
  .credit-card-user {
    width: 90%;
    margin-left: 32px;
  }
}

@include media(">phone", "<=tablet") {
  .credit-card-user {
    @include font-small;
    margin-left: 16px;
  }

  .credit-card-information {
    @include font-small;
    margin-right: 10px;
  }
}

@include media("<=phone") {
  .credit-card-user {
    @include font-smaller;
    margin-left: 16px;
  }

  .credit-card-information {
    @include font-smaller;
    margin-right: 10px;
  }
}
</style>
