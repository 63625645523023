<template>
  <div class="format-block">
    <h3 class="format-block-title">
      {{ $t("create.step3.formatPickerTitle") }}
    </h3>
    <div
      v-if="!isCarouselInline && showFormatPicker"
      class="format-selection-container"
    >
      <div v-if="isFullScreen" class="buttons-container">
        <div v-for="button in buttonsArrayFullscreenFlow" :key="button.text">
          <input
            :id="button.value"
            ref="input"
            class="input-format"
            name="format"
            type="radio"
            :disabled="button.disabled"
          />
          <label
            class="format-label"
            name="format"
            :for="button.text"
            @click="!button.disabled ? emitChangeFormat(button.value) : ''"
          >
            <div class="card-content">
              <img
                v-lazy="button.imageSrc"
                alt="format-image"
                :class="
                  button.disabled ? 'format-image-disabled' : 'format-image'
                "
              />
              <p class="button-text" :class="{ disabled: button.disabled }">
                {{ button.text }}
              </p>
            </div>
          </label>
        </div>
      </div>
      <div v-else-if="showFormatPicker" class="buttons-container">
        <div v-for="button in buttonsArray" :key="button.text">
          <input
            :id="button.value"
            ref="input"
            class="input-format"
            name="format"
            type="radio"
          />
          <label
            class="format-label"
            name="format"
            :for="button.text"
            @click="emitChangeFormat(button.value)"
          >
            <div class="card-content">
              <img
                v-lazy="button.imageSrc"
                alt="format-image"
                class="format-image"
              />
              <p class="button-text">{{ button.text }}</p>
            </div>
          </label>
        </div>
      </div>
    </div>
    <ToggleBox
      :options="[
        { text: 'inline', value: 'inline' },
        { text: 'fullscreen', value: 'fullScreen' },
      ]"
      :is-disabled="isStory || isStickyBar || isPopup"
      :selected="isFullScreen ? 'fullScreen' : 'inline'"
      @option-selected="changeFullScreen"
    ></ToggleBox>
  </div>
</template>

<script>
import BubbleCard from "@/assets/usecases_cards/bubble-fs.png";
import BubbleCardInline from "@/assets/usecases_cards/bubble-in.png";
import PopupCard from "@/assets/usecases_cards/popup-in.png";
import Stories from "@/assets/usecases_cards/stories-fs.png";
import Carousel from "@/assets/usecases_cards/carousel-fs.png";
import StickyCard from "@/assets/usecases_cards/sticky-in.png";
import ToggleBox from "../../shared/ToggleBox.vue";
import enums from "../../../enums";

const { FormatType } = enums;

export default {
  components: {
    ToggleBox,
  },
  props: {
    formatType: {
      type: Number,
      default: 1,
    },
    isStickyBar: {
      type: Boolean,
      default: false,
    },
    chosenDevice: {
      type: String,
      default: "desktop",
    },
    isStory: {
      type: Boolean,
      default: false,
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
    campaignFormatOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isBulk: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FormatType,
      buttonsArrayFullscreenFlow: [
        {
          text: "Bubble",
          imageSrc: BubbleCard,
          value: "fullscreen-bubble",
          checked: false,
        },
        {
          text: "Stories",
          imageSrc: Stories,
          value: "stories",
          checked: false,
        },
        {
          text: "Carousel",
          imageSrc: Carousel,
          value: "carousel",
          checked: false,
        },
      ],
      buttonsArray: [
        {
          text: "Bubble",
          imageSrc: BubbleCardInline,
          value: "bubble",
          checked: false,
        },
        {
          text: "Popup",
          imageSrc: PopupCard,
          value: "popup",
          checked: false,
        },
        {
          text: "Sticky Play",
          imageSrc: StickyCard,
          value: "sticky",
          checked: false,
        },
      ],
      formatObject: {},
    };
  },
  computed: {
    isFullScreen() {
      return this.campaignFormatOptions.isFullScreen;
    },

    isCarouselInline() {
      return (
        this.campaignFormatOptions.formatType === FormatType.carousel &&
        !this.isFullScreen
      );
    },
    isCarousel() {
      return this.campaignFormatOptions.formatType === FormatType.carousel;
    },
    showFormatPicker() {
      if (this.isBulk) return false
      
      const isEditCarousel = this.isCarousel && this.isEdit;
      return !isEditCarousel;
    },
    isEdit() {
      return this.$route.path.includes("edit");
    },
  },
  watch: {
    chosenDevice() {
      this.checkRadioButton();
    },
    formatType() {
      this.checkRadioButton();
    },
    isStory() {
      this.checkRadioButton();
    },
    isStickyBar() {
      this.checkRadioButton();
    },
  },
  mounted() {
    this.checkRadioButton();
    this.formatObject = { ...this.campaignFormatOptions };
  },

  methods: {
    changeCheckMarker(button) {
      button.checked = true;
    },

    checkRadioButton() {
      const checkboxes = this.$refs.input;
      if (!checkboxes) return;
      checkboxes.forEach((checkbox) => {
        if (checkbox.id === this.determineFormat()) checkbox.checked = true;
      });
    },
    determineFormat() {
      if (this.formatType == FormatType.popup && this.isStickyBar) {
        return "sticky";
      } else if (this.formatType == FormatType.popup && !this.isStickyBar) {
        return "popup";
      } else if (this.isFullScreen && this.formatType == FormatType.bubble) {
        return "fullscreen-bubble";
      } else if (this.formatType == FormatType.bubble) {
        return "bubble";
      } else if (this.formatType == FormatType.carousel && this.isStory) {
        return "stories";
      } else if (this.formatType == FormatType.carousel && !this.isStory) {
        return "carousel";
      }
      return false;
    },
    emitChangeFormat(value) {
      if (value === this.determineFormat()) return;
      this.$emit("change-format-type", value);
    },
    changeFullScreen() {
      this.formatObject.isFullScreen = !this.formatObject.isFullScreen;
      this.$emit("change-fullscreen", {
        inputFor: "isFullScreen",
        value: this.formatObject.isFullScreen,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.format-selection-container {
  width: 100%;
}

.buttons-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.box-toggle-section {
  width: 80%;
}

.format-image {
  height: 40px;
  cursor: pointer;
  border-radius: 5px;
}
.format-image-disabled {
  height: 40px;
  cursor: default;
  border-radius: 5px;
}
.input-format {
  display: none;
  &:checked + .format-label .format-image {
    border: 1px solid $dark-purple;
  }
  &:checked + .format-label .button-text {
    color: $dark-grey;
  }
}

.button-text {
  @include font-smallest;
  word-break: break-word;
  width: 70px;
}
.disabled {
  color: $light-grey;
}
</style>
