<template>
  <div class="account">
    <div class="flex-column">
      <div class="card-grid">
        <card-component
          v-for="card in computedCardArr"
          :key="card.title"
          class="card-component"
          :check-icon="card.checkIcon"
          :display-green-check="card.displayGreenCheck"
        >
          <template #icon>
            <component :is="card.icon"></component>
          </template>
          <template #title>
            {{ card.title }}
          </template>

          <component
            :is="card.component"
            @toggle="toggleAddressModal"
          ></component>
        </card-component>
      </div>
      <div class="card-flex bottom-buttons">
        <locale-changer class="lang-dropdown" />
        <div class="right-buttons">
          <button
            v-if="showWatermark"
            class="account-button first"
            @click="removeWatermark"
          >
            <BubbleIcon />
            {{ $t("account.removeWatermark") }}
          </button>
          <button
            class="account-button second"
            :class="{ first: !showWatermark }"
            @click="opeChangePassword"
          >
            <PasswordIcon />{{
              user.user.hasPassword
                ? $t("account.updatePassword")
                : $t("account.setPassword")
            }}
          </button>
          <button
            v-if="user.user.hasPassword"
            class="account-button third"
            @click="logOut"
          >
            <LogOutIcon />{{ $t("account.logOut") }}
          </button>
          <button class="account-button fourth" @click="toggleDeleteModal">
            <TrashIcon />{{ $t("account.deleteAccount") }}
          </button>
        </div>
      </div>
    </div>
    <address-modal v-if="showAddressModal" @close="toggleAddressModal" />
    <ChangePassword v-if="showChangePassword" @close="closeChangePassword" />
    <delete-account-modal v-if="showDeleteModal" @close="toggleDeleteModal" />
    <PlanRegistration
      v-if="showUpgradeModal"
      :new-plan="growthPlan"
      @close="closeUpgradeModal"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import LocaleChanger from "../LocaleChanger.vue";
import UserInformation from "./settings-components/UserInformation";
import CompanyInformation from "./settings-components/CompanyInformation";
import Usage from "./settings-components/Usage";
import Password from "./settings-components/Password";
import AddressModal from "./settings-components/AddressModal";
import DeleteAccountModal from "./settings-components/DeleteAccountModal";
import CardComponent from "@/components/shared/CardComponent.vue";
import ChangePassword from "@/components/settings/ChangePassword.vue";
import PlanRegistration from "@/components/upgrade-plan/PlanRegistration.vue";

import UserIcon from "@/assets/svg/settings/user-info.svg?inline";
import CompanyIcon from "@/assets/svg/settings/company-info.svg?inline";
import UsageIcon from "@/assets/svg/settings/usage.svg?inline";
import PasswordIcon from "@/assets/svg/settings/password.svg?inline";
import BubbleIcon from "@/assets/svg/settings/bubble-icon.svg?inline";
import LogOutIcon from "@/assets/svg/settings/log-out-icon.svg?inline";
import TrashIcon from "@/assets/svg/delete.svg?inline";

export default {
  components: {
    UserInformation,
    CompanyInformation,
    Usage,
    Password,
    AddressModal,
    DeleteAccountModal,
    LocaleChanger,
    CardComponent,
    UserIcon,
    CompanyIcon,
    UsageIcon,
    PasswordIcon,
    BubbleIcon,
    LogOutIcon,
    TrashIcon,
    ChangePassword,
    PlanRegistration,
  },

  data() {
    return {
      showAddressModal: false,
      showDeleteModal: false,
      showChangePassword: false,
      showUpgradeModal: false,
    };
  },
  computed: {
    ...mapState(["user", "plan", "usage"]),
    ...mapGetters({
      userHasInfo: "user/userHasInfo",
      siteHasInfo: "site/siteHasInfo",
      userIsTrial: "plan/userIsTrial",
      currentPlan: "plan/currentPlan",
      currentPricing: "plan/currentPricing",
      showWatermark: "plan/showWatermark",
      videoViewsLimit: "plan/videoViewsLimit",
      videosInCampaignsLimit: "plan/videosInCampaignsLimit",
      numberOfVideosInLibrary: "video/numberOfVideosInLibrary",
    }),

    computedCardArr() {
      return [
        {
          checkIcon: true,
          displayGreenCheck: this.userHasInfo && this.siteHasInfo,
          icon: "UserIcon",
          title: this.$t("account.userInformation.title"),
          component: "UserInformation",
        },
        {
          checkIcon: true,
          displayGreenCheck: !this.userHasReachedUsageLimit,
          icon: "UsageIcon",
          title: this.$t("account.usage.title"),
          component: "Usage",
        },
      ];
    },
    userHasReachedUsageLimit() {
      if (!this.videoViewsLimit && !this.videosInCampaignsLimit) 
        return false;

      if (this.usage.usage.videoViews >= this.videoViewsLimit)
        return true;

      if (this.numberOfVideosInLibrary >= this.videosInCampaignsLimit)
        return true;

      return false;
    },
    growthPlan() {
      return (
        this.plan.plan.pricings.find((pricing) => pricing.name === "growth") ||
        null
      );
    },
  },

  async created() {
    await this.$store.dispatch("plan/getPlan", {
      siteId: this.$store.state.account.user.siteId,
    });
  },

  methods: {
    toggleAddressModal() {
      this.showAddressModal = !this.showAddressModal;
    },
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    async removeWatermark() {
      if (
        (!this.currentPlan || this.currentPlan === "free" || this.currentPlan === "starter") &&
        !(this.currentPlan === "custom" && this.currentPricing?.cost === 25)
      ) {
        this.showUpgradeModal = true;
      } else {
        try {
          await this.$store.dispatch("plan/removeWatermark", {
            siteId: this.$store.state.account.user.siteId,
          });
          this.$notify({
            title: this.$t("shared.toastMessage.success"),
            text: this.$t("shared.toastMessage.watermarkRemoved"),
            type: "success",
          });
        } catch (err) {
          console.log(err);
          this.$notify({
            title: this.$t("shared.toastMessage.error"),
            text: err,
            type: "error",
          });
        }
      }
    },
    opeChangePassword() {
      this.showChangePassword = true;
    },
    closeChangePassword() {
      this.showChangePassword = false;
    },
    async logOut() {
      try {
        await this.$store.dispatch("account/logout");
        this.$notify({
          title: this.$t("shared.toastMessage.success"),
          text: this.$t("shared.toastMessage.logoutSuccess"),
          type: "success",
        });
        this.$router.push({ name: "login" });
      } catch (err) {
        console.log(err);
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.logoutError"),
          type: "error",
        });
      }
    },
    closeUpgradeModal() {
      this.showUpgradeModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.account {
  margin: 0 auto;
}
.card-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.card-component {
  width: 100%;
}

.bottom-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
}

.right-buttons {
  display: flex;
}

.account-button {
  @include base-font;
  display: flex;
  gap: 12px;
  align-items: center;
  height: 34px;
  border: 1px solid $medium-grey;
  background-color: white;
  padding: 8px 24px;
  border-right: none;
}

.first {
  border-radius: 3px 0 0 3px;
  border-right: none;
}
.fourth {
  border-radius: 0 3px 3px 0;
  border-right: 1px solid $medium-grey;
}

@include media(">tablet", "<=desktop") {
  .card-flex {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }

  .main-content-wrapper {
    margin: 0 auto;
    margin-left: calc(73px + 16px);
    margin-right: 16px;
    padding: 0;
  }
}

@include media("<tablet") {
  .card-flex {
    grid-template-columns: 1fr;
  }
}
</style>
