<template>
  <div :class="hasWrapper && 'frame-wrapper'">
    <div
      class="sk-folding-cube"
      :style="{ width: `${width}px`, height: `${height}px` }"
    >
      <div class="sk-cube1 sk-cube"></div>
      <div class="sk-cube2 sk-cube"></div>
      <div class="sk-cube4 sk-cube"></div>
      <div class="sk-cube3 sk-cube"></div>
    </div>
    <p v-show="loadingText" class="loader-text">{{ loadingText }}</p>
    <p v-show="loadingTextAdditional" class="loader-text">
      {{ loadingTextAdditional }}
    </p>
    <div v-if="endValue" class="upload-progress">
      <div class="upload-progress-modal__progress-bar">
        <div
          class="upload-progress-modal__progress-bar__progress"
          :style="{ width: `${(startValue / endValue) * 100}%` }"
        ></div>
      </div>
      <div class="upload-progress-modal__progress-text">
        <p>{{ startValue }} / {{ endValue }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SpinnerLoader",
  props: {
    loadingText: {
      default: "",
      type: String,
    },
    loadingTextAdditional: {
      default: "",
      type: String,
    },
    hasWrapper: {
      default: true,
      type: Boolean,
    },
    width: {
      default: 40,
      type: Number,
    },
    height: {
      default: 40,
      type: Number,
    },
    startValue: {
      type: Number,
      default: 0,
    },
    endValue: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState(["video"]),
  },
  watch: {
    startValue() {
      if (this.startValue === this.endValue) {
        this.$notify({
          type: "success",
          title: this.$t("shared.toastMessage.uploadComplete"),
          ignoreDuplicates: true,
          text: this.$t("shared.toastMessage.uploadSuccess"),
        });
        setTimeout(() => {
          this.$store.commit("video/RESET_UPLOAD_VIDEOS");
          this.$emit("videos-uploaded");
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$square1-color: #f3c2ff;
$square2-color: #e27cfc;
$square3-color: #af3ed8;
$square4-color: #7b01b5;

.frame-wrapper {
  background: white;
  @include frame;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 60px;
  margin: 0;
  z-index: map-get($zindex, overlay);
  box-shadow: 0px 0px 50px 4px #00000026;
}

.loader-text {
  @include font-small;
  color: $dark-purple;
  margin-top: 16px;
  text-align: center;
  max-width: 80%;
}

.sk-folding-cube {
  margin: auto;
  position: relative;
  transform: rotateZ(45deg);

  .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    transform: scale(1.1);
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $square1-color;
      animation: sk-foldCubeAngle 2.4s infinite linear both;
      transform-origin: 100% 100%;
    }
  }
  .sk-cube2 {
    transform: scale(1.1) rotateZ(90deg);
    &::before {
      background-color: $square2-color;
      animation-delay: 0.3s;
    }
  }
  .sk-cube3 {
    transform: scale(1.1) rotateZ(180deg);
    &::before {
      background-color: $square3-color;
      animation-delay: 0.6s;
    }
  }
  .sk-cube4 {
    transform: scale(1.1) rotateZ(270deg);
    &::before {
      background-color: $square4-color;
      animation-delay: 0.9s;
    }
  }
}
.upload-progress {
  width: 100%;
}
.upload-progress-modal__progress-bar {
  background-color: #f3f3f3;
  height: 10px;
  width: 100%;
  border-radius: 5px;
  width: 100%;
  margin-top: 32px;
}
.upload-progress-modal__progress-bar__progress {
  background-color: $dark-purple;
  height: 100%;
  width: 0%;
  transition: width 0.5s;
  border-radius: 5px;
}
.upload-progress-modal__progress-text {
  margin-top: 20px;
  @include font-small;
  @include base-font;
  text-align: center;
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
</style>
