<template>
  <div class="locale-changer">
    <v-select
      id="lang"
      label="name"
      class="vidjet-single-select lang"
      :options="langs"
      :value="lang"
      @input="changeLocale"
    >
      <template slot="option" slot-scope="option">
        <img v-lazy="option.image" class="lang-icon" />
        {{ option.name }}
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LocaleChanger",
  data() {
    return {
      lang: "English",
      langs: [
        {
          name: "French",
          image: require("../assets/svg/settings/french-flag.svg"),
          short: "fr",
        },
        {
          name: "English",
          image: require("../assets/svg/settings/english-flag.svg"),
          short: "en",
        },
        {
          name: "Spanish",
          image: require("../assets/spanish-flag.png"),
          short: "es",
        },
      ],
    };
  },
  computed: {
    ...mapState(["user"]),
  },

  async created() {
    await this.$store.dispatch("user/getUser", {
      userId: this.$store.state.account.user._id,
    });
    // change lang in dropdown
    if (this.user.user.preferredLanguage) {
      const findLang = this.langs.find(
        (lang) => lang.short === this.user.user.preferredLanguage
      );
      this.lang = findLang.name;
    } else {
      const navLang = navigator.language.split("-")[0];
      const findLang = this.langs.find((lang) => lang.short === navLang);
      if (findLang) {
        this.lang = findLang.name;
      } else {
        this.lang = "en";
      }
    }
  },
  methods: {
    async changeLocale(value) {
      this.lang = value.name;
      this.$i18n.locale = value.short;
      if (
        value.short != this.user.user.preferredLanguage ||
        Boolean(this.user.user.preferredLanguage)
      ) {
        await this.$store.dispatch("user/updateUser", {
          userId: this.user.user._id,
          dataToUpdate: {
            preferredLanguage: value.short,
          },
        });
        this.$notify({
          ignoreDuplicates: true,
          title: "Success",
          text: this.$t("shared.toastMessage.profileUpdated"),
          type: "success",
        });
      }
    },
  },
};
</script>

<style lang="scss">
#lang.v-select.lang {
  cursor: pointer;
  .vs__dropdown-toggle {
    height: 34px;
    border-radius: 2px;
  }
  .vs__dropdown-toggle,
  .vs__dropdown-menu {
    min-width: 175px;
    cursor: pointer;
  }
  .vs__clear {
    display: none;
  }
  input {
    cursor: pointer;
  }
}
</style>
<style lang="scss" scoped>
.locale-changer {
  margin-bottom: 30px;
}

.lang-icon {
  width: 14px;
  height: 14px;
}

.lang-icon[lazy="loading"] {
  width: 10px;
  height: auto;
}
</style>
