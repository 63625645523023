<template>
  <small-modal
    :title="$t('account.updatePassword')"
    class="change-password-modal"
    :fit-content="true"
    @close="close"
  >
    <div class="change-password-modal-container">
      <h3 class="subtitle">
        {{
          user.user.hasPassword
            ? $t("account.fillPassword")
            : $t("account.fillSetPassword")
        }}
      </h3>
      <banner-modal
        :title="$t('deleteCampaign.information')"
        :text="$t('account.passwordBanner')"
      >
      </banner-modal>
      <form class="form" @submit.prevent="updatePassword">
        <div class="password-inputs-container">
          <ul class="password-inputs">
            <li class="password-input">
              <label for="old-password">{{
                user.user.hasPassword
                  ? $t("account.password.hasPassword.currentPassword")
                  : $t("account.password.hasNoPassword.setPassword")
              }}</label>
              <input id="old-password" v-model="oldPassword" type="password" />
            </li>
            <li class="password-input">
              <label for="new-password">{{
                user.user.hasPassword
                  ? $t("account.password.hasPassword.newPassword")
                  : $t("account.password.hasNoPassword.confirmPassword")
              }}</label>
              <input id="new-password" v-model="newPassword" type="password" />
            </li>
            <li v-if="user.user.hasPassword" class="password-input">
              <label for="confirm-password">{{
                $t("account.password.hasNoPassword.confirmPassword")
              }}</label>
              <input
                id="confirm-password"
                v-model="confirmPassword"
                type="password"
              />
            </li>
          </ul>
        </div>
        <div class="buttons">
          <input
            class="button-primary --grey"
            type="button"
            :value="$t('deleteCampaign.cancel')"
            @click.prevent="close"
          />
          <label class="button-primary button-flex">
            <input
              class="submit-button"
              type="submit"
              :value="$t('shared.buttons.confirm')"
            />
            <ConfirmIcon />
          </label>
        </div>
      </form>
    </div>
  </small-modal>
</template>

<script>
import { mapState } from "vuex";
import SmallModal from "../shared/SmallModal";
import BannerModal from "../shared/BannerModal.vue";
import ConfirmIcon from "@/assets/svg/confirm-icon.svg?inline";

export default {
  components: {
    SmallModal,
    BannerModal,
    ConfirmIcon,
  },
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
  },
  computed: {
    ...mapState({ user: "user" }),
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async updatePassword() {
      if (this.user.user.hasPassword) {
        if (this.newPassword !== this.confirmPassword) {
          return this.$notify({
            title: this.$t("shared.toastMessage.error"),
            text: this.$t("shared.toastMessage.passwordDoNotMatch"),
            type: "error",
          });
        }
        try {
          const data = {
            newPassword: this.newPassword,
            oldPassword: this.oldPassword,
            userId: this.user.user._id,
          };

          await this.$store.dispatch("account/updatePassword", { data });
          this.$notify({
            title: this.$t("shared.toastMessage.passwordUpdated"),
            type: "success",
          });
        } catch (err) {
          this.$notify({
            title: this.$t("shared.toastMessage.error"),
            text: this.$t("shared.toastMessage.wrongPassword"),
            type: "error",
          });
        }
        this.close();
      } else {
        //newPassword can also be the confirmation password if no password has been set before
        if (this.oldPassword !== this.newPassword) {
          return this.$notify({
            title: this.$t("shared.toastMessage.error"),
            text: this.$t("shared.toastMessage.passwordDoNotMatch"),
            type: "error",
          });
        } else {
          const data = {
            password: this.newPassword,
            userId: this.user.user._id,
          };

          await this.$store.dispatch("account/setPassword", { data });
          this.$notify({
            title: this.$t("shared.toastMessage.newPasswordSet"),
            type: "success",
          });
          this.close();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.change-password-modal-container {
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.subtitle {
  text-align: center;
  @include font-normal;
}

.form {
  width: 100%;
}

.password-inputs-container {
  width: 100%;
}

.password-inputs {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.password-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding: 0;
  width: 100%;
  border: none;
  label {
    @include base-font;
    @include font-small;
  }
  input {
    @include base-font;
    @include font-small;
    width: 100%;
    color: black;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 3px;
    height: 30px;
    float: right;
    padding: 3px;
    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 1px rgba(226, 124, 252, 0.7);
    }
  }
}

.buttons {
  display: flex;
  align-items: flex-end;
  gap: 48px;
  justify-content: center;
}

.button-flex {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  margin-top: 24px;
}

.submit-button {
  @include base-font;
  @include font-small;
  font-weight: 500;
  background: none;
  border: none;
  padding: 0;
}
</style>
