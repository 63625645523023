<template>
  <div id="app">
    <logged-as-card
      v-if="showLogAs && !isLoading"
      :website-url="site.site.websiteUrl"
    ></logged-as-card>
    <navbar
      v-if="userNotOnCampaignCreationSteps"
      @toggle-report-modal="showReportIssueModal = !showReportIssueModal"
    />
    <toast-notifications />
    <spinner-loader v-if="isLoading" />
    <router-view v-else :key="$route.fullPath" />
    <report-issue
      v-show="showReportIssueModal"
      @close-report-modal="showReportIssueModal = false"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Navbar from "./components/Navbar";
import LoggedAsCard from "./components/shared/LoggedAsCard.vue";

import ToastNotifications from "./components/shared/ToastNotifications";
import SpinnerLoader from "./components/shared/SpinnerLoader.vue";
import ReportIssue from "@/components/shared/ReportIssue";

export default {
  name: "App",
  components: {
    Navbar,
    LoggedAsCard,
    ToastNotifications,
    SpinnerLoader,
    ReportIssue,
  },
  data() {
    return {
      showReportIssueModal: false,
      langs: ["fr", "en", "es"],
      isLoading: true,
      isFrillWidjetVisible: false,
    };
  },
  computed: {
    ...mapState(["user", "site"]),
    ...mapGetters({
      getSiteId: "account/getSiteId",
      isAdmin: "account/getIsAdmin",
      isAgency: "account/getIsAgency",
    }),
    // check if siteId in user and site document are the same
    showLogAs() {
      return (
        this.site.isLoggedAs &&
        this.userNotOnCampaignCreationSteps &&
        (this.isAdmin || this.isAgency)
      );
    },
    userNotOnCampaignCreationSteps() {
      return (
        !this.$route.path.includes("create/campaign") &&
        !this.$route.path.includes("edit-campaign")
      );
    },
  },
  async created() {
    this.isLoading = true;

    this.$route.query.authToken &&
      this.$cookies.set("vidjet_authtoken", this.$route.query.authToken);

    const authToken = this.$cookies.get("vidjet_authtoken");

    if (!this.$store.state.account.user && authToken) {
      try {
        await this.$store.dispatch("account/logFromAuthToken", {
          authToken,
        });
      } catch (err) {
        //If there is an error on the auth, it means that token was wrong or site/user were deleted.
        this.$router.push({ name: "login" });
      }
    }

    if (this.$store.state.account.user) {
      await this.$store.dispatch("user/getUser", {
        userId: this.$store.state.account.user._id,
      });
      await this.$store.dispatch("site/getSite", {
        siteId: this.$store.state.account.user._id,
      });
      await this.langSwitch();
    }

    this.checkDeviceType();
    this.isLoading = false;
  },

  methods: {
    checkDeviceType() {
      const ua = navigator.userAgent;
      const mobileRegex =
        /(tablet|ipad|playbook|silk)|(android(?!.*mobi))|Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/i;

      if (mobileRegex.test(ua)) {
        return this.$router.push({ name: "mobile-view" });
      }
    },
    // check if user has preferred language if not take the browser language and save it to the store
    // change of i18n.locale accordingly
    async langSwitch() {
      if (this.user.user.preferredLanguage) {
        const preferredLang = this.user.user.preferredLanguage.toLowerCase();
        this.langs.includes(preferredLang)
          ? (this.$i18n.locale = preferredLang)
          : (this.$i18n.locale = "en");
      } else {
        const navLang = navigator.language.split("-")[0];
        if (this.langs.includes(navLang)) {
          this.$i18n.locale = navLang;
          if (this.user.user) {
            await this.$store.dispatch("user/updateUser", {
              userId: this.user.user._id,
              dataToUpdate: {
                preferredLanguage: navLang,
              },
            });
          }
        } else {
          this.$i18n.locale = "en";
          await this.$store.dispatch("user/updateUser", {
            userId: this.user.user._id,
            dataToUpdate: {
              preferredLanguage: "en",
            },
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/app.scss";

#app {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
