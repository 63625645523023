var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview",class:{
    isEditMode: _vm.isEditMode,
    isEmbed: _vm.isEmbed,
    isBubble: _vm.isBubble,
    isFullScreen: _vm.isFullScreen && _vm.isBubble,
    isPopup: _vm.isPopup,
    isMultiple: _vm.isMultiple,
    bubbleAndPlayerTogether: _vm.bubbleAndPlayerTogether,
    'full-height': _vm.isEmbed && _vm.isMultiple,
    'video-library-modal': _vm.isVideoLibraryModal,
    'video-library-preview-attached': _vm.isVideoLibraryPreview,
  }},[(_vm.previewCampaign?.videos?.length === 0)?_c('div',{staticClass:"transparent-background"}):_vm._e(),(!_vm.isVideoLibraryModal)?_c('div',{staticClass:"top-grid-item"},[(_vm.hasCampaignState && !_vm.isEmbed)?_c('CampaignState',{attrs:{"campaign-id":_vm.campaignId}}):_vm._e(),(!_vm.isStep2)?_c('h3',[_vm._v("👇 "+_vm._s(_vm.$t("create.step3.previewNote")))]):_vm._e(),(_vm.isStep2 && _vm.$route.query.goal === 'shoppableFlow')?_c('h1',{staticClass:"step2-preview-title"},[_vm._v(" "+_vm._s(_vm.$t("create.step2.previewText"))+" ")]):_vm._e()],1):_vm._e(),(_vm.isStep2)?_c('div',{class:[
      'html-container',
      { isLandscape: !_vm.isPortrait && !_vm.isSquare, isSquare: _vm.isSquare },
      { 'video-library-preview': _vm.isVideoLibraryModal },
    ],style:(_vm.htmlPlayerWidth)},[_c('player-lib',{attrs:{"preview-campaign":_vm.campaignPreview,"site-prop":_vm.site || {},"is-in-preview-component":true,"is-preview-device-mobile":false,"is-video-library-preview":_vm.isVideoLibraryPreview}})],1):_c('div',{class:[
      'preview-background',
      {
        'video-preview-desktop': _vm.displayDesktop,
        'video-preview-mobile': !_vm.displayDesktop,
        isMobile: !_vm.displayDesktop && !_vm.isEmbed,
        isPortrait: _vm.isPortrait,
        hideControlBar: _vm.hideControlBar,
        isSquare: _vm.isSquare,
        isLandscape: !_vm.isPortrait && !_vm.isFullScreen,
        'product-pages-preview': _vm.isProductPages || _vm.isBulk,
      },
    ],style:(_vm.isEmbed ? 'background: none;' : '')},[(_vm.isEnabled && _vm.showPreview)?_c('div',{ref:"iframeWrapper",staticClass:"iframe-wrapper",class:{
        'bubble-right': _vm.isBubbleOnTheRight,
      },style:(_vm.displayDesktop ? _vm.desktopMove : _vm.mobileMove)},[_c('sticky-bar',{directives:[{name:"show",rawName:"v-show",value:(_vm.showStickyBar),expression:"showStickyBar"}],attrs:{"sticky-bar-options":_vm.chosenDevice === 'desktop'
            ? _vm.desktop.stickyBarOptions
            : _vm.mobile.stickyBarOptions,"move-y":_vm.displayDesktop ? _vm.desktopMove : _vm.mobileMove},on:{"handleShowVideo":_vm.stickyBarShowVideo,"handleCloseStickyBar":_vm.closeStickyBar}}),(
          _vm.showVideo &&
          !_vm.showStickyBar &&
          _vm.campaignPreview.videos &&
          _vm.campaignPreview.videos[0]?.url
        )?_c('player-lib',{attrs:{"preview-campaign":_vm.campaignPreview,"is-in-preview-component":true,"is-preview-device-mobile":!_vm.displayDesktop,"has-multiple-videos":_vm.isMultiple,"site-prop":_vm.site || {}},on:{"close-all":_vm.handleCloseAll,"close-popup":_vm.handleCloseAll,"add-product":function($event){return _vm.$emit('add-product')},"toggle-fullscreen":_vm.handleFullScreenToggle,"notify-preview":function($event){return _vm.notifyShoppedIcon()},"close-player-preview":_vm.closePlayerPreview}}):_vm._e()],1):_vm._e()]),(!_vm.isBulk && !_vm.isStep2)?_c('div',{staticClass:"bottom-grid-item"},[(!_vm.isEmbed && !_vm.isStory && !_vm.isCarousel)?_c('div',{staticClass:"show-again-components",class:{ isMobile: !_vm.displayDesktop }},[(
          (!_vm.showVideo && !_vm.isStickyBar) ||
          (!_vm.showVideo && _vm.isStickyBar && !_vm.showStickyBar)
        )?_c('button',{staticClass:"button-primary",class:{
          'buffer-space-toggler': _vm.hideTogglerOnStep3,
          right: _vm.isBubbleOnTheRight,
        },on:{"click":_vm.showAgain}},[_vm._v(" "+_vm._s(_vm.$t("shared.buttons.showAgain"))+" ")]):_vm._e()]):_vm._e(),(_vm.hideTogglerOnStep3 && !_vm.isEmbed && !_vm.isProductPages)?_c('div',{staticClass:"device-toggle"},[_c('div',{staticClass:"device-toggle-wrapper"},[_c('img',{staticStyle:{"margin-right":"35px"},attrs:{"src":_vm.displayDesktop ? _vm.desktopIconSelected : _vm.desktopIcon,"alt":"Desktop icon"},on:{"click":function($event){return _vm.changeDisplay()}}}),_c('img',{attrs:{"src":_vm.displayDesktop ? _vm.mobileIcon : _vm.mobileIconSelected,"alt":"Mobile icon"},on:{"click":function($event){return _vm.changeDisplay()}}})])]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }