<template>
  <div class="base-card">
    <div class="title-icon">
      <slot name="icon"> </slot>
      <h3 class="card-title">
        <slot name="title"> </slot>
      </h3>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.base-card {
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.12);
  border-radius: $card-border-radius;
  border: 1px solid $light-grey;
  width: stretch;
  height: auto;
  @include base-font;
  position: relative;
  flex: 0 0 45%;
  padding: 16px 24px 24px;
}

.title-icon {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}

.card-title {
  align-self: center;
  @include font-normal;
}
</style>
