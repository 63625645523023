<template>
  <div
    class="main-content-wrapper"
    :class="{ 'fixed-campaign-manager': showVideoLibrary }"
  >
    <div v-if="hasDataBeenFetched">
      <vidjet-header
        class="title"
        :title="$t('shared.tooltip.campaignManager')"
        :show-create-button="true"
        :show-video-library-button="true"
        :video-library-creation="videoLibraryCreation"
        @show-video-library="showVideoLibrary = true"
        @close-video-library="closeVideoLibrary"
      />
      <div class="search-filter-container">
        <autocompleted-search
          class="campaign-search"
          :items="campaigns"
          :type="campaignString"
          @search="onAutocompletedOptionSelected"
        ></autocompleted-search>
        <div
          class="filter-sort-button"
          :class="{ 'selected-filter-sort-button': filtersActive }"
          @click="openFilterModal"
        >
          <div class="filter-icon">
            <FilterIcon class="filter-svg" />
            <span v-if="numberOfFilters > 0" class="number-filters">{{
              numberOfFilters
            }}</span>
          </div>
          <p class="filter-sort-by">
            {{ $t("campaignManager.filter.filters") }}
          </p>
        </div>
        <div
          class="filter-sort-button"
          :class="{ 'selected-filter-sort-button': sortingActive }"
          @click="openSortingModal"
        >
          <div class="sort-icon">
            <div class="sort-arrows">
              <SortArrow />
              <SortArrow class="reverse-arrow" />
            </div>
            <span v-if="sortType !== null" class="number-sorting">1</span>
          </div>
          <p class="filter-sort-by">{{ $t("campaignManager.sort") }}</p>
        </div>
      </div>
      <div class="campaign-information-banner">
        <div class="campaign-information-data">
          <span class="campaign-info-number">{{ numberOfCampaigns }}</span>
          <p class="campaign-info-text">
            {{ $t("campaignManager.banner.numberOfCampaigns") }}
          </p>
        </div>
        <div class="campaign-information-data">
          <span class="campaign-info-number">{{
            numberOfVideosInCampaigns
          }}</span>
          <p class="campaign-info-text">
            {{ $t("campaignManager.banner.numberOfVideos") }}
          </p>
        </div>
        <div class="campaign-information-data">
          <span class="campaign-info-number">{{
            campaignsWithRecentViews
          }}</span>
          <p class="campaign-info-text">
            {{ $t("campaignManager.banner.campaignVideoViews") }}
          </p>
        </div>
        <div class="campaign-information-data">
          <span class="campaign-info-number">{{ urlWithRecentViews }}</span>
          <p class="campaign-info-text">
            {{ $t("campaignManager.banner.pagesWithViews") }}
          </p>
        </div>
        <div class="campaign-information-data">
          <span class="campaign-info-number">{{
            campaignsWithNoRecentViews
          }}</span>
          <p class="campaign-info-text">
            {{ $t("campaignManager.banner.campaignsWithoutViews") }}
          </p>
        </div>
      </div>
      <div v-if="!campaignsLoading" class="campaignsList">
        <draggable
          :list="campaigns"
          group="campaigns"
          @change="changeCampaignOrderByDragAndDrop"
          @start="drag = true"
          @end="drag = false"
        >
          <div
            v-for="(campaign, index) in sortedCampaigns"
            :ref="campaign._id"
            :key="campaign._id"
            class="campaign-display"
            :class="{
              'campaign-selected': campaign._id === selectedCampaign,
              'campaign-locked': accountIsLocked,
              'bulk-campaign': campaign.bulk,
            }"
          >
            <div
              v-if="accountIsLocked"
              class="campaign-locked-background"
            ></div>

            <div class="prioritization">
              <img
                v-if="allowCampaignReorder"
                class="drag-drop-icon"
                src="../assets/drag-drop-icon.png"
              />
              <input
                type="text"
                :disabled="!allowCampaignReorder"
                :value="index + 1"
                @change="changeCampaignOrderByInput(index, $event)"
              />
            </div>
            <div class="campaign-info">
              <input
                :id="campaign._id"
                v-tippy="{ placement: 'left', arrow: true }"
                :class="`campaign-name-input ${index}`"
                type="text"
                :value="campaign.name"
                :content="campaign.name"
                @input="onCampaignNameUpdate"
                @change="saveCampaignName"
              />
              <p class="campaign-creation-date">
                {{ $t("campaignManager.creationDate") }}
                {{ formattedDates(campaign.creationDate) }}
              </p>
            </div>
            <div class="boxes">
              <p
                v-if="!isNaN(campaign.views)"
                v-tippy="{ arrow: true, placement: 'top', a11y: false }"
                class="campaign-views"
                :content="$t('campaignManager.tooltipViews')"
              >
                <Visibility></Visibility>
                {{ campaign.views }}
              </p>
              <p
                v-if="campaign.videos || campaign.bulk"
                v-tippy="{ arrow: true, placement: 'top', a11y: false }"
                :content="$t('campaignManager.videoNumber')"
              >
                <Slideshow></Slideshow>
                {{ getAmountOfVideos(campaign) }}
              </p>
            </div>
            <div v-if="campaign.formats[0].formatType === FormatType.embed">
              <p class="embed-status">Embed</p>
            </div>
            <div
              v-else-if="
                campaign.formats[0].formatType === FormatType.carousel &&
                campaign.formats[0].isStory
              "
            >
              <p class="embed-status">Stories</p>
            </div>
            <div
              v-else-if="campaign.formats[0].formatType === FormatType.carousel"
            >
              <p class="embed-status">Carousel</p>
            </div>
            <div
              v-else-if="campaign.formats[0].formatType === FormatType.bubble"
              class="embed-status-and-toggle"
            >
              <p class="embed-status">Bubble</p>
              <div
                class="campaign-status"
                :style="`${
                  campaign.stateFlag === StateFlag.active
                    ? 'color:#7B01B5;'
                    : 'color:#E27CFC;'
                }`"
              >
                <!-- click only has actions when checkbox disabled -->
                <section
                  class="campaign-toggle"
                  @click="handleWhenDisabled(campaign)"
                >
                  <span class="state-flag">LIVE</span>
                  <vidjet-toggle-btn
                    :checked="checkToggle(campaign)"
                    :is-disabled="!Boolean(site.site.integration)"
                    :is-in-campaign-manager="true"
                    @change="handleStateChange(campaign, index)"
                  />
                </section>
              </div>
            </div>
            <div
              v-else-if="
                campaign.formats[0].formatType === FormatType.popup &&
                campaign.formats[0].isStickyBar
              "
              class="embed-status-and-toggle"
            >
              <p class="embed-status">Sticky Play</p>
              <div
                class="campaign-status"
                :style="`${
                  campaign.stateFlag === StateFlag.active
                    ? 'color:#7B01B5;'
                    : 'color:#E27CFC;'
                }`"
              >
                <!-- click only has actions when checkbox disabled -->
                <section
                  class="campaign-toggle"
                  @click="handleWhenDisabled(campaign)"
                >
                  <span class="state-flag" style="margin-left: 5px">LIVE</span>
                  <vidjet-toggle-btn
                    :checked="checkToggle(campaign)"
                    :is-disabled="!Boolean(site.site.integration)"
                    :is-in-campaign-manager="true"
                    @change="handleStateChange(campaign, index)"
                  />
                </section>
              </div>
            </div>
            <div
              v-else-if="campaign.formats[0].formatType === FormatType.popup"
              class="embed-status-and-toggle"
            >
              <p class="embed-status">Popup</p>
              <div
                class="campaign-status"
                :style="`${
                  campaign.stateFlag === StateFlag.active
                    ? 'color:#7B01B5;'
                    : 'color:#E27CFC;'
                }`"
              >
                <!-- click only has actions when checkbox disabled -->
                <section
                  class="campaign-toggle"
                  @click="handleWhenDisabled(campaign)"
                >
                  <span class="state-flag" style="margin-left: 5px">LIVE</span>
                  <vidjet-toggle-btn
                    :checked="checkToggle(campaign)"
                    :is-disabled="!Boolean(site.site.integration)"
                    :is-in-campaign-manager="true"
                    @change="handleStateChange(campaign, index)"
                  />
                </section>
              </div>
            </div>
            <div class="tags">
              <TagsManager
                :campaign="campaign"
                @open-delete-tag-modal="openDeleteTagModal($event)"
              />
            </div>
            <div v-if="accountIsLocked" class="videos-no-visible">
              <div class="locked-text">
                <p style="font-weight: 600">
                  {{ $t("campaignManager.videosNoVisible") }}
                </p>
                <p class="upgrade-unlock" @click="toggleUpgradeModal">
                  {{ $t("campaignManager.upgradeToUnlock") }}
                </p>
              </div>
              <LockedIcon class="locked-icon" />
            </div>
            <div v-else class="campaign-buttons">
              <button class="option-button" @click="openOptionsModal(campaign)">
                <img
                  src="../assets/campaign-options-button.png"
                  alt="options-button"
                />
              </button>
            </div>
            <OptionsCampaignModal
              v-if="
                showOptionsModal && selectedCampaignObject._id === campaign._id
              "
              :campaign="selectedCampaignObject"
              :is-embed="isEmbed"
              @edit-campaign="editCampaign"
              @clone-campaign="cloneCampaign"
              @open-delete-modal="
                getIdAndToggleModal(selectedCampaignObject._id)
              "
              @show-urls="showUrls"
              @get-embed-code="getEmbedCode"
              @close-options-modal="closeOptionsModal"
            />
          </div>
        </draggable>
      </div>
      <div v-else class="loading">
        <img style="width: 100px" src="../assets/loading.gif" />
      </div>
      <div ref="bottom"></div>
      <!-- confirmation modal to delete campaigns -->
      <DeleteCampaignModal
        v-show="showDeleteCampaignModal"
        :is-embed="isEmbed"
        :delete-campaign-id="deleteCampaignId"
        @close="toggleDeleteCampaignModal"
      />
      <banner-locked v-if="bannerLocked" :warning-icon="true">
        <template #text="{ reason }">
          <h3>
            {{ reason }}
          </h3>
        </template>
        <template #button="{ buttonText }">
          {{ buttonText }}
        </template>
      </banner-locked>
      <!-- integration modal -->
      <IntegrationPopUp
        v-if="showIntegrationPopup"
        @closePopUp="toggleIntegrationPopUp"
      />
      <!-- modal for plan upgrade -->
      <upgrade-plan
        v-if="showUpgradePlan"
        :plan-chosen="nextPlan"
        @closeUpgradePlan="toggleUpgradeModal"
      />
      <PlanRegistration
        v-if="showPlanRegistrationModal"
        :new-plan="proposedPlan"
        :show-arrows="currentPlan !== 'scale'"
        @close="showPlanRegistrationModal = false"
      />
      <CampaignFilter
        v-show="filtersActive"
        :format-options="formatOptions"
        :published-options="publishedOptions"
        :activity-options="activityOptions"
        @close-filter-modal="closeFilterModal"
        @filter="applyFilters"
        @reset="resetFilters"
      />
      <CampaignSort
        v-show="sortingActive"
        @sort="onSortChange"
        @close-sorting-modal="closeSortingModal"
      />
      <UrlsModal
        v-if="showUrlsModal"
        :campaign="selectedCampaignObject"
        @close-options-modal="closeUrlsModal"
      />
      <DeleteTagModal
        v-if="showDeleteTagModal"
        :tag-to-delete="tagToDelete"
        @close-delete-tag-modal="showDeleteTagModal = false"
      />
      <EmbedCodeModal
        v-if="showEmbedCodeModal"
        :campaign="selectedCampaignObject"
        @close="closeEmbedCodeModal"
      />
      <spinner-loader v-if="isLoading"></spinner-loader>
    </div>
    <spinner-loader v-else></spinner-loader>
  </div>
</template>

<script>
import DeleteCampaignModal from "./campaign-manager/DeleteCampaignModal.vue";
import BannerLocked from "./shared/BannerLocked.vue";
import IntegrationPopUp from "./shared/IntegrationPopUp.vue";
import { mapState, mapGetters } from "vuex";
import VidjetToggleBtn from "./shared/Vidjet-toggle-btn.vue";
import VidjetHeader from "./shared/Vidjet-header";
import UpgradePlan from "./upgrade-plan/UpgradePlan";
import enums from "../enums";
import Draggable from "vuedraggable";
import AutocompletedSearch from "./shared/AutocompletedSearch.vue";
import Visibility from "@/assets/svg/visibility.svg?inline";
import Slideshow from "@/assets/svg/slideshow.svg?inline";
import LockedIcon from "@/assets/svg/lock-locked.svg?inline";
import PlanRegistration from "@/components/upgrade-plan/PlanRegistration.vue";
import FilterIcon from "@/assets/svg/filter-icon.svg?inline";
import CampaignFilter from "@/components/campaign-manager/CampaignFilter.vue";
import SortArrow from "@/assets/svg/sort-arrow.svg?inline";
import CampaignSort from "@/components/campaign-manager/CampaignSort.vue";
import OptionsCampaignModal from "@/components/campaign-manager/OptionsCampaignModal.vue";
import UrlsModal from "@/components/campaign-manager/UrlsModal.vue";
import TagsManager from "@/components/campaign-manager/TagsManager.vue";
import SpinnerLoader from "./shared/SpinnerLoader.vue";
import DeleteTagModal from "@/components/campaign-manager/DeleteTagModal.vue";
import EmbedCodeModal from "@/components/campaign-manager/EmbedCodeModal.vue";
const { StateFlag, FormatType } = enums;

import api from "@/api";

export default {
  name: "CampaignManager",
  components: {
    VidjetToggleBtn,
    UpgradePlan,
    VidjetHeader,
    Draggable,
    DeleteCampaignModal,
    BannerLocked,
    IntegrationPopUp,
    AutocompletedSearch,
    Visibility,
    Slideshow,
    LockedIcon,
    PlanRegistration,
    FilterIcon,
    CampaignFilter,
    SortArrow,
    CampaignSort,
    OptionsCampaignModal,
    UrlsModal,
    TagsManager,
    SpinnerLoader,
    DeleteTagModal,
    EmbedCodeModal,
  },
  beforeRouteEnter(t, f, next) {
    next(async (vm) => {
      vm.init();
    });
  },

  data() {
    return {
      status: "INACTIVE",
      StateFlag,
      FormatType,
      campaigns: [],
      campaignsLoading: true,
      deleteCampaignId: "",
      // campaignToActivate is used for user who integrate their website from campaign manager
      campaignToActivate: "",
      showDeleteCampaignModal: false,
      showIntegrationPopup: false,
      showUpgradePlan: false,
      selectedCampaign: "",
      proposedPlan: {},
      showPlanRegistrationModal: false,
      filtersActive: false,
      sortingActive: false,
      formatOptions: [
        { name: "Bubble", value: { formatType: 1 } },
        { name: "Embed", value: { formatType: 2 } },
        { name: "Popup", value: { formatType: 0, isStickyBar: false } },
        { name: "Sticky Play", value: { formatType: 0, isStickyBar: true } },
        { name: "Stories", value: { formatType: 3, isStory: true } },
        { name: "Carousel", value: { formatType: 3, isStory: false } },
      ],
      publishedOptions: [
        this.$t("campaignManager.filter.publishedOptions.showingOnPages"),
        this.$t("campaignManager.filter.publishedOptions.notShowingOnPages"),
      ],
      activityOptions: [
        this.$t("campaignManager.filter.activityOptions.receivingViews"),
        this.$t("campaignManager.filter.activityOptions.notReceivingViews"),
      ],
      selectedFormats: [],
      selectedPublished: "",
      selectedActivity: "",
      selectedTags: [],
      sortType: null,
      isAscending: true,
      showOptionsModal: false,
      selectedCampaignObject: null,
      showUrlsModal: false,
      hideTagButton: false,
      isLoading: false,
      showDeleteTagModal: false,
      tagToDelete: null,
      hasDataBeenFetched: false,
      showEmbedCodeModal: false,
      showVideoLibrary: false,
      videoLibraryCreation: false,
    };
  },
  computed: {
    ...mapState(["site", "plan", "tag"]),
    ...mapState({
      campaignsState: ({ campaign }) => campaign.campaigns,
    }),

    ...mapGetters({
      currentPlan: "plan/currentPlan",
      nextPlan: "plan/nextPlan",
      bannerLocked: "plan/bannerLocked",
      isLocked: "plan/isLocked",
      numberOfCampaigns: "campaign/numberOfCampaigns",
      numberOfVideosInCampaigns: "campaign/numberOfVideosInCampaigns",
      campaignLimit: "plan/campaignLimit",
      videosInCampaignsLimit: "plan/videosInCampaignsLimit",
      siteId: "site/getSiteId",
      isAdmin: "account/getIsAdmin",
      getTags: "tag/getTags",
    }),

    dragExplanationImg() {
      const locale = this.$i18n.locale ? this.$i18n.locale : "en";
      return require(`@/assets/draganddrop-explanation-${locale}.png`);
    },
    accountIsLocked() {
      return this.isLocked && !this.isAdmin;
    },
    campaignsWithRecentViews() {
      return this.campaigns.filter((campaign) => campaign.views > 0).length;
    },
    campaignsWithNoRecentViews() {
      return this.campaigns.filter((campaign) => campaign.views === 0).length;
    },
    urlWithRecentViews() {
      const uniqueUrls = new Set();
      this.campaigns.forEach((campaign) => {
        if (campaign.urlsWithDisplay) {
          campaign.urlsWithDisplay.forEach((url) => {
            uniqueUrls.add(url);
          });
        }
      });
      return uniqueUrls.size;
    },
    filteredCampaigns() {
      let filteredCampaigns = this.campaigns;

      this.selectedPublished &&
        (filteredCampaigns = this.filterByPublished(filteredCampaigns));
      this.selectedActivity &&
        (filteredCampaigns = this.filterByActivity(filteredCampaigns));

      if (this.selectedFormats.length > 0) {
        filteredCampaigns = this.filterByFormats(filteredCampaigns);
      }

      if (this.selectedTags.length > 0) {
        filteredCampaigns = this.filterByTags(filteredCampaigns);
      }
      return filteredCampaigns;
    },
    sortedCampaigns() {
      let sortedCampaigns = structuredClone(this.filteredCampaigns);

      switch (this.sortType) {
        case null:
          break;

        case "creationDate":
          sortedCampaigns = sortedCampaigns.sort((a, b) => {
            const dateA = new Date(a.creationDate);
            const dateB = new Date(b.creationDate);
            return this.isAscending ? dateA - dateB : dateB - dateA;
          });
          break;

        case "views":
          sortedCampaigns = sortedCampaigns.sort((a, b) => {
            const viewsA = a.views;
            const viewsB = b.views;
            return this.isAscending ? viewsA - viewsB : viewsB - viewsA;
          });
          break;

        case "numberOfVideos":
          sortedCampaigns = sortedCampaigns.sort((a, b) => {
            const videosA = this.getAmountOfVideos(a);
            const videosB = this.getAmountOfVideos(b);
            return this.isAscending ? videosA - videosB : videosB - videosA;
          });
          break;

        default:
          break;
      }
      return sortedCampaigns;
    },
    allowCampaignReorder() {
      return (
        this.selectedFormats.length === 0 &&
        this.selectedTags.length === 0 &&
        (this.selectedPublished === "" || this.selectedPublished === null) &&
        (this.selectedActivity === "" || this.selectedActivity === null) &&
        this.sortType === null
      );
    },
    numberOfFilters() {
      let filterCount = this.selectedFormats.length + this.selectedTags.length;
      if (this.selectedPublished !== "" && this.selectedPublished !== null) {
        filterCount += 1;
      }
      if (this.selectedActivity !== "" && this.selectedActivity !== null) {
        filterCount += 1;
      }
      return filterCount;
    },
    isEmbed() {
      if (this.selectedCampaignObject) {
        const formatType = this.selectedCampaignObject.formats[0].formatType;
        return formatType === FormatType.embed;
      }
      return false;
    },
    campaignString() {
      return this.$t("campaignManager.campaign");
    },
  },
  watch: {
    campaignsState: {
      handler(newValue) {
        if (newValue) {
          this.campaigns = newValue;
        }
      },
      deep: true,
    },
    async selectedCampaignObject(newValue) {
      if (newValue === null) {
        this.campaigns = await structuredClone(this.campaignsState);
      }
    },
  },

  async created() {
    this.campaigns = await structuredClone(this.campaignsState);

    if (this.$route.query.showEmbed) {
      this.selectedCampaignObject = this.campaigns.find(
        (campaign) => campaign._id === this.$route.params.campaignId
      );
      this.showEmbedCodeModal = true;
    }

    this.isLoading = true;

    //Make sure to clean all campaign state so we do not have issue on new create/edit
    this.$store.commit("campaign/resetCampaignState");
    this.$store.commit("video/resetVideos");

    await this.$store.dispatch("plan/getPlan", {
      siteId: this.$store.state.account.user.siteId,
    });
    await this.$store.dispatch("site/getSite", {
      siteId: this.$store.state.account.user.siteId,
    });
    await this.$store.dispatch("tag/getTags", {
      siteId: this.$store.state.account.user.siteId,
    });
    this.hasDataBeenFetched = true;
    if (this.$route.query.action === "videoLibraryCreation") {
      this.videoLibraryCreation = true;
    }
    this.isLoading = false;
    if (this.$route.params.campaignId) {
      await this.scrollToCampaign(this.$route.params.campaignId);
    }
  },
  methods: {
    checkToggle(campaign) {
      return (
        Boolean(this.site.site.integration) &&
        campaign.stateFlag === StateFlag.active
      );
    },

    handleWhenDisabled(campaign) {
      if (!this.site.site.integration) {
        this.showIntegrationPopup = true;
        this.campaignToActivate = campaign;
      }
    },

    changeCampaignOrderByDragAndDrop(event) {
      if (this.allowCampaignReorder) {
        const indexFrom = Math.min(event.moved.newIndex, event.moved.oldIndex);
        const indexTo = Math.max(event.moved.newIndex, event.moved.oldIndex);
        this.updateIndexes(indexFrom, indexTo);
      }
    },
    changeCampaignOrderByInput(oldIndex, event) {
      if (this.allowCampaignReorder) {
        const newIndex = event.target.value - 1;
        const removedElement = this.campaigns.splice(oldIndex, 1);
        this.campaigns.splice(newIndex, 0, removedElement[0]);

        const indexFrom = Math.min(newIndex, oldIndex);
        const indexTo = Math.max(newIndex, oldIndex);
        this.scrollToCampaign(this.campaigns[indexTo]._id);
        this.updateIndexes(indexFrom, indexTo);
      }
    },
    async updateIndexes(indexFrom, indexTo) {
      for (let i = indexFrom; i <= indexTo; i++) {
        await this.$store.dispatch("campaign/updateCampaign", {
          campaignId: this.campaigns[i]._id,
          dataToUpdate: { order: i },
          index: i,
        });
      }
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("shared.toastMessage.campaignOrder"),
        type: "success",
      });
    },

    onCampaignNameUpdate(e) {
      this.newCampaignName = e.target.value;
    },

    async saveCampaignName(e) {
      await this.$store.dispatch("campaign/updateCampaign", {
        campaignId: e.target.id,
        dataToUpdate: { name: this.newCampaignName },
      });
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        ignoreDuplicates: true,
        text: this.$t("shared.toastMessage.campaignName"),
        type: "success",
      });
    },

    async init() {
      await this.$store.dispatch("campaign/getCampaigns", {
        siteId: this.$store.state.account.user.siteId,
      });
      this.campaignsLoading = false;
    },

    async handleStateChange(campaign, index) {
      const newState =
        campaign.stateFlag === StateFlag.paused
          ? { stateFlag: StateFlag.active }
          : { stateFlag: StateFlag.paused };

      await this.$store.dispatch("campaign/updateCampaign", {
        campaignId: campaign._id,
        dataToUpdate: newState,
        index: index,
      });

      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("shared.toastMessage.campaignState"),
        type: "success",
      });
    },
    async editCampaign(campaign) {
      await this.$store.dispatch("campaign/setCampaignSelected", campaign._id);
      this.$router.push({
        name: campaign.videos ? "editCampaign" : "editBulkEmbed",
        params: { campaignId: campaign._id },
        query: { embed: campaign.formats[0].formatType === FormatType.embed },
      });
    },

    async cloneCampaign(campaign) {
      if (
        this.videosInCampaignsLimit &&
        this.numberOfVideosInCampaigns >= this.videosInCampaignsLimit
      ) {
        return this.showPlanRegistration();
      }
      this.isLoading = true;
      await this.$store.dispatch("campaign/cloneCampaign", campaign);
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("shared.toastMessage.campaignCloned"),
        type: "success",
      });
      this.campaigns = await structuredClone(this.campaignsState);
      this.isLoading = false;
      this.showOptionsModal = false;
      this.scrollToCampaign(this.campaigns[this.campaigns.length - 1]._id);
    },

    formattedDates(date) {
      const invoicingDate = new Date(date);
      const month = invoicingDate.toLocaleString(this.$i18n.locale, {
        month: "short",
      });
      return `${month}  ${date.substring(8, 10)},  ${date.substring(0, 4)} `;
    },

    toggleIntegrationPopUp() {
      this.showIntegrationPopup = false;
      if (this.site.site.integration) {
        this.handleStateChange(this.campaignToActivate);
      }
    },

    toggleDeleteCampaignModal() {
      this.showDeleteCampaignModal = !this.showDeleteCampaignModal;
    },

    toggleUpgradeModal() {
      this.showUpgradePlan = !this.showUpgradePlan;
    },

    getIdAndToggleModal(campaignId) {
      this.showOptionsModal = false;
      this.deleteCampaignId = campaignId;
      this.showDeleteCampaignModal = !this.showDeleteCampaignModal;
    },
    async getEmbedCode() {
      if (
        this.selectedCampaignObject.bulk &&
        !this.selectedCampaignObject.bulk[0]?.videos?.length
      ) {
        return this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: "Your first feed must have videos in order to get the embed code",
          type: "error",
        });
      }

      //Get the full video object so we know height and width
      const videosToGet = this.selectedCampaignObject.bulk
        ? this.selectedCampaignObject.bulk[0].videos
        : this.selectedCampaignObject.videos;

      const videos = await api.getVideos({
        siteId: this.siteId,
        query: {
          _id: { $in: videosToGet.map((video) => video.videoId) },
        },
      });

      this.selectedCampaignObject.bulk
        ? (this.selectedCampaignObject.bulk[0].videos = videos)
        : (this.selectedCampaignObject.videos = videos);

      this.showOptionsModal = false;
      this.showEmbedCodeModal = true;
    },
    scrollToCampaign(campaignId) {
      this.$nextTick(() => {
        const campaign = this.$refs[campaignId];
        if (campaign) campaign[0].scrollIntoView({ behavior: "smooth" });
      });
      this.selectedCampaign = campaignId;
      setTimeout(() => {
        this.selectedCampaign = "";
      }, 5000);
    },
    onAutocompletedOptionSelected(campaign) {
      this.scrollToCampaign(campaign._id);
    },
    openFilterModal() {
      this.filtersActive = true;
    },
    closeFilterModal() {
      this.filtersActive = false;
    },
    openSortingModal() {
      this.sortingActive = true;
    },
    closeSortingModal() {
      this.sortingActive = false;
    },
    applyFilters(payload) {
      const key = Object.keys(payload)[0];
      if (key === "formats") this.selectedFormats = payload.formats;
      if (key === "published") this.selectedPublished = payload.published;
      if (key === "activity") this.selectedActivity = payload.activity;
      if (key === "tags") this.selectedTags = payload.tags;
    },
    resetFilters() {
      this.selectedFormats = [];
      this.selectedPublished = "";
      this.selectedActivity = "";
      this.selectedTags = [];
    },
    filterByPublished(campaigns) {
      return campaigns.filter((campaign) => {
        if (this.selectedPublished === "Showing on pages") {
          return campaign.displays !== 0;
        } else if (this.selectedPublished === "Not showing on pages") {
          return campaign.displays === 0;
        }
        return true;
      });
    },

    filterByActivity(campaigns) {
      return campaigns.filter((campaign) => {
        if (this.selectedActivity === "Receiving recent views") {
          return campaign.views !== 0;
        } else if (this.selectedActivity === "Not receiving views") {
          return campaign.views === 0;
        }
        return true;
      });
    },

    filterByFormats(campaigns) {
      return this.selectedFormats.flatMap((format) => {
        return campaigns.filter((campaign) => {
          const formatArray = campaign.formats[0];
          return Object.keys(format).every((key) => {
            return format[key] == (formatArray[key] ?? false);
          });
        });
      });
    },
    filterByTags(campaigns) {
      return campaigns.filter((campaign) => {
        return this.selectedTags.some((tag) => {
          return campaign.tags && campaign.tags.includes(tag._id);
        });
      });
    },
    onSortChange(payload) {
      this.sortType = payload.sortType;
      this.isAscending = payload.isAscending;
    },
    openOptionsModal(campaign) {
      this.showOptionsModal = true;
      this.selectedCampaignObject = campaign;
    },
    closeOptionsModal() {
      this.showOptionsModal = false;
      this.selectedCampaignObject = null;
    },
    showUrls() {
      this.showOptionsModal = false;
      this.showUrlsModal = true;
    },
    closeUrlsModal() {
      this.showUrlsModal = false;
    },
    openDeleteTagModal(tag) {
      this.tagToDelete = tag;
      this.showDeleteTagModal = true;
    },
    getAmountOfVideos(campaign) {
      return campaign.videos
        ? campaign.videos.length
        : campaign.bulk.reduce((total, feed) => total + feed.videos.length, 0);
    },
    notifyTooManyVideos() {
      this.$notify({
        text: this.$t("shared.toastMessage.tooManyVideos"),
        type: "error",
      });
    },
    async showPlanRegistration() {
      if (this.currentPlan === "pro") {
        window.Intercom &&
          window.Intercom("trackEvent", "plan_enterprise_clicked");
        this.notifyTooManyVideos();
        return;
      }
      this.proposedPlan = await this.$store.dispatch("plan/getProposedPlan", {
        siteId: this.siteId,
      });
      this.showPlanRegistrationModal = true;
      this.notifyTooManyVideos();
      return;
    },
    closeEmbedCodeModal() {
      this.showEmbedCodeModal = false;
      this.selectedCampaignObject = null;
    },
    closeVideoLibrary() {
      this.showVideoLibrary = false;
      this.videoLibraryCreation = false;
    },
  },
};
</script>

<style lang="scss">
.tooltip-campaigns-manager {
  @include base-font;
  @include font-small;
  font-weight: 300;
  color: white;
  padding: 5px;
  width: 340px;
}
</style>

<style lang="scss" scoped>
.fixed-campaign-manager {
  position: fixed;
}

.header-title {
  @include base-font;
}

.search-filter-container {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.filter-sort-button {
  display: flex;
  gap: 8px;
  align-items: center;
  border: 1px solid $dark-grey;
  border-radius: 2px;
  padding: 4px 12px;
  height: 14px;
  &:hover {
    background: #f1f1f6;
    cursor: pointer;
    svg {
      path {
        fill: $dark-grey;
      }
    }
  }
}
.filter-sort-by {
  @include font-smaller;
  color: $dark-grey;
  &:hover {
    color: $dark-grey;
  }
}

.selected-filter-sort-button {
  background: $dark-purple;
  svg {
    path {
      fill: white;
    }
  }
  p {
    color: white;
  }
}
.filter-icon {
  position: relative;
}

.filter-svg {
  path {
    fill: $dark-grey;
  }
}
.number-filters {
  position: absolute;
  left: 8px;
  font-size: 6px;
  line-height: 7px;
  text-align: center;
  align-items: center;
  width: 10px;
  height: 10px;
  font-weight: 500;
  background-color: $dark-purple;
  color: white;
  padding: 2px 1px 1px 1px;
  border-radius: 50%;
  box-sizing: border-box;
}

.sort-icon {
  position: relative;
}
.sort-arrows {
  display: flex;
  flex-direction: column;
  gap: 4px;
  transform: scale(50%);
  margin-right: 1px;
  path {
    fill: $dark-grey;
  }
}
.reverse-arrow {
  transform: translateX(-0.9px);
  path {
    transform: rotate(180deg);
    transform-origin: 50% 50%;
  }
}
.number-sorting {
  position: absolute;
  top: 3px;
  left: 10px;
  font-size: 6px;
  line-height: 7px;
  text-align: center;
  align-items: center;
  width: 10px;
  height: 10px;
  font-weight: 500;
  background-color: $dark-purple;
  color: white;
  padding: 2px 1px 1px 1px;
  border-radius: 50%;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.loading {
  text-align: center;
  margin-top: 100px;
}

.v-tooltip-button {
  background: transparent;
  margin-left: 55px;
  margin-top: 40px;
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.draganddrop-explanation {
  width: 50vw;
  max-width: 600px;
  &[lazy="loading"] {
    width: 50px;
    height: 50px;
  }
}

.state-flag {
  font-size: 10px;
  align-self: center;
}

.campaignsList {
  margin-top: 30px;
  margin: 0px auto;
}

.campaign-display {
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: 0.3fr 1fr 1fr 1fr 1fr 0.5fr;
  justify-content: space-around;
  padding: 10px;
  grid-gap: 12px;
  background-color: white;
  border-radius: $card-border-radius;
  align-items: center;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.12);
  position: relative;
  border-radius: $card-border-radius;
  &:hover {
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
  }
}
.campaign-selected {
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
}

.campaign-toggle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

.videos-no-visible {
  display: flex;
  justify-content: flex-end;
}
.locked-text {
  text-align: end;
  margin: auto;
  margin-right: 24px;

  .upgrade-unlock {
    text-decoration: underline;
    pointer-events: auto;
    &:hover {
      cursor: pointer;
    }
  }
}
.campaign-locked {
  grid-template-columns: auto 1fr 1fr 1fr auto;
  pointer-events: none;
}
.locked-icon {
  width: 50px;
  path {
    fill: #585858;
  }
}

.campaign-locked-background {
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  background: rgba(142, 142, 142, 0.4);
  border-radius: $card-border-radius;
  z-index: initial;
}

.prioritization {
  cursor: pointer;
  display: flex;
  align-items: center;
  input {
    font-family: "Yeon Sung", "sans-serif";
    font-size: 40px;
    font-style: normal;
    font-weight: normal;
    text-align: start;
    color: #c4c4c4;
    max-width: 52px;
    border: 0;
    background: transparent;
  }
}

.drag-drop-icon {
  height: 18px;
  margin-right: 10px;
  padding-bottom: 3px;
}

.campaign-name-input {
  border: none;
  @include base-font;
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background: transparent;
}

.campaign-status {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  width: fit-content;
}

.boxes {
  display: flex;
  gap: 20px;
}
.boxes p {
  display: flex;
  gap: 16px;
  text-align: center;
  background: rgba(217, 217, 217, 0.1);
  border: 1.5px solid #d9d9d9;
  border-radius: 4px;
  color: $medium-grey;
  min-width: 60px;
  max-width: 80px;
  padding: 4px 8px;
}

.embed-status-and-toggle {
  display: grid;
  grid-template-columns: 0.3fr 0.1fr;
  gap: 16px;
  align-items: center;
}

.embed-status {
  color: $dark-purple;
  background-color: $light-pink;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  border-radius: 5px;
  padding: 3px 8px;
  width: fit-content;
  height: fit-content;
  text-align: center;
}

.state-flag {
  margin-left: 5px;
  vertical-align: -webkit-baseline-middle;
}

.campaign-creation-date {
  @include font-smallest;
  color: $light-grey;
}

.campaign-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  .button-primary-small {
    background: transparent;
  }
}

img[lazy="loading"] {
  height: 30px;
}

.main-content-wrapper {
  margin-right: 30px;
  margin-left: 100px;
}

.campaign-information-banner {
  width: 100%;
  height: 84px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: linear-gradient(
    56.35deg,
    #edf2ff 14.01%,
    #f6e7ff 73.4%,
    #feffe2 100%
  );
  margin: 24px 0;
  box-sizing: border-box;
  border-radius: 8px;
}
.campaign-information-data {
  display: flex;
  gap: 10px;
  align-items: center;
}
.campaign-info-text {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  width: 100px;
}
.campaign-info-number {
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
}
.option-button {
  background: transparent;
}

@include media(">tablet", "<=desktop") {
  .draganddrop-explanation {
    margin-left: 20px;
  }

  .tooltip-campaigns-manager {
    width: 200px;
  }

  .prioritization {
    font-size: 30px;
  }

  .campaign-name-input {
    width: 150px;
  }

  .switch {
    display: flex;
  }
}

@include media(">phone", "<=tablet") {
  .draganddrop-explanation {
    margin-left: 20px;
    margin-top: 60px;
  }

  .v-tooltip-button {
    margin-left: 0px;
    margin-top: 0px;
  }

  .campaignsList {
    width: 95%;
  }

  .campaign-display {
    height: 160px;
    display: flex;
    justify-content: space-around;
  }

  .campaign-status {
    margin-left: 0%;
    width: 60px;
    margin-left: 2vw;
  }

  .prioritization {
    font-size: 18px;
    width: 30px;
    font-size: 30px;
  }

  .campaign-info {
    margin-left: 0%;
    width: 25%;
  }
  .campaign-name-input {
    width: 100px;
    @include font-smaller;
  }

  .campaign-creation-date {
    width: 100px;
    margin-bottom: 30px;
  }

  .state-flag {
    @include font-smaller;
  }

  .campaign-buttons {
    flex-direction: column;
    justify-content: space-around;
    margin-left: 2%;
  }

  .campaign-buttons {
    width: 30%;
    min-width: 20px;
    margin-right: 4%;
    margin-left: 2vw;
  }
}

@include media("<=phone") {
  .title {
    margin-left: 0px;
  }

  .draganddrop-explanation {
    margin-left: 0px;
    margin-top: 60px;

    width: 100%;
  }

  .v-tooltip-button {
    margin-left: 0px;
    margin-top: 0px;
  }

  .tooltip-campaigns-manager {
    width: 150px;
  }

  .campaignsList {
    width: 95%;
  }

  .campaign-display {
    height: 160px;
  }

  .campaign-status {
    margin-left: 0%;
    width: 60px;
    margin-left: 2vw;
  }

  .prioritization {
    font-size: 18px;
    width: 30px;
    margin-right: 5px;
    font-size: 30px;
  }

  .campaign-info {
    margin-left: 0%;
    width: 25%;
  }
  .campaign-name-input {
    width: 90px;
    @include font-smaller;
  }

  .campaign-creation-date {
    width: 90px;
    margin-bottom: 30px;
  }

  .state-flag {
    @include font-smaller;
  }

  .campaign-buttons {
    flex-direction: column;
    justify-content: space-around;
    height: 160px;
    margin-left: 2%;
  }

  .campaign-buttons {
    width: 30%;
    min-width: 20px;
    margin-right: 2%;
    margin-left: 2vw;
  }
}
.bulk-campaign {
  background: linear-gradient(
    90deg,
    #f9ffe7 5.19%,
    #e3f7ff 25.11%,
    #fff 61.43%
  );
}
</style>
