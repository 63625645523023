
import CreateCampaignStep3 from "./Step3.vue";
import CreateCampaignStep4 from "./Step4.vue";
import CreateCampaignStep5 from "./Step5.vue";
import CreateCampaignStep1 from "./CreateCampaignStep1.vue";
import CreateCampaignStep2 from "./CreateCampaignStep2.vue";

export default {
  CreateCampaignStep1,
  CreateCampaignStep2,
  CreateCampaignStep3,
  CreateCampaignStep4,
  CreateCampaignStep5,
};
