<template>
  <section class="fullscreen-modal">
    <div v-if="showCloseButton" class="close" @click="close">
      <img
        class="close-icon"
        src="../../assets/close-icon.png"
        alt="Close icon"
      />
    </div>
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: "FullscreenModal",
  props: {
    showCloseButton: { type: Boolean, default: true },
  },
  methods: {
    async close() {
      this.$store.commit("video/resetVideos");
      if (
        this.$route.path.includes("create") &&
        !this.$route.path.includes("step5")
      ) {
        this.$emit("close-modal");
      } else if (this.$route.path.includes("edit-campaign/")) {
        this.$router.push({
          name: "campaignManager",
          params: { campaignId: this.$route.params.campaignId },
        });
      } else {
        this.$parent.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fullscreen-modal {
  width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: white;
  z-index: map-get($zindex, modal);

  .close {
    top: 20px;
    right: 20px;
    position: absolute;
    cursor: pointer;
    z-index: inherit;
  }

  .close-icon {
    height: 25px;
  }
}
</style>
