<template>
  <div class="device-tabs">
    <button
      :class="['device-tab', { active: chosenDevice === 'desktop' }]"
      @click="$emit('update-device', 'desktop')"
    >
      <DesktopIcon class="device-icon" />
      <h2 :class="[{ active: chosenDevice === 'desktop' }]">
        {{ $t("dashboard.filters.deviceSelector.desktop") }}
      </h2>
    </button>
    <button
      :class="['device-tab', { active: chosenDevice === 'mobile' }]"
      @click="$emit('update-device', 'mobile')"
    >
      <MobileIcon class="device-icon" />
      <h2 :class="[{ active: chosenDevice === 'mobile' }]">
        {{ $t("dashboard.filters.deviceSelector.mobile") }}
      </h2>
    </button>
  </div>
</template>

<script>
import MobileIcon from "@/assets/svg/campaign-manager/small-mobile-icon.svg?inline";
import DesktopIcon from "@/assets/svg/campaign-manager/small-desktop-icon.svg?inline";
export default {
  components: {
    MobileIcon,
    DesktopIcon,
  },
  props: {
    chosenDevice: {
      type: String,
      default: "desktop",
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  all: unset;
}
.device-tabs {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}
.device-icon {
  transform: scale(1.5);
  stroke: $dark-purple;
  margin-right: 10px;
}
.device-tab {
  width: 40%;
  @include font-big;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
  border-bottom: 2px solid $light-grey;
  color: $dark-purple;
  font-weight: $w-light;
  &.active {
    color: $dark-purple;
    border-bottom: 2px solid $dark-purple;
    .device-icon {
      stroke: $dark-purple;
    }
  }
}
</style>
