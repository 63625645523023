<template>
  <small-modal
    :title="$t('deleteCampaign.title')"
    class="delete-campaign-modal"
    :fit-content="true"
    @close="close"
  >
    <div class="delete-campaign-modal-container">
      <h3 class="subtitle">{{ $t("deleteCampaign.subtitle") }}</h3>
      <banner-modal
        :title="$t('deleteCampaign.information')"
        :text="$t('deleteCampaign.videosAssociated')"
      >
      </banner-modal>
      <banner-modal
        v-if="isEmbed"
        banner-type="warning"
        :title="$t('deleteCampaign.warning')"
        :text="$t('deleteCampaign.embeddedVideos')"
      >
      </banner-modal>
      <div class="buttons">
        <input
          class="button-primary --grey"
          type="button"
          :value="$t('deleteCampaign.cancel')"
          @click.prevent="close"
        />
        <label class="button-primary button-flex">
          <input
            class="submit-button"
            type="submit"
            :value="$t('deleteCampaign.delete')"
            @click="deleteCampaign(deleteCampaignId)"
          />
          <DeleteIcon class="delete-icon" />
        </label>
      </div>
    </div>
    <spinner-loader v-if="isLoading"></spinner-loader>
  </small-modal>
</template>

<script>
import { mapState } from "vuex";

import SmallModal from "../shared/SmallModal";
import SpinnerLoader from "../shared/SpinnerLoader.vue";
import DeleteIcon from "@/assets/svg/delete-small.svg?inline";
import BannerModal from "../shared/BannerModal.vue";

export default {
  components: {
    DeleteIcon,
    SmallModal,
    SpinnerLoader,
    BannerModal,
  },
  props: {
    deleteCampaignId: {
      type: String,
      required: true,
    },
    isEmbed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      campaignsState: ({ campaign }) => campaign.campaigns,
    }),
  },

  methods: {
    close() {
      this.$emit("close");
    },
    async deleteCampaign(campaignId) {
      this.isLoading = true;
      await this.$store.dispatch("campaign/deleteCampaign", campaignId);
      this.isLoading = false;
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("shared.toastMessage.campaignDeleted"),
        type: "success",
      });
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-campaign-modal-container {
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.subtitle {
  text-align: center;
  @include font-normal;
}

.delete-icon {
  transform: scale(175%);
}

/*save and back button*/
.buttons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 24px;
}

.button-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.submit-button {
  @include base-font;
  @include font-small;
  font-weight: 500;
  background: none;
  border: none;
}

@include media(">phone", "<=tablet") {
  .card-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    @include font-normal;
  }

  .subtitle {
    @include font-small;
  }
}

@include media("<=phone") {
  .card-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    @include font-normal;
  }
}
</style>
