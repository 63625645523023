<template>
  <div class="tags-wrapper">
    <div
      v-for="(tag, index) in getTagsNames(tags)"
      :key="tag"
      class="selected-tag-button"
    >
      <div class="selected-tag" :class="getTagColorBackground(index)">
        <p>
          {{ tag | truncate }}
        </p>
        <button
          class="close-icon"
          :class="getTagColor(index)"
          @click="deleteTagFromCampaign(index)"
        >
          <CloseIcon />
        </button>
      </div>
    </div>
    <div v-if="showDisplayAddButton" class="add-tag-button-container">
      <button class="add-tag-button" @click="toggleAddButton">
        {{ $t("campaignManager.tags.addTag") }}
      </button>
    </div>
    <div
      v-else-if="!showDisplayAddButton && tags.length < 2"
      v-click-outside="toggleAddButton"
      class="tags"
    >
      <input
        v-model="tagInput"
        class="search-tags"
        type="text"
        :placeholder="placeholder"
        autofocus
        maxlength="30"
        @keyup.enter="addTagToCampaign()"
      />
      <div class="tags-modal">
        <p v-if="tagInput === ''" class="type-to-create-tag">
          {{ $t("campaignManager.tags.typeToCreate") }}
        </p>
        <p
          v-if="tagInput !== ''"
          class="create-tag-button"
          @click="addTagToCampaign()"
        >
          {{ $t("campaignManager.tags.create") }} "{{ tagInput }}"
        </p>

        <div v-for="(tag, index) in tagsArray" :key="index" class="tags-list">
          <div
            v-if="!isTagAdded(tag)"
            class="tag"
            :class="getTagColor(index)"
            @click="addTagToCampaign(tag)"
          >
            <p>
              {{ tag.name }}
            </p>
            <button
              v-if="tag._id"
              class="trash-icon"
              @click="
                openDeleteTagModal(tag);
                $event.stopPropagation();
              "
            >
              <DeleteIcon :class="getTrashIconColor(index)" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <spinner-loader v-if="isLoading" />
  </div>
</template>
<script>
import CloseIcon from "@/assets/svg/settings/close.svg?inline";
import { mapGetters, mapState, mapMutations } from "vuex";
import DeleteIcon from "@/assets/svg/delete-small.svg?inline";
import SpinnerLoader from "../shared/SpinnerLoader.vue";

export default {
  components: {
    SpinnerLoader,
    CloseIcon,
    DeleteIcon,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showAddButton: false,
      tagInput: "",
      tags: [],
      tagColors: [
        "blue",
        "red",
        "green",
        "orange",
        "purple",
        "brown",
        "light-blue",
        "yellow",
        "grey",
      ],
      placeholder: this.$t("campaignManager.tags.searchCreate"),
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      campaignsState: ({ campaign }) => campaign.campaigns,
    }),
    ...mapGetters({
      getSiteId: "account/getSiteId",
      tagsArray: "tag/getTags",
    }),
    ...mapMutations({
      updatedCampaign: "campaign/updateCampaign",
    }),

    showDisplayAddButton() {
      return this.tags.length < 2 && !this.showAddButton;
    },
  },

  async mounted() {
    this.tags = this.campaign.tags ? structuredClone(this.campaign.tags) : [];
  },

  methods: {
    getTagColor(index) {
      const colorIndex = index % this.tagsArray.length;
      const color = this.tagColors[colorIndex];
      return color || "blue";
    },

    getTagColorBackground(index) {
      const tagId = this.tags[index];
      const colorIndex = this.tagsArray.findIndex((tag) => tag._id === tagId);
      const color = this.tagColors[colorIndex];
      return color ? color + "-bg" : "blue-bg";
    },

    getTrashIconColor(index) {
      const colorIndex = index % this.tagsArray.length;
      const color = this.tagColors[colorIndex];
      return color ? color + "-trash-icon" : "blue-trash-icon";
    },
    toggleAddButton() {
      this.showAddButton = !this.showAddButton;
    },

    async deleteTagFromCampaign(index) {
      this.isLoading = true;
      this.tags.splice(index, 1);
      await this.$store.dispatch("campaign/updateCampaign", {
        campaignId: this.campaign._id,
        dataToUpdate: { tags: this.tags },
      });
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("campaignManager.tags.deleteTagFromCampaign"),
        type: "success",
      });
      this.isLoading = false;
    },
    async addTagToCampaign(tag = null) {
      this.isLoading = true;

      if (tag?._id) {
        this.tags.push(tag._id);
      } else {
        const newTag = await this.createTag(
          tag ? tag.name : this.tagInput.trim()
        );
        if (newTag) {
          this.tags = [...this.tags, newTag._id];
          this.tagInput = "";
        }
      }
      await this.$store.dispatch("campaign/updateCampaign", {
        campaignId: this.campaign._id,
        dataToUpdate: { tags: this.tags },
      });
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("campaignManager.tags.addTagToCampaign"),
        type: "success",
      });
      this.isLoading = false;
      this.showAddButton = false;
    },
    async createTag(tagName) {
      try {
        this.isLoading = true;
        const siteId = this.getSiteId;
        const tag = await this.$store.dispatch("tag/createTag", {
          name: tagName,
          siteId: siteId,
        });
        this.isLoading = false;
        return tag;
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
    },
    getTagsNames(tags) {
      if (tags.length === 0) {
        return [];
      }
      const names = [];
      tags.forEach((id) => {
        const tagName = this.findTagNameById(id);
        if (tagName) {
          names.push(tagName);
        } else {
          const tagToDelete = this.tags.findIndex((tag) => tag._id === id);
          this.tags.splice(tagToDelete, 1);
          this.$store.dispatch("campaign/updateCampaign", {
            campaignId: this.campaign._id,
            dataToUpdate: { tags: this.tags },
          });
        }
      });
      return names;
    },
    findTagNameById(id) {
      const tag = this.tagsArray.find((tag) => tag._id === id);
      return tag ? tag.name : null;
    },
    openDeleteTagModal(tag) {
      this.$emit("open-delete-tag-modal", tag);
    },
    isTagAdded(tag) {
      return this.tags.includes(tag._id);
    },
  },
};
</script>

<style lang="scss" scoped>
.tags-modal {
  padding: 8px;
  width: 185px;
  min-height: 30px;
  z-index: 99;
  background: white;
  position: absolute;
  top: 30px;
  right: -20px;
  border: 1px solid $light-grey;
  border-radius: 4px;
  box-sizing: border-box;
  max-height: 170px;
  overflow-y: auto;
}

.tags-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
}

.add-tag-button {
  @include font-small;
  @include base-font;
  color: $medium-grey;
  padding: 3px 8px;
  background-color: white;
  border: 1px solid $light-grey;
  border-radius: 3px;
  min-width: 120px;
  height: 28px;
  box-sizing: border-box;
  &:hover {
    color: $dark-purple;
    border: 1px solid $dark-purple;
  }
}

.search-tags {
  @include font-small;
  @include base-font;
  padding: 3px 14px 3px 8px;
  width: 145px;
  height: 23px;
  box-sizing: border-box;
  border: 1px solid $dark-purple;
  border-radius: 3px;
  color: $dark-grey;
  &:focus {
    outline: 1px solid $dark-purple;
  }
}

.tags-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tags {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tag {
  @include font-small;
  @include base-font;
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
  &:hover .tag-name,
  &:hover .delete-icon {
    background-color: $light-grey;
  }
  &:hover .trash-icon {
    path {
      fill-opacity: 1;
    }
  }
}

.tag:hover .tag-name,
.tag:hover .delete-icon {
  background-color: inherit;
}
.create-tag-button {
  @include font-small;
  @include base-font;
  font-weight: 600;
  padding: 4px 8px;
  overflow-wrap: break-word;
  background-color: rgba(88, 88, 88, 0.2);
  &:hover {
    cursor: pointer;
  }
}
.type-to-create-tag {
  padding: 4px;
  font-style: italic;
  @include font-small;
  color: $light-grey;
  margin-bottom: 2px;
}

.selected-tag {
  @include font-small;
  @include base-font;
  display: flex;
  padding: 4px 8px;
  border-radius: 4px;
  overflow: ellipsis;
  box-sizing: border-box;
  white-space: nowrap;
  &:hover {
    cursor: default;
  }
}

.close-icon {
  transform: scale(50%);
  background: transparent;
}

.trash-icon {
  transform: scale(150%);
  margin: 0 6px;
  background: transparent;
  path {
    fill-opacity: 0;
  }
}

.blue {
  color: rgba(0, 48, 211, 1);
  &:hover {
    background-color: rgba(0, 48, 211, 0.2);
  }
}
.red {
  color: rgba(181, 3, 14, 1);
  &:hover {
    background-color: rgba(181, 3, 14, 0.2);
  }
}
.green {
  color: rgba(2, 162, 0, 1);
  &:hover {
    background-color: rgba(2, 162, 0, 0.2);
  }
}
.orange {
  color: rgba(236, 128, 0, 1);
  &:hover {
    background-color: rgba(236, 128, 0, 0.2);
  }
}
.purple {
  color: rgba(211, 0, 203, 1);
  &:hover {
    background-color: rgba(211, 0, 203, 0.2);
  }
}
.brown {
  color: rgba(154, 91, 35, 1);
  &:hover {
    background-color: rgba(154, 91, 35, 0.2);
  }
}
.light-blue {
  color: rgba(0, 188, 188, 1);
  &:hover {
    background-color: rgba(0, 188, 188, 0.2);
  }
}
.yellow {
  color: rgba(207, 156, 0, 1);
  &:hover {
    background-color: rgba(207, 156, 0, 0.2);
  }
}
.grey {
  color: rgba(88, 88, 88, 1);
  &:hover {
    background-color: rgba(88, 88, 88, 0.2);
  }
}

.blue-bg {
  color: rgba(0, 48, 211, 1);
  background-color: rgba(0, 48, 211, 0.2);
  svg {
    path {
      fill: rgba(0, 48, 211, 1);
      opacity: 1;
    }
  }
}
.red-bg {
  color: rgba(181, 3, 14, 1);
  background-color: rgba(181, 3, 14, 0.2);
  svg {
    path {
      fill: rgba(181, 3, 14, 1);
    }
  }
}
.green-bg {
  color: rgba(2, 162, 0, 1);
  background-color: rgba(2, 162, 0, 0.2);
  svg {
    path {
      fill: rgba(2, 162, 0, 1);
    }
  }
}
.orange-bg {
  color: rgba(236, 128, 0, 1);
  background-color: rgba(236, 128, 0, 0.2);
  svg {
    path {
      fill: rgba(236, 128, 0, 1);
    }
  }
}
.purple-bg {
  color: rgba(211, 0, 203, 1);
  background-color: rgba(211, 0, 203, 0.2);
  svg {
    path {
      fill: rgba(211, 0, 203, 1);
    }
  }
}
.brown-bg {
  color: rgba(154, 91, 35, 1);
  background-color: rgba(154, 91, 35, 0.2);
  svg {
    path {
      fill: rgba(154, 91, 35, 1);
    }
  }
}
.light-blue-bg {
  color: rgba(0, 188, 188, 1);
  background-color: rgba(0, 188, 188, 0.2);
  svg {
    path {
      fill: rgba(0, 188, 188, 1);
    }
  }
}
.yellow-bg {
  color: rgba(207, 156, 0, 1);
  background-color: rgba(207, 156, 0, 0.2);
  svg {
    path {
      fill: rgba(207, 156, 0, 1);
    }
  }
}
.grey-bg {
  color: rgba(88, 88, 88, 1);
  background-color: rgba(88, 88, 88, 0.2);
  svg {
    path {
      fill: rgba(88, 88, 88, 1);
      opacity: 1;
    }
  }
}

.blue-trash-icon {
  path {
    fill: rgba(0, 48, 211, 1);
  }
}
.red-trash-icon {
  path {
    fill: rgba(181, 3, 14, 1);
  }
}
.green-trash-icon {
  path {
    fill: rgba(2, 162, 0, 1);
  }
}
.orange-trash-icon {
  path {
    fill: rgba(236, 128, 0, 1);
  }
}
.purple-trash-icon {
  path {
    fill: rgba(211, 0, 203, 1);
  }
}
.brown-trash-icon {
  path {
    fill: rgba(154, 91, 35, 1);
  }
}
.light-blue-trash-icon {
  path {
    fill: rgba(0, 188, 188, 1);
  }
}
.yellow-trash-icon {
  path {
    fill: rgba(207, 156, 0, 1);
  }
}
.grey-trash-icon {
  path {
    fill: rgba(88, 88, 88, 1);
  }
}
</style>
