<template>
  <small-modal
    :title="$t('integration.modal.title')"
    class="integration-modal"
    @close="close"
  >
    <h2 v-if="!isIntegrated" class="subtitle">
      {{ $t("integration.modal.subtitle") }}
    </h2>
    <h2 v-else class="subtitle">
      {{ $t("integration.modal.subtitleIntegrated") }}
    </h2>
    <banner-modal
      :title="$t('shared.toastMessage.information')"
      class="integration-banner"
    >
      <slot v-if="cmsChosen.name === 'shopify'">
        <ul class="information-points">
          <li>
            <h2>{{ $t(`integration.modal.pinkSquare.check2`) }}</h2>
          </li>
          <li>
            <h2>{{ $t(`integration.modal.pinkSquare.check5`) }}</h2>
          </li>
        </ul>
      </slot>
      <slot v-else>
        <ul class="information-points">
          <li v-for="number in ['1', '2', '3', '4']" :key="number.id">
            <h2>{{ $t(`integration.modal.pinkSquare.check${number}`) }}</h2>
          </li>
        </ul>
      </slot>
    </banner-modal>
    <div v-if="cmsChosen.name === 'wix' || cmsChosen.name === 'shopify'">
      <ul class="steplist">
        <!-- cmsChosen sent from integration component -->
        <li class="step">
          {{ $t(`integration.modal.cms.${cmsChosen.name}.steps`)[0] }}
          <a
            class="link-to-cms"
            target="blank"
            :href="$t(`integration.modal.cms.${cmsChosen.name}.link`)"
          >
            {{ $t(`integration.modal.cms.${cmsChosen.name}.link`) }}</a
          >
        </li>
        <li class="step">
          {{ $t(`integration.modal.cms.${cmsChosen.name}.steps`)[1] }}
        </li>
        <li class="step">
          {{ $t(`integration.modal.cms.${cmsChosen.name}.steps`)[2] }}
        </li>
      </ul>
    </div>
    <div v-if="false">
      <div class="input-wrapper">
        <div class="title-input">
          <h2>Your Shopify store name*</h2>
          <input v-model="shopifyShopName" class="shopify-input" type="text" />
        </div>
        <button class="action-button flex-button" @click="integrateShopifySite">
          <ShopifyLogo />Install
        </button>
      </div>
      <banner-modal
        :title="$t('integration.modal.shopify-banner-steps.title')"
        class="integration-banner"
      >
        <slot>
          <ul>
            <li class="shopify-step">
              <span>1.</span>
              <h2>{{ $t(`integration.modal.shopify-banner-steps.step1`) }}</h2>
            </li>
            <li class="shopify-step">
              <span>2.</span>
              <h2>{{ $t(`integration.modal.shopify-banner-steps.step2`) }}</h2>
            </li>
            <li class="shopify-step">
              <span>3.</span>
              <h2>{{ $t(`integration.modal.shopify-banner-steps.step3`) }}</h2>
            </li>
          </ul>
        </slot>
      </banner-modal>
    </div>
    <div v-if="cmsChosen.name === 'wordpress' || cmsChosen.name === 'prestashop'">
      <ul class="steplist">
        <!-- cmsChosen sent from integration component -->
        <li class="step">
          {{ $t(`integration.modal.cms.${cmsChosen.name}.steps`)[0] }}
        </li>
        <li class="step">
          {{ $t(`integration.modal.cms.${cmsChosen.name}.steps`)[1] }}
        </li>
        <li class="step">
          {{ $t(`integration.modal.cms.${cmsChosen.name}.steps`)[2] }}
        </li>
        <li class="step">
          {{ $t(`integration.modal.cms.${cmsChosen.name}.steps`)[3] }}
        </li>
        <li class="step">
          {{ $t(`integration.modal.cms.${cmsChosen.name}.steps`)[4] }}
        </li>
        <div class="flex-copy">
          <p class="flex-copy-title">Site ID</p>
          <input class="text-input signup-input" disabled :value="siteId" />
          <button
            v-clipboard="siteId"
            v-clipboard:success="clipboardSuccessHandler"
            v-clipboard:error="clipboardErrorHandler"
            class="action-button copy-to-clipboard"
          >
            {{ $t("integration.modal.copy") }}
          </button>
        </div>
        <div class="flex-copy">
          <p class="flex-copy-title">Token</p>
          <input class="text-input signup-input" disabled :value="authToken" />
          <button
            v-clipboard="authToken"
            v-clipboard:success="clipboardSuccessHandler"
            v-clipboard:error="clipboardErrorHandler"
            class="action-button copy-to-clipboard"
          >
            {{ $t("integration.modal.copy") }}
          </button>
        </div>
        <li class="step">
          {{ $t(`integration.modal.cms.${cmsChosen.name}.steps`)[5] }}
        </li>
      </ul>
    </div>
    <!-- script is displayed at the correct place based on  cmsChosen.scriptPosition -->
    <div v-else class="display-for-manual">
      <ul class="steplist">
        <li
          v-for="step in $t(
            `integration.modal.cms.${cmsChosen.name}.steps`
          ).slice(0, cmsChosen.scriptPosition)"
          :key="step.name"
          class="step"
        >
          {{ step.title }}
          <img
            v-if="Boolean(step.img)"
            v-lazy="
              require(`../../../assets/integration/integration-steps/${cmsChosen.name}/${$i18n.locale}/${step.img}.png`)
            "
            class="image-steps"
            alt=""
          />
          <a
            v-if="Boolean(step.hyperlink)"
            href="https://help.vidjet.io/en/articles/5568648-how-to-track-orders-and-conversions"
            target="blank"
            >{{ step.hyperlink }}</a
          >
        </li>
      </ul>
      <div
        v-if="cmsChosen.name === 'gtm' || cmsChosen.name === 'manual'"
        class="script-to-copy"
      >
        <ul class="script">
          <li>{{ $t("integration.modal.script1") }}</li>
          <li>
            {{ $t("integration.modal.script2") }}{{ site.site._id
            }}{{ $t("integration.modal.script3") }}
          </li>
          <li>
            {{ $t("integration.modal.script4") }}
          </li>
        </ul>
        <button
          v-clipboard="() => script"
          v-clipboard:success="clipboardSuccessHandler"
          v-clipboard:error="clipboardErrorHandler"
          class="copy-to-clipboard action-button"
        >
          {{ $t("integration.modal.copy") }}
        </button>
      </div>
      <ul
        v-for="step in $t(
          `integration.modal.cms.${cmsChosen.name}.steps`
        ).slice(cmsChosen.scriptPosition)"
        :key="step.name"
        class="steplist"
      >
        <li class="step">
          {{ step.title }}
          <img
            v-if="Boolean(step.img)"
            v-lazy="
              require(`../../../assets/integration/integration-steps/${cmsChosen.name}/${$i18n.locale}/${step.img}.png`)
            "
            class="image-steps"
            alt=""
          />
          <a
            v-if="Boolean(step.hyperlink)"
            href="https://help.vidjet.io/en/articles/5568648-how-to-track-orders-and-conversions"
            target="blank"
            >{{ step.hyperlink }}</a
          >
        </li>
      </ul>
    </div>
  </small-modal>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import BannerModal from "../../shared/BannerModal.vue";
import SmallModal from "../../shared/SmallModal";
import ShopifyLogo from "@/assets/svg/shopify-logo.svg?inline";
import api from "../../../api";

export default {
  components: {
    BannerModal,
    SmallModal,
    ShopifyLogo,
  },

  props: ["cmsChosen", "isIntegrated"],

  data() {
    return {
      shopifyShopName: "",
    };
  },

  created() {
    //Prefil input value for Shopify
    if (this.cmsChosen.name === "shopify") {
      this.shopifyShopName = this.site?.integrationData?.shop || "";
    }
  },

  computed: {
    ...mapState({ site: "site" }),
    ...mapGetters({
      siteId: "account/getSiteId",
      authToken: "account/getAuthToken",
    }),
    script() {
      return (
        this.$t("integration.modal.script1") +
        this.$t("integration.modal.script2") +
        this.siteId +
        this.$t("integration.modal.script3") +
        this.$t("integration.modal.script4")
      );
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
    clipboardSuccessHandler() {
      this.$notify({
        title: this.$t("shared.toastMessage.copied"),
        type: "success",
      });
    },
    clipboardErrorHandler() {
      this.$notify({
        title: this.$t("shared.toastMessage.failedToCopy"),
        type: "error",
      });
    },
    async integrateShopifySite() {
      const { authUrl } = await api.processShopifyAuth({
        shop: this.shopifyShopName,
        integrateSiteId: this.siteId,
      });

      if (authUrl) {
        window.top.location.href = authUrl;
      } else {
        this.$notify({
          title: this.$t("shared.toastMessage.errorShopify"),
          type: "error",
        });
      }
    },
  },
};
</script>
<style lang="scss">
.integration-modal .small-modal.bigger {
  padding: 42px 50px;
}
</style>

<style lang="scss" scoped>
.modal {
  height: 100%;
  background-color: transparent;
}

.card-title {
  text-align: center;
  @include font-big;
  margin-top: 28px;
}

.close-btn {
  float: right;
  margin-top: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  position: sticky;
  top: 10px;
}

.subtitle {
  text-align: center;
  margin-bottom: 24px;
}

.integration-banner {
  margin-bottom: 24px;
}

.integration-steps-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: map-get($zindex, modal);

  width: 80vw;
  max-width: 850px;
  height: 460px;
  background-color: #fff;
  box-shadow: 0px 0px 250px 100px rgba(0, 0, 0, 0.2),
    inset 0px 0px 10px 3px #dfdfdf;
  border-radius: 7px;
  @include base-font;
  overflow: auto;
}

.information-points {
  list-style-type: none;
  li {
    margin-bottom: 8px;
  }
}

.steplist {
  display: grid;
  grid-column: 1fr;
  grid-gap: 16px;
  width: 100%;
  max-width: 800px;
  margin-bottom: 16px;
}
.step {
  @include font-normal;
  font-weight: 500;
  display: flex;
  flex-direction: column;
}

.link-to-cms {
  color: $dark-grey;
  text-decoration: underline;
}

.script-to-copy {
  position: relative;
  width: 100%;
  border: 1px solid $dark-purple;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  margin: 16px 0;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include font-small;
}

.display-for-manual {
  position: relative;
  max-width: 800px;
}

.flex-copy {
  display: flex;
  align-items: center;
  gap: 8px;
}

.copy-to-clipboard {
  @include base-font;
  height: fit-content;
  padding: 8px;
  width: 130px;
}
.script {
  width: 75%;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.image-steps {
  height: 400px;
  width: 730px;
  padding: 8px;
}

@include media(">tablet", "<=desktop") {
  .copy-to-clipboard {
    right: 8%;
  }
}
@include media(">phone", "<=tablet") {
  .integration-steps-modal {
    width: 80vw;
    min-width: 320px;
    height: 400px;
    align-items: center;
  }
}

@include media("<=phone") {
  .integration-steps-modal {
    width: 300px;
    height: 350px;
    align-items: center;
  }
}

.signup-input {
  @include base-font;
  @include font-normal;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 1px rgba(226, 124, 252, 0.7);
  }
  width: 60%;
}

.input-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 24px;
}
.title-input {
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 4px;
}

.shopify-input {
  box-sizing: content-box;
  width: 100%;
  line-height: 20px;
  padding: 4px 8px;
  border: 1px solid $light-grey;
  border-radius: 2px;
}

.flex-button {
  width: 130px;
  height: 30px;
}

.shopify-step {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  margin-bottom: 4px;
  span {
    color: $dark-purple;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  h2 {
    color: $dark-purple;
    font-weight: 400;
  }
}
</style>
