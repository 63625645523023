<template>
  <div>
    <div v-if="!isLoading">
      <section class="main-content-wrapper">
        <vidjet-header :title="$t('agencyPortal.title')"></vidjet-header>
        <div class="agency-list">
          <div class="domain-list">
            <div class="title-container">
              <HamburgerMenu class="hamburger-icon" />
              <h2 class="title">{{ $t("agencyPortal.domainList") }}</h2>
            </div>
            <p class="number-domains">
              {{ site.agencySites.length }} {{ $t("agencyPortal.domains") }}
            </p>
          </div>
          <div class="header-elements">
            <p
              v-for="(element, index) in positionHeaderElements"
              :key="index"
              :style="{
                'text-align': element.position === 'right' ? 'right' : 'left',
              }"
            >
              {{ element.text }}
            </p>
          </div>
          <div class="list-container">
            <div
              v-for="site in site.agencySites"
              :key="site.id"
              class="list-elements"
            >
              <p>{{ site.websiteUrl }}</p>
              <p>{{ getPricing(site) }} ({{ site.plan }})</p>
              <p :style="{ color: displayStatusIcon(site.status).color }">
                {{ displayStatusIcon(site.status).text }}
              </p>
              <p class="left-align">{{ site.numberOfUsers }}</p>
              <p class="left-align">{{ site.numberOfCampaigns }}</p>
              <p class="left-align">{{ site.numberOfVideos }}</p>
              <button class="option-button" @click="openOptionsModal(site)">
                <img
                  src="../assets/campaign-options-button.png"
                  small-modalalt="options-button"
                />
                <AgencyOptionsModal
                  v-if="showOptionsModal && selectedSite.siteId === site.siteId"
                  :site="selectedSite"
                  @log-account="logAs(site.siteId)"
                  @delete-site="openDeleteDomainModal(site)"
                  @close-options-modal="closeOptionsModal"
                />
              </button>
            </div>
          </div>

          <button
            class="action-button add-client"
            @click="showClientModal = true"
          >
            {{ $t("agencyPortal.addDomain") }}
          </button>
        </div>
        <small-modal
          v-if="showClientModal"
          :title="$t('agencyPortal.modal.title')"
          :fit-content="true"
          @close="closeClientModal"
        >
          <div class="add-domain-container">
            <h3 class="modal-subtitle">
              {{ $t("agencyPortal.modal.subtitle") }}
            </h3>
            <banner-modal
              :title="$t('deleteCampaign.information')"
              :text="$t('agencyPortal.modal.bannerText')"
            >
            </banner-modal>
            <form class="form-new-client" @submit.prevent="addClient">
              <input-line
                input-type="text"
                input-for="website-url"
                :label-text="$t('agencyPortal.modal.websiteUrl')"
                :input-required="true"
                :use-flex-layout="true"
                @dataInput="addClientData"
              ></input-line>

              <p class="add-user-optional">
                {{ $t("agencyPortal.modal.addUser") }}
              </p>
              <div class="optional-inputs">
                <input-line
                  input-type="email"
                  input-for="client-email"
                  :label-text="$t('agencyPortal.modal.clientEmail')"
                  :input-required="isClientAccess"
                  :use-flex-layout="true"
                  @dataInput="addClientData"
                ></input-line>
                <input-line
                  input-type="password"
                  input-for="password"
                  :label-text="$t('agencyPortal.modal.password')"
                  :input-required="isClientAccess"
                  :use-flex-layout="true"
                  @dataInput="addClientData"
                ></input-line>
              </div>
              <!-- make a component submit btn form -->
              <div class="buttons-footer">
                <input
                  class="button-primary --grey"
                  type="button"
                  :value="$t('agencyPortal.modal.cancel')"
                  @click="closeClientModal"
                />
                <label class="button-primary flex-btn">
                  <input
                    class="confirm-btn"
                    type="submit"
                    :value="$t('agencyPortal.modal.confirm')"
                  />
                  <CircleCheckedIcon class="circle-checked" />
                </label>
              </div>
            </form>
          </div>
        </small-modal>
        <small-modal
          v-if="showDeleteDomain"
          :title="$t('agencyPortal.deleteModal.title')"
          :fit-content="true"
          @close="closeDeleteModal"
        >
          <div class="add-domain-container">
            <h3 class="modal-subtitle">
              {{ $t("agencyPortal.deleteModal.subtitle") }}
            </h3>
            <banner-modal
              banner-type="warning"
              :title="$t('deleteCampaign.warning')"
              :text="$t('agencyPortal.deleteModal.bannerText')"
            >
            </banner-modal>
            <form @submit.prevent="removeClient(selectedSite)">
              <div class="buttons-footer">
                <input
                  class="button-primary --grey"
                  type="button"
                  :value="$t('agencyPortal.modal.cancel')"
                  @click="closeDeleteModal"
                />
                <label class="button-primary flex-btn delete-btn">
                  <input
                    class="confirm-btn"
                    type="submit"
                    :value="$t('agencyPortal.deleteModal.remove')"
                  />
                  <DeleteIcon class="delete-icon" />
                </label>
              </div>
            </form>
          </div>
        </small-modal>
        <div class="title-container">
          <DashboardIcon class="hamburger-icon" />
          <h2 class="title">{{ $t("agencyPortal.masterDashboard") }}</h2>
        </div>
        <Dashboard class="dashboard-multi-account" :is-agency="true" />
      </section>
    </div>
    <spinner-loader v-else></spinner-loader>
  </div>
</template>

<script>
import HamburgerMenu from "@/assets/svg/sidebar/hamburger-menu.svg?inline";
import CircleCheckedIcon from "@/assets/svg/dashboard/circle_checked.svg?inline";
import DeleteIcon from "@/assets/svg/delete-small.svg?inline";
import DashboardIcon from "@/assets/svg/dashboard-icon.svg?inline";
import InputLine from "./shared/InputLine.vue";
import SmallModal from "./shared/SmallModal.vue";
import VidjetHeader from "./shared/Vidjet-header.vue";
import Dashboard from "@/components/dashboard/Dashboard.vue";
import AgencyOptionsModal from "./AgencyOptionsModal";
import BannerModal from "./shared/BannerModal.vue";
import SpinnerLoader from "./shared/SpinnerLoader.vue";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    SmallModal,
    InputLine,
    VidjetHeader,
    HamburgerMenu,
    AgencyOptionsModal,
    BannerModal,
    CircleCheckedIcon,
    DeleteIcon,
    Dashboard,
    DashboardIcon,
    SpinnerLoader,
  },
  data() {
    return {
      isClientAccess: false,
      showClientModal: false,
      dataToSend: {
        siteData: {},
        userData: {},
      },
      headerElements: [
        {
          text: this.$t("agencyPortal.header.websiteDomains"),
          position: "left",
        },
        { text: this.$t("agencyPortal.header.vidjetPlan"), position: "left" },
        {
          text: this.$t("agencyPortal.header.accountStatus"),
          position: "left",
        },
        { text: this.$t("agencyPortal.header.users"), position: "right" },
        { text: this.$t("agencyPortal.header.campaigns"), position: "right" },
        { text: this.$t("agencyPortal.header.videos"), position: "right" },
      ],
      showOptionsModal: false,
      selectedSite: null,
      showDeleteDomain: false,
      isLoading: false,
    };
  },

  computed: {
    ...mapState(["site", "user"]),
    ...mapGetters({
      isAdmin: "account/getIsAdmin",
      siteId: "site/getSiteId",
    }),
    isAdminConnectedToAgency() {
      return this.isAdmin && !this.site.agencyId;
    },
    positionHeaderElements() {
      return this.headerElements.map((element, index) => ({
        text: element.text,
        position: index >= this.headerElements.length - 3 ? "right" : "left",
      }));
    },
  },

  async created() {
    this.isLoading = true;
    this.isAdminConnectedToAgency
      ? this.$store.dispatch(
          "site/saveAgencyId",
          this.$store.state.site.site._id
        )
      : "";
    try {
      const agencyId = this.isAdmin
        ? this.site.agencyId
        : this.$store.state.user.user.siteId;
      await this.$store.dispatch("site/getSiteAgency", {
        agencyId,
      });
      await this.$store.dispatch("site/getSite", {
        siteId: this.siteId,
      });
    } catch (err) {
      console.log(err);
    }
    this.isLoading = false;
  },

  methods: {
    getPricing(site) {
      const amount = site.pricing ? site.pricing : "-";
      if (!site.pricing && site.planCurrency === "EUR") {
        return "0€";
      } else if (!site.pricing && site.planCurrency === "USD") {
        return "$0";
      } else if (site.planCurrency === "EUR") {
        return `${amount}€`;
      } else {
        return `$${amount}`;
      }
    },
    displayStatusIcon(status) {
      switch (status) {
        case "notIntegrated":
          return {
            text: this.$t("agencyPortal.accountStatus.notIntegrated"),
            color: "#585858",
          };
        case "lock":
          return {
            text: this.$t("agencyPortal.accountStatus.locked"),
            color: "#FF0000",
          };
        case "active":
          return {
            text: this.$t("agencyPortal.accountStatus.active"),
            color: "#0DA400",
          };
      }
    },
    async logAs(siteId) {
      await this.$store.dispatch("account/logAs", {
        siteId: siteId,
      });

      await this.$store.dispatch("site/getSite", {
        siteId: this.$store.state.account.user.siteId,
      });

      this.$store.dispatch("site/isLoggedAs", true);

      this.$notify({
        title: this.$t("shared.toastMessage.loggedAs"),
        type: "success",
      });
    },

    addClientData(payload) {
      switch (payload.inputFor) {
        case "website-url":
          this.dataToSend.siteData.websiteUrl = payload.value;
          break;
        case "client-email":
          this.dataToSend.userData.email = payload.value;
          break;
        case "password":
          this.dataToSend.userData.password = payload.value;
      }
    },

    async addClient() {
      this.dataToSend.agencyId = this.$store.state.account.user.siteId;
      if (!this.isClientAccess) {
        delete this.dataToSend.userData;
      }
      try {
        await this.$store.dispatch("site/addClient", this.dataToSend);
        this.showClientModal = false;
        this.$notify({
          title: this.$t("shared.toastMessage.clientAdded"),
          type: "success",
        });
      } catch (err) {
        this.$notify({
          title: "Error",
          text: err,
          type: "error",
        });
      }
    },
    async removeClient(site) {
      if (!site) {
        return;
      }

      try {
        const siteToDelete = this.site.agencySites.filter(
          (agencySite) => agencySite.siteId === site.siteId
        );
        this.$store.dispatch("site/deleteClient", siteToDelete[0].siteId);

        this.showDeleteDomain = false;
        this.selectedSite = null;

        this.$notify({
          title: this.$t("shared.toastMessage.clientDeleted"),
          type: "success",
        });
      } catch (err) {
        this.$notify({
          title: "Error",
          text: err,
          type: "error",
        });
      }
    },
    closeClientModal() {
      this.showClientModal = false;
      this.isClientAccess = false;
    },
    openOptionsModal(site) {
      this.selectedSite = { ...site };
      this.showOptionsModal = true;
    },
    closeOptionsModal() {
      this.showOptionsModal = false;
    },
    openDeleteDomainModal(site) {
      this.showDeleteDomain = true;
      this.selectedSite = site;
    },
    closeDeleteModal() {
      this.showDeleteDomain = false;
      this.selectedSite = null;
    },
  },
};
</script>

<style lang="scss">
.dashboard-multi-account .main-content-wrapper {
  margin: 0;
}

.dashboard-multi-account .header {
  display: none;
}

.dashboard-multi-account .data-table-frame {
  display: none;
}
</style>

<style lang="scss" scoped>
.agency-list {
  display: flex;
  flex-direction: column;
  margin: 40px 0 80px;
}

.title-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.title {
  @include base-font;
  @include font-big;
}

.hamburger-icon {
  path {
    fill: $dark-grey;
  }
}

.number-domains {
  font-weight: 500;
}

.header-elements {
  position: -webkit-sticky;
  position: sticky;
  display: grid;
  grid-template-columns: 2.3fr 1fr 1.2fr 1fr 1fr 1fr 1fr;
  gap: 16px;
  color: $dark-grey;
  background: #ececec;
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 5px;
  align-items: center;
  text-align: start;
  height: 100%;
  padding: 12px;
  margin-bottom: 12px;
  p {
    @include font-small;
    font-weight: 500;
  }
}

.left-align {
  justify-self: flex-end;
}

.option-button {
  position: relative;
  background-color: white;
  height: 50px;
  margin-left: 16px;
}

.domain-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-container {
  max-height: 400px;
}

.list-elements {
  display: grid;
  grid-template-columns: 2.3fr 1fr 1.2fr 1fr 1fr 1fr 1fr;
  gap: 16px;
  align-items: center;
  text-align: start;
  margin: 0 0 8px;
  padding: 0 12px;
  p {
    @include base-font;
    @include font-small;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.status-icon {
  margin: 0 auto;
  height: 24px;
  width: 24px;
}

.log-as {
  max-width: 100px;
}

.modal-title {
  margin: 2rem 0;
  @include font-big;
  text-align: center;
  color: $dark-grey;
}

.add-domain-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 24px;
}

.modal-subtitle {
  @include base-font;
  @include font-normal;
  font-weight: 400;
  white-space: nowrap;
}

.form-new-client {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.flex-centered {
  width: 75%;
  margin: 0 auto;
  justify-content: space-evenly;
}

.add-user-optional {
  @include base-font;
  @include font-normal;
  font-weight: 600;
  text-align: start;
  margin-top: 20px;
}

.optional-inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.access-for-client {
  @include font-normal;
  font-weight: 500;
}

.automatic-email {
  text-align: center;
  margin: 1rem 0;
}
.buttons-footer {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 32px;
}

.flex-btn {
  display: flex;
  align-items: center;
  gap: 8px;
}

.confirm-btn {
  background: none;
  border: none;
  @include base-font;
  @include font-small;
  font-weight: 500;
}
.delete-btn {
  box-shadow: 2px 2px 15px 0px rgba(235, 0, 0, 0.25);
  &:hover {
    background-color: rgba(235, 0, 0, 0.25);
  }
}
.circle-checked {
  path {
    fill: $dark-grey;
  }
}

.delete-icon {
  transform: scale(175%);
}

.add-client {
  align-self: start;
  margin-top: 4px;
  width: 180px;
}

.delete-button {
  padding: 5px;
  max-width: 100px;
  svg {
    margin: auto;
    height: 20px;
    width: 20px;
  }
}
</style>
