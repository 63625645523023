<template>
  <div class="banner-wrapper">
    <div class="left-banner-section">
      <div class="title">
        <InfoIcon class="info-icon" />
        <p>
          {{ titleText }}
        </p>
      </div>
      <button class="redirection-link-article" @click="handleButtonClick">
        {{ linkText }}
        <span v-if="icons" class="icons-container">
          <img
            v-for="(icon, key) in iconElements"
            :key="key"
            class="icon"
            :src="icon"
            :alt="`${key}-icon`"
          />
        </span>
      </button>
    </div>
    <div class="right-banenr-section">
      <img
        class="photo"
        :src="require(`../../assets/${selectedName}-photo.png`)"
      />
      <InstructionElipse class="elipse" />
    </div>
  </div>
</template>

<script>
import InfoIcon from "@/assets/svg/info-icon.svg?inline";
import InstructionElipse from "@/assets/svg/instruction-elipse.svg?inline";

export default {
  name: "InstructionBanner",

  components: {
    InfoIcon,
    InstructionElipse,
  },
  props: {
    selectedName: {
      type: String,
      default: "",
    },
    titleText: {
      type: String,
      default: "",
    },
    linkText: {
      type: String,
      default: "",
    },
    linkHref: {
      type: String,
      default: "",
    },
    icons: {
      type: Object,
      default: null,
    },
  },
  computed: {
    iconElements() {
      if (!this.icons) return [];
      return Object.values(this.icons);
    },
  },
  methods: {
    handleButtonClick() {
      if (this.linkHref) {
        window.open(this.linkHref, "_blank");
      } else {
        this.$emit("handle-button-click");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 44px;
  width: 90%;
  background-color: #fde6ff;
  margin: 0 auto;
  border-radius: 4px;
  height: 77px;
  position: relative;
}

.title {
  @include base-font;
  display: flex;
  gap: 4px;
  align-items: flex-start;
  margin-left: 24px;
  p {
    color: $dark-purple;
    font-weight: 500;
    font-size: 14px;
  }
}

.redirection-link-article {
  @include base-font;
  @include font-smaller;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 24px;
  margin-left: 44px;
  background-color: white;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.12);
  padding: 0 12px;
  a {
    text-decoration: none;
  }
  &:hover {
    cursor: pointer;
  }
}

.info-icon {
  overflow: inherit;
}

.left-banner-section {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: auto 0;
  gap: 8px;
}

.right-banner-section {
  width: 100px;
  height: 77px;
  margin-left: 8px;
}

.elipse {
  position: absolute;
  top: 0;
  right: 0;
}
.photo {
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 0;
  height: 66px;
}
.icons-container {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 4px;
}
.icon {
  width: 14px;
}
</style>
