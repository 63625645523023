<template>
  <full-screen-modal :show-close-button="false">
    <section
      v-lazy:background-image="require('../assets/signup-person.png')"
      class="identification-page login"
    >
      <form class="login-signup-form" @submit.prevent="signUp">
        <img
          v-lazy="require('../assets/vidjet-logo-big.png')"
          alt="logo vidjet"
          class="login-signup-logo left"
        />

        <h1 data-cy="login-header" class="signup-header">
          Welcome Sumo-Ling!
        </h1>
        <div class="signup-inputs-wrapper">
          <label for="email">{{ $t("signUpLogin.email") }}</label>
          <input
            id="email"
            v-model="email"
            data-cy="login-email-input"
            class="text-input signup-input"
            type="email"
            :disabled="true"
          />
          <label for="password">{{ $t("signUpLogin.password") }}</label>
          <input
            id="password"
            v-model="password"
            data-cy="login-password-input"
            maxlength="256"
            class="text-input signup-input"
            type="password"
          />
        </div>
        <button 
          data-cy="login-button" 
          type="submit" 
          class="button-primary"
          :disabled="isSignupDone"
        >
          Set password & Continue
        </button>
      </form>
    </section>
  </full-screen-modal>
</template>

<script>
import FullScreenModal from "./shared/Fullscreen-modal.vue";
import SignupLogin from "./shared/SignupLogin.vue";

export default {
  name: "Signup",
  components: {
    FullScreenModal,
    SignupLogin,
  },
  data() {
    return {
      email: "",
      password: "",
      displayDuplicatedEmailError: false,
      isSignupDone: false
    };
  },
  created() {
    this.email = this.$route.query.email

    if (this.$route.query.error === 'user_has_account') {
      this.displayDuplicatedEmailError = true
    }
  },
  methods: {
    async signUp() {
      try {
        await this.$store.dispatch("account/appsumoSignup", {
          email: this.email,
          password: this.password
        });
        // check if successful
        // show success message and close modal
        if (this.$store.state.account.loggedIn) {
          this.$notify({
            title: this.$t("shared.toastMessage.success"),
            text: this.$t("shared.toastMessage.signUpSuccessful"),
            type: "success",
          });
          this.isSignupDone = true;
          //On the first signup, we want to redirect to step 1 but still allow App sumo user to skip directly to billing view
          this.$router.push({ 
            name: "createCampaignStep1",
            query: { appsumoAllowSkip: "true" },
          });
          return;
        }
      } catch (err) {
        console.log(err);
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: err.response.data,
          type: "error",
        });
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.login-signup-logo {
  position: absolute;
  top: 30px;
}

.login-signup-logo.right {
  right: 10%;
}

.login-signup-logo.left {
  left: 10%;
}

.login-signup-logo.for-modal {
  display: none;
}

.login-signup-form {
  display: flex;
  flex-direction: column;
}

.identification-page {
  &.signup {
    background-position: 10% center, 5% center;
  }
  &.login {
    background-position: 90% center, 5% center;
  }
  &.signup,
  &.login {
    @include media("<=tablet") {
      background-image: none;
    }
    &.for-modal {
      width: 95%;
      background-image: url("~@/assets/signup-person.png"),
        url("~@/assets/svg/signup-engage.svg") !important;
      background-repeat: no-repeat;
      background-position: right, left;
      @include media("<=desktop") {
        background: none;
      }
    }
  }
}

.button-primary {
  width: fit-content;
  align-self: center;
}

.identification-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 100%;
  padding: 90px 0;
  background: no-repeat;
  @include media("<=desktop") {
    background-position: top, bottom;
  }

  .login-text {
    @include font-smallest;
    margin-top: 20px;
    margin-bottom: 30px;
    color: $light-grey;
    .login-link {
      @include base-font;
      @include font-smallest;
      background: white;
      color: $dark-purple;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .signup-header {
    @include font-big;
    margin: 28px auto 35px auto;
  }
  .signup-completed {
    margin: 8px auto;
  }
  .signup-inputs-wrapper {
    display: flex;
    flex-direction: column;
    label {
      @include font-small;
      margin-bottom: 6px;
    }
    #email {
      margin-bottom: 20px;
    }
    #password {
      margin-bottom: 35px;
    }
  }
  .signup-input {
    @include base-font;
    @include font-normal;
    border: 1px solid #c4c4c4;
    border-radius: 3px;
    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 1px rgba(226, 124, 252, 0.7);
    }
  }
}
</style>
