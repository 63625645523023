<template>
  <div class="clima-time-input">
    <span class="open-text">{{
      $t("create.step3.buttonSelector.showAfter")
    }}</span>
    <div class="time-grid">
      <input
        v-model.number="fromMinutes"
        type="number"
        class="number-input"
        :class="{ selected: selected === 'fromSec' }"
        min="0"
        max="59"
        placeholder="min"
        @click="selected = 'fromSec'"
        @input="sanitizeInput($event, 2)"
      />
      <p>:</p>
      <input
        v-model.number="fromSeconds"
        type="number"
        class="number-input"
        :class="{ selected: selected === 'fromSec' }"
        min="0"
        max="59"
        placeholder="sec"
        @click="selected = 'fromSec'"
        @input="sanitizeInput($event, 2)"
      />
      <button
        class="delete-button"
        :class="{ selected: fromSeconds > 0 || fromMinutes > 0 }"
        @click="resetTimeFrom()"
      >
        <DeleteIcon></DeleteIcon>
      </button>
    </div>
    <span class="open-text">{{
      $t("create.step3.buttonSelector.hideAfter")
    }}</span>
    <div class="time-grid">
      <input
        v-model.number="toMinutes"
        type="number"
        class="number-input"
        :class="{ selected: selected === 'toSec' }"
        min="0"
        max="59"
        limit="2"
        placeholder="min"
        @click="selected = 'toSec'"
        @input="sanitizeInput($event, 2)"
      />
      <p>:</p>
      <input
        v-model.number="toSeconds"
        type="number"
        class="number-input"
        :class="{ selected: selected === 'toSec' }"
        min="0"
        max="59"
        limit="2"
        placeholder="sec"
        @click="selected = 'toSec'"
        @input="sanitizeInput($event, 2)"
      />
      <button
        class="delete-button"
        :class="{ selected: toSeconds > 0 || toMinutes > 0 }"
        @click="resetTimeTo()"
      >
        <DeleteIcon></DeleteIcon>
      </button>
    </div>
    <div class="current-time-button-wrapper">
      <button
        v-if="selected"
        class="action-button"
        @click="setCurrentTimeClicked"
      >
        <p>{{ $t("create.step3.buttonSelector.setCurrentTime") }}</p>
      </button>
    </div>
  </div>
</template>

<script>
import DeleteIcon from "@/assets/svg/delete.svg?inline";
export default {
  components: {
    DeleteIcon,
  },
  props: {
    presetValue: {
      type: Object,
      default: () => ({}),
    },
    labelText: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      fromMinutes: null,
      fromSeconds: null,
      toMinutes: null,
      toSeconds: null,
      selected: "",
    };
  },

  watch: {
    fromMinutes: {
      handler() {
        this.convertDates();
      },
      deep: true,
    },
    fromSeconds: {
      handler() {
        this.convertDates();
      },
      deep: true,
    },
    toMinutes: {
      handler() {
        this.convertDates();
      },
      deep: true,
    },
    toSeconds: {
      handler() {
        this.convertDates();
      },
      deep: true,
    },
  },

  created() {
    if (this.presetValue.fromSec || this.presetValue.toSec) {
      const { fromSec = 0, toSec = 0 } = this.presetValue;
      this.fromMinutes = Math.floor(fromSec / 60);
      this.fromSeconds = fromSec % 60;
      this.toMinutes = Math.floor(toSec / 60);
      this.toSeconds = toSec % 60;
    }
  },

  methods: {
    convertDates() {
      const fromSec = parseInt(this.fromMinutes * 60 + this.fromSeconds);
      const toSec = parseInt(this.toMinutes * 60 + this.toSeconds);
      this.$emit("toggle-button", {
        fromSec,
        toSec,
      });
    },

    sanitizeInput(event, maxLength) {
      const input = event.target;
      let sanitizedValue = input.value.replace(/\D/g, ""); // Remove non-digit characters
      if (sanitizedValue.length > maxLength) {
        sanitizedValue = sanitizedValue.slice(0, maxLength);
      }
      input.value = sanitizedValue;
    },

    setCurrentTimeClicked() {
      const video = document.querySelector(".vidjet-video") || document.querySelector(".vjs-vidjet-main video");
      if (!video) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.videoClosed"),
          type: "error",
        });
        return;
      }
      const currentTime = video.currentTime;
      const roundedTime = Math.round(currentTime);
      const minutes = Math.floor(roundedTime / 60);
      const seconds = roundedTime % 60;

      if (this.selected === "fromSec") {
        this.fromMinutes = minutes;
        this.fromSeconds = seconds;
      } else if (this.selected === "toSec") {
        this.toMinutes = minutes;
        this.toSeconds = seconds;
      }
    },
    resetTimeFrom() {
      this.fromMinutes = null;
      this.fromSeconds = null;
    },
    resetTimeTo() {
      this.toMinutes = null;
      this.toSeconds = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.clima-time-input {
  @include font-small;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
}
.open-text {
  text-align: center;
}
.time-grid {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(4, min-content);
  gap: 4px;
  place-items: center;
  margin: 0 auto;
}

.number-input {
  line-height: 24px;
  width: 50px;
  font-size: 14px;
}
.number-input:focus::placeholder {
  color: transparent;
}

.number-input.selected {
  background-color: $light-pink;
  filter: grayscale(0);
}
.time-input-container {
  display: flex;
}

.number-input:hover {
  filter: grayscale(0);
  background-color: $light-pink;
}

.number-input::placeholder {
  color: $light-purple;
}

.current-time-button-wrapper {
  width: 100%;
  text-align: end;
}

.delete-button {
  background: transparent;
  &.selected {
    path {
      fill: $dark-purple;
    }
  }
  path {
    fill: $light-grey;
    fill-opacity: 1;
  }
}
</style>
