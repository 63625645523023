<template>
  <div class="modals">
    <plan-1
      v-show="userIsDowngrading"
      :new-plan="planChosen"
      @close="close"
      @plan1="toggleAllplans"
    />
    <plan-registration
      v-show="showRegistration && !userIsDowngrading"
      :new-plan="planChosen"
      :plan-chosen="true"
      :show-arrows="false"
      @close="close"
      @plan1="backToPlan1"
      @allPlans="backToAllPlans"
    />
  </div>
</template>

<script>
import PlanRegistration from "./PlanRegistration.vue";
import Plan1 from "./Plan1.vue";

import { mapState } from "vuex";

export default {
  components: {
    Plan1,
    PlanRegistration,
  },
  props: {
    planChosen: {
      type: Object,
      default: () => {
        return {};
      },
    },
    userIsDowngrading: { type: Boolean, default: false },
  },

  data() {
    return {
      showPlan1: true,
      showAllPlans: false,
      showRegistration: true,
    };
  },

  computed: {
    ...mapState(["plan"]),
  },
  methods: {
    close() {
      this.showPlan1 = true;
      this.showAllPlans = false;
      this.showPlanRegistration = false;
      this.$emit("closeUpgradePlan");
    },
    // navigation between modals
    toggleAllplans() {
      this.showAllPlans = !this.showAllPlans;
      this.showPlan1 = !this.showPlan1;
    },
    backToPlan1() {
      this.showRegistration = !this.showRegistration;
      this.showPlan1 = !this.showPlan1;
      this.newPlan = "";
    },
    backToAllPlans() {
      this.showRegistration = !this.showRegistration;
      this.showAllPlans = !this.showAllPlans;
      this.newPlan = "";
    },
  },
};
</script>

<style></style>
