<template>
  <div
    :class="[
      'vidjet-radio-button',
      { toggle: variant === 'toggle', normal: variant === 'normal' },
    ]"
  >
    <input
      :id="id"
      :class="[{ isChecked: isChecked }]"
      type="radio"
      :name="name"
      :value="val"
      :checked="isChecked"
      :disabled="disabled"
      @change="$emit('input', val)"
    />
    <label :class="[{ isChecked: isChecked }]" :for="id">{{ text }}</label>
  </div>
</template>

<script>
export default {
  name: "VidjetRadioBtn",
  props: {
    text: {
      default: "",
      type: String,
    },
    val: {
      default: null,
      type: [String, Number, Boolean],
    },
    name: {
      default: "bubble",
      type: String,
    },
    id: {
      default: "myId",
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    variant: {
      validator: function (value) {
        return ["toggle", "normal"].indexOf(value) !== -1;
      },
      default: "normal",
    },
  },
  data() {
    return {
      value: this.val,
    };
  },
  computed: {
    isChecked() {
      return this.val == this.$attrs.value;
    },
  },
};
</script>

<style lang="scss" scoped>
// Two styles of radio button: toggle (looks like a full width button)
// and normal (typical radio button)

// make sure that the radio button has correct styling with isChecked
.normal label.isChecked:after {
  background-color: $light-purple;
  border: 1px solid transparent;
}

.vidjet-radio-button {
  &.toggle {
    @include font-smallest;
    margin: 6px 0;
    input {
      display: none;
    }
    input:checked + label,
    .isChecked {
      background: $dark-purple;
      color: white;
    }

    label {
      background-color: #ffffff;
      color: $dark-grey;
      transition: all 0.2s;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
      border-radius: 20px;
      cursor: pointer;
      padding: 6px 12px;
    }
  }
  &.normal {
    @include font-smaller;
    font-weight: $w-normal;
    input {
      position: absolute;
      pointer-events: none;
      opacity: 0;
      &:checked + label {
        color: $dark-grey;
        &:after {
          background-color: $light-purple;
          border: 1px solid transparent;
        }
      }
    }

    label {
      position: relative;
      display: inline-block;
      padding-left: 28px;
      line-height: 1.5625rem;
      cursor: pointer;
      user-select: none;
      transition: 0.28s ease;
      &:after {
        transform: scale(1.02);
        border: 1px solid $light-purple;
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        margin: 4px;
        content: "";
        transition: 0.28s ease;
        border-radius: 50%;
      }
    }
  }
}
</style>
