import api from "../api";

const state = {
  user: {},
  users: [],
};

const getters = {
  userHasInfo: (state) => {
    return Boolean(
      state.user.firstname && state.user.lastname && state.user.email
    );
  },
  users: (state) => {
    return state.users;
  },
};

const mutations = {
  GET(state, user) {
    state.user = user;
  },

  UPDATE(state, user) {
    state.user = user;
  },
  SET_USERS(state, users) {
    state.users = users;
  },
  DELETE_USER(state, user) {
    const index = state.users.findIndex((element) => element._id === user);
    if (index > -1) {
      state.users.splice(index, 1);
    }
  },
  ADD_LAST_USER(state, user) {
    state.users.push(user);
  },
};

const actions = {
  async getUser({ commit }, { userId }) {
    try {
      const user = await api.getUser(userId);
      commit("GET", user);
    } catch (err) {
      throw err;
    }
  },

  async updateUser({ commit }, { userId, dataToUpdate }) {
    try {
      const user = await api.updateUser(userId, dataToUpdate);
      commit("UPDATE", user);
    } catch (err) {
      throw err;
    }
  },
  async getUsers({ commit }, { siteId }) {
    try {
      const users = await api.getUsers({ siteId });
      commit("SET_USERS", users);
    } catch (err) {
      throw err;
    }
  },
  async addUser({ commit }, { data }) {
    try {
      const lastUser = await api.addUser(data);
      commit("ADD_LAST_USER", lastUser);
    } catch (err) {
      throw err;
    }
  },
  async deleteUser({ commit }, user) {
    try {
      await api.deleteUser(user);
      commit("DELETE_USER", user);
    } catch (err) {
      throw err;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
