import axios from "axios";

export default {
  async getCampaigns({ siteId, stateFlag }) {
    const requestOptions = {
      method: "get",
      url: `${process.env.VUE_APP_API_URL}/campaigns`,
      headers: { "Content-Type": "application/json" },
      params: {
        siteId,
        stateFlag: [0, 1],
      },
    };

    try {
      const res = await axios(requestOptions);
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  },
  async getSingleCampaign(campaignId) {
    const requestOptions = {
      method: "get",
      url: `${process.env.VUE_APP_API_URL}/campaign/${campaignId}`,
      headers: { "Content-Type": "application/json" },
    };

    try {
      const res = await axios(requestOptions);
      return res.data;
    } catch (err) {
      throw err;
    }
  },
  async createCampaign(data) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data,
    };

    try {
      const res = await axios(
        `${process.env.VUE_APP_API_URL}/campaign`,
        requestOptions
      );
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  },
  async updateCampaign(campaignId, data) {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data,
    };

    try {
      const res = await axios(
        `${process.env.VUE_APP_API_URL}/campaign/${campaignId}`,
        requestOptions
      );
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  },
  async deleteCampaign(campaignId) {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    try {
      const res = await axios(
        `${process.env.VUE_APP_API_URL}/campaign/${campaignId}`,
        requestOptions
      );

      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  },
  async uploadVideo(formData, siteId, campaignId) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    };

    try {
      const res = await axios(
        `${process.env.VUE_APP_API_URL}/campaign/videoupload/${siteId}/${campaignId}`,
        requestOptions
      );
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  },
  async campaignScheduler(campaignData) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: campaignData,
    };

    try {
      const res = await axios(
        `${process.env.VUE_APP_API_URL}/campaign/scheduler`,
        requestOptions
      );
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  },
};
