<template>
  <div>
    <section class="dashboard-frames main-frames">
      <div
        v-for="(frame, i) in frames"
        :key="frame.name[i18nLocale]"
        class="frame"
        :class="getSelectedColor(frame.color, i)"
        @click="selectFrame(i)"
      >
        <div class="frame-wrapper">
          <div class="frame-body">
            <div class="frame-title">
              <template>
                <img
                  v-if="i == selectedFrame"
                  :src="
                    require(`@/assets/svg/dashboard-icon-${frame.metric}-selected.svg`)
                  "
                  alt="icon"
                />
                <img
                  v-else
                  :src="
                    require(`@/assets/svg/dashboard-icon-${frame.metric}.svg`)
                  "
                  alt="icon"
                />
                <h2
                  v-tippy="{ placement: 'top', arrow: true }"
                  :content="frame.tooltip[i18nLocale]"
                >
                  {{ frame.name[i18nLocale] }}
                </h2>
              </template>
              <div class="box-footer">
                <a
                  v-if="showHowToConversion && frame.metric === 'totalSales'"
                  class="track-sales"
                  :href="kbLink"
                  target="_blank"
                >
                  {{ kbLinkText }}<new-tab-icon class="new-tab-icon" />
                </a>
                <div
                  v-tippy="{ placement: 'bottom', arrow: true }"
                  class="badge"
                  :content="frame.badgeTooltip[i18nLocale]"
                >
                  <span>{{
                    frame.badgeValue >= 0 ? "&#8599;" : "&#8600;"
                  }}</span>
                  <span>{{
                    parseMetricForFrame(frame.badgeValue, "percentage")
                  }}</span>
                </div>
              </div>
            </div>
            <h3
              v-tippy="{ placement: 'top', arrow: true }"
              class="frame-metric"
              :content="frame.tooltip[i18nLocale]"
            >
              {{ parseMetricForFrame(frame.metric, frame.type) }}
              {{ frame.keyword ? frame.keyword[i18nLocale] : "" }}
            </h3>
            <p class="frame-submetric">
              {{
                parseMetricForFrame(frame.subheaderMetric, frame.subHeaderType)
              }}
              {{
                frame.subheaderKeyword ? frame.subheaderKeyword[i18nLocale] : ""
              }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="dashboard-frames subframes">
      <div
        v-for="(child, index) in frames[selectedFrame].childrenMetrics"
        v-show="!child.optional || dashboardData[child.metric]"
        :key="child.name[i18nLocale]"
        v-tippy="{ placement: 'top', arrow: true }"
        class="subframe"
        :class="getSelectedColor(child.color)"
        :content="child.tooltip[i18nLocale]"
      >
        <div class="frame-wrapper">
          <h2 class="frame-title">{{ child.name[i18nLocale] }}</h2>
          <div class="frame-body">
            <template v-if="child.isSelectType">
              <div class="select-box">
                <CtaVSelect
                  v-model="selectedMetric"
                  :placeholder="placeholder"
                  :options="child.metric[i18nLocale]"
                />
                <h3 class="frame-selectable-metric">
                  {{
                    parseMetricForSelectableFrame(selectedMetric, child.metric)
                  }}
                </h3>
              </div>
            </template>
            <template v-else>
              <h3 v-if="child.metric2" class="frame-metric">
                <span class="frame-metric">
                  {{
                    Math.round(
                      Number.isNaN(
                        dashboardData[child.metric] /
                          dashboardData[child.metric2]
                      )
                        ? 0
                        : (dashboardData[child.metric] /
                            dashboardData[child.metric2]) *
                            100
                    )
                  }}%
                </span>
                ({{ parseMetricForFrame(child.metric, child.type) }}
                <span
                  >/ {{ parseMetricForFrame(child.metric2, child.type) }})</span
                >
              </h3>
              <h3 v-else class="frame-metric">
                {{ parseMetricForFrame(child.metric, child.type) }}
              </h3>
            </template>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import NewTabIcon from "@/assets/svg/open_new_tab_small.svg?inline";
import CtaVSelect from "@/components/shared/CtaVSelect.vue";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  components: {
    NewTabIcon,
    CtaVSelect,
  },
  props: {
    dashboardData: {
      type: Object,
      default: () => ({}),
    },
    frames: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: "$",
    },
    selectedFrame: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedMetric: "",
      kbLink:
        "https://help.vidjet.io/en/articles/5568648-how-to-send-orders-and-conversions",
      kbLinkText: "How to track",
      placeholder: this.$t("create.step3.selectType"),
    };
  },
  computed: {
    ...mapGetters({
      siteIntegration: "site/siteIntegration",
    }),
    showHowToConversion() {
      return (
        this.siteIntegration === "manual" ||
        this.siteIntegration === "wordpress" ||
        !this.siteIntegration
      );
    },
    i18nLocale() {
      return this.$i18n.locale ? this.$i18n.locale : "en";
    },
  },
  async created() {
    await this.$store.dispatch("site/getSite", {
      siteId: this.$store.state.account.user.siteId,
    });
  },
  methods: {
    parseMetricForFrame(metricName, type) {
      if (type === "time") {
        let seconds = this.dashboardData[metricName];
        if (seconds > 3600)
          return moment.utc(1000 * seconds).format("H [hours and] mm [min]");
        if (seconds < 60) return moment.utc(1000 * seconds).format("ss [sec]");
        return moment.utc(1000 * seconds).format("mm [min] ss");
      }
      if (type === "amount") {
        let rounded = this.$options.filters.round(
          this.dashboardData[metricName]
        );
        return this.currency + " " + Number(rounded).toLocaleString();
      }
      if (type === "percentage") {
        if (!this.dashboardData[metricName]) return "0%";
        let rounded = this.$options.filters.round(
          this.dashboardData[metricName],
          0
        );
        return (rounded == 0 ? 1 : rounded) + "%";
      }
      return Number(
        this.$options.filters.round(this.dashboardData[metricName])
      ).toLocaleString();
    },
    parseMetricForSelectableFrame(metric, options) {
      if (!metric) {
        let total = 0;
        options.en.forEach((option) => {
          total += Number(this.dashboardData[option.shortName]);
        });
        let rounded = this.$options.filters.round(total, 0);
        return Number(rounded).toLocaleString();
      }
      let option = options.en.find((option) => option.value === metric);
      let rounded = this.$options.filters.round(
        this.dashboardData[option.shortName],
        0
      );
      return Number(rounded).toLocaleString();
    },
    getPercentFromTwoMetrics(metricOne, metricTwo) {
      let result =
        (this.dashboardData[metricOne] / this.dashboardData[metricTwo]) * 100;
      if (result < 0) {
        return 0;
      } else if (result > 100) {
        return 100;
      } else {
        return result;
      }
    },
    selectFrame(index) {
      this.$emit("selectedFrame", index);
    },
    getSelectedColor(color, i = this.selectedFrame) {
      if (i === this.selectedFrame) return `is-selected-${color}`;
    },
  },
};
</script>

<style lang="scss" scoped>
$frames-space: 8px;
$subframes-space: 24px;

.box-footer {
  position: absolute;
  @include font-smaller;
  font-weight: $w-normal;
  @include flex-centered;
  color: $dark-grey;
  right: 0;
  bottom: 0;
  margin-right: 16px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.new-tab-icon {
  margin-left: 4px;
}

.main-frames {
  margin: 32px auto $subframes-space auto;
  grid-gap: $frames-space;
  .frame:first-child {
    border-radius: 8px 0px 0px 8px;
  }
  .frame:last-child {
    border-radius: 0px 8px 8px 0px;
  }
}

.subframes {
  margin: 8px auto $subframes-space auto;
  grid-gap: $subframes-space;
}

.dashboard-frames {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  @include media(">=tablet", "<=desktop") {
    grid-template-columns: 1fr 1fr;
    column-gap: 60px;
    padding: 0 60px;
    margin: 60px auto;
  }
  @include media("<tablet") {
    grid-template-columns: 1fr;
    margin: 60px auto;
  }

  .frame {
    @include frame;
    border-radius: 0;
    padding: 20px;
    position: relative;
    border: 1.5px solid $medium-grey;
    cursor: pointer;
  }
  .subframe {
    @include frame;
    border-radius: 6px;
    padding: 20px;
    position: relative;
    display: block;

    .frame-title,
    .frame-body {
      justify-content: center !important;
      align-items: center !important;
      font-size: 14px;
      margin-bottom: 0px;
    }

    .frame-metric {
      font-size: 18px;
    }
    .frame-selectable-metric {
      white-space: nowrap;
      font-size: 18px;
    }
  }

  .frame-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    text-align: center;
  }

  .frame-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .frame-title {
    font-size: 18px;
    margin-bottom: 16px;
    display: flex;
    width: 100%;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
  }

  .frame-metric {
    white-space: nowrap;
    margin-top: 8px;
    @include font-giant;
  }

  .frame-metric-right-side {
    padding-left: 8px;
    color: $light-grey;
    @include font-small;
  }
  .frame-submetric {
    margin-top: 18px;
  }

  .metric-metadata {
    @include font-smallest;
    width: 47px;
    background: rgba(226, 124, 252, 0.3);
    border-radius: 10px;
    padding: 4px 8px;
    color: $dark-purple;
  }

  .is-selected-darkBlue {
    color: #ffffff;
    background-color: #400081;
    border: 0;
    .track-sales {
      color: #ffffff;
      path {
        fill: white;
      }
    }
    .badge {
      background: #ffffff;
      color: black;
    }
  }
  .is-selected-darkPurple {
    color: #ffffff;
    background-color: #6e15ba;
    border: 0;
    .badge {
      background: #ffffff;
      color: black;
    }
  }
  .is-selected-lightPink {
    color: #ffffff;
    background-color: #a328c1;
    border: 0;
    .badge {
      background: #ffffff;
      color: black;
    }
  }

  .badge {
    color: #ffffff;
    background: black;
    border-radius: 10px;
    padding: 4px 8px;
    display: flex;
    gap: 4px;
  }
  .select-box {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    margin-top: 8px;
    .v-select {
      width: 90%;
    }
  }
}
</style>
