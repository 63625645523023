<template>
  <div class="search-bar">
    <input
      v-model="query"
      class="search-input"
      type="text"
      @keyup="sendQueryToParent()"
    />
    <magnifying-glass class="search-icon"></magnifying-glass>
  </div>
</template>

<script>
import MagnifyingGlass from "@/assets/svg/magnifying-glass.svg?inline";
import { normalizeString } from "@/utils/stringNormalize";

export default {
  components: {
    MagnifyingGlass,
  },
  props: {
    preselectedQuery: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      query: this.preselectedQuery,
    };
  },
  watch: {
    preselectedQuery(newVal) {
      this.query = newVal;
    },
  },
  created() {
    this.query = this.preselectedQuery;
  },
  methods: {
    sendQueryToParent() {
      const normalizedQuery = normalizeString(this.query);
      this.$emit("query", normalizedQuery);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-bar {
  margin: 16px auto;
  display: flex;
  height: 30px;
  justify-content: space-evenly;
  position: relative;
  width: 80%;
}

.search-input {
  width: 100%;
  @include input-style;
}

.search-icon {
  position: absolute;
  right: 8px;
  bottom: 6px;
}
</style>
