<template>
  <div
    v-click-outside="closeOptionsModal"
    class="product-options-modal"
    :class="{ 'small-modal': !product.isCreatedManually }"
  >
    <h1 class="options-title">{{ $t("campaignManager.options") }}</h1>
    <div class="options">
      <button
        v-if="product.isCreatedManually"
        class="option-button"
        @click="editProduct"
      >
        <EditIcon class="edit-icon" />
        {{ $t("productPages.options.edit") }}
      </button>
      <a
        :href="product.productUrl"
        target="_blank"
        class="option-button"
        @click="openProductUrl"
      >
        <OpenIcon class="icon" />
        {{ $t("productPages.options.open") }}
      </a>
      <button
        v-if="product.isCreatedManually"
        class="option-button"
        @click="openDeleteModal"
      >
        <DeleteIcon class="icon" />
        {{ $t("productPages.options.delete") }}
      </button>
    </div>
  </div>
</template>

<script>
import OpenIcon from "@/assets/svg/cta/open-icon.svg?inline";
import DeleteIcon from "@/assets/svg/delete.svg?inline";
import EditIcon from "@/assets/svg/edit-icon.svg?inline";

export default {
  components: {
    OpenIcon,
    DeleteIcon,
    EditIcon,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closeOptionsModal() {
      this.$emit("close-options-modal");
    },
    openProductUrl() {
      if (this.product.productUrl) {
        window.open(this.product.productUrl, "_blank");
      } else {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("productPages.options.noUrl"),
          type: "error",
        });
      }
    },
    editProduct() {
      this.$emit("edit-product", this.product);
    },
    openDeleteModal() {
      this.$emit("open-delete-modal", this.product);
    },
  },
};
</script>

<style lang="scss" scoped>
.product-options-modal {
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.25);
  padding: 16px;
  width: 200px;
  z-index: 99;
  background: white;
  position: absolute;
  top: -124px;
  right: 30px;
  border-radius: 4px;
}

.small-modal {
  top: -50px;
}
.options-title {
  @include base-font;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  color: $light-grey;
  margin-bottom: 16px;
}
.options {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
}
.option-button {
  @include base-font;
  background: transparent;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: $dark-grey;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  position: relative;
  text-align: left;
  text-decoration: none;
  &:hover {
    width: 100%;
    background: $dark-purple;
    color: white;
    border-radius: 4px;
    text-align: left;
    .icon path {
      fill: white;
    }
    .edit-icon path {
      stroke: white;
    }
  }
}
</style>
