<template>
  <sliding-modal
    :title="urlsTitle"
    :feed-name="currentFeedEdit.feedName"
    @close="close"
  >
    <div class="url-sliding-container">
      <h2 class="subtitle">
        {{ $t("create.bulk.pagesModal.subtitle.text1") }}
        <span
          ><a
            href="https://help.vidjet.io/en/articles/8368680-embedding-the-master-component-on-your-page-template"
            target="_blank"
            >{{ $t("create.bulk.pagesModal.subtitle.link") }}
          </a></span
        >
        {{ $t("create.bulk.pagesModal.subtitle.text2") }}
      </h2>
      <div class="content">
        <PagesSegmentation
          :segmentation="currentFeedEdit.segmentation"
          @updateSegmentation="updateCurrentFeedSegmentation"
        />
      </div>
      <div class="buttons-container">
        <div class="buttons">
          <button class="button-primary --grey" @click="close">
            {{ $t("create.bulk.modalButtons.cancel") }}
          </button>
          <button
            class="button-primary flex-button"
            @click="savePagesSegmentation"
          >
            {{ $t("create.bulk.modalButtons.confirm") }}<ConfirmIcon />
          </button>
        </div>
      </div>
    </div>
  </sliding-modal>
</template>
<script>
import SlidingModal from "@/components/shared/SlidingModal.vue";
import ConfirmIcon from "@/assets/svg/confirm-icon.svg?inline";
import deepClone from "@/utils/deepClone.js";
import PagesSegmentation from "@/components/create-campaign-steps/PagesSegmentation.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    SlidingModal,
    ConfirmIcon,
    PagesSegmentation,
  },
  props: {
    currentFeedEditIndex: { type: Number, required: true },
  },
  data() {
    return {
      urlsTitle: this.$t("create.bulk.pagesModal.title"),
      areAllInputsFilled: true,
      equalTriggersHaveValidUrls: true,
    };
  },
  computed: {
    ...mapGetters({
      siteId: "account/getSiteId",
      currentFeedEdit: "campaign/currentFeedEdit",
      feedList: "campaign/feedList",
      getCurrency: "site/getCurrency",
    }),
  },
  methods: {
    close() {
      this.$emit("close-urls-modal");
    },
    updateCurrentFeedSegmentation(payload) {
      if (payload.hasOwnProperty("areAllInputsFilled"))
        this.areAllInputsFilled = payload.areAllInputsFilled;
      if (payload.hasOwnProperty("equalTriggersHaveValidUrls"))
        this.equalTriggersHaveValidUrls = payload.equalTriggersHaveValidUrls;

      delete payload.equalTriggersHaveValidUrls;
      delete payload.areAllInputsFilled;

      this.$store.commit(
        "campaign/setCurrentFeedEdit",
        deepClone({
          ...this.currentFeedEdit,
          segmentation: payload,
        })
      );
    },
    savePagesSegmentation() {
      if (!this.areAllInputsFilled) {
        return this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.segmentation"),
          type: "error",
        });
      }

      if (!this.equalTriggersHaveValidUrls) {
        return this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.wrongUrl"),
          type: "error",
        });
      }

      this.$store.commit("campaign/updateFeedList", {
        currentFeedEdit: deepClone(this.currentFeedEdit),
        index: this.currentFeedEditIndex,
      });

      this.close();
    },
  },
};
</script>
<style lang="scss" scoped>
.url-sliding-container {
  display: flex;
  flex-direction: column;
  min-height: 90%;
  margin: 0 40px;
  box-sizing: border-box;
}
.subtitle {
  @include base-font;
  @include font-normal;
  font-weight: 400;
  text-align: center;
  width: 70%;
  margin: 0 auto 16px;
}

.content {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 24px;
}

.content::-webkit-scrollbar {
  width: 0;
}

.content::-webkit-scrollbar-track {
  background-color: transparent;
}

.content::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.buttons-container {
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 74px;
  margin-bottom: 48px;
}
.flex-button {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tooltip-icon {
  width: 15px;
  &:hover {
    cursor: pointer;
  }
}
</style>
