const deepClone = (obj) => {
  if (obj === null) return null;
  if (typeof obj !== "object") return obj;
  return Array.isArray(obj)
    ? obj.map((item) => deepClone(item))
    : Object.entries(obj).reduce(
        (copy, [key, value]) => ({ ...copy, [key]: deepClone(value) }),
        {}
      );
};
export default deepClone;
