<template>
  <small-modal
    :title="deleteVideosTitle"
    class="delete-videos-modal"
    :fit-content="true"
    @close="close"
  >
    <div class="delete-campaign-modal-container">
      <h3 class="subtitle">{{ deleteVideosSubtitle }}</h3>
      <banner-modal
        :title="$t('deleteCampaign.information')"
        :text="bannerText"
        class="formatted-banner-text"
      >
      </banner-modal>
      <div class="buttons">
        <input
          class="button-primary --grey"
          type="button"
          :value="$t('deleteCampaign.cancel')"
          @click.prevent="close"
        />
        <label
          class="button-primary button-flex"
          :class="{ 'disabled-button': areVideosInCampaigns }"
        >
          <input
            class="submit-button"
            type="submit"
            :value="$t('deleteCampaign.delete')"
            :disabled="areVideosInCampaigns"
            @click="deleteSelectedVideos"
          />
          <DeleteIcon class="delete-icon" />
        </label>
      </div>
    </div>
  </small-modal>
</template>

<script>
import SmallModal from "@/components/shared/SmallModal";
import DeleteIcon from "@/assets/svg/delete-small.svg?inline";
import BannerModal from "@/components/shared/BannerModal.vue";

export default {
  components: {
    DeleteIcon,
    SmallModal,
    BannerModal,
  },
  props: {
    numberOfVideosSelected: {
      type: Number,
      default: null,
    },
    numberOfVideosSelectedInCampaigns: {
      type: Number,
      default: null,
    },
    campaignNamesWithSelectedVideos: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    areVideosInCampaigns() {
      return this.numberOfVideosSelectedInCampaigns > 0;
    },
    deleteVideosTitle() {
      if (this.areVideosInCampaigns) {
        if (this.numberOfVideosSelectedInCampaigns === 1) {
          return `${this.$t("create.step2.deleteVideosModal.title4")} ${
            this.numberOfVideosSelectedInCampaigns
          } ${this.$t("create.step2.deleteVideosModal.title6")}`;
        } else {
          return `${this.$t("create.step2.deleteVideosModal.title4")} ${
            this.numberOfVideosSelectedInCampaigns
          } ${this.$t("create.step2.deleteVideosModal.title5")}`;
        }
      } else {
        if (this.numberOfVideosSelected === 1) {
          return `${this.$t("create.step2.deleteVideosModal.title1")} ${
            this.numberOfVideosSelected
          } ${this.$t("create.step2.deleteVideosModal.title3")}`;
        } else {
          return `${this.$t("create.step2.deleteVideosModal.title1")} ${
            this.numberOfVideosSelected
          } ${this.$t("create.step2.deleteVideosModal.title2")}`;
        }
      }
    },
    deleteVideosSubtitle() {
      if (this.areVideosInCampaigns) {
        return this.$t("create.step2.deleteVideosModal.subtitle3");
      } else {
        if (this.numberOfVideosSelected === 1) {
          return this.$t("create.step2.deleteVideosModal.subtitle2");
        } else {
          return this.$t("create.step2.deleteVideosModal.subtitle1");
        }
      }
    },
    bannerText() {
      if (!this.areVideosInCampaigns) {
        if (this.numberOfVideosSelected === 1) {
          return this.$t("create.step2.deleteVideosModal.banner2");
        } else {
          return this.$t("create.step2.deleteVideosModal.banner1");
        }
      } else {
        let text = this.$t("create.step2.deleteVideosModal.banner3") + "\n\n";
        this.campaignNamesWithSelectedVideos.forEach((campaign) => {
          text += `\u00A0\u00A0\u00A0\u00A0· ${campaign.campaignName}\n`;
          if (campaign.feedName !== null) {
            text += `\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0· ${campaign.feedName}\n`;
          }
        });
        return text;
      }
    },
  },
  methods: {
    close() {
      this.$emit("close-delete-videos-modal");
    },
    deleteSelectedVideos() {
      this.$emit("delete-selected-videos");
    },
  },
};
</script>

<style lang="scss">
.delete-videos-modal .small-modal.fit-modal {
  width: 500px;
}
.delete-videos-modal {
  .formatted-banner-text {
    white-space: pre-line;
  }

  .subtitle {
    text-align: center;
    @include font-normal;
    margin-bottom: 24px;
  }

  .delete-icon {
    transform: scale(175%);
  }

  .buttons {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 24px;
  }

  .button-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .submit-button {
    @include base-font;
    @include font-small;
    font-weight: 500;
    background: none;
    border: none;
  }
  .disabled-button {
    cursor: default;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.18);
    &:hover {
      background: white;
    }
    .submit-button {
      color: $light-grey;
    }
    .delete-icon {
      path {
        fill: $light-grey;
      }
    }
  }
}
</style>
