<template>
  <div v-click-outside="closeOptionsModal" class="agency-options-modal">
    <div class="options">
      <h1 class="options-title">{{ $t("campaignManager.options") }}</h1>
      <button class="option-button" @click="logAccount">
        {{ $t("agencyPortal.optionsModal.log") }}
      </button>
      <button class="option-button" @click="deleteSite">
        {{ $t("agencyPortal.optionsModal.delete") }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    site: {
      type: Object,
      required: true,
    },
  },
  methods: {
    logAccount() {
      this.$emit("log-account", this.site);
    },
    deleteSite() {
      this.$emit("delete-site", this.campaign);
    },
    closeOptionsModal() {
      this.$emit("close-options-modal");
    },
  },
};
</script>
<style lang="scss" scoped>
.agency-options-modal {
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.25);
  padding: 16px;
  width: 180px;
  min-height: 100px;
  z-index: 99;
  background: white;
  position: absolute;
  top: 48px;
  right: 24px;
  border-radius: 4px;
}
.options-title {
  @include base-font;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  color: $light-grey;
  margin-left: 12px;
}
.options {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
}
.option-button {
  @include base-font;
  background: transparent;
  padding: 4px 12px;
  color: $dark-grey;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  position: relative;
  text-align: left;
  &:hover {
    width: 100%;
    background: $dark-purple;
    color: white;
    border-radius: 4px;
    text-align: left;
  }
}
</style>
