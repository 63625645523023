<template>
  <div class="main-card">
    <div class="title-icon">
      <slot name="icon"> </slot>
      <h3 class="card-title">
        <slot name="title"> </slot>
      </h3>
    </div>
    <div v-if="checkIcon" class="check-icon">
      <section v-if="displayGreenCheck">
        <Check></Check>
      </section>
      <section v-else>
        <Cross></Cross>
      </section>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import Check from "@/assets/svg/settings/green-check.svg?inline";
import Cross from "@/assets/svg/settings/red-cross.svg?inline";

export default {
  components: {
    Check,
    Cross,
  },
  props: {
    checkIcon: {
      type: Boolean,
      default: false,
    },
    displayGreenCheck: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.main-card {
  min-height: 390px;
  min-width: 450px;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.12);
  border: 1px solid $light-grey;
  border-radius: $card-border-radius;
  width: 37vw;
  @include base-font;
  position: relative;
  flex: 0 0 45%;
  margin-bottom: 32px;
}

.title-icon {
  width: 90%;
  display: flex;
  margin: 16px auto 24px
}

.card-title {
  padding-left: 8px;
  align-self: flex-end;
  @include font-normal;
}

.check-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  svg {
    height: 24px;
    width: 24px;
  }
}

@include media(">tablet", "<=desktop") {
  .main-card {
    width: 40vw;
    min-width: 350px;
  }
}

@include media(">phone", "<=tablet") {
  .main-card {
    width: 60vw;
    min-width: 320px;
  }
  .card-title {
    padding-left: 16px;
  }
}

@include media("<=phone") {
  .main-card {
    width: 300px;
  }

  .card-title {
    @include font-small;
    padding-left: 12px;
  }
}
</style>
