<template>
  <small-modal
    :fit-content="true"
    :show-close-button="false"
    class="sorting-modal"
    @close="$emit('close-sorting-modal')"
  >
    <div class="campaign-sorting-container">
      <h1 class="order-by">{{ $t("campaignManager.sort") }}</h1>
      <span class="reset" @click="sortCampaigns(null, true)">{{
        $t("campaignManager.filter.reset")
      }}</span>
      <div class="sorting-wrapper">
        <div
          v-for="sortingOption in sortingOptions"
          :key="sortingOption.key"
          class="sorting-options"
        >
          <div class="sorting-option">
            <h2
              class="sorting-type-title"
              :class="{
                'sort-selected': selectedSorting === sortingOption.key,
              }"
              @click="toggleSortDirection(sortingOption.key)"
            >
              {{ sortingOption.label }}
            </h2>
            <div class="sorting-arrows">
              <SortArrow
                class="sort-arrow"
                :class="{
                  'arrow-selected':
                    selectedSorting === sortingOption.key &&
                    selectedArrow === 'asc',
                }"
                @click="sortCampaigns(sortingOption.key, true)"
              />
              <SortArrow
                class="sort-arrow reverse-arrow"
                :class="{
                  'arrow-selected':
                    selectedSorting === sortingOption.key &&
                    selectedArrow === 'desc',
                }"
                @click="sortCampaigns(sortingOption.key, false)"
              />
            </div>
          </div>
          <div class="separation-line"></div>
        </div>
      </div>
    </div>
  </small-modal>
</template>

<script>
import SmallModal from "@/components/shared/SmallModal.vue";
import SortArrow from "@/assets/svg/sort-arrow.svg?inline";

export default {
  components: {
    SmallModal,
    SortArrow,
  },
  data() {
    return {
      selectedSorting: null,
      selectedArrow: null,
      sortingOptions: [
        {
          key: "creationDate",
          label: this.$t("campaignManager.sortOptions.creationDate"),
        },
        {
          key: "views",
          label: this.$t("campaignManager.sortOptions.last7DaysViews"),
        },
        {
          key: "numberOfVideos",
          label: this.$t("campaignManager.sortOptions.videosPerCampaign"),
        },
      ],
    };
  },
  methods: {
    sortCampaigns(sorting, isAscending) {
      this.selectedSorting = sorting;
      this.selectedArrow = isAscending ? "asc" : "desc";
      const sortType = {
        sortType: sorting,
        isAscending: isAscending,
      };
      this.$emit("sort", sortType);
    },
    toggleSortDirection(sorting) {
      if (this.selectedSorting === sorting) {
        if (this.selectedArrow === "asc") {
          this.sortCampaigns(sorting, false);
        } else {
          this.sortCampaigns(sorting, true);
        }
      } else {
        this.sortCampaigns(sorting, true);
      }
    },
  },
};
</script>

<style lang="scss">
.sorting-modal .small-modal.fit-modal {
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.25);
  padding: 16px;
  top: 130px;
  left: 40%;
  transform: translate(0, 0);
}
</style>

<style lang="scss" scoped>
.modal-background {
  background: transparent;
}

.sorting-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.sorting-options {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.sorting-option {
  display: flex;
  gap: 12px;
  justify-content: space-between;
}
.sorting-type-title {
  &:hover {
    cursor: pointer;
  }
}

.order-by {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  cursor: default;
  margin-bottom: 14px;
}
.reset {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: $light-grey;
  position: fixed;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.separation-line {
  width: 100%;
  height: 0;
  border: 1px solid $light-grey;
}

.sorting-arrows {
  display: flex;
  flex-direction: column;
  gap: 4px;
  transform: scale(70%);
  .sort-arrow {
    &:hover {
      cursor: pointer;
    }
    path {
      fill: $light-grey;
    }
  }
  .reverse-arrow {
    transform: translateX(-0.9px);
    path {
      transform: rotate(180deg);
      transform-origin: 50% 50%;
    }
  }
  .arrow-selected {
    path {
      fill: $dark-purple;
    }
  }
}

.sort-selected {
  color: $dark-purple;
}
</style>
