<template>
  <div class="usage-wrapper">
    <BannerModal
      v-if="billingDate || daysLeftTrial"
      class="banner"
      :title="bannerText"
      title-font-weight="400"
      content-gap="0"
    />
    <div class="bars-container">
      <div class="usage-bar">
        <p class="pageviews">{{ $t("account.usage.videoAccount") }}</p>
        <section v-if="videosInCampaignsLimit">
          <div id="campaignsBar-free">
            <div
              id="campaignsBarFull"
              :style="{
                width: (numberOfVideosInLibrary / videosInCampaignsLimit) * 100 + '%',
              }"
            ></div>
            <p class="indication">
              {{ numberOfVideosInLibrary }}
              {{ $t("account.usage.indications.hasSubscribed.outOf") }}
              {{ videosInCampaignsLimit }}
              {{ $t("account.usage.indications.hasSubscribed.included") }}
            </p>
          </div>
        </section>
        <section v-else>
          <div id="campaignsBar-free">
            <div id="campaignsBarFull" style="width: 100%"></div>
            <p class="indication">
              {{ $t("account.usage.indications.custom") }}
            </p>
          </div>
        </section>
      </div>
      <div class="usage-bar">
        <p class="pageviews">{{ $t("account.usage.videoViewed") }}</p>
        <!-- different displays if user is still on a free trial 
        <section v-if="userIsTrial">
          <div id="campaignsBar-free">
            <div id="campaignsBarFull" style="width: 100%"></div>
            <p class="indication">
              {{ $t("account.usage.indications.isTrial.unlimited") }}
              {{ daysLeftTrial }}
              {{ $t("account.usage.indications.isTrial.daysRemaining") }}
            </p>
          </div>
        </section> -->
        <section v-if="!videoViewsLimit">
          <div id="campaignsBar-free">
            <div id="campaignsBarFull" style="width: 100%"></div>
            <p class="indication">
              {{ $t("account.usage.indications.custom") }}
            </p>
          </div>
        </section>
        <section v-else>
          <div id="campaignsBar">
            <!-- sets width of progress bar -->
            <div
              id="campaignsBarFull"
              :style="{
                width: (usage.usage.videoViews / videoViewsLimit) * 100 + '%',
              }"
            ></div>
            <p class="indication">
              {{ userVideoViews }}
              {{ $t("account.usage.indications.hasSubscribed.outOf") }}
              {{ videoViewsLimit }}
              {{ $t("account.usage.indications.hasSubscribed.included") }}
            </p>
          </div>
        </section>
      </div>
    </div>
    <div class="plans-container">
      <p class="pageviews">{{ $t("account.usage.monthlyPlan") }}</p>
      <div class="plan-cards">
        <div
          v-for="(plan, index) in plans"
          :key="index"
          class="plan"
          :class="{ 'selected-plan': currentPlan === plan.name }"
        >
          <span class="plan-info black">{{ plan.name.toUpperCase() }}</span>
          <span class="plan-info"
            >{{ plan.videosInCampaignsLimit ? plan.videosInCampaignsLimit : "X" }}
            {{ $t("account.usage.videos") }}</span
          >
          <span class="plan-info"
            >{{ plan.videoViewLimit ? plan.videoViewLimit : "X" }}
            {{ $t("account.usage.plays") }}</span
          >
          <span class="plan-info black">{{
            plan.cost ? "$" + plan.cost + $t("account.usage.month") : "Custom"
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import BannerModal from "@/components/shared/BannerModal.vue";

export default {
  components: {
    BannerModal,
  },
  data() {
    return {
      campaigns: [],
      plans: [],
    };
  },
  computed: {
    ...mapState(["plan", "usage", "site"]),
    ...mapGetters({
      userIsTrial: "plan/userIsTrial",
      currentPlan: "plan/currentPlan",
      daysLeftTrial: "plan/daysLeftTrial",
      siteId: "site/getSiteId",
      getCurrency: "site/getCurrency",
      videoViewsLimit: "plan/videoViewsLimit",
      videosInCampaignsLimit: "plan/videosInCampaignsLimit",
      numberOfVideosInLibrary: "video/numberOfVideosInLibrary",
    }),
    userVideoViews() {
      return this.usage.usage.videoViews > this.videoViewsLimit
        ? this.videoViewsLimit
        : this.usage.usage.videoViews;
    },
    billingDate() {
      if (this.userIsTrial) return null;
      if (this.$store.state.plan.plan.currentPlan == "free")
        return new Date(
          this.$store.state.plan.plan.freemiumCycleEndDate
        ).getDate();
      if (this.$store.state.plan.plan.billingCycleEndDate)
        return new Date(
          this.$store.state.plan.plan.billingCycleEndDate
        ).getDate();
      return null;
    },
    bannerText() {
      if (this.userIsTrial) {
        return `${this.daysLeftTrial} ${this.$t(
          "billing.accountPlan.daysLeftTrial"
        )}`;
      }
      return `${this.$t("billing.creditCardInformation.billingDate1Cycle")} ${
        this.billingDate
      } ${this.$t("billing.creditCardInformation.billingDate2")}`;
    },
  },
  async created() {
    this.campaigns = [
      ...(await this.$store.dispatch("campaign/getCampaigns", {
        siteId: this.$store.state.account.user.siteId,
      })),
    ];
    await this.$store.dispatch("site/getSite", {
      siteId: this.$store.state.account.user.siteId,
    });
    const hasCustomObject = this.plan.plan.pricings.some(
      (pricing) => pricing.name === "custom"
    );

    const hasFreeObject = this.plan.plan.pricings.some(
      (pricing) => pricing.name === "free"
    );

    if (!hasCustomObject && !hasFreeObject ) {
      this.plans = [...this.plan.plan.pricings, { name: "enterprise" }];
    } else {
      this.plans = this.plan.plan.pricings;
    }
  },
};
</script>

<style scoped lang="scss">
.usage-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.banner {
  width: 90%;
  margin: 0 auto;
}
.bars-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.usage-bar {
  width: 90%;
  display: grid;
  grid-template-columns: 0.75fr 2fr;
  margin: auto;
  p {
    float: right;
  }
}
.pageviews {
  @include font-small;
  line-height: 23px;
}
#campaignsBar {
  width: 95%;
  height: 1rem;
  border: 1px solid $dark-purple;
  background-color: #fff;
  border-radius: 10px;
  margin-left: 1rem;
  margin-top: 4px;
}

#campaignsBar-free {
  width: 95%;
  height: 1rem;
  border: 1px solid $dark-purple;
  background-color: #fff;
  border-radius: 10px;
  margin-left: 1rem;
  margin-top: 3px;
}

#campaignsBarFull {
  height: 1rem;
  background-color: #f4c8ff;
  width: 0%;
  min-width: 8%;
  max-width: 100%;
  border-radius: 10px;
}

.bar-indication {
  display: flex;
  flex-direction: column;
}

.indication {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #c4c4c4;
  padding-top: 4px;
}

.billing-block {
  @include font-small;
  display: flex;
  justify-content: flex-start;
  margin: 12px auto;
  width: 90%;
}

.billing-date,
.shopify-info {
  color: $light-grey;
}

.plans-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 auto;
}

.plan-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 8px;
}

.plan {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  min-width: 80px;
  padding: 8px 2px;
  border: 1px solid $medium-grey;
  border-radius: 4px;
  box-sizing: border-box;
}

.selected-plan {
  border: 1px solid $dark-purple;
  background-color: rgba(226, 124, 252, 0.2);
}

.plan-info {
  display: flex;
  @include base-font;
  color: black;
  @include font-smaller;
  flex-wrap: nowrap;
}

.black {
  font-weight: 700;
}

// responsiveness

@include media(">phone", "<=tablet") {
  .indication,
  .pageviews {
    @include font-smaller;
  }
}

@include media("<=phone") {
  .indication,
  .pageviews {
    @include font-smaller;
  }
}
</style>
