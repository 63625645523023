<template>
  <small-modal @close="closeReportModal">
    <form class="report-issue-wrapper" @submit.prevent="handleSubmitIssue">
      <h1 class="heading">{{ $t("reportIssueComponent.heading") }}</h1>
      <div class="select-issue-wrapper">
        <label for="select-label" class="select-heading">{{
          $t("reportIssueComponent.label")
        }}</label>
        <v-select
          id="issue-select"
          v-model="selected"
          class="vidjet-single-select issue-select"
          :placeholder="$t('reportIssueComponent.select')"
          label="name"
          :options="options"
          :searchable="false"
          :required="!selected"
          :reduce="(option) => option.key"
          name="issue-select"
        >
        </v-select>
      </div>
      <div class="textarea-input-wrapper">
        <label for="comment" class="text-input-heading">{{
          $t("reportIssueComponent.details")
        }}</label>
        <textarea
          id="comment"
          v-model="comment"
          class="textarea-input issue-textarea --with-borders"
          name="comment"
          cols="30"
          rows="10"
        ></textarea>
      </div>
      <div class="optional-email">
        <label for="email" class="email-heading">{{
          $t("reportIssueComponent.emailLabel")
        }}</label>
        <input
          id="email"
          v-model="newEmail"
          class="text-input --with-borders"
          type="email"
          name="email"
        />
      </div>
      <div class="buttons">
        <button class="button-primary --grey" @click="closeReportModal">
          {{ $t("shared.buttons.back") }}
        </button>
        <button type="submit" class="button-primary confirm-button">
          {{ $t("shared.buttons.submit") }}
          <ConfirmIcon class="confirm-icon" />
        </button>
      </div>
    </form>
  </small-modal>
</template>

<script>
import SmallModal from "@/components/shared/SmallModal";
import { mapGetters } from "vuex";
import ConfirmIcon from "@/assets/svg/dashboard/circle_checked.svg?inline";

export default {
  components: {
    SmallModal,
    ConfirmIcon,
  },
  data() {
    return {
      comment: "",
      options: [],
      selected: "",
      hubspotCookie: "",
      newEmail: "",
    };
  },
  computed: {
    ...mapGetters({
      userEmail: "account/userEmail",
      siteId: "account/getSiteId",
    }),
  },
  created() {
    // data properties come from locale, check en.json
    this.options = this.$t("reportIssueComponent.issueList");
    const HUBSPOT_COOKIE = "hubspotutk";
    this.hubspotCookie = this.getCookie(HUBSPOT_COOKIE);
    this.newEmail = this.userEmail;
  },
  methods: {
    closeReportModal() {
      this.$emit("close-report-modal");
    },
    getCookie(cookieName) {
      const cookieValue = document.cookie
        .split("; ")
        .find((row) => row.startsWith(cookieName))
        .split("=")[1];
      return cookieValue ? cookieValue : "";
    },
    validateFields() {
      if (!this.selected) {
        return this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.report.selectIssue"),
          type: "error",
        });
      } else if (!this.comment) {
        return this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.report.writeIssue"),
          type: "error",
        });
      } else if (!this.newEmail) {
        return this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.report.enterEmail"),
          type: "error",
        });
      }
    },
    async handleSubmitIssue() {
      const dataToSend = {
        hs_context: { hutk: this.hubspotCookie, pageUri: this.$route.fullPath },
        email: this.newEmail,
        help_form_description: this.comment,
        help_form_category: this.selected,
        siteId: this.siteId,
      };
      this.validateFields();

      if (this.comment && this.selected && this.newEmail) {
        try {
          await this.$store.dispatch("issue/postIssue", dataToSend);
          this.$notify({
            title: this.$t("shared.toastMessage.success"),
            text: this.$t("shared.toastMessage.report.successMessage"),
            type: "success",
          });
          this.closeReportModal();
        } catch (error) {
          return this.$notify({
            title: this.$t("shared.toastMessage.error"),
            text: error,
            type: "error",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
.issue-select .vs__dropdown-toggle {
  height: 32px;

  .vs__search {
    font-size: 14px;
  }
  .vs__selected-options {
    font-size: 14px;
  }
}
.vidjet-single-select .vs__dropdown-menu {
  position: absolute !important;
}
</style>

<style scoped lang="scss">
.success-image {
  position: relative;
  left: 65px;
  object-fit: contain;

  @include media("<=desktop") {
    left: 60px;
  }
  @include media("<=tablet") {
    left: 45px;
  }
}

.report-issue-wrapper,
.success-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 44px 50px;
}
.heading {
  @include font-big;
  font-weight: 600;
  margin: 0 auto 12px auto;
}
.select-heading,
.email-heading,
.text-input-heading {
  width: 500px;
  @include font-normal;
  font-weight: 400;
}
.select-issue-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-bottom: 16px;
}
.buttons {
  display: flex;
  width: 40%;
  justify-content: space-evenly;
  margin: 30px auto 28px auto;

  @include media("<=tablet") {
    width: 90%;
  }
}

.confirm-button {
  display: flex;
  align-items: center;
  gap: 8px;
}
.confirm-icon {
  path {
    fill: $dark-grey;
  }
}
.textarea-input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.textarea-input {
  width: 95%;
}

.issue-select {
  width: 500px;

  @include media("<=tablet") {
    width: 85%;
    margin: 10px auto auto auto;
  }
}
.issue-textarea {
  margin: 0 60px;

  @include media("<=tablet") {
    width: 85%;
    margin: auto;
  }
}
.optional-email {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
