import axios from "axios";

async function getUsers(params) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    params,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/users`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function getUser(userId) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/user/${userId}`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function updateUser(userId, data) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/user/${userId}`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function addUser(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };
  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/user`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function deleteUser(userId) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  };
  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/user/${userId}`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function createShopifyUser(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/shopify/create-user`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

export default {
  getUsers,
  updateUser,
  getUser,
  addUser,
  deleteUser,
  createShopifyUser,
};
