<template>
  <div ref="image" class="format-block">
    <div class="flex-wrapper">
      <h3 class="format-block-title">Thumbnail</h3>
    </div>
    <div v-if="addThumbnail" class="upload-block">
      <button class="action-button" @click="$refs.fileInput.click()">
        {{ $t("create.step3.thumbnailSelector.upload") }}
      </button>
      <button class="action-button" @click="takeScreenShot()">
        {{ $t("create.step3.thumbnailSelector.screenshot") }}
      </button>
      <p class="screenshot-indication">
        {{ $t("create.step3.thumbnailSelector.tip") }}
      </p>
      <input
        ref="fileInput"
        style="display: none"
        accept=".jpeg, .jpg, .png"
        type="file"
        @change="onFileChanged"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasThumbnail: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      blobUrl: null,
      addThumbnail: false,
    };
  },

  created() {
    this.addThumbnail = this.hasThumbnail;
  },
  methods: {
    changeThumbnail() {
      this.addThumbnail = !this.addThumbnail;
      if (!this.addThumbnail) {
        this.$emit("upload-image", { blob: "", tempThumbnailFile: "" });
      } else {
        this.$emit("scroll-to-bottom");
      }
    },
    takeScreenShot() {
      const canvas = document.createElement("canvas");
      const video = document.querySelector(".vidjet-video") || document.querySelector(".vjs-vidjet-main video");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      canvas.toBlob((blob) => {
        this.$emit("upload-image", {
          blob: URL.createObjectURL(blob),
          tempThumbnailFile: blob,
        });
      }, "image/png");
      this.notifyChangeThumbnail();
    },

    onFileChanged(payload) {
      const file = payload.target.files[0];
      this.$emit("upload-image", {
        blob: URL.createObjectURL(file),
        tempThumbnailFile: file,
      });
      this.notifyChangeThumbnail();
    },

    notifyChangeThumbnail() {
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("shared.toastMessage.thumbnail"),
        type: "success",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle-btn-cta {
  position: absolute;
  top: 7px;
  right: 10px;
}

.flex-wrapper {
  width: 100%;
  position: relative;
}

.upload-block {
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 16px;
  width: 100%;
}

.screenshot-indication {
  @include base-font;
  @include font-small;
  width: 100%;
  color: $dark-grey;
}

.thumbnail-btn {
  background-color: white;
  border: 1px solid $dark-grey;
  border-radius: 4px;
  padding: 4px;
  align-self: flex-start;
  @include base-font;
  color: $dark-grey;
}
</style>
