export default [
  { name: "Pacific/Niue -11", offset: -11 },
  { name: "US/Hawaii -10", offset: -10 },
  { name: "America/Anchorage -9", offset: -9 },
  { name: "America/Los Angeles -8", offset: -8 },
  { name: "America/Boise -7", offset: -7 },
  { name: "America/Chicago -6", offset: -6 },
  { name: "America/New York -5", offset: -5 },
  { name: "America/Aruba -4", offset: -4 },
  { name: "America/Buenos Aires -3", offset: -3 },
  { name: "Brazil/DeNoronha -2", offset: -2 },
  { name: "Atlantic/Azores -1", offset: -1 },
  { name: "UTC 0", offset: 0 },
  { name: "Europe/Amsterdam +1", offset: 1 },
  { name: "Europe/Athens +2", offset: 2 },
  { name: "Europe/Moscow +3", offset: 3 },
  { name: "Indian/Mahe +4", offset: 4 },
  { name: "Asia/Ashgabat +5", offset: 5 },
  { name: "Asia/Dhaka +6", offset: 6 },
  { name: "Asia/Bangkok +7", offset: 7 },
  { name: "Asia/Hong Kong +8", offset: 8 },
  { name: "Asia/Pyongyang +9", offset: 9 },
  { name: "Australia/Sydney + 10", offset: 10 },
  { name: "Asia/Magadan +11", offset: 11 },
];
