<template>
  <small-modal
    :title="$t('productPages.selectWidgetModal.title')"
    class="select-widget-modal"
    :fit-content="true"
    @close="close"
  >
    <div class="select-widget-modal-container">
      <h3 class="subtitle">
        {{ $t("productPages.selectWidgetModal.subtitle") }}
      </h3>
      <v-select
        v-if="isAddWidget"
        v-model="selectedWidget"
        class="vidjet-single-select cta-dropdown"
        :placeholder="placeholder"
        :searchable="false"
        :clearable="false"
        label="name"
        :options="possibleWidgets"
        :reduce="(option) => option._id"
      >
      </v-select>
      <v-select
        v-else
        v-model="selectedWidget"
        class="vidjet-single-select cta-dropdown"
        :placeholder="placeholder"
        :searchable="false"
        :clearable="false"
        label="campaignName"
        :options="possibleWidgets"
        :reduce="(option) => option.campaignId"
      >
      </v-select>
      <banner-modal
        class="banner"
        banner-type="info"
        :title="$t('productPages.selectWidgetModal.tip')"
        :text="$t('productPages.selectWidgetModal.tipText')"
      >
      </banner-modal>
      <div class="buttons">
        <input
          class="button-primary --grey"
          type="button"
          :value="$t('deleteCampaign.cancel')"
          @click.prevent="close"
        />

        <input
          class="button-primary submit-button"
          type="submit"
          :value="$t('shared.buttons.confirm')"
          @click.prevent="selectWidget"
        />
      </div>
    </div>
  </small-modal>
</template>

<script>
import SmallModal from "@/components/shared/SmallModal";
import BannerModal from "@/components/shared/BannerModal.vue";

export default {
  components: {
    SmallModal,
    BannerModal,
  },
  props: {
    possibleWidgets: {
      type: Array,
      required: true,
    },
    isAddWidget: {
      type: Boolean,
      default: false,
    },
    selectedProductId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectedWidget: null,
      selectedWidgetIndex: null,
      selectedWidgetId: null,
    };
  },
  computed: {
    placeholder() {
      return this.$t("productPages.selectWidgetModal.placeholder");
    },
    possibleWidgetsNames() {
      return this.possibleWidgets.map((widget) => widget.campaignName);
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    selectWidget() {
      this.$emit(
        "widget-selected",
        this.selectedWidget,
        this.selectedProductId
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.select-widget-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subtitle {
  margin-bottom: 16px;
}

.cta-dropdown {
  width: 75%;
  margin-bottom: 24px;
}
.banner {
  margin-bottom: 36px;
  width: 90%;
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 90%;
  input {
    width: 170px;
  }
}
</style>
