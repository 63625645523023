var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',{staticClass:"header"},[_vm._v(" "+_vm._s(_vm.$t("create.step2.instagram.header"))+" "),_c('img',{staticClass:"instagram-logo",attrs:{"src":require('../../assets/instagram-logo.svg'),"alt":"logo instagram"}})]),(_vm.isLoading)?_c('div',{staticClass:"loading-container"},[_c('spinner-loader')],1):(_vm.accounts.length > 0 && _vm.videos.length === 0)?_c('div',{staticClass:"ig-accounts"},_vm._l((_vm.accounts),function(account){return _c('button',{key:account.id,staticClass:"button-primary",on:{"click":function($event){_vm.selectedId = account.instagram_business_account.id}}},[_vm._v(" "+_vm._s(account.name)+" ")])}),0):(_vm.integration && _vm.videos && _vm.videos.length > 0)?_c('div',{staticClass:"integration"},[_c('div',{staticClass:"sub-header"},[(_vm.videos && _vm.videos.length > 0)?_c('div',{staticClass:"account-name"},[_c('img',{attrs:{"src":require("../../assets/ig-account-icon.png"),"alt":""}}),_vm._v("@"+_vm._s(_vm.videos[0].username)+" "),_c('Check')],1):_vm._e(),_c('button',{staticClass:"button-primary",on:{"click":function($event){return _vm.$emit('disconnectClicked')}}},[_vm._v(" "+_vm._s(_vm.$t("create.step2.instagram.disconnect"))+" ")])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"previous"},[_c('button',{staticClass:"paging-button",class:{ hidden: !_vm.paging.previous },on:{"click":function($event){return _vm.getInstagramVideos(_vm.paging.previous)}}},[_c('ArrowBackIcon')],1)]),_c('div',{staticClass:"videos"},_vm._l((_vm.videos),function(video){return _c('div',{key:video.id,staticClass:"video",on:{"click":function($event){return _vm.toggleSelectedVideo(video)}}},[(video.thumbnail_url && video.media_url)?_c('div',[_c('img',{staticClass:"video-img",class:{
                selected: _vm.getVideoIndex(video) > -1,
              },attrs:{"src":video.thumbnail_url,"alt":video.caption}}),_c('div',[_c('p',{staticClass:"caption",class:{
                  selected: _vm.getVideoIndex(video) > -1,
                }},[_vm._v(" "+_vm._s(_vm._f("truncate")(video.caption))+" ")]),_c('p',{staticClass:"date",class:{
                  selected: _vm.getVideoIndex(video) > -1,
                }},[_vm._v(" "+_vm._s(_vm.$t("create.step2.instagram.createdOn"))+" "+_vm._s(_vm._f("formatDate")(video.timestamp))+" ")])])]):_vm._e()])}),0),_c('div',{staticClass:"next"},[_c('button',{staticClass:"paging-button",class:{ hidden: !_vm.paging.next },on:{"click":function($event){return _vm.getInstagramVideos(_vm.paging.next)}}},[_c('ArrowNextIcon')],1)])]),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button-primary",on:{"click":function($event){return _vm.$emit('close-clicked')}}},[_vm._v(" "+_vm._s(_vm.$t("shared.buttons.back"))+" ")]),_c('button',{staticClass:"button-primary",on:{"click":function($event){return _vm.importClicked()}}},[_vm._v(" "+_vm._s(_vm.$t("create.step2.videoLibraryModal.import"))+" ")])])]):_c('div',{staticClass:"connect-button"},[_c('a',{staticClass:"button-primary",attrs:{"href":_vm.url,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("create.step2.instagram.connect"))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }