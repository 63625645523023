<template>
  <div
    class="player-atc-wrapper"
    @click.self="isPopup && changeIframe('destroyIframe')"
  >
    <div
      :style="borderRadiusStyle"
      class="player-container"
      :class="[
        {
          'is-story': isStory,
          isSquare,
          isPortrait: isPortrait && !isSquare,
          isPopup: isPopup,
        },
        { isLoading: isLoading && isCurrentVideo },
        { isTrimmedPlaying },
        { hideControlBar },
      ]"
      @mouseover="hideTitleAndShowControlsOnHover()"
      @mouseleave="showTitleAndHideControlsOnLeave"
      v-visibility-change="visibilityChange"
      ref="playerContainer"
    >
      <close-button
        v-if="showCloseButton"
        :video-index="videoIndex"
      ></close-button>
      <!-- TODO would be better to move in iteration section -->
      <player-text
        v-if="showPlayerText"
        :video-index="videoIndex"
      ></player-text>
      <video
        @mousedown="handleClickDown"
        @mouseup="handleClickUp"
        @touchstart="handleClickDown"
        @touchend="handleClickUp"
        ref="videoPlayer"
        :class="[
          'vjs-vidjet-main',
          'video-js',
          { isFullScreen },
          { hideVideo },
        ]"
        preload="metadata"
        :style="[...videoStyle, borderRadiusStyle]"
        :poster="thumbnail"
        playsinline
        muted
      ></video>
      <PlayPause
        class="play-pause-container"
        v-show="showPlayPause && !isLoading"
        @click.native="togglePlayPause()"
        @touchend.native="togglePlayPause()"
        :is-small="isStory && !isPlayerOpened"
        :showPlayButton="showPlayButton"
        :showPauseButton="isUserActive"
        :hasNotClicked="hasNotClicked"
      />
      <!-- this should be moved to player interface -->
      <NavigationArrows
        v-if="showNavigationArrows"
        class="navigation-arrows-container"
        :video-index="videoIndex"
      />
      <div class="player-interface">
        <PlayerInterface
          v-if="showPlayerInterface"
          :showTitle="!showControls && !isMultiple && !isFullScreen"
          :showControls="showControls || player.paused()"
          :video-index="videoIndex"
          :is-current-video="isCurrentVideo"
          :is-cta-within-range="isCtaWithinRange"
          @notify-preview="$emit('notify-preview')"
          @pause-video="player.pause()"
        />
      </div>
      <SpinnerLoader
        v-show="isLoading && isCurrentVideo && isPlayerOpened"
        :isBigSpinner="false"
        background="transparent"
      />
    </div>
    <div v-if="showAtcFullScreen && isCtaWithinRange" class="atc-form">
      <atc-multiple-products
        :video-index="videoIndex"
        style="height: calc(var(--vh, 1vh) * 100)"
      ></atc-multiple-products>
    </div>
  </div>
</template>

<script>
import enums from "@/enums.js";
import api from "@/api/api.js";

import "video.js/dist/video-js.min.css";

import { changeIframe } from "@/utils/utils";

import timeControl from "@/utils/videojs-custom/time-control.js";
import progressBar from "@/utils/videojs-custom/progress-bar.js";
const { FormatType, CtaType } = enums;

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    PlayPause: () =>
      import("../components/player-components/PlayPauseControls.vue"),
    PlayerInterface: () => import("../components/PlayerInterface.vue"),
    NavigationArrows: () =>
      import("@/components/additional-components/NavigationArrows.vue"),
    AtcMultipleProducts: () => import("./add-to-cart/AtcMultipleProducts.vue"),
    SpinnerLoader: () => import("@/components/shared/SpinnerLoader.vue"),
    PlayerText: () => import("./player-components/PlayerText.vue"),
    CloseButton: () => import("./player-components/CloseButton.vue"),
  },
  data() {
    return {
      player: null,
      // video player data properties
      hasNotClicked: true,
      showPlayButton: true,
      viewDurationSent: 0,
      showControls: true,
      watchId: null,
      timerId: null,
      touchEvent: null,
      // enums
      FormatType,
      CtaType,
      startY: 0,
      isLoading: true,
      detachTooltip: null,
      revertProgressBar: null,
      // had to add this property since player.currentSrc is not reliable
      isTrimmedPlaying: false,
      changeIframe,
    };
  },

  props: {
    videoIndex: {
      type: Number,
      default: 0,
    },
    clickedIndex: {
      type: Number,
      default: 0,
    },
  },

  watch: {
    async isCurrentVideo(newValue) {
      // for preview when selecting one video
      if (!this.player && newValue) {
        this.initVideoJS();
      } else {
        if (newValue && this.player?.currentSrc() === this.placeholderUrl) {
          this.player.posterImage.show();
          this.isLoading = true;
          const sources = await this.setVideoSources({
            video: this.video,
            isCurrentVideo: this.isCurrentVideo,
          });

          this.player.src(sources);
          this.showPlayButton = false;
        }
        if (newValue && !this.hasNotClicked) {
          this.showPlayButton = false;
          this.player.muted(this.isMuted);
          this.vidjetPlay();
          // for format multiple keep behaviour of the player as if user hasn't clicked
        } else if (newValue && this.hasNotClicked) {
          this.player?.muted(this.isMuted);
          // if embed multiple no autoplay we want to keep the thumbnails
        } else if (!newValue && this.isPlayerOpened) {
          this.sendViewDuration();
          this.player.pause();
          !this.isCarousel && this.player.currentTime(0.1);
        }
      }
    },
    // TODO: is this needed?
    isMuted() {
      if (!this.isCurrentVideo && !this.isCarouselInline) {
        this.hasNotClicked = false;
      }
    },

    // all these watcher could be deleted if we were to simply reset player
    // for preview
    thumbnail(oldValue, newValue) {
      if (!this.player) {
        return;
      }
      if (newValue !== oldValue) {
        if (!this.thumbnail && this.player.poster_) {
          this.player.posterImage.hide();
          this.player.pause();
          this.player.currentTime(0.1);
        } else if ((this.isEmbed || this.isCarouselInline) && this.thumbnail) {
          this.showPosterImage();
        } else if (this.isCarousel && this.thumbnail && this.isFullScreen) {
          this.thumbnail !== this.player.poster_ &&
            this.isCarousel &&
            this.togglePlayer(false);
          this.player.posterImage.setSrc(this.thumbnail);
        }
      }
    },

    // for preview
    textOptionsPreview() {
      if (this.isInPreview) {
        this.player.pause();
        this.player.currentTime(0);
        this.player.hasStarted(false);
        this.hasNotClicked = true;
        this.player.posterImage.show();
      }
    },

    // added a prop on step 2 otherwise player get reset each time cta is modified
    "video.url": {
      async handler(oldValue, newValue) {
        const haveUrlsChanged = oldValue !== newValue;
        if (this.player) {
          if (
            this.player.currentSrc() !== this.placeholderUrl &&
            haveUrlsChanged &&
            this.isInPreview
          ) {
            const sources = await this.setVideoSources({
              video: this.video,
              isCurrentVideo: true,
            });
            this.player.src(sources);
            this.isCurrentVideo && this.vidjetPlay();
          }
        } else if (
          !this.player &&
          haveUrlsChanged &&
          this.isTrimmedVideoOnStart
        ) {
          this.playTrimmedVideo();
        }
      },
      deep: true,
    },

    async isTrimmedVideoOnStart(newValue) {
      if (newValue) {
        this.playTrimmedVideo();
      } else if (!this.player && !newValue) {
        const videoPlayer = this.$refs.videoPlayer;
        videoPlayer.pause();
        videoPlayer.currentTime = 0;
        videoPlayer.poster = this.thumbnail;
        videoPlayer.src = "";
      } else if (this.player && !newValue) {
        this.showPosterImage();
      }
      this.isTrimmedPlaying = newValue;
    },

    // for preview
    isMobile() {
      if (this.isCurrentVideo) {
        this.vidjetPlay();
      }
    },
    stopAllVideos(newVal) {
      if (newVal) {
        this.stopTrimmedVideo();
      }
    },
  },

  async mounted() {
    if (
      (this.isFullScreen && this.isPlayerOpened) ||
      (this.isBubble && (this.hasPlayerLoaded || !this.isTrimmedVideoOnStart))
    ) {
      this.hasNotClicked = false;
      this.initVideoJS();
    } else if (this.isTrimmedVideoOnStart && this.isCarousel) {
      this.isLoading = false;
      this.bindViewportDetection();
    } else if (
      (this.isPopup ||
        this.isBubble ||
        (this.isEmbed && this.isTrimmedVideoOnStart)) &&
      this.isCurrentVideo
    ) {
      this.isLoading = false;
      this.playTrimmedVideo();
    } else {
      this.isLoading = false;
      const videoPlayer = this.$refs.videoPlayer;
      videoPlayer.poster = this.thumbnail;
    }
    document.addEventListener("keydown", this.handleKeyDown);
  },

  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeyDown);

    if (this.player) {
      this.sendViewDuration();
      this.player.dispose();
    }
    // unbind listeners
    if (this.revertSmoothProgressBar) {
      this.revertSmoothProgressBar();
    }
    if (this.detachTooltip) {
      this.detachTooltip();
    }
  },

  computed: {
    ...mapGetters("playerCampaign", [
      "campaign",
      "format",
      "isEmbed",
      "isBubble",
      "isCarousel",
      "isPopup",
      "isFullScreen",
      "isCarouselFullScreen",
      "isStory",
      "isHorizontalSwipe",
      "isTrimmedVideoOnStart",
      "stopAllVideos",
    ]),
    ...mapGetters("videos", [
      "videos",
      "isMultiple",
      "isPortrait",
      "isSquare",
      "isFirstVideo",
      "isLastVideo",
      "getIsCurrentVideoByIndex",
      "getVideoByIndex",
      "textOptionsPreview",
    ]),
    ...mapGetters("player", [
      "isMobile",
      "isInPreview",
      "isIos",
      "isPlayerOpened",
      "hasPlayerLoaded",
      "isMuted",
    ]),
    ...mapGetters("playerSite", ["site"]),
    ...mapGetters("videoJS", ["placeholderUrl"]),

    video() {
      return this.getVideoByIndex(this.videoIndex);
    },

    thumbnail() {
      return this.video.thumbnail;
    },

    cta() {
      return this.video.cta ?? {};
    },

    textOptions() {
      return this.video.textOptions ?? {};
    },

    isCurrentVideo() {
      // when thumbnail are animated all videos in carousel have to play
      if (this.isCarouselInline) {
        return (
          this.getIsCurrentVideoByIndex(this.videoIndex) &&
          this.clickedIndex === this.videoIndex
        );
      } else {
        return this.getIsCurrentVideoByIndex(this.videoIndex);
      }
    },

    // controls
    showPlayPause() {
      // for one client that has specific play btn
      if (this.hidePlayThumb && !this.isPlayerOpened) {
        return false;
      }
      if (this.isCarouselInline && !this.player) {
        return true;
      } else if (this.isPlayerOpened) {
        // On trim we always want the control to show for the user to  know it's clickable
        return this.isTrimmedPlaying || !this.format.hidePlayPause;
      } else {
        return !this.hideStoryPlay ?? true;
      }
    },

    showPlayerInterface() {
      return this.player?.currentTime() > 0.1 && !this.isTrimmedPlaying;
    },

    showCloseButton() {
      if (
        this.isCarouselInline ||
        this.isEmbed ||
        (this.isCarousel && !this.isPlayerOpened)
      )
        return false;
      return true;
    },

    // so pause button doesn't show at the same time as play
    isUserActive() {
      return this.player?.userActive && this.player?.userActive();
    },

    isCarouselInline() {
      return this.isCarousel && !this.isFullScreen;
    },

    hideStoryPlay() {
      return !this.isPlayerOpened && this.format?.hideStoryPlay;
    },
    hideVideo() {
      return this.player && this.isCarousel && this.player.paused();
    },

    showPlayerText() {
      return (
        (this.isEmbed || (this.isCarousel && !this.isStory)) &&
        this.hasNotClicked &&
        !this.isLoading &&
        Object.keys(this.textOptions).length > 0
      );
    },

    showAtcFullScreen() {
      return (
        this.cta.ctaType === CtaType.product &&
        this.isFullScreen &&
        !this.isMobile &&
        !this.isHorizontalSwipe &&
        this.cta.products &&
        this.cta.products[0] &&
        this.cta.products[0]._id !== "" &&
        this.isPlayerOpened
      );
    },

    buttonColors() {
      return {
        buttonColor: this.cta?.buttonColor,
        textColor: this.cta?.textColor,
      };
    },

    canSendViewDuration() {
      if (this.isFullScreen) {
        return this.player.currentTime() > 3 && this.isPlayerOpened;
      } else {
        return !this.hasNotClicked && this.isPlayerOpened;
      }
    },

    //We want to display the navigation on all multiple formats except the carousel inline.
    showNavigationArrows() {
      return (
        !this.hideArrows &&
        this.isMultiple &&
        this.isPlayerOpened &&
        !(this.isCarousel && !this.isFullScreen)
      );
    },

    hideControlBar() {
      // don't show control bar in preview if videojs is loaded and trim is playing
      return (this.format.hideControlBar || this.isTrimmedPlaying) ?? false;
    },

    hideArrows() {
      return this.format.hideFeedArrow ?? false;
    },

    hidePlayThumb() {
      return this.format?.hidePlayThumb ?? false;
    },
    isCtaWithinRange() {
      if (!Object.keys(this.cta?.timeframe ?? {}).length === 0) {
        return true;
      } else if (
        this.cta?.timeframe?.fromSec &&
        this.player?.currentTime() < this.cta?.timeframe.fromSec
      ) {
        return false;
      } else if (
        this.cta?.timeframe?.toSec &&
        this.player?.currentTime() > this.cta?.timeframe.toSec
      ) {
        return false;
      }
      return true;
    },
    videoStyle() {
      const styles = [];

      if (this.isCarousel && !(this.isFullScreen && this.isPlayerOpened)) {
        styles.push({ "object-fit": "cover" });
      }
      if (!this.isFullScreen && this.isBubble && !this.isInPreview) {
        // on multiple we force videos to be 16:9
        const aspectRatio = this.isMultiple
          ? 1.77777
          : this.video.height / this.video.width;
        styles.push({ height: `calc(var(--vw, 1vw) * 100 * ${aspectRatio})` });
      }

      return styles;
    },
    borderRadiusStyle() {
      if (
        (this.isCarousel && this.isFullScreen && this.isPlayerOpened) ||
        (this.isBubble && this.isFullScreen && this.isPlayerOpened)
      ) {
        return {};
      }

      if (Number.isInteger(this.format.cornerShape)) {
        let borderRadius = this.format.cornerShape.toString() + "px";
        return { borderRadius: borderRadius };
      } else {
        return { borderRadius: "10px" };
      }
    },
  },

  methods: {
    ...mapMutations({
      togglePlayer: "player/TOGGLE_PLAYER",
      toggleMute: "player/TOGGLE_MUTE",
      setHasPlayerLoaded: "player/SET_HAS_PLAYER_LOADED",
      setCurrentIndex: "videos/SET_CURRENT_INDEX",
      toggleAtc: "player/TOGGLE_ATC",
    }),
    ...mapActions("videoJS", ["createVideoOptions", "setVideoSources"]),
    ...mapActions({
      sendEvent: "analytics/sendEvent",
    }),
    async initVideoJS() {
      if (this.$refs.videoPlayer) {
        this.isLoading = true;
        // Create video js options
        const videoJsOptions = await this.createVideoOptions({
          format: this.format,
          video: this.video,
          isCurrentVideo: this.isCurrentVideo,
        });
        if (!this.player) {
          const { default: videojs } = await import("video.js");
          this.player = videojs(
            this.$refs.videoPlayer,
            videoJsOptions,
            function onPlayerReady() {
              this.currentTime(0.1);
              // make user inactive so controls are hidden on load
              this.userActive(false);
            }
          );
          // on initialisation we mute on mobile so it autoplay, ios is very reluctant to autoplaying videos
          this.hasNotClicked = false;
          this.player.muted(this.isIos);
          this.player.loop(false);
          this.toggleMute(this.isIos);
          if (process.env.VUE_APP_PLAYER_URL === "https://player.vidjet.io") {
            videojs.log.level("off");
          }
        }

        this.isTrimmedPlaying = false;
        this.vidjetPlay();
        // init time tooltip with detachTooltip to unbind listeners
        this.detachTooltip = timeControl.attachTooltip(
          this.player,
          this.isMobile
        );
        // init smooth progress bar animation with revertProgressBar to unbind listeners
        this.revertProgressBar = progressBar.smoothDragProgressBar(this.player);

        // overwrite of the default behaviour of the player
        if (!this.isMobile) {
          this.player.player_.handleTechClick_ = () => {
            this.handleClickOnPlayer();
          };
        } else {
          // do the same for mobile
          this.player.player_.handleTechTouchEnd_ = () => {
            this.handleClickOnPlayer();
          };
        }

        this.initializePlayerEvents();

        if (this.isPopup) {
          this.togglePlayer(true);
          this.hasNotClicked = false;
        }

        this.watchId = this.$uuid.v1();
      }
    },

    // avoid error play was interrupted when we try to play the video before it is loaded
    // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
    vidjetPlay(forcePlay = false) {
      if (
        forcePlay ||
        (this.isCurrentVideo && this.player.paused() && this.isPlayerOpened)
      ) {
        // Function to add timeout to the promise
        const addTimeoutToPromise = (promise, timeoutDuration) => {
          return Promise.race([
            promise,
            new Promise((_, reject) =>
              setTimeout(
                () => reject(new Error("Promise timed out")),
                timeoutDuration
              )
            ),
          ]);
        };
        const playPromise = this.player.play();
        const timeoutDuration = 1000;
        //We add timeout to the promise because something it's getting stuck in pending without resolving
        const playPromiseWithTimeout = addTimeoutToPromise(
          playPromise,
          timeoutDuration
        );

        if (playPromiseWithTimeout !== undefined) {
          playPromiseWithTimeout
            .then(() => {
              // Automatic playback started!
              // Show playing UI.
            })
            .catch((error) => {
              if (error.name === "NotAllowedError") {
                // Handle the case when autoplay is blocked
                this.isLoading = false;
                this.showPlayButton = true;
                this.showPosterImage();
              } else {
                // it happens the play promise get stuck, so we force the play again
                this.player.play();
              }
            });
        }
      } else if (!this.isCurrentVideo && !this.isTrimmedPlaying) {
        this.player.pause();
      }
    },

    sendViewDurationOnLeave() {
      window.addEventListener("beforeunload", () => {
        this.sendViewDuration();
      });
    },

    initializePlayerEvents() {
      this.player.on("loadedmetadata", this.onLoadedMetadata.bind(this));
      this.player.on("error", this.onError.bind(this));
      this.player.on("ended", this.onEnded.bind(this));
      this.player.on("pause", this.onPause.bind(this));
      this.player.on("playing", this.onPlay.bind(this));
      this.player.on("touchend", this.onTouchEnd.bind(this));
      this.player.controlBar.muteToggle.on(
        "click",
        this.onMuteToggleClick.bind(this)
      );
      this.player.controlBar.muteToggle.on(
        "touchend",
        this.onMuteToggleClick.bind(this)
      );
    },

    showPosterImage() {
      this.player.posterImage.setSrc(this.thumbnail);
      this.player.pause();
      this.player.currentTime(0);
      this.player.hasStarted(false);
      this.hasNotClicked = true;
      this.player.posterImage.show();
    },

    visibilityChange(e, hidden) {
      if (!this.player) return;
      if (hidden && this.isMobile) {
        this.player.pause();
      } else if (hidden) {
        this.sendViewDuration();
      }
    },

    initializePlayer() {
      //Toggle sound on for one client
      const sitesToUnmute = [
        "0854fe18-fab3-41e1-bdaf-a757e9e66b5a",
        "f1eea2b0-b38c-4ba2-ac13-b7746ab3a9d3",
        "43430b1e-da9c-4b58-beac-ac0036211f01",
        "8ecc8e49-14bc-40ab-bf11-dde602c64c67",
        "962da816-597b-4c10-8ed5-bb51c9f777a0",
        "25b1525b-9b2e-4251-9f8a-74290374ecb9",
        "94a57fdb-4b07-4dec-9556-d6421a191ee7",
        "52cead80-e7ef-4d54-8e4b-51ba434efc26",
        "35448a7b-3dbc-4831-897d-ac0a728aa942",
        "23e05429-02ac-418a-a6b2-ed2610730d49",
        "8382aace-337e-426b-ae86-fd45ab7f1a96",
      ];

      if (sitesToUnmute.includes(this.campaign.siteId)) {
        this.player.muted(false);
      }

      if (!this.isEmbed || this.isInPreview) {
        this.setHasPlayerLoaded(true);
      }
      if (this.isCarousel && this.isCurrentVideo && this.isPlayerOpened) {
        this.showPlayButton = false;

        this.vidjetPlay();
      } else if (!this.isEmbed) {
        !this.isMultiple && (this.hasNotClicked = false);
      }
    },

    sendViewDuration() {
      if (
        this.player?.currentTime() > this.viewDurationSent + 0.5 &&
        this.canSendViewDuration &&
        !this.isInPreview
      ) {
        this.sendEvent(
          {
            type: "viewDuration",
            watchId: this.watchId,
            videoViewTime: this.player.currentTime(),
            videoTotalDuration: this.player.duration(),
            percentageSeen:
              (this.player.currentTime() / this.player.duration()) * 100,
          },
          this.isEmbed
        );
        this.viewDurationSent = this.player.currentTime();
      }
    },

    togglePlayPause() {
      if (!this.player) {
        this.initVideoJS();
        this.hasNotClicked = false;
        if (this.isCarouselInline) {
          this.$store.dispatch("playerCampaign/stopAllVideos", true);
        }
        return;
      } else if (this.player.ended()) {
        this.player.currentTime(0);
        this.vidjetPlay();
      } else {
        this.player.paused() ? this.vidjetPlay() : this.player.pause();
      }
      this.showTitleAndHideControlsOnLeave();
      this.hasNotClicked = false;
    },

    unmuteVideo() {
      this.hasNotClicked = false;
      this.player.muted(false);
      this.isMultiple && this.toggleMute(false);
    },

    // so controls hide directly when leaving the player
    hideTitleAndShowControlsOnHover() {
      if (this.player) {
        this.player && this.player.userActive(true);
        this.showControls = true;
        // if on mobile hide controls after 2 seconds
        if (this.isMobile) {
          clearTimeout(this.timerId);
          this.timerId = setTimeout(() => {
            this.player && this.player.userActive(false);
          }, 2000);
        }
      }
    },

    showTitleAndHideControlsOnLeave() {
      if (this.player) {
        this.player && this.player.userActive(false);
        this.showControls = false;
      }
    },

    // for embed
    bindViewportDetection() {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.9,
      };
      let video = this.$refs.playerContainer;
      let hasDisplayEventBeenSent = false;
      let observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            this.playTrimmedVideo();
            observer.unobserve(entry.target);
          }
          if (!hasDisplayEventBeenSent && !this.isInPreview) {
            this.setHasPlayerLoaded(true);
            this.isCurrentVideo &&
              this.sendEvent({
                type: "display",
              });
            hasDisplayEventBeenSent = true;
          }
        }),
          options;
      });
      observer.observe(video);
    },
    //  start playing video when the video is in the view port
    playTrimmedVideo() {
      // since every videos don't have a trimmed url We have to resort to this
      this.isTrimmedPlaying = true;
      if (this.player) {
        this.player.muted(true);
        this.player.loop(true);
        this.player.src([
          {
            src: this.video.trimmedUrl ? this.video.trimmedUrl : this.video.url,
            type: "video/mp4",
          },
        ]);
        this.vidjetPlay(true);
      } else {
        const player = this.$refs.videoPlayer;

        player.src = this.video.trimmedUrl || this.video.url;
        this.removeAudioFromTrimmedVideo(player);
        player.muted = true;
        player.loop = true;
        player.play().catch(() => {
          player.pause();
          player.currentTime = 0;
          player.poster = this.video.thumbnail;
          this.showPlayButton = true;
          player.src = "";
        });
        if (!this.video.trimmedUrl) {
          player.addEventListener("timeupdate", this.loopFourSeconds);
        }
      }
    },

    stopTrimmedVideo() {
      const videoPlayer = this.$refs.videoPlayer;
      if (videoPlayer) {
        videoPlayer.pause();
        videoPlayer.currentTime = 0;
      }
      this.isTrimmedPlaying = false;
    },

    loopFourSeconds() {
      const player = this.$refs.videoPlayer;
      if (player.currentTime >= 4) {
        player.currentTime = 0; // Looping back to the start
      }
      if (this.player) {
        // this.player.src({ src: "", type: "video/mp4" });
        // this.player.src({ src: this.video.url, type: "video/mp4" });
        player.removeEventListener("timeupdate", this.loopFourSeconds);
      }
    },

    // otherwise safari blocks the autoplay even with the muted attribute
    removeAudioFromTrimmedVideo(player) {
      const mediaStream = player.srcObject;

      // Check if a MediaStream exists
      if (mediaStream) {
        // Get audio tracks from the MediaStream
        const audioTracks = mediaStream.getAudioTracks();

        // Remove each audio track
        audioTracks.forEach((track) => {
          track.stop(); // Stop the track
          mediaStream.removeTrack(track); // Remove the track from the stream
        });

        // Reassign the modified stream to the video element
        player.srcObject = mediaStream;
      }
    },

    // method to determine if it's a click or a drag
    handleClickDown(event) {
      if (!this.player && !this.isCarouselInline) {
        this.initVideoJS();
      }
      this.startY = this.isMobile
        ? event?.touches && event?.touches[0].pageY
        : event.pageY;
      this.startX = this.isMobile
        ? event?.touches && event?.touches[0].pageX
        : event.pageX;
    },

    // TODO we should not use this logic
    handleClickUp(event) {
      const shouldSkipEventHandling = (targetClassName) => {
        const classNamesToSkip = [
          "product-image inline-product-image",
          "atc-button-container atc-button-container-mobile",
          "atc-button-container atc-button-container-mobile dragging",
          "call-to-action call-to-action-atc",
          "button-with-image is-inline",
          "SVGAnimatedString {baseVal: '', animVal: ''}",
          "name",
          "product-information",
          "product-image",
        ];
        return classNamesToSkip.includes(targetClassName);
      };
      if (shouldSkipEventHandling(event?.target?.className)) return;
      const delta = 10;
      const endY = this.isMobile
        ? event?.changedTouches && event?.changedTouches[0].pageY
        : event.pageY;
      const endX = this.isMobile
        ? event?.changedTouches && event?.changedTouches[0].pageX
        : event.pageX;
      // if the user has scrolled more than delta pixels
      if (
        Math.abs(endY - this.startY) > delta ||
        Math.abs(endX - this.startX) > delta
      ) {
        this.player.pause();
      } else {
        this.hasNotClicked = false;
        if (this.isCarouselInline) {
          this.$store.dispatch("playerCampaign/stopAllVideos", true);
        }
      }
      if (!this.player) {
        this.isLoading = true;
      }
    },

    handleClickOnPlayer() {
      // On mobile we want to show the controls on click
      // Pause is only valid if
      if (this.isMobile && !this.showControls) return;
      this.player.paused() ? this.vidjetPlay() : this.player.pause();
    },

    // // player events
    onLoadedMetadata() {
      this.initializePlayer();
    },

    onError() {
      if (!this.isInPreview) {
        const dataToSend = {
          videoJsErrorLog: this.player.error(),
          formatId: this.$route.params.siteId,
          browser: window.navigator.userAgent,
          deviceType: this.$route.query.device,
          date: this.$route.query.date,
        };
        api.sendError(dataToSend);
        this.handleErrorBasedOnSrc();
      }
    },

    // Determine the action based on source type
    handleErrorBasedOnSrc() {
      if (this.player.currentSrc().includes("m3u8")) {
        this.player.error(null);
        // TODO videoOptions no longer exists
        this.player.src([this.videoOptions.sources[1]]);
      } else {
        changeIframe("destroyIframe");
      }
    },

    onEnded() {
      this.player.userActive(true);
      this.sendViewDuration();
      this.handleVideoEndBasedOnFormat();
    },

    handleVideoEndBasedOnFormat() {
      if (this.format.autoLoop && !this.format.autoScroll) {
        this.player.currentTime(0.1);
        this.vidjetPlay();
      }
      if (this.format.autoScroll) {
        this.setCurrentIndex(this.videoIndex + 1);
      }
    },

    onPause() {
      this.sendViewDuration();
      this.showPlayButton = true;
      this.showControls = true;
      this.player.userActive(true);
    },

    onPlay() {
      this.isLoading = false;
      if (!this.isInPreview) {
        this.sendViewDurationOnLeave();
      }

      if (!this.isCurrentVideo && !this.isTrimmedPlaying) {
        this.player.pause();
      }

      this.showPlayButton = this.isTrimmedPlaying;
    },

    onTouchEnd() {
      if (!this.player.ended()) {
        this.player.userActive()
          ? this.hideTitleAndShowControlsOnHover()
          : this.showTitleAndHideControlsOnLeave();
      }
    },

    // Handler for mute toggle 'click'
    onMuteToggleClick() {
      this.toggleMute(this.player.muted());
    },

    handleKeyDown(event) {
      if (
        this.isFullScreen &&
        !this.isInPreview &&
        (event.key === " " || event.key === "Spacebar")
      ) {
        this.togglePlayPause();
        event.preventDefault();
      }
      if (
        event.key === "ArrowDown" &&
        !this.isLastVideo &&
        this.isCurrentVideo
      ) {
        this.setCurrentIndex(this.videoIndex + 1);
      }
      if (
        event.key === "ArrowUp" &&
        !this.isFirstVideo &&
        this.isCurrentVideo
      ) {
        this.setCurrentIndex(this.videoIndex - 1);
      }
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
@import "video.js/src/css/vjs.scss";

.player-container {
  .video-js .vjs-progress-control:hover .vjs-mouse-display,
  .video-js .vjs-time-tooltip,
  .video-js .vjs-time-control,
  .vjs-vidjet-main .vjs-big-play-button {
    display: none !important;
  }

  .vjs-vidjet-main.video-js .vjs-tech {
    border-radius: $player-rounded-corners;
    z-index: 5;
  }

  .vjs-vidjet-main.video-js {
    border-radius: $player-rounded-corners;
    box-shadow: 2px 5px 2px rgba(0, 0, 0, 0.2);
    background: $background-control-bar;
    z-index: 1;
  }

  .vjs-vidjet-main.video-js.bubbleRight,
  .vjs-vidjet-main.video-js.isPopup {
    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.3);
  }

  /* This appears to be needed because the control bar (or its contents) will otherwise break out of the player. */
  .vjs-vidjet-main.video-js .vjs-control-bar {
    z-index: 5;
    background: transparent;
    border-bottom-left-radius: $player-rounded-corners;
    border-bottom-right-radius: $player-rounded-corners;
    transition: none !important;
  }
  /* Fix the control bar due to us resetting the line-height on the video-js 
    touch-action -> https://developers.google.com/web/updates/2017/01/scrolling-intervention#breakage_and_guidance*/

  .vjs-control-bar {
    touch-action: none;
    line-height: 1;
  }
  // for thumbnail
  .vjs-poster {
    border-radius: 10px;
    background-size: cover;
    background-color: black;
    z-index: 10;
    border-radius: $player-rounded-corners;
    touch-action: none;
    pointer-events: none;
    img {
      object-fit: cover;
    }
  }

  // for portrait videos, fix from
  // https://stackoverflow.com/questions/46747320/limit-the-height-in-videojs-in-fluid-mode

  .video-js.vjs-fluid,
  .video-js.vjs-16-9,
  .video-js.vjs-4-3,
  video.video-js,
  video.vjs-tech {
    position: relative !important;
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding-top: 0 !important;
    line-height: 0;
    // Prevents background from overflowing the player
    vertical-align: bottom;
    overflow: hidden;
    display: flex;
    justify-content: center;
    background: black;
  }

  .isDesktop {
    .video-js.vjs-fluid,
    .video-js.vjs-16-9,
    .video-js.vjs-4-3,
    video.video-js,
    video.vjs-tech {
      max-height: inherit;
      // Makes the watermark be behind the player
      z-index: 1;
    }
  }

  .vjs-subs-caps-button {
    display: none;
  }

  .hideControlBar {
    .vjs-progress-control {
      background: transparent !important;
    }

    .vjs-mute-control {
      position: absolute;
      right: 0;
    }
    .vjs-fullscreen-control {
      position: absolute;
      right: 35px;
    }
  }
  /* Override the default videojs progress bar styles */
  .video-js .vjs-progress-control {
    position: absolute;
    bottom: 0;
    height: 5px;
    width: 100%;
    background-color: transparent;
  }

  .video-js .vjs-control-bar {
    height: 20px;
    &:hover {
      .vjs-progress-control,
      .vjs-progress-holder {
        height: 10px;
      }
    }
  }

  .video-js .vjs-progress-holder {
    height: 5px;
    background-color: transparent;
    margin: 0;
  }

  .vjs-progress-holder:hover .vjs-play-progress,
  .vjs-progress-holder:hover .vjs-load-progress {
    transform: scaleY(1.5); /* increase the height on hover */
  }

  .vjs-control-text {
    display: none;
  }

  .video-js .vjs-play-progress:before {
    display: block;
    height: 100%;
    background-color: white;
  }

  /* Custom styles for tooltip time control */
  .vjs-progress-holder:hover .vidjet-time-tooltip {
    opacity: 1;
  }
  .vidjet-time-tooltip {
    opacity: 1;
    position: absolute;
    bottom: 100%;
    left: 0;
    white-space: nowrap;
    pointer-events: none;
    transition: opacity 0.2s;
    transform: translateX(-50%);
    padding: 5px 8px;
    border-radius: 4px;
    background-color: #000;
    color: #fff;
    font-size: 12px;
    z-index: 25;
  }

  /* Custom styles for the play button */
  .video-js .vjs-play-control {
    position: absolute;
    bottom: 25px;
    left: 16px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $transparent-black;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
      transition: $button-transition;
    }
  }
  .video-js .vjs-play-control span {
    width: 100%;
    height: 100%;
  }

  /* Center the play icon */
  .video-js .vjs-icon-placeholder:before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* Custom styles for the volume control button */
  .video-js .vjs-mute-control {
    position: absolute;
    bottom: 25px;
    right: 16px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $transparent-black;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
      transition: $button-transition;
    }
  }
  .video-js .vjs-fullscreen-control {
    position: absolute;
    bottom: 65px;
    left: 16px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $transparent-black;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
      transition: $button-transition;
    }
  }

  .video-js .vjs-mute-control span {
    width: 100%;
    height: 100%;
  }

  /* Center the mute icon */
  .video-js .vjs-mute-control .vjs-icon-placeholder:before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* Custom styles for the volume slider */
  .video-js .vjs-volume-level:before {
    background-color: white;
  }

  /* Custom styles for the progress bar */
  .video-js .vjs-play-progress {
    background-color: white;
    transition: all 0.2s ease-in-out;
  }

  .video-js .vjs-play-progress::before {
    display: none;
  }
}

.isFullScreen {
  .video-js .vjs-play-control,
  .video-js .vjs-mute-control,
  .video-js .vjs-fullscreen-control {
    transform: scale(1.3);
  }
}

.isFullScreen.isHorizontalSwipe {
  .video-js .vjs-play-control,
  .video-js .vjs-mute-control,
  .video-js .vjs-fullscreen-control {
    transform: scale(1);
    bottom: 17px;
    &:hover {
      transform: scale(1.1);
      transition: $button-transition;
    }
  }
}

.isCarousel .player-container .video-js.vjs-fluid,
.video-js.vjs-fluid,
.video-js.vjs-16-9,
.video-js.vjs-4-3,
video.video-js,
video.vjs-tech {
  height: 100%;
}

.isFullScreen .player-container,
.isMultiple .player-container {
  .vjs-vidjet-main.video-js {
    box-shadow: none;
  }
  .video-js.vjs-fluid,
  .video-js.vjs-16-9,
  .video-js.vjs-4-3,
  video.video-js,
  video.vjs-tech {
    background: black !important;
    height: 100%;
    max-height: 100vh;
    border-radius: 0px;
    overflow: hidden;
  }
}
.isPopup.player-container {
  .video-js.vjs-fluid,
  .video-js.vjs-16-9,
  .video-js.vjs-4-3,
  video.video-js,
  video.vjs-tech {
    border-radius: 10px;
  }
}

.hideVideo .vjs-vidjet-main.video-js .vjs-tech {
  display: none;
}
.isMultiple .player-container .vjs-vidjet-main.video-js .vjs-tech {
  height: 100%;
}
.isEmbed .player-container .vjs-vidjet-main.video-js .vjs-tech {
  height: calc(var(--vh, 1vh) * 100);
}

.isFullScreen,
.isEmbed {
  .video-js.vjs-fluid,
  .video-js.vjs-16-9,
  .video-js.vjs-4-3,
  video.video-js,
  video.vjs-tech {
    border-radius: 0px;
  }
}

.isCarousel {
  .video-js.vjs-fluid,
  .video-js.vjs-16-9,
  .video-js.vjs-4-3,
  video.video-js,
  video.vjs-tech {
    border-radius: 10px;
  }
}

.isPortrait .video-js .vjs-fullscreen-control {
  right: 16px;
  left: unset;
}

.main-wrapper.isFullScreen.isHorizontalSwipe .player-container {
  .video-js.vjs-fluid,
  .video-js.vjs-16-9,
  .video-js.vjs-4-3,
  video.video-js,
  video.vjs-tech {
    border-radius: 10px;
  }
  // for popup
  height: calc(var(--vh, 1vh) * 75);
  max-width: calc(var(--vh, 1vh) * (75 * 0.5625));
  border-radius: 10px;
}
</style>

<style scoped lang="scss">
$player-rounded-corners: 10px;
// transform scale to avoid white border created by blur
.player-container.isLoading {
  background: black;
  border-radius: 10px;
  .vjs-vidjet-main.video-js {
    width: 98%;
    filter: blur(3px);
    margin: 0 auto;
  }
}

.player-atc-wrapper {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.isPopup .player-atc-wrapper,
.isFullScreen.isHorizontalSwipe .player-atc-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  margin: 0;
}

.player-container {
  position: relative;
  height: fit-content;
  transition: opacity 0.5s;
  margin: 0 auto;
  width: 100%;
}

.main-wrapper.isMultiple.isBubble,
.isFullScreen .player-container {
  height: 100%;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}

.player-atc-wrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  height: 100%;
  transition: width 0.5s ease-in-out;
  background: "black";
}

.isPopup .player-atc-wrapper {
  place-items: center;
  background: transparent;
}

.isCarousel .player-atc-wrapper {
  place-items: center;
}

.isCarousel .player-container {
  height: 100%;
  // no need for blur on carousel
  &.isLoading .vjs-vidjet-main.video-js {
    transform: none;
  }
}

.isCarousel .vidjet-carousel-items.is-story .player-container {
  height: 80px;
}

.isBubble.isMultiple {
  .player-container {
    height: 100%;
  }
  .player-atc-wrapper {
    height: 100%;
    width: 100%;
    margin: 0;
  }
}
.isBubble {
  .player-atc-wrapper {
    margin: 0;
    height: auto;
    width: auto;
  }
}

.atc-form {
  width: 350px;
  overflow: auto;
  background: white;
}

.atc-form::-webkit-scrollbar {
  width: 0;
}
.atc-form::-webkit-scrollbar-track {
  background-color: transparent;
}
.atc-form::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.unmute-button {
  background: transparent;
  position: absolute;
  z-index: $index-mid-position;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.play-pause-container {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
}
.navigation-arrows-container {
  position: absolute;
  z-index: $index-icon-position;
  top: 50%;
  right: 16px;
  transform: translate(0%, -50%);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.player-interface {
  height: 100%;
  position: absolute;
  width: 100%;
  bottom: 0px;
}

.unmute-icon {
  height: 35px;
}

// popup sizing
.isPopup {
  .player-container {
    border-radius: $player-rounded-corners;
    max-width: 45%;
    width: fit-content;
    height: fit-content;

    @include media(">tablet", "<=desktop") {
      max-width: 60%;
    }

    @include media(">phone", "<=tablet") {
      max-width: 90%;
    }

    @include media("<=phone") {
      max-width: 90%;
    }
  }

  .player-container.isPortrait {
    max-width: 20%;

    &.isMobile {
      max-width: 60%;
      max-height: 500px;
    }

    @include media(">tablet", "<=desktop") {
      max-width: 35%;
    }

    @include media(">phone", "<=tablet") {
      max-width: 50%;
      min-width: 250px;
    }

    @include media("<=phone") {
      max-width: 70%;
    }
  }

  .player-container.isSquare {
    max-width: 30%;

    &.isMobile {
      max-width: 70%;
      max-height: 500px;
    }

    @include media(">tablet", "<=desktop") {
      max-width: 45%;
    }

    @include media(">phone", "<=tablet") {
      max-width: 90%;
      min-width: 250px;
    }

    @include media("<=phone") {
      max-width: 90%;
    }
  }
}
</style>
