<template>
  <div class="arrow-icon-settings">
    <TextWithBtnToggle
      :label-text="$t('create.step3.hideCarouselArrows')"
      input-for="hideCarouselArrows"
      :preset-value="formatObject.hideCarouselArrows"
      @toggle-button="toggleCarouselArrows"
    />
    <div class="arrows-icon-container">
      <h3
        class="title"
        :class="{ 'disable-title': formatObject.hideCarouselArrows }"
      >
        {{ $t("create.step3.buttonSelector.buttonStyle") }}
        <div class="btn-group">
          <button class="navigation-arrow">
            <BtnArrow class="left" @click="scrollLeft" />
          </button>
          <button class="navigation-arrow">
            <BtnArrow class="right" @click="scrollRight" />
          </button>
        </div>
      </h3>
      <div
        class="icon-type-boxes arrow-icon-box"
        :class="{
          'bulk-icon-type-boxes': isBulk,
          disabled: formatObject.hideCarouselArrows,
        }"
      >
        <button
          v-for="icon in icons"
          :key="icon.name"
          class="icon-type"
          :class="{
            selected: iconType === icon.name,
            'not-allowed': formatObject.hideCarouselArrows,
          }"
          :disabled="formatObject.hideCarouselArrows"
          @click="
            onArrowSettingsChange({
              inputFor: 'iconType',
              value: icon.name,
            })
          "
        >
          <component :is="icon.icon"></component>
          <h3>{{ icon.label | capitalize }}</h3>
        </button>
      </div>
    </div>
    <div class="icon-customization">
      <div
        class="input-color"
        :class="{ disabled: formatObject.hideCarouselArrows }"
      >
        <h3 class="box-title">Icon color</h3>
        <button
          class="value-color"
          :class="{ 'not-allowed': formatObject.hideCarouselArrows }"
          :style="`background-color:${
            formatObject?.arrowSettings?.color || 'white'
          }`"
          :disabled="formatObject.hideCarouselArrows"
          @click="showPicker(`color`)"
        />
      </div>
      <div
        class="input-color"
        :class="{
          disabled: isMinimal || formatObject.hideCarouselArrows,
        }"
      >
        <h3 class="box-title">
          {{ $t("create.step3.buttonSelector.backgroundColor") }}
        </h3>
        <div class="buttons-color">
          <button
            class="value-color"
            :class="{ 'not-allowed': formatObject.hideCarouselArrows }"
            :disabled="isMinimal || formatObject.hideCarouselArrows"
            :style="`background-color:${
              formatObject.arrowSettings?.secondaryColor || 'grey'
            }`"
            @click="showPicker(`secondaryColor`)"
          />
        </div>
      </div>
      <div
        v-if="displayPicker"
        key="color-picker"
        v-click-outside="hidePicker"
        class="vidjet-color-picker"
      >
        <colorpicker
          :value="
            formatObject?.arrowSettings &&
            formatObject?.arrowSettings[pickerType]
          "
          @input="onPickerChange($event, pickerType)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Chrome } from "vue-color";
import BtnArrow from "@/assets/svg/new-carousel-arrow.svg?inline";
import TextWithBtnToggle from "./TextWithBtnToggle.vue";

export default {
  name: "ArrowSettings",

  components: {
    colorpicker: Chrome,
    BtnArrow,
    TextWithBtnToggle,
  },

  props: {
    campaignFormatOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isBulk: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      icons: [
        {
          name: "corner1",
          label: "corner 1",
          icon: require("@/assets/svg/carousel-arrows/corner1.svg?inline"),
        },
        {
          name: "corner2",
          label: "corner 2",
          icon: require("@/assets/svg/carousel-arrows/corner2.svg?inline"),
        },
        {
          name: "corner3",
          label: "corner 3",
          icon: require("@/assets/svg/carousel-arrows/corner3.svg?inline"),
        },
        {
          name: "line1",
          label: "line 1",
          icon: require("@/assets/svg/carousel-arrows/line1.svg?inline"),
        },
        {
          name: "line2",
          label: "line 2",
          icon: require("@/assets/svg/carousel-arrows/line2.svg?inline"),
        },
        {
          name: "line3",
          label: "line 3",
          icon: require("@/assets/svg/carousel-arrows/line3.svg?inline"),
        },
        {
          name: "bold1",
          label: "bold 1",
          icon: require("@/assets/svg/carousel-arrows/bold1.svg?inline"),
        },
        {
          name: "bold2",
          label: "bold 2",
          icon: require("@/assets/svg/carousel-arrows/bold2.svg?inline"),
        },
        {
          name: "bold3",
          label: "bold 3",
          icon: require("@/assets/svg/carousel-arrows/bold3.svg?inline"),
        },
      ],
      displayPicker: false,
      pickerType: "",
    };
  },

  computed: {
    formatObject() {
      return this.campaignFormatOptions;
    },
    iconType() {
      return this.formatObject.arrowSettings?.iconType;
    },
    isMinimal() {
      return (
        this.iconType === "corner1" ||
        this.iconType === "line1" ||
        this.iconType === "bold1"
      );
    },
  },
  methods: {
    onPickerChange(e, name) {
      this.$emit("change-format-object", {
        inputFor: "arrowSettings",
        value: { ...this.formatObject.arrowSettings, [name]: e.hex8 },
      });
    },
    hidePicker() {
      this.displayPicker = false;
    },
    showPicker(type) {
      this.pickerType = type;
      this.displayPicker = true;
      this.p;
    },
    onArrowSettingsChange(e) {
      this.$emit("change-format-object", {
        inputFor: "arrowSettings",
        value: { ...this.formatObject.arrowSettings, [e.inputFor]: e.value },
      });
    },
    scrollLeft() {
      let content = document.querySelector(".arrow-icon-box");
      content.scrollLeft -= 76;
    },
    scrollRight() {
      let content = document.querySelector(".arrow-icon-box");
      content.scrollLeft += 76;
    },
    toggleCarouselArrows({ inputFor, value }) {
      if (inputFor === "hideCarouselArrows") {
        this.$emit("change-format-object", {
          inputFor: "hideCarouselArrows",
          value,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.value-color {
  margin-right: 0;
}
.arrows-icon-container:hover {
  background-color: $settings-hover;
}
.arrow-icon-settings {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .title {
    display: flex;
    justify-content: space-between;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    margin-bottom: 12px;
    .right {
      transform: rotate(180deg);
    }
  }
  .box-title {
    font-weight: 400;
  }
  .icon-type-boxes {
    max-width: 370px;
    min-width: 270px;
    overflow-x: hidden;
  }
  .bulk-icon-type-boxes {
    min-width: unset;
    max-width: 280px;
    gap: 8px;
    @media (max-width: 1350px) {
      max-width: 250px;
    }
    @media (max-width: 1250px) {
      max-width: 230px;
    }
  }
  .navigation-arrow {
    background: transparent;
    padding: 0;
    &:hover {
      rect {
        fill: $dark-purple;
      }
    }
  }
}
.disable-title {
  color: $light-grey;
}

.not-allowed {
  cursor: not-allowed;
}
</style>
