<template>
  <div class="credit-card-inputs">
    <li class="form-input">
      <label class="registration-label" for="card-number">{{ $t("billing.creditCardInformation.modal.cardNumber") }}</label>
      <input
        id="card-number"
        v-cardformat:formatCardNumber
        required
        :disabled="disabled"
        :value="cardNumber"
        @input="updateCardNumber"
      />
    </li>
    <li class="flex-inputs">
      <div class="form-input date-and-cvc">
        <label class="registration-label align" for="exp-date">{{ $t("billing.creditCardInformation.modal.expDate") }}</label>
        <input
          id="exp-date"
          v-cardformat:formatCardExpiry
          required
          :disabled="disabled"
          :value="expDate"
          @input="updateExpDate"
        />
      </div>
      <div class="form-input date-and-cvc">
        <label class="registration-label align" for="cvc">{{ $t("billing.creditCardInformation.modal.cvc") }}</label>
        <input
          id="cvc"
          v-cardformat:formatCardCVC
          maxlength="4"
          required
          :disabled="disabled"
          :value="cvc"
          @input="updateCvc"
        />
      </div>
    </li>
    <li class="form-input">
      <label class="registration-label" for="name">{{ $t("planRegistration.cardholderName") }}</label>
      <input
        id="name"
        type="text"
        required
        :disabled="disabled"
        :value="cardHolderName"
        @input="updateCardHolderName"
      />
    </li>
  </div>
</template>

<script>
export default {
  props: {
    inputValue: Object, // Pass an object to store all input values
    disabled: Boolean,
  },
  data() {
    return {
      cardNumber: this.inputValue.cardNumber || "",
      expDate: this.inputValue.expDate || "",
      cvc: this.inputValue.cvc || "",
      cardHolderName: this.inputValue.cardHolderName || "",
    };
  },
  methods: {
    updateCardNumber(event) {
      this.cardNumber = event.target.value;
      this.emitInputChange();
    },
    updateExpDate(event) {
      this.expDate = event.target.value;
      this.emitInputChange();
    },
    updateCvc(event) {
      this.cvc = event.target.value;
      this.emitInputChange();
    },
    updateCardHolderName(event) {
      this.cardHolderName = event.target.value;
      this.emitInputChange();
    },
    emitInputChange() {
      const updatedValue = {
        cardNumber: this.cardNumber,
        expDate: this.expDate,
        cvc: this.cvc,
        cardHolderName: this.cardHolderName,
      };
      this.$emit("input-change", updatedValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.credit-card-inputs {
  @include flex-col-gap(8px);
}
.form-input {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.registration-label {
  @include font-small;
  font-weight: 500;
  vertical-align: -webkit-baseline-middle;
}

.flex-inputs {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;
}

.date-and-cvc {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

#name,
#card-number {
  @include base-font;
  @include font-normal;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  float: right;
  padding: 3px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 1px rgba(226, 124, 252, 0.7);
  }
}

#exp-date,
#cvc {
  @include base-font;
  @include font-normal;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3px;
  margin: 0;
  width: 100%;
  padding: 3px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 1px rgba(226, 124, 252, 0.7);
  }
}

#name {
  &:disabled {
    background-color: $light-grey;
  }
}
</style>
