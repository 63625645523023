<template>
  <div>
    <form class="user-form">
      <ul class="company-input">
        <div class="name-wrapper">
          <li class="input-block">
            <label class="company-label" for="firstname">{{
              $t("account.userInformation.inputs.firstName")
            }}</label>
            <input
              id="firstname"
              class="name-input"
              type="text"
              :value="firstName"
              @input="updateUser('firstname', $event.target.value)"
            />
          </li>
          <li class="input-block">
            <label class="company-label" for="lastname">{{
              $t("account.userInformation.inputs.lastName")
            }}</label>
            <input
              id="lastname"
              class="name-input"
              type="text"
              :value="lastName"
              @input="updateUser('lastname', $event.target.value)"
            />
          </li>
        </div>
        <li class="input-block">
          <label class="company-label" for="email">{{
            $t("account.userInformation.inputs.email")
          }}</label>
          <tippy class="tippy" a11y="false">
            <template #trigger>
              <input
                id="email"
                type="text"
                :disabled="true"
                :value="user.user.email"
                @input="updateUser('email', $event.target.value)"
              />
            </template>
            <p>{{ $t("account.userInformation.changeEmail") }}</p>
          </tippy>
        </li>
        <li class="input-block">
          <label class="company-label" for="website-url">{{
            $t("account.companyInformation.inputs.websiteUrl")
          }}</label>
          <input
            id="website-url"
            type="text"
            :value="site.site.websiteUrl"
            @input="onUrlUpdate"
            @change="updateSite"
          />
        </li>

        <li class="input-block">
          <label class="company-label" for="company-name">{{
            $t("account.companyInformation.inputs.companyName")
          }}</label>
          <input
            id="company-name"
            type="text"
            :value="site.site.companyName"
            @input="onNameUpdate"
            @change="updateSite"
          />
        </li>

        <li class="input-block">
          <label class="company-label" for="address">{{
            $t("account.companyInformation.inputs.address")
          }}</label>
          <input
            id="address"
            type="text"
            autocomplete="off"
            :value="getAddressString"
            @click="openAddressModal"
          />
        </li>
      </ul>
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import isEmpty from "lodash/isEmpty";

export default {
  data() {
    return {
      firstName: "",
      lastName: "",
    };
  },
  computed: {
    ...mapState(["user", "site"]),
    ...mapGetters({
      siteId: "site/getSiteId",
      getAddressString: "site/getAddressString",
    }),
  },

  created() {
    if (this.user.user) {
      this.firstName = this.user.user.firstname;
      this.lastName = this.user.user.lastname;
    }
  },

  methods: {
    async updateUser(inputFor, value) {
      const dataToUpdate = {
        [inputFor]: value,
      };

      if (isEmpty(dataToUpdate)) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.emptyFields"),
          type: "error",
        });
      } else {
        await this.$store.dispatch("user/updateUser", {
          userId: this.user.user._id,
          dataToUpdate,
        });
        this.$notify({
          title: this.$t("shared.toastMessage.success"),
          text: this.$t("shared.toastMessage.profileUpdated"),
          type: "success",
        });
      }
    },
    openAddressModal() {
      this.$emit("toggle");
    },

    onUrlUpdate(e) {
      this.newUrl = e.target.value;
    },

    onNameUpdate(e) {
      this.newName = e.target.value;
    },

    async updateSite() {
      if (this.newUrl === "" || this.newName === "") {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.emptyField"),
          type: "error",
        });
      } else {
        await this.$store.dispatch("site/updateSite", {
          siteId: this.siteId,
          dataToUpdate: { websiteUrl: this.newUrl, companyName: this.newName },
        });
        this.$notify({
          title: this.$t("shared.toastMessage.success"),
          ignoreDuplicates: true,
          text: this.$t("shared.toastMessage.profileUpdated"),
          type: "success",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.user-form {
  width: 90%;
  margin: 0 auto;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding-bottom: 24px;
}
.tippy {
  height: 30px;
  margin-bottom: 2px;
}

.company-input {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.name-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.company-label {
  width: 100%;
  @include font-small;
  vertical-align: -webkit-baseline-middle;
}

.input-block {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

#firstname,
#lastname,
#email,
#website-url,
#company-name,
#address {
  @include font-small;
  @include input-style;
  color: black;
  height: 30px;
  float: right;
}

#email {
  width: 100%;
  background-color: $light-grey;
}
</style>
