export default {
  free: [
    {
      getter: "numberOfCampaigns",
      name: "numberOfCampaigns",
      limit: 5,
    },
    {
      getter: "users",
      name: "users",
      limit: 1,
    },
  ],
};
