import api from "../api";

const state = {
  tags: [],
  selectedTags: [],
};

const getters = {
  getTags: (state) => {
    const defaultTags = [
      { name: "Product tutorials" },
      { name: "UGC videos" },
      { name: "Product try-ons" },
      { name: "Informative videos" },
      { name: "Bulk embed" },
    ];
    if (state.tags.length === 0) {
      return defaultTags;
    } else {
      const tagNames = state.tags.map((tag) => tag.name);
      const allTagsMatchDefault = tagNames.every((tag) =>
        defaultTags.some((defaultTag) => defaultTag.name === tag)
      );
      if (allTagsMatchDefault) {
        const getUnusedDefaultTags = defaultTags.filter(
          (tag) => !tagNames.includes(tag.name)
        );
        return [...state.tags, ...getUnusedDefaultTags];
      } else {
        return state.tags;
      }
    }
  },
};

const mutations = {
  setTags(state, tags) {
    state.tags = tags;
  },
  addTag(state, tag) {
    state.tags.push(tag);
  },
  deleteTag(state, tagId) {
    state.tags = state.tags.filter((tag) => tag._id !== tagId);
  },
  setSelectedTags(state, selectedTags) {
    state.selectedTags = selectedTags;
  },
};

const actions = {
  async getTags({ commit }, { siteId }) {
    try {
      const tags = await api.getTags({ siteId });
      commit("setTags", tags);
      return tags;
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },

  async createTag({ commit }, tagData) {
    try {
      const newTag = await api.createTag(tagData);
      commit("addTag", newTag);
      return newTag;
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },

  async deleteTag({ commit }, tagId) {
    try {
      await api.deleteTag(tagId);
      commit("deleteTag", tagId);
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
