<template>
  <div>
    <div v-if="!short" class="info-wrapper">
      <h2 class="klaviyo-integ-subtitle">
        {{ $t("integration.modal.tool.klaviyo.subtitle") }}
      </h2>
      <ul class="steplist">
        <li class="step">
          1. Create a private Api Key for Vidjet
          <a
            class="link-to-shopify"
            target="blank"
            href="https://www.klaviyo.com/account#api-keys-tab"
          >
            https://www.klaviyo.com/account#api-keys-tab</a
          >
        </li>
        <li class="step">2. Copy Paste it and click Integrate Klaviyo</li>
      </ul>
    </div>
    <form class="klaviyo-form" @submit.prevent="integrateKlaviyo()">
      <div class="api-key-flex">
        <p>Api Key</p>
        <input
          id="apiKey"
          v-model="apiKey"
          class="text-input signup-input"
          type="text"
          required
        />
      </div>
      <button type="submit" class="action-button integration-button">
        <img
          v-lazy="require(`@/assets/klaviyo-logo.png`)"
          class="klaviyo-logo-img"
          alt=""
        />
        {{ $t("integration.modal.tool.klaviyo.integrateBtn") }}
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      apiKey: "",
    };
  },
  computed: {
    ...mapGetters({
      siteId: "account/getSiteId",
      getKlaviyoApiKey: "site/getKlaviyoApiKey",
    }),
    klaviyoIntegration() {
      return this.$store.state.site.toolIntegrations.find(
        (integration) => integration.type == "klaviyo"
      );
    },
  },
  props: {
    short: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  async created() {
    await this.$store.dispatch("site/getIntegration", {
      siteId: this.siteId,
      type: "klaviyo",
    });
    this.apiKey = this.getKlaviyoApiKey;
  },
  methods: {
    async integrateKlaviyo() {
      try {
        await this.$store.dispatch("site/integrateKlaviyo", {
          siteId: this.siteId,
          apiKey: this.apiKey,
          status: "active",
          type: "klaviyo",
        });
        this.$notify({
          title: this.$t("integration.modal.tool.klaviyo.integrationSuccess"),
          type: "success",
        });
      } catch (err) {
        this.$notify({
          title: this.$t("integration.modal.tool.klaviyo.integrationFailure"),
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.klaviyo-integ-title {
  text-align: center;
  @include font-big;
}

.klaviyo-integ-subtitle {
  text-align: center;
  margin-bottom: 24px;
}

.klaviyo-form {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
}

.api-key-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.signup-input {
  @include base-font;
  @include font-normal;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  width: 45%;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 1px rgba(226, 124, 252, 0.7);
  }
}

.steplist {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  width: 100%;
  max-width: 800px;
  margin-bottom: 32px;
}
.step {
  @include font-normal;
  font-weight: 500;
}
.integration-button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 230px;
  height: 30px;
}
.klaviyo-logo-img {
  width: 24px;
}
</style>
