<template>
  <small-modal
    class="apply-changes-modal"
    :fit-content="true"
    :title="$t('create.step3.applyChanges')"
    @close="$emit('close-apply-changes-modal')"
  >
    <div class="apply-changes-modal-wrapper">
      <h2 v-if="isBulk" class="subtitle">
        {{ $t("create.step3.applyChangesExplanationBulk") }}
      </h2>
      <h2 v-else class="subtitle">
        {{ $t("create.step3.applyChangesExplanation") }}
      </h2>
      <banner-modal
        :title="$t('shared.toastMessage.information')"
        :text="
          isBulk
            ? $t('create.step3.applyChangesInformationBulk')
            : $t('create.step3.applyChangesInformation')
        "
      />

      <div class="buttons">
        <button
          class="button-primary --grey"
          @click="$emit('close-apply-changes-modal')"
        >
          {{ $t("shared.buttons.cancel") }}
        </button>
        <button class="button-primary --icon" @click="applyChanges">
          {{ $t("shared.buttons.confirm") }}
          <CircleChecked class="confirm-icon"></CircleChecked>
        </button>
      </div>
    </div>
  </small-modal>
</template>

<script>
import SmallModal from "@/components/shared/SmallModal.vue";
import BannerModal from "@/components/shared/BannerModal.vue";
import CircleChecked from "@/assets/svg/dashboard/circle_checked.svg?inline";
export default {
  components: {
    SmallModal,
    BannerModal,
    CircleChecked,
  },
  props: {
    isBulk: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async applyChanges() {
      this.$emit("submit-changes");
    },
  },
};
</script>

<style lang="scss" scoped>
.apply-changes-modal-wrapper {
  width: 450px;
  @include flex-col-gap(24px);
  justify-content: center;
  box-sizing: content-box;
  align-items: center;
}

.subtitle {
  @include font-normal;
  @include base-font;
}
.buttons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.confirm-icon {
  margin-top: 1px;
  path {
    fill: $dark-grey;
    fill-opacity: 1;
  }
}
</style>
