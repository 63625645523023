var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"step-1-wrapper"},[_c('div',{staticClass:"step-1-title"},[_c('h1',[_vm._v(_vm._s(_vm.$t("create.step1.heading")))])]),_c('button',{staticClass:"close-icon",on:{"click":_vm.backToCampaignManager}},[_c('close-icon')],1),_c('div',{staticClass:"step-1-content"},[_c('div',{staticClass:"usecase-card-section"},[_c('div',{staticClass:"format-wrapper"},[_c('div',{staticClass:"format-title"},[_c('h2',[_vm._v(_vm._s(_vm.$t("create.step1.fullScreen")))]),_c('img',{directives:[{name:"tippy",rawName:"v-tippy",value:({
              arrow: true,
              placement: 'right',
              a11y: false,
              maxWidth: 450,
              boundary: 'window',
            }),expression:"{\n              arrow: true,\n              placement: 'right',\n              a11y: false,\n              maxWidth: 450,\n              boundary: 'window',\n            }"}],staticClass:"tooltip-icon",attrs:{"src":require("@/assets/usecases_cards/tooltip-icon.png"),"alt":"tooltip-icon","content":_vm.$t('create.step1.tooltip.fullScreen')}})]),_c('div',{staticClass:"use-case-card-container"},_vm._l((_vm.fullScreenFormats),function({
              text,
              imageSrc,
              goal,
              backside,
              tag,
              videoSrc,
              index,
            }){return _c('div',{key:index},[_c('use-case-card',{attrs:{"image-location":imageSrc,"selected":_vm.selectedCardImageSrc === imageSrc,"tag-location":tag},on:{"card-selected":function($event){_vm.selectCard(imageSrc);
                _vm.changeVideoSource(videoSrc);}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(text))]},proxy:true},{key:"backside",fn:function(){return [_c('div',{staticClass:"card-text"},[_c('p',[_vm._v(_vm._s(backside))])])]},proxy:true}],null,true)})],1)}),0)]),_c('div',{staticClass:"format-wrapper"},[_c('div',{staticClass:"format-title"},[_c('h2',[_vm._v(_vm._s(_vm.$t("create.step1.inlineVideos")))]),_c('img',{directives:[{name:"tippy",rawName:"v-tippy",value:({
              arrow: true,
              placement: 'right',
              a11y: false,
              maxWidth: 450,
              boundary: 'window',
            }),expression:"{\n              arrow: true,\n              placement: 'right',\n              a11y: false,\n              maxWidth: 450,\n              boundary: 'window',\n            }"}],staticClass:"tooltip-icon",attrs:{"src":require("@/assets/usecases_cards/tooltip-icon.png"),"alt":"tooltip-icon","content":_vm.$t('create.step1.tooltip.inlineVideos')}})]),_c('div',{staticClass:"use-case-card-container"},_vm._l((_vm.inlineFormats),function({
              text,
              imageSrc,
              goal,
              backside,
              tag,
              videoSrc,
              index,
            }){return _c('div',{key:index},[_c('use-case-card',{key:text,attrs:{"image-location":imageSrc,"send-to-goal":goal,"selected":_vm.selectedCardImageSrc === imageSrc,"tag-location":tag},on:{"card-selected":function($event){_vm.selectCard(imageSrc);
                _vm.changeVideoSource(videoSrc);}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(text))]},proxy:true},{key:"backside",fn:function(){return [_c('div',{staticClass:"card-text"},[_c('p',[_vm._v(_vm._s(backside))])])]},proxy:true}],null,true)})],1)}),0)])]),_c('div',{staticClass:"example-video-section"},[_c('div',{staticClass:"video-with-image"},[(_vm.selectedCardImageSrc)?_c('img',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            arrow: true,
            placement: 'top',
            a11y: false,
            maxWidth: 450,
          }),expression:"{\n            arrow: true,\n            placement: 'top',\n            a11y: false,\n            maxWidth: 450,\n          }"}],attrs:{"src":require("@/assets/usecases_cards/example-customer.png"),"alt":"customer-example","content":_vm.$t('create.step1.tooltip.examples')}}):_c('img',{attrs:{"src":require("@/assets/usecases_cards/select-card.png"),"alt":"select-card"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedVideoSrc),expression:"selectedVideoSrc"}],staticClass:"card-selected"},[_c('video',{ref:"video",attrs:{"width":"100%","height":"100%","autoplay":"","loop":"","playsinline":"","muted":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.selectedVideoSrc,"type":"video/mp4"}})])]),(!_vm.selectedVideoSrc)?_c('div',{staticClass:"no-card-selected"},[_vm._m(0)]):_vm._e()]),_c('SettingsFooter',{staticClass:"footer",attrs:{"show-back-btn":false},nativeOn:{"click":function($event){return _vm.toCreateCampaignStep2.apply(null, arguments)}}})],1)]),(_vm.showBulkModal)?_c('RegularOrBulkModal',{on:{"close-bulk-modal":_vm.closeBulkModal,"choose-bulk-option":_vm.chooseBulkOption}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vidjet-video-wrapper",staticStyle:{"padding-bottom":"58.25%","position":"relative","overflow":"hidden","height":"auto","margin":"0px auto"}},[_c('iframe',{staticClass:"vidjet-embed-iframe",staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%","border-radius":"10px"},attrs:{"src":"https://player.vidjet.io/ea450a97-86d5-47c2-8dde-5cbed280663b/44f4c327-00fe-4e06-86ee-0ad5824654de","allow":"clipboard-read; clipboard-write; fullscreen","frameborder":"0"}})])
}]

export { render, staticRenderFns }