export const generateVideoCover = (urlFile, seekTo = 2.0) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.setAttribute("crossorigin", "anonymous");
    video.setAttribute("src", urlFile);
    video.load();
    video.addEventListener("error", (ex) => {
      reject("error when loading video file", ex);
    });
    video.addEventListener("loadedmetadata", () => {
      if (video.duration < 2) {
        reject("video is too short.");
        return;
      }
      if (video.duration < seekTo) {
        seekTo = 2;
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        video.currentTime = seekTo;
      }, 200);
      // extract video thumbnail once seeking is complete
      video.addEventListener("seeked", () => {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        drawImageFromVideo(canvas, video).toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/jpeg",
          1 /* quality */
        );
      });
    });
  });
};

const drawImageFromVideoWithRatio = (canvas, video) => {
  var hRatio = canvas.width / video.videoWidth;
  var vRatio = canvas.height / video.videoHeight;
  var ratio = Math.min(hRatio, vRatio);
  var centerShift_x = (canvas.width - video.videoWidth * ratio) / 2;
  var centerShift_y = (canvas.height - video.videoHeight * ratio) / 2;
  const ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(
    video,
    0,
    0,
    video.videoWidth,
    video.videoHeight,
    centerShift_x,
    centerShift_y,
    video.videoWidth * ratio,
    video.videoHeight * ratio
  );
  return ctx.canvas;
};

const drawImageFromVideo = (canvas, video) => {
  const ctx = canvas.getContext("2d");
  ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  return ctx.canvas;
};
