<template>
  <div>
    <h2 class="header">
      {{ $t("create.step2.instagram.header") }}
      <img
        :src="require('../../assets/instagram-logo.svg')"
        class="instagram-logo"
        alt="logo instagram"
      />
    </h2>
    <div v-if="isLoading" class="loading-container">
      <spinner-loader />
    </div>
    <div
      v-else-if="accounts.length > 0 && videos.length === 0"
      class="ig-accounts"
    >
      <button
        v-for="account in accounts"
        :key="account.id"
        class="button-primary"
        @click="selectedId = account.instagram_business_account.id"
      >
        {{ account.name }}
      </button>
    </div>
    <div
      v-else-if="integration && videos && videos.length > 0"
      class="integration"
    >
      <div class="sub-header">
        <div v-if="videos && videos.length > 0" class="account-name">
          <img src="../../assets/ig-account-icon.png" alt="" />@{{
            videos[0].username
          }}
          <Check></Check>
        </div>
        <button class="button-primary" @click="$emit('disconnectClicked')">
          {{ $t("create.step2.instagram.disconnect") }}
        </button>
      </div>
      <div class="content">
        <div class="previous">
          <button
            class="paging-button"
            :class="{ hidden: !paging.previous }"
            @click="getInstagramVideos(paging.previous)"
          >
            <ArrowBackIcon />
          </button>
        </div>
        <div class="videos">
          <div
            v-for="video in videos"
            :key="video.id"
            class="video"
            @click="toggleSelectedVideo(video)"
          >
            <div v-if="video.thumbnail_url && video.media_url">
              <img
                :src="video.thumbnail_url"
                :alt="video.caption"
                :class="{
                  selected: getVideoIndex(video) > -1,
                }"
                class="video-img"
              />
              <div>
                <p
                  class="caption"
                  :class="{
                    selected: getVideoIndex(video) > -1,
                  }"
                >
                  {{ video.caption | truncate }}
                </p>
                <p
                  class="date"
                  :class="{
                    selected: getVideoIndex(video) > -1,
                  }"
                >
                  {{ $t("create.step2.instagram.createdOn") }}
                  {{ video.timestamp | formatDate }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="next">
          <button
            class="paging-button"
            :class="{ hidden: !paging.next }"
            @click="getInstagramVideos(paging.next)"
          >
            <ArrowNextIcon />
          </button>
        </div>
      </div>

      <div class="buttons">
        <button class="button-primary" @click="$emit('close-clicked')">
          {{ $t("shared.buttons.back") }}
        </button>
        <button class="button-primary" @click="importClicked()">
          {{ $t("create.step2.videoLibraryModal.import") }}
        </button>
      </div>
    </div>

    <div v-else class="connect-button">
      <a class="button-primary" :href="url" target="_blank">
        {{ $t("create.step2.instagram.connect") }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../../api";
import ArrowNextIcon from "@/assets/svg/btn-arrow-next.svg?inline";
import ArrowBackIcon from "@/assets/svg/btn-arrow-back.svg?inline";
import Check from "@/assets/svg/settings/green-check.svg?inline";
import SpinnerLoader from "../../components/shared/SpinnerLoader.vue";

export default {
  components: {
    ArrowBackIcon,
    ArrowNextIcon,
    Check,
    SpinnerLoader,
  },
  props: {
    integration: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      videos: [],
      selectedVideos: [],
      paging: {},
      url: `https://www.facebook.com/v16.0/dialog/oauth?client_id=747945307048013&display=page&redirect_uri=${process.env.VUE_APP_URL}/instagram/auth&response_type=token&scope=instagram_basic,pages_show_list`,
      isLoading: false,
      accounts: [],
      selectedId: "",
    };
  },
  computed: {
    ...mapGetters({
      getSiteId: "account/getSiteId",
    }),
  },
  watch: {
    integration() {
      if (!this.accounts || this.accounts.length === 0)
        this.getInstagramAccounts();
    },
    selectedId() {
      this.getInstagramVideos();
    },
  },
  async created() {
    if (this.integration) this.getInstagramAccounts();
  },
  methods: {
    async getInstagramAccounts(url = null) {
      this.isLoading = true;
      if (!this.integration) return;
      this.accounts = await api
        .getInstagramAccountInformation(this.integration.apiKey, url)
        .then((res) => {
          this.isLoading = false;
          return res.data;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    async getInstagramVideos(url = null) {
      this.isLoading = true;
      if (!this.selectedId) return;
      await api
        .getInstagramVideos(this.selectedId, this.integration.apiKey, url)
        .then((res) => {
          this.videos = res.data;
          this.paging = res.paging;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    toggleSelectedVideo(video) {
      const i = this.getVideoIndex(video);
      if (i !== -1) {
        this.selectedVideos.splice(i, 1);
      } else {
        this.selectedVideos = [video];
      }
    },
    getVideoIndex(video) {
      return this.selectedVideos.findIndex(
        (selectedVideo) => video.id === selectedVideo.id
      );
    },
    importClicked() {
      this.isLoading = true;
      this.createVideosFromInstagramObject().then((videos) => {
        this.$emit("instagram-import", videos);
      });
    },
    async createVideosFromInstagramObject() {
      const result = [];

      for (const video of this.selectedVideos) {
        const blob = await fetch(video.media_url).then((res) => res.blob());
        result.push(
          new File([blob], `instagram_import_${video.id}.mp4`, {
            type: "video/mp4",
          })
        );
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  @include font-big;
  justify-content: center;
  gap: 8px;
  margin-bottom: 10px;
}
.sub-header {
  display: flex;
  justify-content: space-between;
  margin: 0px 50px;
  .account-name {
    display: flex;
    align-items: center;
    gap: 8px;
    color: $medium-grey;
    svg {
      width: 12px;
    }
  }
}
.content {
  display: flex;
  align-items: center;
  .hidden {
    visibility: hidden;
  }
}

.loading-container {
  min-height: 560px;
  min-width: 916px;
}

.paging-button {
  background: transparent;
}
.videos {
  margin: 10px;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 800px;
  row-gap: 20px;
  @media (max-height: 650px) {
    max-height: 300px;
    overflow-y: scroll;
  }
}
.video-img {
  max-height: 175px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;

  &.selected {
    border: 1px solid $dark-purple;
  }
}
.video-img:hover {
  filter: grayscale(50%) brightness(110%);
  cursor: pointer;
}

.caption {
  @include font-small;
  &.selected {
    color: $dark-purple;
  }
}

.date {
  @include font-small;
  color: $medium-grey;
  &.selected {
    color: $dark-purple;
  }
}
.buttons {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.connect-button {
  display: flex;
  justify-content: space-around;
}
.ig-accounts {
  text-align: center;
  padding: 24px;
  display: flex;
  gap: 16px;
  flex-direction: column;
}
</style>
