
export default (chartData) => {
  return {
    type: "line",
    data: {
      datasets: chartData,
    },
    options: {
      layout: {
        padding: {
          left: 30,
          right: 30,
          top: 50,
          bottom: 15,
        },
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return;
          },
        },
      },
      scales: {
        xAxes: [
          {
            type: "time",
            distribution: "linear",
            time: {
              unit: "day",
            },
            gridLines: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              beginAtZero: true,
              // autoSkip: true
            },
            display: true,
          },
        ],
        yAxes: [
          {
            gridLines: {
              borderDash: [5],
            },
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                return value;
              },
            },
          },
        ],
      },
      // responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      aspectRatio: 4,
      plugins: {
        zoom: false,
        pan: false,
      },
    },
  };
};
