<template>
  <div v-click-outside="closeOptionsModal" class="campaign-options-modal">
    <h1 class="options-title">{{ $t("campaignManager.options") }}</h1>
    <div class="options">
      <button class="option-button" @click="cloneFeed">
        <CloneIcon class="icon" />
        {{ $t("create.bulk.cloneFeed") }}
      </button>
      <button class="option-button" @click="deleteFeed">
        <DeleteIcon class="icon" />
        {{ $t("create.bulk.deleteFeed") }}
      </button>
    </div>
  </div>
</template>

<script>
import DeleteIcon from "@/assets/svg/delete.svg?inline";
import CloneIcon from "@/assets/svg/clone-icon.svg?inline";

export default {
  components: {
    DeleteIcon,
    CloneIcon,
  },
  props: {
    currentFeedEditIndex: {
      type: Number,
      required: true,
    },
  },
  methods: {
    closeOptionsModal() {
      this.$emit("close-options-modal");
    },
    deleteFeed() {
      this.$emit("open-delete-feed-modal", this.currentFeedEditIndex);
    },
    cloneFeed() {
      this.$emit("clone-feed", this.currentFeedEditIndex);
    },
  },
};
</script>
<style lang="scss" scoped>
.campaign-options-modal {
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.25);
  padding: 16px;
  width: 200px;
  min-height: 100px;
  z-index: 99;
  background: white;
  position: absolute;
  top: 0px;
  right: 64px;
  border-radius: 4px;
}
.options-title {
  @include base-font;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  color: $light-grey;
  margin-bottom: 16px;
}
.options {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
}
.option-button {
  @include base-font;
  background: transparent;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: $dark-grey;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  position: relative;
  text-align: left;
  &:hover {
    width: 100%;
    background: $dark-purple;
    color: white;
    border-radius: 4px;
    text-align: left;
    .icon path {
      fill: white;
    }
    .edit-icon path {
      stroke: white;
    }
  }
}
</style>
