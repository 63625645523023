<template>
  <div
    :class="[
      'input-container',
      { smallInput: inputSmall, flexLayout: useFlexLayout },
    ]"
  >
    <label :for="inputFor" :class="['label', { smallInput: inputSmall }]">{{
      labelText
    }}</label>
    <input
      :id="inputFor"
      v-model="dataInput"
      :class="[`${inputType}-input --with-borders`, { smallInput: inputSmall }]"
      :type="inputType"
      :required="inputRequired"
      :disabled="isDisabled"
      @change="sendDataInput"
    />
  </div>
</template>

<script>
export default {
  props: {
    inputFor: {
      type: String,
      default: "",
    },
    inputType: {
      type: String,
      default: "",
    },
    labelText: {
      type: String,
      default: "",
    },
    inputRequired: {
      type: Boolean,
      default: false,
    },
    inputValue: {
      type: [String, Number],
      default: null,
    },
    inputSmall: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    useFlexLayout: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataInput: null,
    };
  },
  created() {
    this.dataInput = this.inputValue;
  },
  methods: {
    sendDataInput() {
      const dataToSend = {
        value: this.dataInput,
        inputFor: this.inputFor,
      };
      this.$emit("dataInput", dataToSend);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 8px;
  height: 34px;
  &.smallInput {
    @include font-small;
  }
}

.flexLayout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  .label {
    align-self: flex-start;
  }
  input {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 12px;
    height: 34px;
  }
}

.label {
  align-self: center;
  margin-right: 16px;
  &.smallInput {
    margin-right: 8px;
  }
}
</style>
