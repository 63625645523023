import axios from "axios";

async function sendSuggestion(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/integration/user-suggestion`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err;
  }
}

export default {
  sendSuggestion,
};
