<template>
  <div class="wrapper">
    <signup-login />
  </div>
</template>
<script>
import SignupLogin from "./shared/SignupLogin.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Login",
  components: { SignupLogin },
  computed: {
    ...mapState(["site"]),
    ...mapGetters({
      isOnboardingComplete: "site/isOnboardingComplete",
    }),
  },
  async created() {
    // This will be useful so users that were connected with local storage will get auto login
    let localUser = localStorage.getItem("user");

    if (localUser) {
      localUser = JSON.parse(localUser);

      try {
        await this.$store.dispatch("account/logFromLocalStorage", localUser);
        await this.$store.dispatch("site/getSite", {
          siteId: this.$store.state.account.user.siteId,
        });
        if (!this.isOnboardingComplete) {
          return this.$router.push({ name: "onboarding" });
        } else return this.$router.push({ name: "dashboard" });
      } catch (err) {
        console.log(`Err: Login local storage into auth: ${err}`);
      }

      localStorage.removeItem("user");
    }

    //If the user is already connected and goes on /login, redirect to dashboard
    if (this.$store.state.account.user) {
      return this.$router.push({ name: "dashboard" });
    }
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: map-get($zindex, modal);
  background: white;
}
</style>
