<template>
  <div class="modal-background" @click.self="close">
    <div
      class="sliding-modal"
      :class="[
        'sliding-modal',
        {
          'slide-right': slideDirection === 'right',
        },
      ]"
    >
      <div
        v-if="isProductPages || isBulk"
        class="product-pages-sliding-modal"
        :class="{ 'campaign-without-videos': campaign?.videos.length === 0 }"
      ></div>
      <button class="close-icon" @click="close">
        <CloseIcon :class="{ 'white-icon': campaign?.videos.length === 0 }" />
      </button>
      <h2 v-if="feedName" class="feed-name">{{ feedName }}</h2>
      <h1 v-if="title" class="modal-title">{{ title }}</h1>
      <slot> </slot>
      <div class="cover"></div>
    </div>
  </div>
</template>

<script>
import CloseIcon from "@/assets/svg/settings/close.svg?inline";

export default {
  components: {
    CloseIcon,
  },
  props: {
    title: { type: String, default: "" },
    feedName: { type: String, default: "" },
    slideDirection: { type: String, default: "left" },
    isProductPages: { type: Boolean, default: false },
    campaign: { type: Object, default: null },
    isBulk: { type: Boolean, default: false },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.modal-background {
  z-index: map-get($zindex, modal);
  background-color: rgba(88, 88, 88, 0);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.sliding-modal {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 90%;
  background-color: #fff;
  box-shadow: 0px 0px 250px 100px rgba(0, 0, 0, 0.2),
    inset 0px 0px 10px 3px #dfdfdf;
  border-radius: 7px 0 0 7px;
  display: flex;
  flex-direction: column;
  padding: 40px 0px 40px 0px;
  margin-left: auto;
  @media (max-width: 1200px) {
    width: 95%;
  }
  &.slide-right {
    margin-left: 0;
    margin-right: auto;
    border-radius: 0 7px 7px 0;
  }
}

.sliding-modal.slide-right {
  animation: slideInRight 0.3s ease-out;
}

@keyframes slideInRight {
  0% {
    margin-left: -100%;
  }
  100% {
    margin-left: 0;
  }
}

.modal-title {
  margin-bottom: 16px;
  @include font-big;
  font-weight: 600;
  text-align: center;
  color: $dark-grey;
  white-space: nowrap;
}

.close-icon {
  top: 10px;
  right: 10px;
  background: none;
  position: absolute;
  z-index: 501;
}

.feed-name {
  position: absolute;
  top: 0;
  left: 0%;
  background-color: rgba(123, 1, 181, 0.15);
  border-radius: 7px 0px 7px 0px;
  padding: 10px 24px;
  color: $dark-purple;
}
.cover {
  position: absolute;
  bottom: 80px;
  left: 0;
  width: 25px;
  height: 56px;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.15));
  z-index: 100;
}
.product-pages-sliding-modal {
  background: rgba(226, 124, 252, 0.1);
  position: absolute;
  height: 40px;
  width: 75%;
  right: 0;
  top: 0;
}

.campaign-without-videos {
  background-color: rgba(0, 0, 0, 0.3);
}
.white-icon {
  path {
    fill: white;
  }
}
</style>
