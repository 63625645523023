var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('toggle-box',{staticClass:"toggle-box",attrs:{"options":[
      { text: _vm.$t('shared.toggleBox.left'), value: 0 },
      { text: _vm.$t('shared.toggleBox.right'), value: 1 },
    ],"header":_vm.$t('create.step3.bubblePositionHeader'),"input-for":"position","selected":_vm.formatObject.position},on:{"option-selected":_vm.handleFormatEvent}}),_c('toggle-box',{staticClass:"toggle-box",attrs:{"options":[
      { text: _vm.$t('shared.toggleBox.round'), value: 'round' },
      { text: _vm.$t('shared.toggleBox.square'), value: 'square' },
      { text: _vm.$t('shared.toggleBox.original'), value: 'original' },
    ],"header":_vm.$t('shared.toggleBox.bubbleShape'),"input-for":"bubbleShape","selected":_vm.formatObject.bubbleShape},on:{"option-selected":_vm.handleFormatEvent}}),_c('toggle-box',{staticClass:"toggle-box",attrs:{"options":[
      { text: _vm.$t('shared.toggleBox.small'), value: 60 },
      { text: _vm.$t('shared.toggleBox.medium'), value: 90 },
      { text: _vm.$t('shared.toggleBox.large'), value: 120 },
    ],"header":_vm.$t('create.step3.bubbleSize'),"input-for":"bubbleSize","selected":_vm.formatObject.bubbleSize},on:{"option-selected":_vm.handleFormatEvent}}),_c('div',{staticClass:"bubble-placement-container"},[_c('TextWithSlider',{attrs:{"label-text":_vm.$t('create.step3.sideMargin'),"input-for":"moveX","max-value":1000,"step":5,"preset-value":_vm.formatObject.moveX},on:{"toggle-button":_vm.handleFormatEvent}}),_c('TextWithSlider',{attrs:{"label-text":_vm.$t('create.step3.bottomMargin'),"input-for":"moveY","max-value":500,"step":5,"preset-value":_vm.formatObject.moveY},on:{"toggle-button":_vm.handleFormatEvent}}),_c('text-with-btn-and-color',{attrs:{"label-text":_vm.$t('create.step3.bubbleBorder'),"input-for":"bubbleBorder","preset-value":_vm.bubbleBorder,"color":_vm.formatObject.bubbleBorderColor},on:{"toggle-button":_vm.handleFormatEvent,"picker-change":_vm.onPickerChange}}),_c('TextWithBtnToggle',{attrs:{"label-text":_vm.$t('create.step3.hideBubble'),"input-for":"hideBubble","preset-value":_vm.formatObject.bubbleShape === 'original' || _vm.formatObject.hideBubble,"disabled":_vm.isHideBubbleDisabled},on:{"toggle-button":_vm.handleFormatEvent}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }