<template>
  <fullscreen-modal :show-close-button="false">
    <div></div>
    <spinner-loader v-if="isLoading"></spinner-loader>
  </fullscreen-modal>
</template>
<script>
import FullscreenModal from "./shared/Fullscreen-modal.vue";
import SpinnerLoader from "./shared/SpinnerLoader.vue";
import { mapGetters } from "vuex";

export default {
  name: "InstagramIntegration",
  components: { FullscreenModal, SpinnerLoader },
  data() {
    return { accessToken: "", isLoading: false };
  },
  computed: {
    ...mapGetters({
      siteId: "account/getSiteId",
    }),
    isIntegrated() {
      return this.$store.state.site.toolIntegrations.find(
        (integration) => integration.type == "instagram"
      );
    },
  },
  watch: {
    isIntegrated() {
      if (this.isIntegrated && this.isIntegrated.apiKey) {
        window.close();
        setTimeout(() => {
          alert(
            "Instagram was integrated succesfully, you can now close this window"
          );
        }, 1000);
      }
    },
  },
  created() {
    if (this.$route.query.error_code) {
      this.$notify({
        title: this.$t("shared.toastMessage.integrationError"),
        type: "error",
        text: this.$route.query.error_message,
      });
      return;
    }
    this.isLoading = true;
    this.accessToken = this.$route.hash
      .split("access_token=")[1]
      .split("&data_access_expiration_time=")[0];
    if (this.accessToken) this.integrateInstagram();
    this.isLoading = false;
  },
  methods: {
    async integrateInstagram() {
      try {
        await this.$store.dispatch("site/integrateInstagram", {
          siteId: this.siteId,
          apiKey: this.accessToken,
          status: "active",
          type: "instagram",
        });
      } catch (err) {
        this.$notify({
          title: this.$t("shared.toastMessage.integrationError"),
          type: "error",
          text: this.$t("shared.toastMessage.instagramIntegrationError"),
        });
      }
    },
  },
};
</script>

<style lang="scss"></style>
