<template>
  <ul class="trigger-segmentation">
    <li class="trigger-list-item">
      <vidjet-radio-btn
        id="landing"
        v-model="trigger.type"
        name="trigger"
        :text="$t('create.step4.trigger.options.landing')"
        :val="TriggerType.landing"
        :checked="trigger.type === TriggerType.landing"
        @input="handleOption()"
      />
    </li>
    <li class="trigger-list-item">
      <vidjet-radio-btn
        id="exit"
        v-model="trigger.type"
        name="trigger"
        :text="$t('create.step4.trigger.options.exitIntent')"
        :val="TriggerType.exit"
        :checked="trigger.type === TriggerType.exit"
        @input="handleOption()"
      />
    </li>
    <li class="trigger-list-item">
      <vidjet-radio-btn
        id="scroll"
        v-model="trigger.type"
        name="trigger"
        :text="$t('create.step4.trigger.options.scroll')"
        :val="TriggerType.scroll"
        :checked="trigger.type === TriggerType.scroll"
        @input="handleOption()"
      />
      <span
        v-show="trigger.type === TriggerType.scroll"
        class="trigger-option-text"
      >
        {{ $t("create.step4.trigger.distanceScrolled") }}
        <input
          v-model="trigger.option"
          class="number-input trigger-input"
          type="number"
          placeholder="20"
          min="1"
          max="99"
          @change="handleOption"
        />
        %.
      </span>
    </li>
    <li class="trigger-list-item">
      <vidjet-radio-btn
        id="delay"
        v-model="trigger.type"
        name="trigger"
        :text="$t('create.step4.trigger.options.delay')"
        :val="TriggerType.delay"
        :checked="trigger.type === TriggerType.delay"
        @input="handleOption()"
      />
      <span
        v-show="trigger.type === TriggerType.delay"
        class="trigger-option-text"
      >
        {{ $t("create.step4.trigger.delayEquals") }}
        <input
          v-model="trigger.option"
          class="number-input trigger-input"
          type="number"
          placeholder="5"
          min="1"
          @change="handleOption"
        />
        {{ $t("create.step4.trigger.seconds") }}
      </span>
    </li>
  </ul>
</template>

<script>
import enums from "@/enums";
import isEmpty from "lodash/isEmpty";
import VidjetRadioBtn from "../shared/Vidjet-radio-btn.vue";

const { TriggerType } = enums;
export default {
  name: "TriggerSegmentation",
  components: { VidjetRadioBtn },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      TriggerType,
      scrollOption: null,
      delayOption: null,
    };
  },
  computed: {
    trigger() {
      if (!this.value) return { type: 0 };
      return this.value;
    },
  },
  methods: {
    handleOption() {
      if (this.trigger.type === TriggerType.scroll) {
        event.target.value =
          parseInt(event.target.value) > 99
            ? 99
            : parseInt(event.target.value) <= 0
            ? 1
            : event.target.value;
        this.scrollOption = parseInt(event.target.value);
      } else if (this.trigger.type === TriggerType.delay) {
        event.target.value =
          parseInt(event.target.value) <= 0 ? 1 : event.target.value;
        this.delayOption = parseInt(event.target.value);
      } else {
        this.trigger.option = null;
      }

      this.trigger.option = parseInt(event.target.value);
      return this.$emit("input", this.trigger);
    },
  },
};
</script>

<style lang="scss" scoped>
.trigger-segmentation {
  .trigger-list-item {
    .trigger-option-text {
      margin-top: 5px;
      margin-left: 18px;
      @include base-font;
      @include font-smallest;
      font-weight: 500;
      display: flex;
    }
    .trigger-input {
      margin: -2px 7px;
    }
  }
}
</style>
