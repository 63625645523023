import { render, staticRenderFns } from "./DeviceTabs.vue?vue&type=template&id=abebdba6&scoped=true&"
import script from "./DeviceTabs.vue?vue&type=script&lang=js&"
export * from "./DeviceTabs.vue?vue&type=script&lang=js&"
import style0 from "./DeviceTabs.vue?vue&type=style&index=0&id=abebdba6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abebdba6",
  null
  
)

export default component.exports