var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"play-icon-settings"},[(!_vm.formatObject.hideStoryPlay)?_c('div',{staticClass:"icon-type-container"},[_c('h3',{staticClass:"title",class:{
        'disabled-text': _vm.formatObject.hideStoryPlay,
      }},[_vm._v(" "+_vm._s(_vm.$t("create.step3.buttonSelector.buttonStyle"))+" "),_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"navigation-arrow"},[_c('BtnArrow',{staticClass:"left",on:{"click":_vm.scrollLeft}})],1),_c('button',{staticClass:"navigation-arrow"},[_c('BtnArrow',{staticClass:"right",on:{"click":_vm.scrollRight}})],1)])]),_c('div',{staticClass:"icon-type-boxes play-icon-box",class:{ 'bulk-carousel': _vm.isBulk }},_vm._l((_vm.icons),function(icon){return _c('button',{key:icon,staticClass:"icon-type",class:{
          selected: _vm.iconType === icon,
          bulk: _vm.isBulk,
          'not-allowed': _vm.formatObject.hideStoryPlay,
        },attrs:{"disabled":_vm.formatObject.hideStoryPlay},on:{"click":function($event){return _vm.onIconSettingsChange({
            inputFor: 'iconType',
            value: icon,
          })}}},[(icon === 'minimal')?_c('PlayMinimal'):_vm._e(),(icon === 'round')?_c('PlayRound'):_vm._e(),(icon === 'outline')?_c('PlayOutline'):_vm._e(),(icon === 'square')?_c('PlaySquare'):_vm._e(),_c('h3',{class:{
            'disabled-text': _vm.formatObject.hideStoryPlay,
          }},[_vm._v(" "+_vm._s(_vm._f("capitalize")(icon))+" ")])],1)}),0)]):_vm._e(),(!_vm.formatObject.hideStoryPlay)?_c('div',{staticClass:"icon-customization"},[_c('div',{staticClass:"input-color"},[_c('h3',{staticClass:"box-title"},[_vm._v("Icon color")]),_c('button',{staticClass:"value-color",style:(`background-color:${
          _vm.formatObject?.iconSettings?.color || 'white'
        }`),attrs:{"disabled":_vm.formatObject.hideStoryPlay},on:{"click":function($event){return _vm.showPicker(`color`)}}})]),_c('div',{staticClass:"input-color",class:{
        disabled: _vm.iconType === 'minimal',
      }},[_c('h3',{staticClass:"box-title"},[_vm._v(" "+_vm._s(_vm.$t("create.step3.buttonSelector.backgroundColor"))+" ")]),_c('div',{staticClass:"buttons-color"},[_c('button',{staticClass:"value-color",style:(`background-color:${
            _vm.formatObject.iconSettings?.secondaryColor || 'grey'
          }`),attrs:{"disabled":_vm.iconType === 'minimal'},on:{"click":function($event){return _vm.showPicker(`secondaryColor`)}}})])]),_c('toggle-box',{staticClass:"toggle-box",attrs:{"options":[
        { text: _vm.$t('shared.toggleBox.none'), value: 'none' },
        { text: _vm.$t('shared.toggleBox.shadow'), value: 'wave' },
        { text: _vm.$t('shared.toggleBox.scale'), value: 'scale-down' },
      ],"header":_vm.$t('shared.toggleBox.animation'),"input-for":"animation","selected":_vm.formatObject.iconSettings?.animation},on:{"option-selected":function($event){return _vm.onIconSettingsChange($event)}}}),(_vm.displayPicker)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hidePicker),expression:"hidePicker"}],key:"color-picker",staticClass:"vidjet-color-picker"},[_c('colorpicker',{attrs:{"value":_vm.formatObject?.iconSettings && _vm.formatObject?.iconSettings[_vm.pickerType]},on:{"input":function($event){return _vm.onPickerChange($event, _vm.pickerType)}}})],1):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }