import axios from "axios";

async function getAgencyDashboardGlobalMetrics(query) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    params: query,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/agency/dashboard-global-metrics`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function getAgencyDashboardGraphMetrics(query) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    params: query,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/agency/dashboard-graph-metrics`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function getDashboardGlobalMetrics(query) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    params: query,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/dashboard-global-metrics`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function getDashboardGraphMetrics(query) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    params: query,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/dashboard-graph-metrics`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function getDashboardCampaignMetrics(query) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    params: query,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/dashboard-campaign-metrics`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

export default {
  getDashboardGlobalMetrics,
  getDashboardGraphMetrics,
  getDashboardCampaignMetrics,
  getAgencyDashboardGlobalMetrics,
  getAgencyDashboardGraphMetrics,
};
