var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"edit-footer"},[_c('button',{class:[
      'button-primary --grey',
      { 'small-font': _vm.isSmallFontOnMobile, disabled: _vm.disabledPrev },
    ],attrs:{"disabled":_vm.disabledPrev},on:{"click":_vm.handleClickPrev}},[_vm._v(" "+_vm._s(_vm.prevText)+" ")]),_c('button',{class:[
      'button-primary',
      { 'small-font': _vm.isSmallFontOnMobile, disabled: _vm.disabledNext },
    ],attrs:{"disabled":_vm.disabledNext},on:{"click":_vm.handleClickNext}},[_vm._v(" "+_vm._s(_vm.nextText)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }