<template>
  <fullscreen-modal :show-close-button="false">
    <div></div>
    <spinner-loader v-if="isLoading"></spinner-loader>
  </fullscreen-modal>
</template>
<script>
import FullscreenModal from "./shared/Fullscreen-modal.vue";
import SpinnerLoader from "./shared/SpinnerLoader.vue";
import api from "../api";
import createApp from "@shopify/app-bridge";
import { getSessionToken } from "@shopify/app-bridge-utils";
import { mapState, mapGetters } from "vuex";

export default {
  name: "ShopifyIntegration",
  components: { FullscreenModal, SpinnerLoader },
  beforeRouteEnter(t, f, next) {
    next(async (vm) => {
      vm.init();
    });
  },
  data() {
    return { isLoading: true };
  },
  computed: {
    ...mapState(["site"]),
    ...mapGetters({
      isOnboardingComplete: "site/isOnboardingComplete",
    }),
  },
  methods: {
    async init() {
      try {
        const { shop, isFirstInstall, isIntegration } = this.$route.query;

        let sessionToken;

        // Check if required to go through Oauth
        if (!isFirstInstall) {
          const { authUrl } = await api.processShopifyAuth({ shop });
          if (authUrl) {
            try {
              return (window.top.location.href = authUrl);
            } catch (error) {
              
            }
          }
        }

        //Initiate Shopify Client App Bridge
        const app = createApp({
          apiKey: process.env.VUE_APP_SHOPIFY_API_KEY,
          host: new URLSearchParams(location.search).get("host"),
          forceRedirect: true,
        });

        try {
          //Retreive Shopify Session Token
          const sessionToken = await getSessionToken(app);

          //Retreive Vidjet Token based on the Shopify one that will be decoded with JWT
          //And save it into the state
          try {
            await this.$store.dispatch("account/logShopifyUser", {
              sessionToken,
            });
          } catch (err) {
            console.log(`Err decoding shopify token: ${err}`);
          }
        } catch (err) {
          console.log(`Err retreiving shopify session token: ${err}`);
        }
        await this.$store.dispatch("site/getSite", {
          siteId: this.$store.state.account.user.siteId,
        });
        if (isFirstInstall || !this.isOnboardingComplete) {
          this.$router.push({ name: "onboarding" });
        } else {
          this.$router.push({ name: "dashboard" });
        }
      } catch (err) {
        console.log(`Err Auth Shopify: ${err}`);
      }
    },
  },
};
</script>

<style lang="scss"></style>
