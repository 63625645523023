<template>
  <div ref="image" class="format-block">
    <div class="flex-wrapper">
      <h3 class="format-block-title">
        {{
          isStory
            ? $t("create.step3.textStory")
            : $t("create.step3.buttonSelector.thumbnailText")
        }}
      </h3>
      <InterrogationMark
        v-tippy="{
          arrow: false,
          placement: 'right',
          a11y: false,
          boundary: 'window',
        }"
        :content="$t('create.step3.buttonSelector.infoTooltip')"
        class="interrogation-mark tooltip-trigger-not-responsive"
      />
    </div>
    <div class="button-inputs" :class="{ 'bulk-button-inputs': isBulk }">
      <div class="text-form">
        <label>{{ $t("create.step3.text") }}</label>
        <input
          :value="textOptions.text"
          :maxlength="isStory ? 20 : 1000"
          class="text-input --with-borders smallInput"
          type="text"
          @input="
            onTextOptionsChange({
              inputFor: 'text',
              value: $event.target.value,
            })
          "
        />
      </div>

      <div class="styles-form">
        <label>{{ $t("create.step3.buttonSelector.textStyle") }}</label>
        <div class="style-inputs" :class="{ 'bulk-style-inputs': isBulk }">
          <div class="text-styles">
            <button
              class="button-input"
              :class="{ selected: textOptions.isBold }"
              @click="toggleButton(`isBold`)"
            >
              <Bold></Bold>
            </button>
            <button
              class="button-input"
              :class="{ selected: textOptions.isItalic }"
              @click="toggleButton(`isItalic`)"
            >
              <Italic></Italic>
            </button>
            <button
              class="button-input"
              :class="{ selected: textOptions.isUnderlined }"
              @click="toggleButton(`isUnderlined`)"
            >
              <Underlined></Underlined>
            </button>
          </div>
          <div
            v-if="!isStory"
            class="position-styles"
            :class="{ 'bulk-flex': isBulk }"
          >
            <button
              class="button-input"
              :class="{ selected: textOptions.position === 'end' }"
              @click="
                onTextOptionsChange({
                  inputFor: 'position',
                  value: 'end',
                })
              "
            >
              <PositionBottom></PositionBottom>
            </button>
            <button
              class="button-input"
              :class="{ selected: textOptions.position !== 'end' }"
              @click="
                onTextOptionsChange({
                  inputFor: 'position',
                  value: 'start',
                })
              "
            >
              <PositionTop></PositionTop>
            </button>
          </div>
          <button
            v-if="!isStory"
            class="button-input wider"
            @click="showModal = true"
          >
            <Left></Left
            ><svg
              class="accordion-icon"
              :class="{ isOpen: showModal }"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 16 10"
              aria-hidden="true"
            >
              <path
                d="M15 1.2l-7 7-7-7"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <div
            v-if="showModal"
            v-click-outside="hideModal"
            class="align-modal"
            :class="{ 'bulk-align-modal': isBulk }"
          >
            <div
              class="align-button"
              @click="
                onTextOptionsChange({
                  inputFor: 'placement',
                  value: 'left',
                })
              "
            >
              <button
                class="button-input"
                :class="{
                  selected:
                    !textOptions.placement || textOptions.placement == 'left',
                }"
              >
                <Left></Left>
              </button>
              {{ $t("shared.toggleBox.left") }}
            </div>

            <div
              class="align-button"
              @click="
                onTextOptionsChange({
                  inputFor: 'placement',
                  value: 'center',
                })
              "
            >
              <button
                class="button-input"
                :class="{ selected: textOptions.placement == 'center' }"
              >
                <Center></Center>
              </button>

              {{ $t("shared.toggleBox.center") }}
            </div>

            <div
              class="align-button"
              @click="
                onTextOptionsChange({
                  inputFor: 'placement',
                  value: 'right',
                })
              "
            >
              <button
                class="button-input"
                :class="{ selected: textOptions.placement == 'right' }"
              >
                <Right></Right>
              </button>
              {{ $t("shared.toggleBox.right") }}
            </div>
          </div>
        </div>
      </div>

      <div class="input-color">
        <label v-if="!isStory" class="box-title">{{
          $t("create.step3.buttonSelector.textColorAndSize")
        }}</label>
        <label v-else class="box-title">
          {{ $t("create.step3.buttonSelector.textColor") }}</label
        >
        <div class="buttons-color">
          <div v-if="!isStory" class="size-button">
            <input
              type="number"
              class="size-input"
              min="12"
              max="30"
              :value="
                textOptions.size === undefined
                  ? defaultTextOptions.size
                  : textOptions.size
              "
              content="size"
              @focusout="validateCarouselInputSize"
              @input="
                onTextOptionsChange({
                  inputFor: 'size',
                  value: $event.target.value,
                })
              "
            />
            <span
              >px
              <TooltipIcon
                v-tippy="{
                  arrow: true,
                  placement: 'right',
                  a11y: false,
                  boundary: 'window',
                }"
                class="tooltip-icon"
                :content="$t('create.step3.buttonSelector.carouselTooltip')"
            /></span>
          </div>
          <div v-else class="size-button">
            <input
              type="number"
              class="size-input"
              min="10"
              max="14"
              :value="
                textOptions.size === undefined
                  ? defaultTextOptions.size
                  : textOptions.size
              "
              content="size"
              @focusout="validateStoriesInputSize"
              @input="
                onTextOptionsChange({
                  inputFor: 'size',
                  value: $event.target.value,
                })
              "
            />
            <span
              >px
              <TooltipIcon
                v-tippy="{
                  arrow: true,
                  placement: 'right',
                  a11y: false,
                  boundary: 'window',
                }"
                class="tooltip-icon"
                :content="$t('create.step3.buttonSelector.storiesTooltip')"
            /></span>
          </div>
          <button
            class="value-color"
            :style="`background-color:${
              textOptions.color || defaultTextOptions.color
            }`"
            @click="showPicker(`color`)"
          />
        </div>
      </div>
      <div v-if="!isStory" class="input-color">
        <label class="box-title">{{
          $t("create.step3.buttonSelector.backgroundColor")
        }}</label>
        <div class="buttons-color">
          <button
            class="value-color"
            :style="`background-color:${
              textOptions.backgroundColor || 'transparent'
            }`"
            @click="showPicker(`backgroundColor`)"
          />
        </div>
      </div>
    </div>
    <div
      v-if="displayPicker"
      key="color-picker"
      v-click-outside="hidePicker"
      class="vidjet-color-picker"
    >
      <colorpicker
        :value="
          textOptions[pickerType] ||
          (pickerType == 'color' ? 'black' : 'FFFFFF5E')
        "
        @input="onPickerChange($event, pickerType)"
      />
    </div>
  </div>
</template>

<script>
import Bold from "@/assets/svg/text-options/text-bold.svg?inline";
import Italic from "@/assets/svg/text-options/text-italic.svg?inline";
import Underlined from "@/assets/svg/text-options/text-underlined.svg?inline";
import PositionBottom from "@/assets/svg/text-options/position-bottom.svg?inline";
import PositionTop from "@/assets/svg/text-options/position-top.svg?inline";
import Left from "@/assets/svg/text-options/left.svg?inline";
import Center from "@/assets/svg/text-options/center.svg?inline";
import Right from "@/assets/svg/text-options/right.svg?inline";
import { Chrome } from "vue-color";
import { mapMutations } from "vuex";
import TooltipIcon from "@/assets/svg/tooltip-icon-dark.svg?inline";
import InterrogationMark from "@/assets/svg/settings/interrogation-mark.svg?inline";
import { mapGetters } from "vuex";

export default {
  components: {
    colorpicker: Chrome,
    Bold,
    Italic,
    Underlined,
    PositionBottom,
    PositionTop,
    Left,
    Center,
    Right,
    TooltipIcon,
    InterrogationMark,
  },
  props: {
    textOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isStory: {
      type: Boolean,
      default: false,
    },
    isBulk: {
      type: Boolean,
      default: false,
    },
    currentIndex: {
      type: Number,
      required: true,
    },
    campaignVideos: {
      type: Array,
      required: true,
    },
    currentFeedEditIndex: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      displayPicker: false,
      pickerType: "",
      showModal: false,
      defaultTextOptions: {
        size: this.isStory ? 12 : 16,
        color: "black",
      },
    };
  },
  computed: {
    ...mapGetters({
      feedList: "campaign/feedList",
    }),
  },
  watch: {
    currentIndex() {
      this.copyTextOptionsFromPrevious();
    },
    currentFeedEditIndex() {
      this.copyTextOptionsFromPrevious();
    },
    "textOptions.text"(newValue) {
      if (newValue) {
        this.$emit("change-text-object", {
          ...this.textOptions,
          color: this.textOptions.color || this.defaultTextOptions.color,
          size: this.textOptions.size || this.defaultTextOptions.size,
        });
      }
    },
  },
  created() {
    this.copyTextOptionsFromPrevious();
    if (this.isBulk) {
      this.copyTextFromSameVideoInAnotherFeed();
    }
    this.ensureDefaultSize();
  },
  methods: {
    // store from player
    ...mapMutations({
      setPlayerText: "videos/SET_TEXT_OPTIONS_PREVIEW",
    }),

    hidePicker() {
      this.displayPicker = false;
    },
    showPicker(type) {
      this.pickerType = type;
      this.displayPicker = true;
    },
    onPickerChange(e, name) {
      this.$emit("change-text-object", { ...this.textOptions, [name]: e.hex8 });
      this.setPlayerText({ ...this.textOptions, [name]: e.hex8 });
    },
    toggleButton(name) {
      let value = !this.textOptions[name];
      this.$emit("change-text-object", { ...this.textOptions, [name]: value });
      this.setPlayerText({ ...this.textOptions, [name]: value });
    },
    onTextOptionsChange(e) {
      this.$emit("change-text-object", {
        ...this.textOptions,
        [e.inputFor]: e.value,
      });
      this.setPlayerText({ ...this.textOptions, [e.inputFor]: e.value });
    },
    hideModal() {
      this.showModal = false;
    },
    validateCarouselInputSize() {
      let value;
      if (this.textOptions.size === "") {
        value = 16;
      } else if (this.textOptions.size > 30) {
        value = 30;
      } else if (this.textOptions.size < 12) {
        value = 12;
      }
      if (value) this.onTextOptionsChange({ inputFor: "size", value });
    },
    validateStoriesInputSize() {
      let value;
      if (this.textOptions.size === "") {
        value = 12;
      } else if (this.textOptions.size > 14) {
        value = 14;
      } else if (this.textOptions.size < 10) {
        value = 10;
      }
      if (value) this.onTextOptionsChange({ inputFor: "size", value });
    },
    copyTextOptionsFromPrevious() {
      if (!this.textOptions || Object.keys(this.textOptions).length === 0) {
        if (
          this.currentIndex === 0 &&
          this.isBulk &&
          this.currentFeedEditIndex !== 0
        ) {
          const prevFeedIndex = this.currentFeedEditIndex - 1;
          if (prevFeedIndex >= 0) {
            const prevFeedVideos = this.feedList[prevFeedIndex].videos;
            const lastVideoIndex = prevFeedVideos.length - 1;
            if (lastVideoIndex >= 0) {
              const prevVideoTextOptions =
                prevFeedVideos[lastVideoIndex].textOptions;
              if (prevVideoTextOptions) {
                const { text, ...rest } = prevVideoTextOptions;
                this.$emit("change-text-object", { ...rest });
                this.setPlayerText({ ...rest });
                return;
              }
            }
          }
        } else {
          const prevIndex = this.currentIndex - 1;
          if (prevIndex >= 0 && this.campaignVideos[prevIndex].textOptions) {
            const { text, ...rest } =
              this.campaignVideos[prevIndex].textOptions;
            this.$emit("change-text-object", { ...rest });
            this.setPlayerText({ ...rest });
          }
        }
      }
    },
    copyTextFromSameVideoInAnotherFeed() {
      if (!this.textOptions || Object.keys(this.textOptions).length === 0) {
        const currentVideo = this.campaignVideos[this.currentIndex];
        const fileName = currentVideo.fileName;

        for (const feed of this.feedList) {
          for (const video of feed.videos) {
            if (
              video.fileName === fileName &&
              video.textOptions &&
              video.textOptions.text
            ) {
              this.$emit("change-text-object", { ...video.textOptions });
              this.setPlayerText({ ...video.textOptions });
              return;
            }
          }
        }
      }
    },
    ensureDefaultSize() {
      if (this.textOptions.text && !this.textOptions.size) {
        this.onTextOptionsChange({
          inputFor: "size",
          value: this.defaultTextOptions.size,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button-inputs {
  margin: 12px auto;
  width: 100%;
  text-align: left;
  display: grid;
  gap: 16px;
  @include font-small;
  color: $dark-grey;
  .button-input {
    border-radius: 4px;
    height: 32px;
    display: flex;
    width: 32px;
    align-items: center;
    justify-content: center;
    &.selected {
      background: $dark-purple;
      path {
        fill: white;
      }
    }
    &.wider {
      width: 42px !important;
    }
  }
}

.text-form,
.styles-form {
  .bulk-style-inputs {
    display: grid;
    gap: 4px;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    justify-content: unset;
    .text-styles {
      grid-column: span 3;
    }

    .position-styles {
      grid-column: 1 / 3;
      grid-row: 2;
    }

    .button-input {
      grid-column: 2 / 3;
      grid-row: 2 / 2;
    }
  }
}
.text-form,
.styles-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.style-inputs {
  display: flex;
  justify-content: space-between;
  .text-styles {
    display: flex;
    gap: 4px;
  }
  .position-styles {
    display: flex;
    gap: 4px;
  }
}

.size-button {
  margin-right: 16px;
  display: flex;
  align-items: center;
}
.size-input {
  margin-right: 4px;
  height: 24px;
  width: 30px;
}
.align-modal {
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.25);
  padding: 16px;
  z-index: 99;
  background: white;
  position: absolute;
  right: 0;
  bottom: -10px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.bulk-align-modal {
  right: 32px;
}

.align-button {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}
.isOpen {
  transform: rotate(180deg);
  transition: all 0.1s;
}
.accordion-icon {
  transition: all 0.1s;
  width: 13px;
  height: 7px;
}
.flex-wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}
.interrogation-mark {
  border: solid 1px #585858;
  border-radius: 50%;
  transform: scale(1.1);
}
</style>
