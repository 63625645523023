<template>
  <div class="format-block">
    <div class="button-and-toggle">
      <h3 class="format-block-title">
        {{ $t("create.step3.stickyBarTitle") }}
      </h3>
    </div>
    <div class="format-block-body">
      <div class="button-inputs">
        <input
          :id="`${chosenDevice}-stickybar-text-value`"
          v-model="stickyBarObject.text"
          :name="`${chosenDevice}-stickybar-text-value`"
          class="input-text"
          type="text"
          placeholder="Sticky Bar Text"
        />
      </div>
      <div
        v-if="displayPicker"
        key="color-picker"
        v-click-outside="hidePicker"
        class="vidjet-color-picker"
      >
        <colorpicker :value="colorChosen" @input="onPickerChange" />
      </div>
      <div class="color-settings">
        <div class="color-setting">
          <button
            class="value-color"
            :style="`background-color:${stickyBarObject.backgroundColor}`"
            @click="showPicker(`stickybar-background`)"
          />
          <div class="cta-title-format">
            {{ $t("create.step3.buttonSelector.buttonColor") }}
          </div>
        </div>
        <div class="color-setting">
          <button
            class="value-color"
            :style="`background-color:${stickyBarObject.textColor}`"
            @click="showPicker(`stickybar-text`)"
          />
          <div class="cta-title-format">
            {{ $t("create.step3.buttonSelector.textColor") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import isEmpty from "lodash/isEmpty";
import { Chrome } from "vue-color";

export default {
  components: {
    colorpicker: Chrome,
  },

  props: {
    campaignStickyBarOption: {
      type: Object,
      default: () => {
        return {};
      },
    },
    chosenDevice: {
      type: String,
      default: "desktop",
    },
  },

  data() {
    return {
      stickyBarObject: {},
      defaultStickyBarObject: {
        text: "Watch Video",
        textColor: "#FFFFFF",
        backgroundColor: "#7B01B5",
      },
      displayPicker: false,
      colorChosen: "",
      pickerType: "",
    };
  },

  watch: {
    stickyBarObject: {
      handler: function () {
        this.$emit("change-sticky-bar-object", this.stickyBarObject);
      },
      deep: true,
    },

    chosenDevice() {
      this.stickyBarObject = !isEmpty(this.campaignStickyBarOption)
        ? { ...this.campaignStickyBarOption }
        : { ...this.defaultStickyBarObject };
    },
  },

  created() {
    // These come from the locales files, check en.json
    this.buttonOptions = this.$t("create.step3.buttonSelector.buttonOptions");

    // copy stickyBarObject from parent
    this.stickyBarObject = !isEmpty(this.campaignStickyBarOption)
      ? { ...this.campaignStickyBarOption }
      : { ...this.defaultStickyBarObject };
  },

  methods: {
    showPicker(type) {
      this.pickerType = type;
      this.displayPicker = true;
      this.colorChosen =
        type === "stickybar-text"
          ? this.stickyBarObject.textColor
          : type === "stickybar-background"
          ? this.stickyBarObject.backgroundColor
          : "";
    },
    onPickerChange(e) {
      this.pickerType === "stickybar-text"
        ? (this.stickyBarObject.textColor = e.hex8)
        : this.pickerType === "stickybar-background"
        ? (this.stickyBarObject.backgroundColor = e.hex8)
        : "";
    },

    hidePicker() {
      this.displayPicker = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.color-settings {
  margin-top: 10px;
}
.color-setting {
  width: 50%;
  text-align: center;
}
.input-text {
  @include base-font;
  @include font-smaller;
  font-weight: 500;
  border: 1px solid $light-grey;
  border-radius: 2px;
  background-color: transparent;
  outline: none;
  width: 95%;
  margin-bottom: 10px;
  &::placeholder {
    color: $light-grey;
  }
}

.cta-title-format {
  margin-top: 5px;
  @include font-smallest;
  font-weight: 500;
}
</style>
