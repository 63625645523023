import api from "../api";
import Vue from "vue";

const state = {
  site: {},
  agencySites: [],
  // save agency siteId for log as
  agencyId: "",
  isLoggedAs: false,
  toolIntegrations: [],
};

const getters = {
  getSite: (state) => {
    return state.site;
  },
  siteIntegration: (state) => {
    return state.site?.integration;
  },
  isPrestashop: () => {
    return state.site?.integration === 'prestashop';
  },
  isPayByCard: (state) => {
    return state.site?.payByCard;
  },
  getAddress: (state) => {
    return state.site?.address;
  },
  getSiteId: (state) => {
    return state.site._id;
  },
  siteHasInfo: (state) => {
    return Boolean(
      state.site.companyName && state.site.websiteUrl && state.site.address
    );
  },
  getAddressString: (state) => {
    if (!state.site.address) {
      return "";
    }
    const addressLine1 = state.site.address.line1
      ? state.site.address.line1 + "; "
      : "";
    const addressCity = state.site.address.city
      ? state.site.address.city + "; "
      : "";
    const addressCountry = state.site.address.country || "";
    return `${addressLine1}${addressCity}${addressCountry}`;
  },

  getRawCurrency: (state) => {
    return state.site.currency;
  },

  getCurrency: (state) => {
    if (state.site.currency) {
      if (state.site.currency === "EUR") {
        return "€";
      } else if (state.site.currency === "USD") {
        return "$";
      } else {
        return state.site.currency;
      }
    } else {
      return "$";
    }
  },
  getKlaviyoApiKey: (state) => {
    return (
      state.toolIntegrations.find(
        (integration) => integration.type === "klaviyo"
      )?.apiKey || ""
    );
  },
  isOnboardingComplete: (state) => {
    const onboarding = state.site.onboarding;

    if (!onboarding) {
      return false;
    }

    return (
      onboarding.integrateMain &&
      onboarding.trackSales &&
      onboarding.embedBulkComponent &&
      onboarding.publishScale &&
      onboarding.embedHomepage &&
      onboarding.embedLanding
    );
  },
};

const mutations = {
  GET(state, site) {
    state.site = site;
  },

  UPDATE(state, site) {
    state.site = site;
  },
  GET_AGENCY(state, agencySites) {
    state.agencySites = agencySites;
  },
  ADD_CLIENT(state, newClient) {
    state.agencySites.unshift(newClient);
  },
  DELETE_CLIENT(state, clientId) {
    const index = state.agencySites.findIndex(
      (element) => element.siteId === clientId
    );
    if (index > -1) {
      state.agencySites.splice(index, 1);
    }
  },
  SAVE_AGENCY_ID(state, agencyId) {
    state.agencyId = agencyId;
  },
  IS_LOGGED_AS(state, status) {
    state.isLoggedAs = status;
  },
  GET_TOOL_INTEGRATIONS(state, integrations) {
    state.toolIntegrations = integrations;
  },
  GET_TOOL_INTEGRATION(state, integration) {
    const index = state.toolIntegrations?.findIndex(
      (element) => element.type === integration.type
    );
    if (index == -1 && integration) state.toolIntegrations.push(integration);
  },
  UPDATE_TOOL_INTEGRATION(state, integration) {
    const index = state.toolIntegrations.findIndex(
      (element) => element.type === integration.type
    );
    if (index == -1 && integration) state.toolIntegrations.push(integration);
    else Vue.set(state.toolIntegrations, index, integration);
  },
  DELETE_TOOL_INTEGRATION(state, integration) {
    const index = state.toolIntegrations.findIndex(
      (element) => element.type === integration.type
    );
    if (index > -1) {
      state.toolIntegrations.splice(index, 1);
    }
  },
};

const actions = {
  async getSite({ commit }, { siteId }) {
    const site = await api.getSite(siteId);
    commit("GET", site);
  },

  async getSiteAgency({ commit }, { agencyId }) {
    const agencySites = await api.getSiteAgency(agencyId);
    commit("GET_AGENCY", agencySites);
  },

  async updateSite({ commit }, { siteId, dataToUpdate }) {
    const site = await api.updateSite(siteId, dataToUpdate);
    commit("UPDATE", site);
  },

  async addClient({ commit }, dataToSend) {
    const newClient = await api.addClient(dataToSend);
    commit("ADD_CLIENT", newClient);
  },

  async deleteClient({ commit }, clientId) {
    await api.deleteClient(clientId);
    commit("DELETE_CLIENT", clientId);
  },
  saveAgencyId({ commit }, agencyId) {
    commit("SAVE_AGENCY_ID", agencyId);
  },
  isLoggedAs({ commit }, status) {
    commit("IS_LOGGED_AS", status);
  },
  async integrateKlaviyo({ commit }, { siteId, apiKey, status, type }) {
    const integration = await api.integrateKlaviyo(siteId, {
      apiKey,
      status,
      type,
    });
    commit("UPDATE_TOOL_INTEGRATION", integration);
  },
  async integrateInstagram({ commit }, { siteId, apiKey, status, type }) {
    const integration = await api.integrateInstagram(siteId, {
      apiKey,
      status,
      type,
    });
    commit("UPDATE_TOOL_INTEGRATION", integration);
  },
  async getIntegrations({ commit }, { siteId }) {
    const integration = await api.getIntegrations(siteId);
    commit("GET_TOOL_INTEGRATIONS", integration);
  },
  async getIntegration({ commit }, { siteId, type }) {
    const integration = await api.getIntegration(siteId, type);
    commit("GET_TOOL_INTEGRATION", integration);
  },
  async updateIntegration({ commit }, { siteId, type }) {
    const integration = await api.getIntegration(siteId, type);
    commit("UPDATE_TOOL_INTEGRATION", integration);
  },
  async deleteIntegration({ commit }, { siteId, _id }) {
    const integration = await api.deleteIntegration(siteId, _id);
    commit("DELETE_TOOL_INTEGRATION", integration);
  },
  async updateOnboarding({ commit, state }, { property, value }) {
    const updatedSite = { ...state.site };

    if (!updatedSite.onboarding) {
      updatedSite.onboarding = {};
    }

    updatedSite.onboarding = { ...updatedSite.onboarding, [property]: value };

    const site = await api.updateSite(state.site._id, {
      onboarding: updatedSite.onboarding,
    });
    commit("UPDATE", site);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
