var render = function render(){var _vm=this,_c=_vm._self._c;return _c('small-modal',{staticClass:"see-products-modal",attrs:{"title":_vm.modalTitle,"fit-content":true},on:{"close":_vm.close}},[_c('h2',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t("create.step2.seeProductsModal.subtitle"))+" "),_c('span',[_vm._v(_vm._s(_vm.selectedVideo.fileName))])]),(!_vm.selectedVideo.productIds || _vm.selectedVideo.productIds.length === 0)?_c('div',{staticClass:"empty-products-list"},[_c('img',{staticClass:"sitting-man",attrs:{"src":require("@/assets/sitting-man.png"),"alt":"sitting-man"}}),_c('div',{staticClass:"explanation"},[_c('p',[_vm._v(_vm._s(_vm.$t("create.step2.seeProductsModal.info1")))]),_c('p',{staticClass:"bold"},[_vm._v(_vm._s(_vm.$t("create.step2.seeProductsModal.info2")))]),_c('div',{staticClass:"icons"}),_c('FloatingButtons')],1)]):_c('div',_vm._l((_vm.filteredProducts),function(product){return _c('div',{staticClass:"product-list"},[_c('div',{staticClass:"product-row"},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'top', arrow: true }),expression:"{ placement: 'top', arrow: true }"}],staticClass:"image-name",attrs:{"content":product.name}},[_c('div',{staticClass:"product-img-wrapper",on:{"mouseenter":function($event){return _vm.handleMouseEnter(product._id)},"mouseleave":_vm.handleMouseLeave}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(product.imageUrl),expression:"product.imageUrl"}],staticClass:"product-img",attrs:{"alt":"product-img"}}),(product.productUrl)?_c('div',{class:[
                'product-link',
                { visible: _vm.hoveredProductId === product._id },
              ]},[_c('a',{attrs:{"href":product.productUrl,"target":"_blank"}},[_c('NewTabIcon',{staticClass:"new-tab-icon"})],1)]):_vm._e()]),_c('div',{staticClass:"name-info"},[_c('h2',{staticClass:"product-name"},[_vm._v(_vm._s(_vm.truncateName(product.name)))]),_c('div',[(_vm.isShopify)?_c('p',{staticClass:"product-info"},[_vm._v(" "+_vm._s(_vm.firstVariantPrice(product.variants))+" - "+_vm._s(_vm.calculateTotalQty(product.variants))+" ")]):_c('p',{staticClass:"product-info"},[_vm._v(" "+_vm._s(_vm.firstVariantPrice(product.variants))+" ")])])])]),_c('div',{staticClass:"status"},[_c('p',{staticClass:"number-of-videos",class:{ 'there-are-videos': product.videosAttached }},[(product.videosAttached)?_c('span',[_vm._v(_vm._s(product.videosAttached.length))]):_c('span',[_vm._v("0")]),_vm._v(" "+_vm._s(_vm.$t("account.usage.videos"))+" ")])]),_c('div',{staticClass:"widgets"},[(
              product.formatsAttached && product.formatsAttached.length > 0
            )?_c('div',{staticClass:"widgets-images"},[_vm._l((product.formatsAttached),function(format){return [_c('tippy',{key:format.campaignId,attrs:{"arrow":"true","placement":"top","a11y":"false","interactive":"true"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c(_vm.getWidgetComponent(format.formatName),{tag:"component",staticClass:"widget-icon"})]},proxy:true}],null,true)},[_c('div',{staticClass:"tippy-content"},[_c('p',[_vm._v(_vm._s(_vm.getWidgetFormat(format.formatName)))]),_c('p',{staticClass:"tooltip-campaign-name"},[_vm._v(" \""+_vm._s(format.campaignName)+"\" ")])])])]})],2):_vm._e(),(
              !product.formatsAttached ||
              product.formatsAttached.length < _vm.bulkCampaignCount.length
            )?_c('div',{staticClass:"no-widget",on:{"click":function($event){return _vm.addWidget(
                product.productId,
                product.formatsAttached,
                product.videosAttached
              )}}},[_c('p',[_vm._v("+")])]):_vm._e()])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }