<template>
  <div class="subscription-carousel">
    <carousel
      ref="carousel"
      :per-page-custom="[
        [400, 1],
        [600, 2],
        [800, 3],
        [1000, 4],
        [1160, 5],
      ]"
      :pagination-enabled="true"
      :loop="true"
      :mouse-drag="false"
    >
      <!-- class VueCarousel-slide-active isn't loaded on page load, so the class is pushed onto the Carousel with initialLoad  -->
      <slide
        v-for="item in subscriptionArray"
        :key="item.id"
        :class="{ 'VueCarousel-slide-active': initialLoad }"
      >
        <plan-card
          :subscription="item"
          @card-clicked="handleCardClick"
        ></plan-card>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import PlanCard from "../../shared/PlanCard.vue";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    PlanCard,
    Carousel,
    Slide,
  },
  props: {
    proposedPlan: { type: String, default: "growth" },
    subscriptionArray: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      initialLoad: true,
    };
  },

  computed: {
    ...mapState(["plan"]),
    ...mapGetters({
      currency: "invoices/currency",
      userIsTrial: "plan/userIsTrial",
      currentPlan: "plan/currentPlan",
    }),
  },

  methods: {
    handleCardClick(payload) {
      this.$emit("card-clicked", payload);
    },
  },
};
</script>

<style lang="scss">
// carousel styling
.VueCarousel-navigation-button {
  color: #c4c4c4 !important;
  outline: none !important;
}
.VueCarousel-slide {
  display: flex;
  justify-content: center;
}

.VueCarousel-dot {
  margin-top: 0 !important;
}

.VueCarousel-dot--active {
  background-color: $dark-purple !important;
}
</style>

<style scoped lang="scss">
.subscription-carousel {
  max-width: 1200px;
  margin: 0 auto;
}
</style>
