var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content-wrapper"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"title-subtitle"},[_c('h1',{staticClass:"onboarding-title"},[_vm._v(_vm._s(_vm.$t("onboarding.title")))]),_c('h2',{staticClass:"onboarding-subtitle"},[_vm._v(_vm._s(_vm.$t("onboarding.subtitle")))])]),_c('InstructionBanner',{staticClass:"onboarding-instruction-banner",attrs:{"selected-name":"baptiste","title-text":_vm.$t('onboarding.banner.title'),"link-text":_vm.$t('onboarding.banner.button'),"icons":_vm.flags},on:{"handle-button-click":_vm.onboardingHelpRequest}})],1),_c('div',{staticClass:"onboarding-steps"},_vm._l((_vm.steps),function(step,index){return _c('div',{key:index,staticClass:"step-box",class:{
        'step-box-completed': _vm.numberOfProgress(index) == step.totalSmallSteps,
      }},[_c('div',{staticClass:"header-progress"},[_c('div',{staticClass:"step-header"},[_c('div',{staticClass:"title-tooltip"},[_c('h2',{staticClass:"step-title"},[_c('span',{staticClass:"step-number"},[_vm._v(_vm._s(index + 1))]),_vm._v(" "+_vm._s(step.title)+" ")]),_c('TooltipIcon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                arrow: true,
                placement: 'right',
                a11y: false,
                maxWidth: 450,
                boundary: 'window',
              }),expression:"{\n                arrow: true,\n                placement: 'right',\n                a11y: false,\n                maxWidth: 450,\n                boundary: 'window',\n              }"}],staticClass:"tooltip-icon",class:{
                'tooltip-completed':
                  _vm.numberOfProgress(index) === step.totalSmallSteps,
              },attrs:{"content":step.tooltip}})],1),_c('svg',{staticClass:"accordion-icon",class:{ isFullyCompleted: _vm.accordionStates[index] },attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 16 10","aria-hidden":"true"},on:{"click":function($event){return _vm.toggleAccordion(index)}}},[_c('path',{attrs:{"d":"M15 1.2l-7 7-7-7","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar",class:{
              'half-completed':
                _vm.numberOfProgress(index) / step.totalSmallSteps >= 0.5,
              'fully-completed':
                _vm.numberOfProgress(index) / step.totalSmallSteps === 1,
            },style:({
              width: `${
                (_vm.numberOfProgress(index) / step.totalSmallSteps) * 100
              }%`,
            })}),_c('p',{staticClass:"progress-number"},[_vm._v(" "+_vm._s(_vm.numberOfProgress(index))+" "+_vm._s(_vm.$t("onboarding.of"))+" "+_vm._s(step.totalSmallSteps)+" "+_vm._s(_vm.$t("onboarding.completed"))+" ")])])]),(!_vm.accordionStates[index])?_c('div',{staticClass:"small-steps"},[(index === 1)?_c('div',{staticClass:"small-step"},[_c('div',{staticClass:"small-step-title-checkbox"},[_c('div',{staticClass:"checkbox",class:{
                'full-checkbox': _vm.isSmallStepCompleted(index, 1),
                'clickable-checkbox': index !== 0,
              },on:{"click":function($event){return _vm.toggleCheckbox(index, 1)}}},[_c('CheckedStep')],1),_c('p',{staticClass:"small-step-title",class:{
                'small-step-title-completed': _vm.isSmallStepCompleted(index, 1),
              }},[_vm._v(" "+_vm._s(step.smallStep1)+" ")])]),_c('a',{staticClass:"tutorial",class:{
              'tutorial-completed': _vm.isSmallStepCompleted(index, 1),
            },attrs:{"href":step.link1,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("onboarding.tutorial"))),_c('OpenTutorial')],1)]):_vm._e(),_vm._l((index === 1 ? [2, 3] : [1, 2]),function(i){return _c('div',{key:i,staticClass:"small-step"},[_c('div',{staticClass:"small-step-title-checkbox"},[_c('div',{staticClass:"checkbox",class:{
                'full-checkbox': _vm.isSmallStepCompleted(index, i),
                'clickable-checkbox': index !== 0,
              },on:{"click":function($event){return _vm.toggleCheckbox(index, i)}}},[_c('CheckedStep')],1),_c('p',{staticClass:"small-step-title",class:{
                'small-step-title-completed': _vm.isSmallStepCompleted(index, i),
              }},[_vm._v(" "+_vm._s(step[`smallStep${i}`])+" ")])]),_c('a',{staticClass:"tutorial",class:{
              'tutorial-completed': _vm.isSmallStepCompleted(index, i),
            },attrs:{"href":step[`link${i}`],"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("onboarding.tutorial"))),_c('OpenTutorial')],1)])})],2):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }