<template>
  <notifications
    classes="vidjet-notifications"
    position="top right"
    width="350px"
    :duration="4000"
  >
    <template slot="body" slot-scope="props">
      <div
        :class="[
          'vidjet-notifications',
          {
            error: props.item.type === 'error',
            success: props.item.type === 'success',
          },
        ]"
      >
        <div class="notification-icon">
          <SuccessIcon v-if="props.item.type === 'success'" />
          <ErrorIcon v-if="props.item.type === 'error'" />
        </div>
        <div class="notification-content">
          <h4 class="notification-title">{{ props.item.title }}</h4>
          <p class="notification-text">{{ props.item.text }}</p>
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
import SuccessIcon from "../../assets/svg/circle_check_outline.svg?inline";
import ErrorIcon from "../../assets/svg/error_outline.svg?inline";

export default {
  name: "ToastNotifications",
  components: { SuccessIcon, ErrorIcon },
};
</script>

<style lang="scss" scoped>
.vidjet-notifications {
  background: white;
  position: relative;
  z-index: map-get($zindex, notification);
  display: flex;
  padding: 10px;
  margin: 5px;
  @include font-small;

  border: 2px solid blue;
  border-left: 14px solid #187fe7;
  box-sizing: border-box;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  .notification-content {
    display: flex;
    flex-direction: column;
    max-height: inherit;
  }
  .notification-icon {
    @include flex-centered;
    margin-right: 10px;
  }

  .notification-title {
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
  }
  .notification-text {
    white-space: pre-wrap;
  }

  &.error {
    color: $error-red;
    border-color: $error-red;
  }

  &.success {
    color: $success-green;
    border-color: $success-green;
  }
}
</style>
