<template>
  <small-modal
    :title="$t('create.step3.emailModal.addEmail')"
    :fit-content="true"
    @close="onCloseModal"
  >
    <h3 class="subtitle">
      {{ $t("create.step3.emailModal.addEmailSubtitle") }}
    </h3>
    <banner-modal :title="$t('deleteCampaign.information')">
      <slot>
        <p class="banner-text">
          {{ $t("create.step3.emailModal.bannerText") }}
        </p>
      </slot>
    </banner-modal>
    <div class="image-grid">
      <div
        v-for="tool in tools"
        :key="tool"
        class="img-wrapper"
        :class="{ 'is-selected': selectedTool === tool }"
        @click="selectedTool = tool"
      >
        <img
          v-lazy="
            require(`../../../assets/integration/cms-provider/integrations-${tool}.png`)
          "
          class="cms-image"
        />
      </div>
      <div class="integration-controls">
        <div v-if="selectedTool == 'google'" class="google-integration">
          <GoogleSheet
            v-if="!googleIntegration"
            class="google-button"
            :short="true"
          >
            {{ $t("create.step3.emailModal.authorize") }}Google sheets
          </GoogleSheet>
          <div v-else class="email-integration">
            Google Sheets {{ $t("create.step3.emailModal.isIntegrated") }}
          </div>
        </div>
        <div v-if="selectedTool == 'klaviyo'" class="klaviyo-integration">
          <Klaviyo v-if="!klaviyoIntegration" :short="true"></Klaviyo>
          <div v-else class="email-integration">
            Klaviyo {{ $t("create.step3.emailModal.isIntegrated") }}
          </div>
        </div>
      </div>
    </div>
  </small-modal>
</template>

<script>
import SmallModal from "@/components/shared/SmallModal";
import Klaviyo from "../../settings/integration-modals/Klaviyo.vue";
import GoogleSheet from "../../settings/integration-modals/GoogleSheet.vue";
import BannerModal from "@/components/shared/BannerModal.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    SmallModal,
    Klaviyo,
    GoogleSheet,
    BannerModal,
  },
  props: {
    integrations: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      tools: ["google", "klaviyo"],
      suggestion: "",
      selectedTool: "",
      klaviyoKey: "",
    };
  },

  computed: {
    klaviyoIntegration() {
      return this.integrations.find((e) => e.type == "klaviyo");
    },
    googleIntegration() {
      return this.integrations.find((e) => e.type == "google");
    },
    ...mapGetters({
      siteId: "account/getSiteId",
    }),
  },
  methods: {
    handleSubmit() {
      if (this.suggestion) {
        this.postSuggestion();
        return;
      }
      if (this.integrations && this.integrations.length > 0) {
        this.$notify({
          title: this.$t("shared.toastMessage.integratedSuccess"),
          type: "success",
        });
      } else {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("create.step3.emailModal.integrationError"),
          type: "error",
        });
      }
      this.$emit("close");
    },
    onCloseModal() {
      this.$emit("close");
    },
    async postSuggestion() {
      const dataToSend = {
        siteId: this.siteId,
        suggestion: this.suggestion,
      };
      await this.$store.dispatch("suggestion/postSuggestion", dataToSend);
      this.suggestion = "";
      this.$notify({
        title: this.$t("shared.toastMessage.suggestionSuccess"),
        type: "success",
      });
    },
  },
};
</script>

<style lang="scss">
.google-button {
  width: 100% !important;
}
</style>

<style lang="scss" scoped>
.email-integration {
  background-color: #e3f4f5;
  color: #5cc9bf;
  border-radius: 3px;
  margin: 5px;
  padding: 5px;
}
.capitalized {
  text-transform: capitalize;
}

.modal-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
  @include font-big;
  text-align: center;
  color: #585858;
}

.banner-text {
  @include base-font;
  @include font-normal;
  font-weight: 400;
  color: $dark-purple;
  white-space: nowrap;
}
.subtitle {
  @include base-font;
  @include font-normal;
  font-weight: 400;
  margin-bottom: 24px;
}
.image-grid {
  margin: 24px auto 0;
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
}

.img-wrapper {
  height: 110px;
  width: 250px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  border: 1px solid $light-grey;
  border-radius: 5px;
  &:hover {
    border: 1px solid $dark-purple;
  }
}
.cms-image {
  width: 220px;
  margin: auto;
}

.integration-controls,
.guide-link {
  grid-column: 1 / span 2;
  text-align: start;
}
.is-selected {
  border: 1.5px solid $dark-purple;
}
.google-integration {
  display: flex;
}
</style>
