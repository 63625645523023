import api from "../api";

const state = {
  invoices: [],
};

const getters = {
  currency: (state) => {
    return state.invoices;
  },
};

const mutations = {
  GET(state, invoices) {
    const sortedInvoices = invoices.sort(function (a, b) {
      return new Date(b.creationDate) - new Date(a.creationDate);
    });
    state.invoices = sortedInvoices;
  },
  getSingle(state, invoice) {
    state.invoice = invoice;
  },
  ADD_LAST_INVOICE(state, invoice) {
    state.invoices.push(invoice);
  },
};

const actions = {
  async getInvoices({ commit }, { siteId }) {
    try {
      const invoices = await api.getInvoices(siteId);
      commit("GET", invoices);
    } catch (err) {
      throw err;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
