<template>
  <div
    :style="`background-color:${stickyBarOptions.backgroundColor}; 
             ${moveY}`"
    class="stickyBar"
  >
    <div style="height: 100%; display: flex">
      <div
        style="align-items: center; display: flex"
        @click="stickyBarShowVideo"
      >
        <img
          height="16"
          width="16"
          src="https://vidjetvideostorage.s3.eu-west-1.amazonaws.com/Play+Icon+2.0+SVG.svg"
          style="margin-left: 6px"
        />
        <span
          :style="`color: ${stickyBarOptions.textColor};`"
          class="stickyBar-text"
          >{{ stickyBarOptions.text }}</span
        >
      </div>
      <div style="align-items: center; display: flex" @click="closeStickyBar">
        <img
          height="18"
          width="18"
          src="https://vidjetvideostorage.s3.eu-west-1.amazonaws.com/Close+icon+Sticky+Play+Button.svg"
          style="margin-left: 8px; margin-right: 7px"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StickyBar",
  props: {
    stickyBarOptions: {
      type: Object,
      default: () => {
        return {
          text: "Watch Video",
          textColor: "#FFFFFF",
          backgroundColor: "#7B01B5",
        };
      },
    },
    moveY: { type: String, default: "bottom: 20px;" },
    isSticky: { type: Boolean, default: true },
  },
  methods: {
    stickyBarShowVideo() {
      this.$emit("handleShowVideo");
    },
    closeStickyBar() {
      this.$emit("handleCloseStickyBar");
    },
  },
};
</script>

<style lang="scss" scoped>
.stickyBar {
  width: 31px;
  z-index: 15;
  height: 34px;
  position: absolute;

  background-color: #7660ff;
  border-radius: 0px 100px 100px 0px;
  overflow: hidden;
  cursor: pointer;
}

.stickyBar:hover {
  width: fit-content;
  height: 38px;
}

.stickyBar-text {
  font-size: 14px;
  font-weight: 600;
  margin-left: 9px;
  line-height: 14px;
  margin-right: 4px;
  font-family: "Gothic A1", sans-serif;
}
</style>
