import axios from "axios";

async function sendIssue(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/hubspot-form/help`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err;
  }
}

export default {
  sendIssue,
};
