<template>
  <article
    class="usecase-card"
    :class="{ selected }"
    @click="$emit('card-selected', index)"
  >
    <img v-if="tagLocation" class="card-tag" :src="selectedTag" />
    <div class="card-front">
      <img class="card-image" :src="imageLocation" :alt="imageAlt" />
      <h1 class="card-title">
        <slot name="title"></slot>
      </h1>
    </div>
    <div class="card-back">
      <slot name="backside"></slot>
    </div>
  </article>
</template>

<script>
export default {
  props: {
    imageLocation: {
      type: String,
      default: "",
    },
    imageAlt: {
      type: String,
      default: "A description of the image",
    },
    tagLocation: {
      type: String,
      default: "",
    },
    selected: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  computed: {
    selectedTag() {
      if (this.selected) {
        return require(`@/assets/usecases_cards/selected-${this.tagLocation}`);
      }
      return require(`@/assets/usecases_cards/${this.tagLocation}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.usecase-card:hover .card-back {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
.usecase-card:hover .card-front {
  display: none;
}

.usecase-card:hover .card-tag {
  display: none;
}

.card-tag {
  position: absolute;
  top: -10px;
  right: 8px;
}

.card-front {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.card-back {
  @include font-smaller;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: $medium-grey;
  background-color: rgba(226, 124, 252, 0.1);
  border-radius: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  opacity: 0;
  p {
    text-align: start;
  }
}

.card-title {
  @include base-font;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  margin: auto;
  color: $dark-grey;
}

.card-image {
  width: 100px;
  margin-left: 8px;
}
.usecase-card {
  position: relative;
  cursor: pointer;
  border-radius: $card-border-radius;
  text-align: center;
  width: 230px;
  height: 95px;
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
  border: 2px solid $medium-grey;
  box-sizing: border-box;
  border-radius: 8px;
  @media (max-width: 1200px) {
    width: 255px;
    height: 90px;
  }
}

.selected {
  border: 2px solid $dark-purple;
  .card-title {
    color: $dark-purple;
  }
}
</style>
