import axios from "axios";

export default {
  async getTags({ siteId }) {
    const requestOptions = {
      method: "GET",
      url: `${process.env.VUE_APP_API_URL}/tags/${siteId}`,
      headers: { "Content-Type": "application/json" },
    };
    try {
      const res = await axios(requestOptions);
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  },

  async createTag(data) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data,
    };

    try {
      const response = await axios(
        `${process.env.VUE_APP_API_URL}/tag`,
        requestOptions
      );

      return response.data;
    } catch (err) {
      throw err.response.data;
    }
  },

  async deleteTag(tagId) {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    try {
      const res = await axios(
        `${process.env.VUE_APP_API_URL}/tag/${tagId}`,
        requestOptions
      );
      return res.data;
    } catch (err) {
      throw err.response.data;
    }
  },
};
