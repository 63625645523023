<template>
  <div class="date-device-filters">
    <div id="datepicker-trigger" class="date-picker" @click="toggleDatePicker">
      <div class="datebox-left datebox startDate">
        <CalendarIcon />
        <div class="datepicker-date">{{ formatDate(fromDatePick) }}</div>
      </div>
      <div class="arrow-right" />
      <div class="arrow-right2" />
      <div class="datebox datebox-right endDate">
        <CalendarIcon />
        <div class="datepicker-date">{{ formatDate(toDatePick) }}</div>
      </div>
    </div>
    <AirbnbStyleDatepicker
      :trigger-element-id="'datepicker-trigger'"
      :mode="'range'"
      :fullscreen-mobile="true"
      :date-one="fromDatePick"
      :date-two="toDatePick"
      :trigger="showDates"
      :show-shortcuts-menu-trigger="false"
      @closed="showDates = false"
      @apply="showDates = false"
      @date-one-selected="updateDate('fromDatePick', $event)"
      @date-two-selected="updateDate('toDatePick', $event)"
    />
  </div>
</template>

<script>
import CalendarIcon from "@/assets/svg/dashboard/calendar.svg?inline";
import monthNames from "@/utils/monthNames";

export default {
  components: {
    CalendarIcon,
  },
  props: {
    firstInitDoneProp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fromDatePick: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      toDatePick: new Date(),
      showDates: false,
      monthNames,
    };
  },
  methods: {
    toggleDatePicker() {
      this.showDates = !this.showDates;
    },
    formatDate(date) {
      const newDate = new Date(date);
      return `${
        this.monthNames[newDate.getMonth()]
      } ${newDate.getDate()}, ${newDate.getFullYear()}`;
    },
    updateDate(type, value) {
      if (value && this.firstInitDoneProp) {
        if (type === "fromDatePick") {
          this.fromDatePick = value;
        } else {
          this.toDatePick = value;
        }
        if (this.fromDatePick && this.toDatePick) {
          this.$emit("dates-changed", this.fromDatePick, this.toDatePick);
        }
      }
    },
  },
};
</script>

<style lang="scss">
/* We need this additional tag to override the Multiselect component default styles */
@import "~vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css";

.asd__wrapper--datepicker-open {
  z-index: map-get($zindex, colorPicker) !important;
}
</style>
<style lang="scss" scoped>
svg {
  path {
    fill: currentColor;
  }
}

.date-picker {
  display: flex;
  color: #fff;
  @include font-smaller;
  cursor: pointer;
  min-height: 36px;
  width: 300px;

  .datebox {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
    height: 36px;
  }

  .datebox-left {
    border-radius: 5px 0px 0px 5px;
  }

  .datebox-right {
    border-radius: 0px 5px 5px 0px;
  }

  .startDate {
    background-color: $pink;
  }

  .endDate {
    background-color: $dark-purple;
  }

  .arrow-right {
    width: 0;
    height: 0;
    border-top: 20px solid $dark-purple;
    border-bottom: 16px solid $dark-purple;
    border-left: 16px solid $pink;
  }
}
</style>
