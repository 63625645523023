<template>
  <div
    class="preview"
    :class="{
      isEditMode,
      isEmbed,
      isBubble,
      isFullScreen: isFullScreen && isBubble,
      isPopup,
      isMultiple,
      bubbleAndPlayerTogether,
      'full-height': isEmbed && isMultiple,
      'video-library-modal': isVideoLibraryModal,
      'video-library-preview-attached': isVideoLibraryPreview,
    }"
  >
    <div
      v-if="previewCampaign?.videos?.length === 0"
      class="transparent-background"
    ></div>
    <div v-if="!isVideoLibraryModal" class="top-grid-item">
      <CampaignState
        v-if="hasCampaignState && !isEmbed"
        :campaign-id="campaignId"
      />
      <h3 v-if="!isStep2">👇 {{ $t("create.step3.previewNote") }}</h3>
      <h1
        v-if="isStep2 && $route.query.goal === 'shoppableFlow'"
        class="step2-preview-title"
      >
        {{ $t("create.step2.previewText") }}
      </h1>
    </div>

    <!-- step 2 doesn't require the player -->

    <div
      v-if="isStep2"
      :class="[
        'html-container',
        { isLandscape: !isPortrait && !isSquare, isSquare },
        { 'video-library-preview': isVideoLibraryModal },
      ]"
      :style="htmlPlayerWidth"
    >
      <player-lib
        :preview-campaign="campaignPreview"
        :site-prop="site || {}"
        :is-in-preview-component="true"
        :is-preview-device-mobile="false"
        :is-video-library-preview="isVideoLibraryPreview"
      />
    </div>
    <div
      v-else
      :class="[
        'preview-background',
        {
          'video-preview-desktop': displayDesktop,
          'video-preview-mobile': !displayDesktop,
          isMobile: !displayDesktop && !isEmbed,
          isPortrait,
          hideControlBar,
          isSquare,
          isLandscape: !isPortrait && !isFullScreen,
          'product-pages-preview': isProductPages || isBulk,
        },
      ]"
      :style="isEmbed ? 'background: none;' : ''"
    >
      <!-- preview wrapper plays the same role as the iframe -->
      <div
        v-if="isEnabled && showPreview"
        ref="iframeWrapper"
        class="iframe-wrapper"
        :class="{
          'bubble-right': isBubbleOnTheRight,
        }"
        :style="displayDesktop ? desktopMove : mobileMove"
      >
        <sticky-bar
          v-show="showStickyBar"
          :sticky-bar-options="
            chosenDevice === 'desktop'
              ? desktop.stickyBarOptions
              : mobile.stickyBarOptions
          "
          :move-y="displayDesktop ? desktopMove : mobileMove"
          @handleShowVideo="stickyBarShowVideo"
          @handleCloseStickyBar="closeStickyBar"
        />
        <player-lib
          v-if="
            showVideo &&
            !showStickyBar &&
            campaignPreview.videos &&
            campaignPreview.videos[0]?.url
          "
          :preview-campaign="campaignPreview"
          :is-in-preview-component="true"
          :is-preview-device-mobile="!displayDesktop"
          :has-multiple-videos="isMultiple"
          :site-prop="site || {}"
          @close-all="handleCloseAll"
          @close-popup="handleCloseAll"
          @add-product="$emit('add-product')"
          @toggle-fullscreen="handleFullScreenToggle"
          @notify-preview="notifyShoppedIcon()"
          @close-player-preview="closePlayerPreview"
        />
      </div>
    </div>

    <div v-if="!isBulk && !isStep2" class="bottom-grid-item">
      <div
        v-if="!isEmbed && !isStory && !isCarousel"
        class="show-again-components"
        :class="{ isMobile: !displayDesktop }"
      >
        <button
          v-if="
            (!showVideo && !isStickyBar) ||
            (!showVideo && isStickyBar && !showStickyBar)
          "
          class="button-primary"
          :class="{
            'buffer-space-toggler': hideTogglerOnStep3,
            right: isBubbleOnTheRight,
          }"
          @click="showAgain"
        >
          {{ $t("shared.buttons.showAgain") }}
        </button>
      </div>
      <div
        v-if="hideTogglerOnStep3 && !isEmbed && !isProductPages"
        class="device-toggle"
      >
        <div class="device-toggle-wrapper">
          <img
            style="margin-right: 35px"
            :src="displayDesktop ? desktopIconSelected : desktopIcon"
            alt="Desktop icon"
            @click="changeDisplay()"
          />
          <img
            :src="displayDesktop ? mobileIcon : mobileIconSelected"
            alt="Mobile icon"
            @click="changeDisplay()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CampaignState from "./CampaignState.vue";
import { Home } from "player";
import StickyBar from "./StickyBar.vue";
import isEmpty from "lodash/isEmpty";
import enums from "@/enums";
import api from "@/api";
import { mapGetters } from "vuex";

const { FormatType } = enums;
export default {
  name: "VidjetPreview",
  components: {
    CampaignState,
    PlayerLib: Home,
    StickyBar,
  },
  props: {
    isEditMode: { type: Boolean, default: false },
    isStep2: { type: Boolean, default: false },
    isEmbed: { type: Boolean, default: false },
    chosenDevice: {
      validator: function (value) {
        return ["mobile", "desktop"].indexOf(value) !== -1;
      },
      default: "desktop",
    },
    desktop: { type: Object, default: () => {} },
    previewCampaign: { type: Object },
    mobile: { type: Object },
    hasCampaignState: { type: Boolean },
    hideVideo: { type: Boolean, default: false },
    // For the campaign state
    campaignId: { type: String, default: "" },
    isDesktopToggled: { type: Boolean, default: true },
    isMobileToggled: { type: Boolean, default: true },
    ctaPreview: { type: [Object, Array], default: () => {} },
    textOptionsPreview: { type: [Object, Array], default: () => {} },
    embedThumbnail: { type: String, default: "" },
    isCarousel: { type: Boolean, default: false },
    showPreview: { type: Boolean, default: true },
    allVideos: { type: Array, default: () => [] },
    isVideoLibraryModal: { type: Boolean, default: false },
    isVideoLibraryPreview: { type: Boolean, default: false },
    isProductPages: { type: Boolean, default: false },
    isBulk: { type: Boolean, default: false },
    campaignHasNoVideos: { type: Boolean, default: false },
  },
  data() {
    return {
      FormatType,
      desktopIconSelected: require("../../assets/desktop-icon-selected.png"),
      desktopIcon: require("../../assets/desktop-icon.png"),
      mobileIconSelected: require("../../assets/mobile-icon-selected.png"),
      mobileIcon: require("../../assets/mobile-icon.png"),
      currentDesktopIcon: require("../../assets/desktop-icon-selected.png"),
      currentMobileIcon: require("../../assets/mobile-icon.png"),
      showStickyBar: false,
      showVideo: true,
      paddingBottom: 170,
      isCurrentlyFullscreen: false,
      clickedCarouselIndex: null,
      videoMetadata: {},
      currentVideoIndex: 0,
    };
  },
  computed: {
    ...mapGetters({ site: "site/getSite" }),
    firstVideo() {
      return this.allVideos[0];
    },
    desktopMove() {
      if (this.isCurrentlyFullscreen) return;
      const { moveX, moveY } = this.desktop;

      if (this.isStickyBar) {
        return `bottom: ${moveY || 0 + 20}px`;
      }

      const offsetMoveX = moveX !== 0 && !isNaN(moveX) ? moveX * 0.5 : 0;
      const offsetMoveY = moveY !== 0 && !isNaN(moveY) ? moveY * 0.75 : 0;
      return `${
        this.isBubbleOnTheRight ? "right" : "left"
      }: ${offsetMoveX}px; bottom: ${offsetMoveY}px;`;
    },

    mobileMove() {
      if (this.isCurrentlyFullscreen) return;
      const { moveX, moveY } = this.mobile;

      if (this.isStickyBar) {
        return `bottom:${moveY || 0 + 20}px`;
      }

      const offsetMoveX = moveX !== 0 ? moveX * 0.25 : 0;
      const offsetMoveY = moveY !== 0 ? moveY * 0.75 : 0;

      return `${
        this.isBubbleOnTheRight ? "right" : "left"
      }: ${offsetMoveX}px; bottom: ${offsetMoveY}px;`;
    },

    displayDesktop() {
      return this.chosenDevice === "desktop";
    },

    htmlPlayerWidth() {
      const video = this.campaignPreview.videos[0];
      if (video.height) {
        const { height, width } = video;
        if (this.isPortrait && this.isVideoLibraryModal) {
          return "width: 35%";
        } else if (height >= width) {
          return "width: 23%";
        } else {
          return "width: 75%";
        }
      }
      return "width: 40%";
    },

    isBubble() {
      return (
        !this.isStep2 &&
        this[this.displayDesktop ? "desktop" : "mobile"].formatType ===
          FormatType.bubble
      );
    },

    isPopup() {
      // Since 0 is popup and 1 is bubble
      return (
        !this.isFullScreen &&
        !this.isStep2 &&
        this[this.displayDesktop ? "desktop" : "mobile"].formatType ===
          FormatType.popup
      );
    },

    isPortrait() {
      // bubble multiple i always portrait sizing
      if (this.isMultiple && this.isBubble) {
        return true;
      } else if (this.isStep2) {
        const video = this.previewCampaign;
        return video.height > video.width;
      } else if (this.isSquare || this.isFullScreen) {
        return false;
        // embed multiple take the sizing of the first video
      } else if (this.campaignHasNoVideos) {
        return false;
      } else if (
        (this.allVideos &&
          this.allVideos[0] &&
          this.allVideos[0].height &&
          this.isEmbed &&
          this.isMultiple) ||
        !this.isMultiple
      ) {
        return this.allVideos[0].height > this.allVideos[0].width;
      }
      return false;
    },

    isSquare() {
      if (!this.allVideos || !this.allVideos[0]) return;
      let { height, width } = this.allVideos[0];
      return height / width > 0.9 && height / width < 1.2;
    },

    isFullScreen() {
      const selectedFormat = this.displayDesktop
        ? { ...this.desktop }
        : { ...this.mobile };
      return selectedFormat.isFullScreen;
    },

    isBubbleOnTheRight() {
      // Since 0 is left and 1 is right, Boolean(0) is false
      return this.displayDesktop
        ? Boolean(this.desktop?.position)
        : Boolean(this.mobile?.position);
    },

    hideTogglerOnStep3() {
      return (
        this.$route.name !== "editCampaignFormat" &&
        this.$route.name !== "createCampaignStep3" &&
        !isEmpty(this.desktop) &&
        !isEmpty(this.mobile)
      );
    },

    isEnabled() {
      return this.chosenDevice === "desktop"
        ? this.isDesktopToggled
        : this.isMobileToggled;
    },

    isStickyBar() {
      const selectedFormat = this.displayDesktop
        ? { ...this.desktop }
        : { ...this.mobile };

      return selectedFormat.isStickyBar;
    },

    isStory() {
      const selectedFormat = this.displayDesktop
        ? { ...this.desktop }
        : { ...this.mobile };

      return selectedFormat.isStory;
    },

    isMultiple() {
      return this.allVideos.length > 1;
    },

    // deep object copy to avoid directly mutating props
    campaignPreview() {
      const campaign = JSON.parse(JSON.stringify(this.previewCampaign ?? {}));
      if (this.isStep2) {
        const defaultStep2formats = [
          {
            formatType: 2,
            deviceType: 1,
            autoLoop: false,
            animatedThumbnail: false,
            hidePlayPause: false,
            hideControlBar: false,
            title: "",
            cta: {},
            isStickyBar: false,
          },
        ];
        return { videos: [campaign], formats: defaultStep2formats };
      }
      campaign.formats = [];
      if (Object.keys(this.desktop ?? {}).length > 0)
        campaign.formats.push(this.desktop);
      if (
        !this.isCarousel &&
        !this.isEmbed &&
        Object.keys(this.mobile ?? {}).length > 0
      )
        campaign.formats.push(this.mobile);
      // add the embed thumbnail if there is one
      if (this.embedThumbnail) {
        campaign.videos[0].thumbnail = this.embedThumbnail;
      }

      return campaign;
    },

    bubbleAndPlayerTogether() {
      if (this.isBubble) {
        return this.displayDesktop ? !this.desktop.hideBubble : false;
      }
      return false;
    },

    hideControlBar() {
      return this.displayDesktop
        ? this.desktop.hideControlBar
        : this.mobile.hideControlBar;
    },
  },
  watch: {
    isStickyBar(newValue) {
      if (newValue) {
        this.showVideo = false;
        this.showStickyBar = true;
      } else {
        this.showVideo = true;
        this.showStickyBar = false;
      }
    },
    firstVideo: {
      handler() {
        if (this.isEmbed && this.isMultiple && !this.isFullScreen) {
          this.setIframeWrapperSize();
        }
      },
      deep: true,
    },
    isFullScreen() {
      this.showVideo = false;
      setTimeout(() => {
        this.showVideo = true;
      }, 1);
    },
  },
  mounted() {
    if (!this.isStep2) {
      //If hideVideo is set to true, it means that the device is deactivated
      if (this.hideVideo) {
        this.showVideo = false;
        return;
      }
      this.previewCampaign.videos.forEach((video) => {
        this.isThumbnailReturnError(video.thumbnail).then((result) => {
          if (!result) {
            api.sendLog({
              logMsg: "Thumbnail is not available",
              campaignId: this.campaignId,
              videoId: video.videoId,
            });
            return;
          }
        });
      });

      if (this.isStickyBar) {
        this.showStickyBar = true;
        this.showVideo = false;
      }
    }
    if (this.isEmbed && this.isMultiple && !this.isFullScreen) {
      this.setIframeWrapperSize();
    }

    if (!this.site) {
      this.$store.dispatch("site/getSite", {
        siteId: this.$store.state.account.user.siteId,
      });
    }
  },

  methods: {
    notifyShoppedIcon() {
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("shared.toastMessage.shopped"),
        type: "success",
      });
    },

    changeDisplay() {
      this.$emit("change-display");
    },

    handleCloseAll() {
      if (this.isStickyBar) {
        this.showStickyBar = true;
        this.showVideo = false;
        return;
      }
    },
    // once we have metadata we can size the container

    stickyBarShowVideo() {
      this.showVideo = true;
      this.showStickyBar = false;
    },

    closeStickyBar() {
      this.showStickyBar = false;
    },

    closePlayerPreview() {
      this.showVideo = false;
      if (this.isStickyBar) {
        this.showStickyBar = true;
      }
    },

    isThumbnailReturnError(thumbnail) {
      return new Promise((resolve) => {
        let img = new Image();
        img.onload = () => {
          resolve(true);
        };
        img.onerror = () => {
          resolve(false);
        };
        img.src = thumbnail;
      });
    },

    showAgain() {
      this.isStickyBar ? (this.showStickyBar = true) : (this.showVideo = true);
    },

    handleFullScreenToggle(payload) {
      this.isCurrentlyFullscreen = payload;
      this.$emit("fullscreen-toggle", this.isCurrentlyFullscreen);
    },

    setIframeWrapperSize() {
      this.showVideo = false;
      const size = this.isEmbed
        ? this.firstVideo
        : {
            height: 16,
            width: 9,
          };
      setTimeout(() => {
        const ratio = size.height / size.width;
        const width = this.$refs.iframeWrapper.offsetWidth;
        const height = width * ratio;
        this.$refs.iframeWrapper.style.height = `${height}px`;
        this.showVideo = true;
      }, 2);
    },
  },
};
</script>

<style lang="scss">
// default is landscape and popup
$player-container-max-width-portrait: 200px;
$player-container-max-width-landscape: 350px;
$player-container-max-width-square: 30%;
$mobile-min-width-landscape: 200px;
$mobile-min-width-square: 80%;
$background-desktop-min-width: 900px;
$background-desktop-min-height: 600px;
$background-mobile-min-width: 350px;
// take this in external css sheet
.step-2-wrapper .main-wrapper {
  background: transparent !important;
  .player-interface {
    display: none;
  }
}

.html-container {
  text-align: center;
}

.iframe-wrapper {
  min-width: 370px;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .main-wrapper {
    border-radius: 20px;
  }
}

.isPortrait .iframe-wrapper {
  min-width: calc(220px + 20px);
}

.player-wrapper {
  min-width: 190px;
}

.isLandscape .player-wrapper {
  min-width: 350px;
}

.isMultiple .isLandscape .player-wrapper {
  min-width: unset;
}

.share-button {
  display: none;
}

.hideControlBar .video-js .vjs-control-bar {
  background: none !important;
}

.hideControlBar .video-js .vjs-play-control,
.hideControlBar .video-js .vjs-progress-control,
.hideControlBar .video-js .vjs-time-control,
.hideControlBar .video-js .vjs-fullscreen-control {
  display: none;
}

.preview .isMobile .isPopup .player-container {
  max-width: 90%;
}

.preview.isPopup .isMobile .player-container.isPortrait {
  max-width: 60%;
}

.isFullScreen.isBubble .iframe-wrapper {
  overflow: hidden;
  border-radius: 10px;
  bottom: 0;
  height: 100% !important;
  width: 100% !important;
}

.isFullScreen.isBubble .player-wrapper {
  height: 100% !important;
}

.full-height .player-container {
  height: 100% !important;
}

.isPopup .panel {
  height: 100% !important;
}

.isBubble .iframe-wrapper {
  margin: 0 !important;
  width: 45%;
  overflow: visible;
  height: auto;
  position: absolute;
  bottom: 0;
}

.isBubble .bubble-player {
  margin: 0 10px;
}

.isBubble.isMultiple .isPortrait .iframe-wrapper {
  margin: 10px;
  width: 240px;
  height: 426.667px;
  overflow: hidden;
  border-radius: 10px;
  position: absolute;
}

.isBubble.isMultiple .title-container {
  display: none;
}

.isBubble.isMultiple .player-container video.video-js,
.isBubble.isMultiple .player-container video.vjs-tech {
  box-shadow: none !important;
  position: absolute !important;
}

.isBubble.isSquare .iframe-wrapper {
  width: 30%;
}

.isBubble .isPortrait .iframe-wrapper {
  width: 25%;
}

.isBubble .isMobile.isLandscape .iframe-wrapper {
  width: 90%;
}

.isBubble .isMobile.isPortrait .iframe-wrapper {
  width: 60%;
}

.bubble-player {
  position: absolute;
  bottom: 16px;
  left: 16px;
}

.isEmbed.isMultiple .iframe-wrapper {
  margin: auto;
}

.isEmbed .preview-background {
  background: none;
}

.isEmbed .panel {
  height: 100% !important;
}

.isEmbed .player-container {
  border-radius: 10px;
  overflow: hidden;
}

.isEmbed .main-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.isEmbed .player-wrapper {
  border-radius: 8px;
  overflow: hidden;
  margin: 0 auto;
}

.isEmbed .iframe-wrapper {
  width: 80%;
  height: fit-content;
  align-self: center;
  margin: 0 auto;
}

.isEmbed .isSquare .iframe-wrapper {
  width: 50% !important;
}

.isEmbed .isPortrait .iframe-wrapper {
  width: 35% !important;
}
</style>

<style scoped lang="scss">
.bottom-grid-item {
  width: 100%;
  margin: 16px 0;
}

.preview {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
    "progress"
    "player"
    "showagain"
    "device";
  grid-template-rows: minmax(40px, auto) minmax(0, 1fr);
  place-items: center;
  align-content: start;
  align-items: center;
  position: relative;
}
.video-library-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-template-areas: "player";
}
.top-grid-item {
  margin: 16px auto 16px auto;
}

.hideVideo {
  opacity: 0;
  display: none;
}

.step2-preview-title {
  @include font-big;
  font-weight: $w-bold;
}
.preview-background {
  position: relative;
  display: flex;
  overflow: hidden;
}

.video-preview-desktop {
  background-image: url("~@/assets/svg/preview-desktop.svg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 95%;
  height: 100%;
  border-radius: 20px;
}

.video-preview-mobile {
  height: 100%;
  width: 40%;
  margin: 0 auto;
  background-image: url("~@/assets/svg/preview-mobile.svg");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px;
  width: 65%;
  width: 400px;
  max-height: 700px;
  &.isEditMode {
    margin: 42px 0px 15px 0px;
    @media (max-width: 1500px) {
      margin: 32px 0px 15px 0px;
    }
  }
}

// This takes the place of the iframe in player-app

.show-again-components {
  align-self: center;
  .button-primary {
    position: absolute;
    margin-left: 50px;
    bottom: 50px;
  }
}
.bubble-right {
  right: 0;
}
.right {
  right: 50px;
}

.buffer-space-toggler {
  margin-bottom: 50px;
}

.device-toggle {
  margin: 0 auto;
  width: fit-content;

  .device-toggle-wrapper {
    display: flex;
  }
  img {
    cursor: pointer;
  }
}
.video-library-preview {
  transform: translateY(-40px);
}

.video-library-preview-attached {
  position: absolute;
  right: 0;
  width: 70%;
}

.product-pages-preview {
  height: 80%;
  align-self: flex-start;
}

.transparent-background {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 100;
}
</style>
