export default (siteCountry) => {
  const intraCommunity = [
    "AT",
    "BE",
    "HR",
    "BG",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "FR",
    "DE",
    "GR",
    "HU",
    "IE",
    "IT",
    "LV",
    "LT",
    "LU",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SK",
    "SI",
    "ES",
    "SE",
  ];

  if (intraCommunity.includes(siteCountry)) {
    return true;
  }
  return false;
};
