var render = function render(){var _vm=this,_c=_vm._self._c;return _c('small-modal',{staticClass:"sorting-modal",attrs:{"fit-content":true,"show-close-button":false},on:{"close":function($event){return _vm.$emit('close-sorting-modal')}}},[_c('div',{staticClass:"campaign-sorting-container"},[_c('h1',{staticClass:"order-by"},[_vm._v(_vm._s(_vm.$t("campaignManager.sort")))]),_c('span',{staticClass:"reset",on:{"click":function($event){return _vm.sortCampaigns(null, true)}}},[_vm._v(_vm._s(_vm.$t("campaignManager.filter.reset")))]),_c('div',{staticClass:"sorting-wrapper"},_vm._l((_vm.sortingOptions),function(sortingOption){return _c('div',{key:sortingOption.key,staticClass:"sorting-options"},[_c('div',{staticClass:"sorting-option"},[_c('h2',{staticClass:"sorting-type-title",class:{
              'sort-selected': _vm.selectedSorting === sortingOption.key,
            },on:{"click":function($event){return _vm.toggleSortDirection(sortingOption.key)}}},[_vm._v(" "+_vm._s(sortingOption.label)+" ")]),_c('div',{staticClass:"sorting-arrows"},[_c('SortArrow',{staticClass:"sort-arrow",class:{
                'arrow-selected':
                  _vm.selectedSorting === sortingOption.key &&
                  _vm.selectedArrow === 'asc',
              },on:{"click":function($event){return _vm.sortCampaigns(sortingOption.key, true)}}}),_c('SortArrow',{staticClass:"sort-arrow reverse-arrow",class:{
                'arrow-selected':
                  _vm.selectedSorting === sortingOption.key &&
                  _vm.selectedArrow === 'desc',
              },on:{"click":function($event){return _vm.sortCampaigns(sortingOption.key, false)}}})],1)]),_c('div',{staticClass:"separation-line"})])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }