import api from "../api";

const state = {
  log: {},
};

const actions = {
  async sendLogAction({ commit }, { siteId, logMsg }) {
    try {
      await api.sendLog({ siteId, logMsg });
    } catch (err) {
      throw err;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
};
