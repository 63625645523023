<template>
  <small-modal
    class="urls-modal"
    :fit-content="true"
    @close="$emit('close-options-modal')"
  >
    <div class="url-wrapper">
      <div class="url-details">
        <h1>{{ $t("campaignManager.pagesAssociated") }}</h1>
        <p>
          {{ $t("campaignManager.pagesAssociatedDescription1") }}
          <strong>{{ campaign.name }}</strong
          >{{ $t("campaignManager.pagesAssociatedDescription2") }}
        </p>
      </div>
      <div v-if="hasUrlsWithViews">
        <div
          v-for="url in campaign.urlsWithDisplay"
          :key="url"
          class="url-list"
        >
          <div class="url">
            <a class="url-anchor" :href="url" target="_blank"
              ><img class="url-image" src="@/assets/svg/open-url.svg" />{{
                url
              }}</a
            >
          </div>
          <div class="separation-line"></div>
        </div>
      </div>
      <div v-else>
        <p>{{ $t("campaignManager.noUrlsWithViews") }}</p>
      </div>
    </div>
  </small-modal>
</template>

<script>
import SmallModal from "@/components/shared/SmallModal.vue";
export default {
  components: {
    SmallModal,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasUrlsWithViews() {
      return (
        this.campaign.urlsWithDisplay &&
        this.campaign.urlsWithDisplay.length > 0
      );
    },
  },
};
</script>
<style lang="scss">
.urls-modal .small-modal.fit-modal {
  width: 623px;
  padding: 24px !important;
}
</style>

<style lang="scss" scoped>
.url-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.url-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
  h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
  }
}
p {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}
.url {
  display: flex;
  gap: 6px;

  .url-anchor {
    display: flex;
    text-decoration: none;
    font-size: 12px;
    color: $dark-grey;
    align-items: center;
  }
}

.url-image {
  margin-right: 4px;
  margin-top: 2px;
}
.separation-line {
  width: 100%;
  height: 0;
  border: 1px solid $light-grey;
  margin: 8px 0;
}
</style>
