import axios from "axios";

async function getUsage(data) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    params: data,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/usage`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

export default {
  getUsage,
};
