<template>
  <div class="campaign-state">
    <section v-if="findStateFlag() === 1">
      <p>
        {{ $t("editCampaign.campaignState.campaignState")
        }}<span class="live">LIVE</span>
      </p>
    </section>
    <section v-else class="flex-inactive">
      <p>
        {{ $t("editCampaign.campaignState.campaignState")
        }}<span class="inactive">INACTIVE</span>
      </p>
      <a class="button-primary link-to-campaign" @click="launchCampaign">
        {{ $t("editCampaign.campaignState.preview") }}
      </a>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    campaignId: { type: String },
  },
  computed: {
    ...mapState({ campaign: "campaign" }),
  },
  methods: {
    findStateFlag() {
      return this.campaign.campaigns.find(
        (campaign) => campaign._id === this.campaignId
      )?.stateFlag;
    },

    launchCampaign() {
      this.$router.push({
        name: "launchCampaign",
        params: {
          campaignId: this.campaignId,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.campaign-state {
  text-align: center;
  @include base-font;
  @include font-normal;
  p {
    margin-bottom: 15px;
  }
}

.flex-inactive {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.live {
  color: $dark-purple;
  font-weight: 500;
}

.inactive {
  color: $light-purple;
  font-weight: 500;
}

.link-to-campaign {
  width: fit-content;
  margin-bottom: 16px;
}
</style>
