<template>
  <div class="cta-integration-block">
    <div
      v-for="integration in integrations"
      :key="integration.id"
      class="email-integration"
    >
      <span class="capitalized">{{ integration.type }} </span
      >{{ $t("create.step3.emailModal.isIntegrated") }}
    </div>

    <button
      v-if="!integrations || integrations.length === 0"
      class="action-button"
      @click="emitOpenModal()"
    >
      Add Integration
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    integrations: {
      type: Array,
      default: [],
    },
  },
  computed: {
    ...mapGetters({
      getSiteId: "account/getSiteId",
    }),
  },
  async created() {
    await this.$store.dispatch("site/getIntegrations", {
      siteId: this.getSiteId,
    });
  },
  methods: {
    emitOpenModal() {
      this.$emit('openModal');
    },
  }
};
</script>

<style scoped>

.action-button {
  margin: 10px auto 0 auto;
}

.email-integration {
  background-color: #e3f4f5;
  color: #5cc9bf;
  border-radius: 3px;
  margin: 5px;
  padding: 5px;
}

.capitalized {
  text-transform: capitalize;
}
</style>
