<template>
  <div class="images">
    <img
      v-for="image in images"
      :key="image"
      :src="require(`@/assets/${image}-logo.svg`)"
      class="card-logos"
      alt="card-logos"
    />
  </div>
</template>
<script>
export default {
  name: "CreditCardImages",
  props: {
    images: {
      type: Array,
      default() {
        return ["visa", "mastercard", "amex"]
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.images {
  display: flex;
  gap: 8px;
  align-items: center;
  float: right;
}
</style>
