<template>
  <form action="">
    <ul class="company-input">
      <li>
        <label class="company-label" for="website-url">{{
          $t("account.companyInformation.inputs.websiteUrl")
        }}</label>
        <input
          id="website-url"
          type="text"
          :value="site.site.websiteUrl"
          @input="onUrlUpdate"
          @change="updateSite"
        />
      </li>

      <li>
        <label class="company-label" for="company-name">{{
          $t("account.companyInformation.inputs.companyName")
        }}</label>
        <input
          id="company-name"
          type="text"
          :value="site.site.companyName"
          @input="onNameUpdate"
          @change="updateSite"
        />
      </li>

      <li>
        <label class="company-label" for="address">{{
          $t("account.companyInformation.inputs.address")
        }}</label>
        <input
          id="address"
          type="text"
          autocomplete="off"
          :value="getAddressString"
          @click="openAddressModal"
        />
      </li>
    </ul>
  </form>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "CompanyInformation",
  computed: {
    ...mapState({ site: "site" }),
    ...mapGetters({
      siteId: "site/getSiteId",
      getAddressString: "site/getAddressString",
    }),
  },
  methods: {
    openAddressModal() {
      this.$emit("toggle");
    },

    onUrlUpdate(e) {
      this.newUrl = e.target.value;
    },

    onNameUpdate(e) {
      this.newName = e.target.value;
    },

    async updateSite() {
      if (this.newUrl === "" || this.newName === "") {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.emptyField"),
          type: "error",
        });
      } else {
        await this.$store.dispatch("site/updateSite", {
          siteId: this.siteId,
          dataToUpdate: { websiteUrl: this.newUrl, companyName: this.newName },
        });
        this.$notify({
          title: this.$t("shared.toastMessage.success"),
          ignoreDuplicates: true,
          text: this.$t("shared.toastMessage.profileUpdated"),
          type: "success",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.company-input {
  height: 100px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.check-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  svg {
    height: 24px;
    width: 24px;
  }
}

.company-label {
  margin-left: 24px;
  @include font-small;
  vertical-align: -webkit-baseline-middle;
}

#website-url,
#company-name,
#address {
  @include base-font;
  @include font-small;
  color: black;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3px;
  width: 55%;
  height: 34px;
  margin-right: 2vw;
  float: right;
  padding: 3px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 1px rgba(226, 124, 252, 0.7);
  }
}

@include media(">tablet", "<=desktop") {
  .company-label {
    @include font-small;
    margin-left: 16px;
  }

  #website-url,
  #company-name,
  #address {
    @include font-small;
    min-width: 180px;
  }
}

@include media(">phone", "<=tablet") {
  .company-label {
    margin-left: 12px;
    @include font-smaller;
  }

  #website-url,
  #company-name,
  #address {
    @include font-smaller;
    width: 60%;
    min-width: 150px;
  }
}

@include media("<=phone") {
  .company-label {
    margin-left: 12px;
    @include font-smaller;
  }

  #website-url,
  #company-name,
  #address {
    vertical-align: sub;
    @include font-smaller;
    width: 50%;
  }
}
</style>
