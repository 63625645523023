<template>
  <div class="range-wrapper">
    <input
      :id="inputName"
      class="range-input"
      type="range"
      :name="inputName"
      :min="min"
      :max="max"
      :step="step"
      :style="{ width: inputWidth }"
      :value="sliderValue"
      @input="$emit('update:slider-value', Number($event.target.value))"
    />
    <output :id="inputName" class="output">{{ valueInPx }}</output>
  </div>
</template>

<script>
export default {
  props: {
    inputName: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      default: "bubblePosition",
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 10,
    },
    inputWidth: {
      type: String,
      default: "100px",
    },
    sliderValue: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: "px",
    },
  },
  computed: {
    valueInPx() {
      return this.sliderValue + this.type;
    },
  },
};
</script>

<style lang="scss" scoped>
.range-input {
  -webkit-appearance: none;
  height: 4px;
  border-radius: 5px;
  background-color: $nice-pink;
}

.range-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: $light-purple;
  cursor: ew-resize;
  transition: background 0.3s ease-in-out;
}
.range-wrapper {
  display: flex;
  align-items: center;
}
.output {
  @include font-smaller;
  color: $dark-purple;
  margin-left: 8px;
  // We set widht to prevent layout shift on range increase
  width: 30px;
}
</style>
