<template>
  <div :class="['integration', { 'integration-modal': isModal }]">
    <h2 class="title">
      {{ $t("integration.modal.titleIntegrated") }}
    </h2>
    <div class="flex">
      <div v-if="Boolean(site.site.integration)" class="integration-status">
        <Check></Check>
        <h3 class="subtitle">
          {{ $t("integration.modal.subtitleIntegrated") }}
        </h3>
      </div>
      <div v-else class="integration-status">
        <Cross></Cross>
        <h2 class="subtitle">{{ $t("integration.modal.subtitle") }}</h2>
      </div>
      <div class="check-integration">
        <!-- get latest state to check if the website was integrated -->
        <button
          v-if="Boolean(!site.site.integration)"
          class="button-primary"
          @click="getSite()"
        >
          {{ $t("integration.modal.button") }}
        </button>
      </div>
    </div>
    <div class="image-grid">
      <img
        v-for="image in images"
        :key="image.id"
        v-lazy="
          require(`../../assets/integration/cms-provider/integrations-${image.alt}.png`)
        "
        class="cms-image"
        :class="{ 'is-integrated': isIntegrated(image.alt) }"
        :alt="image.alt"
        @click="getCmsAndToggleModal(image.alt, image.scriptPosition)"
      />
    </div>
    <div>
      <integration-steps
        v-if="modalSteps"
        v-click-outside="toggleStepsModal"
        :cms-chosen="cmsChosen"
        :is-integrated="isIntegrated(cmsChosen.name)"
        @close="toggleStepsModal"
      ></integration-steps>
    </div>
    <div class="tool-integration-title">
      <h2>{{ $t("integration.modal.toolTitle") }}</h2>
      <h3 class="tool-subtitle">{{ $t("integration.modal.toolSubtitle") }}</h3>
    </div>
    <div class="image-grid">
      <img
        v-for="tool in tools"
        v-lazy="
          require(`../../assets/integration/cms-provider/integrations-${tool}.png`)
        "
        class="cms-image"
        :class="{ 'is-integrated': isToolIntegrated(tool) }"
        @click="getToolAndToggleModal(tool)"
      />
    </div>
    <div>
      <tool-integration-steps
        v-if="showToolModal"
        v-click-outside="toggleToolModal"
        :tool-chosen="toolChosen"
        @close="toggleToolModal"
      ></tool-integration-steps>
    </div>
    <spinner-loader v-if="isLoading" />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Check from "@/assets/svg/settings/green-check.svg?inline";
import Cross from "@/assets/svg/settings/red-cross.svg?inline";
import IntegrationSteps from "./settings-components/IntegrationSteps.vue";
import ToolIntegrationSteps from "./settings-components/ToolIntegrationSteps.vue";
import SpinnerLoader from "../shared/SpinnerLoader.vue";

export default {
  components: {
    Check,
    Cross,
    IntegrationSteps,
    SpinnerLoader,
    ToolIntegrationSteps,
  },

  props: {
    isModal: { type: Boolean, default: false },
  },

  data() {
    return {
      isLoading: false,
      images: [
        { alt: "shopify" },
        { alt: "wordpress" },
        { alt: "prestashop" },
        { alt: "wix" },
        { alt: "gtm", scriptPosition: 7 },
        { alt: "manual" },
      ],
      tools: ["google", "klaviyo"],
      modalSteps: false,
      integrationModal: false,
      cmsChosen: { name: "", scriptPosition: 1 },
      toolChosen: "",
      toolIntegrationModal: false,
      showToolModal: false,
    };
  },

  computed: {
    ...mapState({ site: "site" }),
    ...mapGetters({
      getSiteId: "account/getSiteId",
    }),
  },

  async created() {
    await this.$store.dispatch("site/getIntegrations", {
      siteId: this.getSiteId,
    });
  },

  methods: {
    // toggle cross is used when integration is used as a modal

    //For CMS integration modal
    toggleCmsModal() {
      this.modalSteps = !this.modalSteps;
      this.$emit("toggleCross");
    },

    getCmsAndToggleModal(name, scriptPosition = 1) {
      this.cmsChosen.name = name;
      this.cmsChosen.scriptPosition = scriptPosition;
      this.toggleCmsModal();
    },

    toggleStepsModal() {
      this.modalSteps = !this.modalSteps;
    },

    //For Tools integration modal
    toggleToolModal() {
      this.showToolModal = !this.showToolModal;
    },

    getToolAndToggleModal(name) {
      this.toolChosen = name;
      this.toggleToolModal();
    },

    async getSite() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("site/getSite", {
          siteId: this.$store.state.account.user.siteId,
        });
        // check if user has integrated and not just clicked on the button
        if (!this.site.site.integration) {
          this.$notify({
            title: this.$t("shared.toastMessage.pleaseIntegrate"),
            type: "error",
          });
          this.isLoading = false;
        } else {
          this.$notify({
            title: this.$t("shared.toastMessage.integratedSuccess"),
            type: "success",
          });
          this.isLoading = false;
          this.$emit("closeIntegration");
        }
      } catch (err) {
        this.$notify({
          title: err,
          type: "error",
        });
        this.isLoading = false;
      }
    },
    isIntegrated(integration) {
      if (!this.site || !this.site.site) return false;
      return integration === "wordpress" || integration === "woocommerce"
        ? ["wordpress", "woocommerce"].includes(this.site.site.integration)
        : this.site.site.integration === integration;
    },
    isToolIntegrated(tool) {
      if (!this.site || !this.site.toolIntegrations) return false;
      return Boolean(
        this.site.toolIntegrations.find(
          (integration) => integration.type === tool
        )
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  @include font-big;
  font-weight: 600;
}
.subtitle {
  margin-left: 5%;
  white-space: nowrap;
  color: $medium-grey;
}
.integration {
  display: grid;
  justify-content: center;
  overflow-y: auto;
}

.integration-status {
  display: flex;
  align-self: center;
  align-items: center;
  svg {
    width: 26px;
    height: 26px;
  }
}

.chech-integration {
  .button-primary {
    margin: 3px;
  }
}
.tool-integration-title {
  margin-top: 36px;
  h2 {
    @include font-big;
    font-weight: 600;
  }
}
.tool-subtitle {
  margin-top: 4px;
  color: $medium-grey;
}
.flex {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}

.image-grid {
  margin-top: 24px;
  width: 100%;
  max-width: 775px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.cms-image {
  cursor: pointer;
  height: 100px;
  width: calc(700px / 3);
  border: 1px solid $light-grey;
  border-radius: $card-border-radius;
  &:hover {
    border: 1px solid $dark-purple;
  }
}

.is-integrated {
  border: 1.5px solid $green;
}

.gtm {
  padding: 10px 5px;
  height: 80px;
  width: calc(670px / 3);
}

@include media(">tablet", "<=desktop") {
  .flex {
    width: 90%;
  }
  .image-grid {
    width: inherit;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .cms-image {
    width: calc(630px / 3);
  }
  .gtm {
    padding: 10px 5px;
    height: 80px;
    width: calc(670px / 3);
  }
}
@include media(">phone", "<=tablet") {
  .image-grid {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .cms-image {
    width: 300px;
    height: 120px;
  }
}

@include media("<=phone") {
  .image-grid {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
  }
  .cms-image {
    width: 250px;
    height: 100px;
  }
}
</style>
