<template>
  <label
    :class="{ isDisabled: isDisabled && !isInCampaignManager }"
    class="switch"
  >
    <input
      type="checkbox"
      :disabled="isDisabled"
      :checked="checked"
      @change="onChangeEventHandler"
    />
    <!-- no slide is to keep button from toggling if webite is not integrated -->
    <span
      :class="{ isDisabled: isDisabled && !isInCampaignManager }"
      class="slider round"
    ></span>
  </label>
</template>

<script>
export default {
  name: "VidjetToggleBtn",
  components: {},
  props: {
    checked: {
      default: false,
      type: Boolean,
    },
    toggleName: {
      type: String,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isInCampaignManager: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onChangeEventHandler(event) {
      if (this.toggleName) {
        this.$emit("change", {
          status: event.target.checked,
          toggleName: this.toggleName,
        });
        this.$emit("input", {
          status: event.target.checked,
          toggleName: this.toggleName,
        });
      } else {
        this.$emit("change", event.target.checked);
        this.$emit("input", event.target.checked);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.isDisabled {
  cursor: not-allowed;
}
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 22px;
}

.small-switch.switch {
  height: 20px;
  .slider:before {
    height: 16px;
    width: 16px;
    left: 3px;
  }
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  transition: 0.4s;
  border: 1px solid rgba(226, 124, 252, 0.5);
  &.isDisabled {
    cursor: not-allowed;
    border: 1px solid $dark-grey;
  }
}

.slider.round.isDisabled::before {
  filter: grayscale(1) !important;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 1px;
  background-color: $dark-purple !important;
  transition: 0.4s;
  &.isDisabled {
    cursor: not-allowed;
  }
}

input:checked + .slider {
  background-color: rgba(226, 124, 252, 0.5);
  border: 1px solid transparent;
  &.isDisabled {
    cursor: not-allowed;
    filter: grayscale(1) !important;
  }
}

input:focus + .slider {
  box-shadow: 0 0 1px rgba(226, 124, 252, 0.5);
  &.isDisabled {
    box-shadow: 0 0 1px $dark-grey;
  }
}

input:checked + .slider:before {
  transform: translateX(21px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
