export default [
  { name: "Pacifique / Niue -11", offset: -11 },
  { name: "US/Hawaii -10", offset: -10 },
  { name: "Amérique/Anchorage -9", offset: -9 },
  { name: "Amérique/Los Angeles -8", offset: -8 },
  { name: "Amérique/Boise -7", offset: -7 },
  { name: "Amérique/Chicago -6", offset: -6 },
  { name: "Amérique/New York -5", offset: -5 },
  { name: "Amérique/Aruba -4", offset: -4 },
  { name: "Amérique/Buenos_Aires -3", offset: -3 },
  { name: "Brésil/DeNoronha -2", offset: -2 },
  { name: "Atlantique/Azores -1", offset: -1 },
  { name: "UTC 0", offset: 0 },
  { name: "Europe/Amsterdam +1", offset: 1 },
  { name: "Europe/Athènes +2", offset: 2 },
  { name: "Europe/Moscou +3", offset: 3 },
  { name: "Inde/Mahe +4", offset: 4 },
  { name: "Asie/Ashgabat +5", offset: 5 },
  { name: "Asie/Dhaka +6", offset: 6 },
  { name: "Asie/Bangkok +7", offset: 7 },
  { name: "Asie/Hong Kong +8", offset: 8 },
  { name: "Asie/Pyongyang +9", offset: 9 },
  { name: "Australie/Sydney + 10", offset: 10 },
  { name: "Asie/Magadan +11", offset: 11 },
];
