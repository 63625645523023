<template>
  <div class="create-users">
    <div class="user-list-container">
      <div class="user-list-title-and-user-count">
        <div class="user-list-title">
          <MultipleUsers />
          <h2 class="title">{{ $t("account.userInformation.userList") }}</h2>
        </div>
        <div class="user-count">
          {{ user.users.length }} {{ $t("account.userInformation.usersCount") }}
        </div>
      </div>
    </div>
    <list-items
      class="user-list-header"
      :header-elements="[
        $t('account.userInformation.header.email'),
        $t(''),
        $t('account.userInformation.header.firstName'),
        $t('account.userInformation.header.lastName'),
        $t(''),
      ]"
    >
      <div v-for="newUser in user.users" :key="newUser.id" class="user-card">
        <p>{{ newUser.email }}</p>
        <p>{{ newUser.firstname }}</p>
        <p>{{ newUser.lastname }}</p>
        <button
          v-if="newUser._id !== user.user._id"
          class="delete-button"
          @click="toDeleteValidation(newUser._id)"
        >
          <DeleteIcon />
          {{ $t("account.userInformation.remove") }}
        </button>
      </div>
    </list-items>
    <!-- Add user modal -->
    <small-modal v-if="showAddUser" :fit-content="true" @close="close">
      <div class="user-modal-header">
        <h1 class="add-user-title">
          {{ $t("account.userInformation.addUserModal.title") }}
        </h1>
        <h2 class="add-user-subtitle">
          {{ $t("account.userInformation.addUserModal.subtitle") }}
        </h2>
        <banner-modal
          banner-type="info"
          :title="$t('deleteCampaign.information')"
          :text="$t('account.userInformation.addUserModal.bannerText')"
        >
          <template #icon>
            <InfoIcon />
          </template>
        </banner-modal>
      </div>
      <form class="form" @submit.prevent="addUser()">
        <ul class="user-info">
          <li class="label-input">
            <label class="add-user-label" for="email">{{
              $t("account.userInformation.inputs.email")
            }}</label>
            <input
              id="email"
              v-model="email"
              class="add-user-input"
              type="email"
              required
            />
          </li>
          <div class="first-last-name">
            <li class="label-input">
              <label class="add-user-label" for="firstname">{{
                $t("account.userInformation.inputs.firstName")
              }}</label>
              <input
                id="firstname"
                v-model="firstname"
                class="add-user-input"
                type="text"
                required
              />
            </li>
            <li class="label-input">
              <label class="add-user-label" for="lastname">{{
                $t("account.userInformation.inputs.lastName")
              }}</label>
              <input
                id="lastname"
                v-model="lastname"
                class="add-user-input"
                type="text"
                required
              />
            </li>
          </div>
          <li class="label-input">
            <label class="add-user-label" for="password">{{
              $t("account.userInformation.inputs.password")
            }}</label>
            <input
              id="password"
              v-model="password"
              class="add-user-input"
              type="password"
              required
            />
          </li>
        </ul>
        <div class="buttons">
          <input
            class="button-primary --grey"
            type="button"
            :value="$t('shared.buttons.back')"
            @click.prevent="close"
          />
          <label class="button-primary button-flex">
            <input
              class="confirm-button"
              type="submit"
              :value="$t('shared.buttons.save')"
            />
            <ConfirmIcon />
          </label>
        </div>
      </form>
    </small-modal>
    <!-- delete user modal -->
    <small-modal v-if="showDeleteValidation" :fit-content="true" @close="close">
      <div class="user-modal-header">
        <h1 class="add-user-title">
          {{ $t("account.userInformation.deleteUserModal.title") }}
        </h1>
        <h2 class="add-user-subtitle">
          {{ $t("account.userInformation.deleteUserModal.subtitle") }}
        </h2>
        <banner-modal
          banner-type="warning"
          :title="$t('deleteCampaign.warning')"
          :text="$t('account.userInformation.deleteUserModal.bannerText')"
        >
          <template #icon>
            <WarningIcon />
          </template>
        </banner-modal>
      </div>
      <div class="delete-user-buttons">
        <button class="button-primary --grey">
          {{ $t("shared.buttons.cancel") }}
        </button>
        <button
          class="button-primary button-flex remove-user-button"
          @click="deleteUser"
        >
          {{ $t("account.userInformation.remove") }}
          <DeleteIcon class="delete-icon-button" />
        </button>
      </div>
    </small-modal>
    <button class="action-button add-user" @click="showAddUser = true">
      {{ $t("account.userInformation.addUser") }}
    </button>
    <InstructionBanner
      class="multi-account-instruction-banner"
      selected-name="leo"
      :title-text="$t('account.userInformation.bannerInformation.text')"
      :link-text="$t('account.userInformation.bannerInformation.button')"
      :link-href="instructionBannerLink"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import DeleteIcon from "@/assets/svg/trash-icon.svg?inline";
import SmallModal from "@/components/shared/SmallModal.vue";
import MultipleUsers from "@/assets/svg/multiple-users-icon.svg?inline";
import BannerModal from "@/components/shared/BannerModal.vue";
import InstructionBanner from "@/components/shared/InstructionBanner.vue";
import InfoIcon from "@/assets/svg/banner-info-icon.svg?inline";
import WarningIcon from "@/assets/svg/banner-warning-icon.svg?inline";
import ConfirmIcon from "@/assets/svg/confirm-icon.svg?inline";
import ListItems from "../shared/ListItems.vue";

export default {
  components: {
    DeleteIcon,
    SmallModal,
    MultipleUsers,
    BannerModal,
    InfoIcon,
    WarningIcon,
    ConfirmIcon,
    ListItems,
    InstructionBanner,
  },

  data() {
    return {
      showDeleteValidation: false,
      showAddUser: false,
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      deleteUserId: "",
      instructionBannerLink:
        "https://help.vidjet.io/en/articles/8294039-integrate-other-domains-to-vidjet",
    };
  },

  computed: {
    ...mapState(["user", "site"]),
    ...mapGetters({ siteId: "site/getSiteId" }),
    emailAlreadyTaken() {
      const filter = this.user.users.filter(
        (user) => user.email === this.email
      );
      return filter.length === 0 ? true : false;
    },
  },
  methods: {
    async addUser() {
      const data = {
        siteId: this.siteId,
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        password: this.password,
      };
      if (
        /(.+)@(.+){2,}\.(.+){2,}/.test(data.email) &&
        this.emailAlreadyTaken
      ) {
        try {
          await this.$store.dispatch("user/addUser", { data });
          this.showAddUser = false;
          this.$notify({
            title: this.$t("shared.toastMessage.userAdded"),
            type: "success",
          });
        } catch (err) {
          this.$notify({
            title: err,
            type: "error",
          });
        }
      } else {
        this.$notify({
          title: this.$t("shared.toastMessage.invalidEmail"),
          type: "error",
        });
      }
    },

    close() {
      this.showAddUser = false;
      this.showDeleteValidation = false;
    },

    toDeleteValidation(id) {
      this.showDeleteValidation = true;
      this.deleteUserId = id;
    },

    async deleteUser() {
      try {
        await this.$store.dispatch("user/deleteUser", this.deleteUserId);
        this.showDeleteValidation = false;
        this.$notify({
          title: this.$t("shared.toastMessage.userDeleted"),
          type: "success",
        });
      } catch (err) {
        this.$notify({
          title: err,
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.create-users {
  .list-header {
    height: 40px;
  }
}

.multi-account-instruction-banner.banner-wrapper {
  margin-top: 40px;
  width: 480px;
}

.multi-account-instruction-banner .redirection-link-article {
  width: 260px;
}
</style>

<style lang="scss" scoped>
.create-users {
  @include flex-col-gap(16px);
}
.user-list-title-and-user-count {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-list-title {
  display: flex;
  align-items: center;
  gap: 8px;
}
.title {
  @include base-font;
  @include font-big;
}
.user-count {
  @include base-font;
  @include font-normal;
  font-weight: 500;
}
.user-list-header {
  @include flex-col-gap(8px);
}
.user-card {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  place-items: flex-start;
  align-items: center;
  border: 1px solid $light-grey;
  border-radius: 5px;

  padding: 14px;
  min-height: 34px;
  box-sizing: content-box;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include font-normal;
  }
  p:nth-child(1) {
    place-self: flex-start;
    align-items: end;
    margin: auto 0;
  }
}

.add-user {
  width: 120px;
  float: left;
}
.user-modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}
.add-user-title {
  @include base-font;
  @include font-big;
  font-weight: 600;
}

.add-user-subtitle {
  @include base-font;
  @include font-normal;
  font-weight: 400;
  white-space: nowrap;
}

.add-user-label {
  font-size: 14px;
}

.add-user-input {
  font-size: 16px;
}

.delete-button {
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 8px;
  @include base-font;
  @include font-normal;
  font-weight: 500;
  color: $medium-grey;
}
// modal delete user
.delete-content {
  height: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.delete-user-buttons {
  display: flex;
  justify-content: center;
  gap: 72px;
  margin-top: 32px;
}

// modal add user
.form {
  height: inherit;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  @include font-small;
}

.label-input {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.user-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-start;
  margin: 1rem 0;
  input {
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 3px;
    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 1px rgba(226, 124, 252, 0.7);
    }
  }
}
.first-last-name {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 100%;
}

.buttons {
  margin-top: 10px;
  width: 70%;
  @include flex-centered;
  justify-content: space-evenly;
}
.button-flex {
  display: flex;
  align-items: center;
  gap: 8px;
}
.remove-user-button {
  box-shadow: 2px 2px 15px 0px rgba(235, 0, 0, 0.25);
  &:hover {
    background-color: rgba(235, 0, 0, 0.25);
  }
}
.confirm-button {
  @include base-font;
  @include font-small;
  font-weight: 500;
  background: none;
  border: none;
}
.delete-icon-button {
  path {
    fill: $dark-grey;
  }
}

@include media(">phone", "<=tablet") {
  .user-card {
    @include font-small;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

@include media("<phone") {
  .user-card {
    @include font-small;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    padding: 4px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .user-info {
    width: 280px;
    @include font-small;
    input {
      width: 70%;
    }
  }
}
</style>
