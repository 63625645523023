<template>
  <small-modal
    class="bulk-modal"
    :title="$t('create.step1.bulkModal.title')"
    :fit-content="true"
    @close="$emit('close-bulk-modal')"
  >
    <div class="content-wrapper">
      <h2 class="subtitle">{{ $t("create.step1.bulkModal.subtitle") }}</h2>
      <div class="cards-container">
        <div
          class="card"
          :class="{ selected: selectedCard === 'regular' }"
          @click="chooseRegularEmbed"
        >
          <div class="additional-info">
            <p>{{ $t("create.step1.bulkModal.regularAdditionalInfo") }}</p>
          </div>
          <img
            class="card-img"
            src="@/assets/regular-embed.png"
            alt="regular"
          />
          <h3 class="card-title">{{ $t("create.step1.bulkModal.regular") }}</h3>
          <p class="card-text">
            {{ $t("create.step1.bulkModal.regularInfo") }}
          </p>
        </div>
        <div
          class="card"
          :class="{ selected: selectedCard === 'bulk', cursor: isFreemium }"
          @click="chooseBulkEmbed"
        >
          <div class="additional-info">
            <p>{{ $t("create.step1.bulkModal.bulkAdditionalInfo") }}</p>
          </div>
          <img class="card-img" src="@/assets/bulk-embed.png" alt="regular" />
          <div class="icon-title">
            <h3 class="card-title">{{ $t("create.step1.bulkModal.bulk") }}</h3>
            <tippy
              v-if="isFreemium"
              arrow="true"
              placement="right"
              a11y="false"
              interactive="true"
            >
              <template #trigger>
                <lock-icon class="lock-icon"></lock-icon>
              </template>
              <div class="tippy-content">
                <p class="tooltip-text">
                  {{ $t("create.step1.bulkModal.tooltip") }}
                </p>
                <button
                  class="button-primary tooltip-button"
                  @click="openPlans"
                >
                  {{ $t("agencyPortal.tooltip.button") }}
                </button>
              </div>
            </tippy>
          </div>
          <p class="card-text">
            {{ $t("create.step1.bulkModal.bulkInfo") }}
          </p>
        </div>
      </div>
    </div>
  </small-modal>
</template>

<script>
import SmallModal from "@/components/shared/SmallModal.vue";
import LockIcon from "@/assets/svg/lock-icon-small.svg?inline";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    SmallModal,
    LockIcon,
  },
  data() {
    return {
      selectedCard: null,
    };
  },
  computed: {
    ...mapState(["site"]),
    ...mapGetters({
      currentPlan: "plan/currentPlan",
    }),
    isFreemium() {
      return this.currentPlan === "free";
    },
  },
  async created() {
    await this.$store.dispatch("plan/getPlan", {
      siteId: this.$store.state.account.user.siteId,
    });
  },
  methods: {
    openPlans() {
      this.$router.push({ name: "billing" });
    },
    chooseRegularEmbed() {
      this.selectedCard = "regular";
      this.$emit("choose-bulk-option", false);
    },
    chooseBulkEmbed() {
      if (this.isFreemium) {
        return;
      } else {
        this.selectedCard = "bulk";
        this.$emit("choose-bulk-option", true);
      }
    },
  },
};
</script>

<style lang="scss">
.bulk-modal .small-modal.fit-modal {
  padding: 44px 96px 52px;
}
</style>

<style lang="scss" scoped>
.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subtitle {
  @include base-font;
  @include font-normal;
  font-weight: 400;
  white-space: nowrap;
  margin-bottom: 24px;
}

.cards-container {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.card {
  width: 190px;
  border-radius: 4px;
  border: 2px solid $light-grey;
  padding-bottom: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: $light-pink;
  }
}
.selected {
  border: 2px solid $dark-purple;
}

.card-img {
  margin-bottom: 24px;
  width: 102px;
}

.icon-title {
  display: flex;
  justify-content: center;
  gap: 6px;
}

.lock-icon {
  cursor: pointer;
  path {
    fill: $dark-purple;
  }
}

.card-title {
  @include base-font;
  @include font-normal;
  font-weight: 600;
  margin-bottom: 16px;
}

.card-text {
  @include base-font;
  @include font-small;
  color: $medium-grey;
  padding: 0 12px;
}

.tippy-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 12px;
}

.tooltip-text {
  @include base-font;
  @include font-small;
  font-weight: 500;
  color: white;
  text-align: start;
  width: 150px;
  cursor: default;
}
.tooltip-button {
  width: fit-content;
  @include font-small;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
}

.cursor {
  cursor: default;
}

.additional-info {
  background: $light-pink;
  width: 100%;
  padding: 12px 8px;
  box-sizing: border-box;
  border-radius: 4px 4px 0 0;
  margin-bottom: 24px;
  p {
    @include base-font;
    @include font-smaller;
    color: $dark-purple;
  }
}
</style>
