<template>
  <small-modal
    class="integration-modal-wrapper"
    :show-close-button="showCloseButton"
    @close="closePopUp"
  >
    <div class="integration-modal">
      <h2 class="title">{{ $t("integration.popupTitle") }}</h2>
      <Integration @toggleCross="toggleCross" @closeIntegration="closePopUp" />
    </div>
  </small-modal>
</template>

<script>
import SmallModal from "./SmallModal.vue";
import Integration from "../settings/Integration";

export default {
  components: {
    SmallModal,
    Integration,
  },
  data() {
    return {
      showCloseButton: true,
    };
  },
  methods: {
    closePopUp() {
      this.$emit("closePopUp");
    },
    toggleCross() {
      this.showCloseButton = !this.showCloseButton;
    },
  },
};
</script>

<style lang="scss">
.integration-modal-wrapper .small-modal {
  padding: 16px 0 32px;
}
</style>

<style lang="scss" scoped>
.integration-modal {
  @include flex-centered;
  flex-direction: column;
}
.title {
  width: 90%;
  margin: 16px 0px;
  text-align: center;
  @include font-big;
}
</style>
