import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";
import api from "./api";
import i18n from "./i18n";

import ToggleButton from "vue-js-toggle-button";
import Notifications from "vue-notification";
import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
import vClickOutside from "v-click-outside";
import vSelect from "vue-select";
import VCalendar from "v-calendar";
import round from "lodash/round";
import isNaN from "lodash/isNaN";
import truncate from "lodash/truncate";
import VueTippy, { TippyComponent } from "vue-tippy";
import Clipboard from "v-clipboard";
import VueCardFormat from "vue-credit-card-validation";
import VueLazyload from "vue-lazyload";
import VueCookies from "vue-cookies";
import SmartTable from "vuejs-smart-table";
import moment from "moment";

const style = {
  green: "#5fb96e",
  lightGreen: "#f4faf5",
  grey: "#9b9b9b",
  greyish: "#4a4a4a",
  black: "#282b30",
  pumpkin: "#f5a623",
  blue: "#3e4ffb",
  red: "#ec4257",
};

const datepickerOptions = {
  sundayFirst: true,
  daysShort: ["M", "T", "W", "T", "F", "S", "S"],
  colors: {
    selected: style.lightGreen,
    inRange: style.lightGreen,
    selectedText: style.greyish,
    text: style.greyish,
    inRangeBorder: style.lightGreen,
    disabled: "#fff",
    hoveredInRange: style.lightGreen,
  },
};

// Filters
Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter("round", function (value, decimalPlaces = 2) {
  return round(isNaN(value) ? 0 : value, decimalPlaces);
});
Vue.filter("truncate", function (string, limit = 18) {
  return truncate(string, { length: limit });
});
Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("MM/DD/YYYY");
  }
});

const loadImage = require("./assets/image-loading.gif");

Vue.use(ToggleButton);
Vue.use(Notifications);
// TODO: remove Airbnb and use only VCalendar
Vue.use(AirbnbStyleDatepicker, datepickerOptions);
Vue.use(vClickOutside);
Vue.use(SmartTable);
Vue.component("VSelect", vSelect);
Vue.use(VCalendar);
Vue.use(Clipboard);
Vue.use(VueTippy);
Vue.use(VueCardFormat);
Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: loadImage,
  throttleWait: 0,
  attempt: 3,
});
Vue.component("Tippy", TippyComponent);
Vue.use(VueCookies);

export default new Vue({
  router,
  store,
  api,
  i18n,
  el: "#app",
  render: (h) => h(App),
});

// vue-notifications: https://github.com/euvl/vue-notification/
// vue tippy: https://kabbouchi.github.io/vue-tippy/4.0/demo.html
// v-clipboard https://github.com/euvl/v-clipboard
// v-credit-card: https://www.npmjs.com/package/vue-credit-card-validation
