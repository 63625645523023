var render = function render(){var _vm=this,_c=_vm._self._c;return _c('small-modal',{staticClass:"add-to-cart",attrs:{"fit-content":true},on:{"close":function($event){return _vm.$emit('close-add-to-cart')}}},[_c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"title-container"},[_c('h1',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.$t(`create.step3.atcModal.plural`))+" ")]),(!_vm.isShopify)?_c('tippy',{attrs:{"arrow":"true","placement":"bottom","a11y":"false","interactive":"true"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('QuestionIcon')]},proxy:true}],null,false,1757517909)},[_c('div',{staticClass:"tippy-content"},[_c('p',{staticClass:"tooltip-text"},[_vm._v(" "+_vm._s(_vm.$t("create.step3.productsTippy.text"))+" ")]),_c('button',{staticClass:"button-primary tooltip-button",on:{"click":_vm.goToProductsTab}},[_vm._v(" "+_vm._s(_vm.$t("create.step3.productsTippy.button"))+" ")])])]):_vm._e()],1),_c('h2',{staticClass:"number-products"},[_vm._v(" "+_vm._s(_vm.selectedProducts.length)+" products selected. ")]),_c('search-bar',{on:{"query":_vm.filterListOfProducts}}),_c('div',{staticClass:"sorting"},_vm._l((_vm.sortingOptions),function(sortingOption){return _c('div',{key:sortingOption.key},[(_vm.isShopify || sortingOption.key !== 'quantity')?_c('div',{staticClass:"sort-by"},[_c('h3',{staticClass:"sort-option",class:{ 'sort-option-selected': sortingOption.order !== '' },on:{"click":function($event){return _vm.toggleSortingOrder(sortingOption.key)}}},[_vm._v(" "+_vm._s(sortingOption.label)+" ")]),_c('div',{staticClass:"sorting-arrows"},[_c('SortArrow',{staticClass:"sort-arrow",class:{
                'arrow-selected': sortingOption.order === 'ascending',
              },on:{"click":function($event){return _vm.toggleSortingOrder(sortingOption.key)}}}),_c('SortArrow',{staticClass:"sort-arrow reverse-arrow",class:{
                'arrow-selected': sortingOption.order === 'descending',
              },on:{"click":function($event){return _vm.toggleSortingOrder(sortingOption.key)}}})],1)]):_vm._e()])}),0),(!_vm.isShopify && _vm.hasNoProducts)?_c('div',{staticClass:"no-products-section"},[_c('div',{staticClass:"no-products-instructions"},[_c('div',{staticClass:"no-products-image"},[_c('img',{attrs:{"src":require("@/assets/sitting-man.png"),"alt":"sitting-man"}})]),_c('div',{staticClass:"no-products-text"},[_c('p',[_vm._v(_vm._s(_vm.$t("productPages.noProductsInstructions.1")))]),_c('p',[_vm._v(_vm._s(_vm.$t("productPages.noProductsInstructions.goodNews")))])])]),_c('button',{staticClass:"no-products-button action-button",on:{"click":_vm.goToProductsTab}},[_vm._v(" "+_vm._s(_vm.$t("productPages.noProductsInstructions.button"))+" ")])]):_c('form',{staticClass:"product-form"},[_c('div',{ref:"topForm",staticClass:"product-card-container"},_vm._l((_vm.combinedProducts),function({
            imageUrl,
            name,
            variants,
            productUrl,
            _id,
          }){return _c('div',{key:_id,staticClass:"input-container"},[_c('label',{directives:[{name:"tippy",rawName:"v-tippy",value:({
              arrow: true,
              placement: 'top',
              a11y: false,
              maxWidth: 200,
            }),expression:"{\n              arrow: true,\n              placement: 'top',\n              a11y: false,\n              maxWidth: 200,\n            }"}],staticClass:"product-card",class:{
              selected: _vm.selectedProducts.find(
                (product) => product._id === _id
              ),
            },attrs:{"content":name,"name":"product","for":_id},on:{"click":function($event){return _vm.addProductToSelectedProduct(_id)}}},[_c('div',{staticClass:"img-container"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(imageUrl),expression:"imageUrl"}],staticClass:"product-image",attrs:{"alt":"product-image"}})]),_c('div',{staticClass:"product-info"},[_c('p',{staticClass:"product-name"},[_vm._v(" "+_vm._s(name.length > 28 ? name.substring(0, 20) + "..." : name)+" ")]),_c('p',{staticClass:"product-data"},[_vm._v(_vm._s(_vm.firstVariantPrice(variants)))]),_c('p',{staticClass:"product-data"},[_vm._v(_vm._s(_vm.calculateTotalQty(variants)))])]),(productUrl)?_c('a',{staticClass:"new-tab",attrs:{"href":productUrl,"target":"_blank"}},[_c('NewTabIcon')],1):_vm._e()])])}),0)])],1),_c('div',{staticClass:"buttons-container"},[_c('button',{staticClass:"button-primary --grey product-button",on:{"click":function($event){return _vm.$emit('close-add-to-cart')}}},[_vm._v(" "+_vm._s(_vm.$t("shared.buttons.back"))+" ")]),_c('button',{staticClass:"button-primary button-flex product-button",on:{"click":function($event){return _vm.submitAddToCartProducts()}}},[_vm._v(" "+_vm._s(_vm.$t("shared.buttons.save"))+" "),_c('ConfirmIcon')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }