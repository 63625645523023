var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subscription-carousel"},[_c('carousel',{ref:"carousel",attrs:{"per-page-custom":[
      [400, 1],
      [600, 2],
      [800, 3],
      [1000, 4],
      [1160, 5],
    ],"pagination-enabled":true,"loop":true,"mouse-drag":false}},_vm._l((_vm.subscriptionArray),function(item){return _c('slide',{key:item.id,class:{ 'VueCarousel-slide-active': _vm.initialLoad }},[_c('plan-card',{attrs:{"subscription":item},on:{"card-clicked":_vm.handleCardClick}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }