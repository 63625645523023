<template>
  <div :class="['settings-footer-block', { showBackBtn }]">
    <button
      v-if="showBackBtn"
      :disabled="disabledPrev"
      :class="['previous', { disabled: disabledPrev }]"
      @click="handleClickPrev"
    >
      <ArrowBackIcon />
    </button>
    <button
      :disabled="disabledNext"
      :class="['next', { disabled: disabledNext }]"
      @click="handleClickNext"
    >
      {{ $t("shared.buttons.nextStep") }}
      <ArrowNextIcon />
    </button>
  </div>
</template>

<script>
import ArrowNextIcon from "@/assets/svg/arrow-next.svg?inline";
import ArrowBackIcon from "@/assets/svg/arrow-back.svg?inline";
export default {
  name: "SettingsFooter",
  components: { ArrowNextIcon, ArrowBackIcon },
  props: {
    disabledPrev: {
      type: Boolean,
      default: false,
    },
    disabledNext: {
      type: Boolean,
      default: false,
    },
    showBackBtn: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleClickPrev() {
      this.$emit("prev");
    },
    handleClickNext() {
      this.$emit("next");
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-footer-block {
  display: flex;
  button {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: white;
    @include base-font;
    @include font-small;
    color: white;
    height: 40px;
    @include flex-centered;
    font-weight: $w-bolder;
    z-index: 1;
  }

  .previous {
    width: 15%;
    background: $dark-purple;
    margin-right: 11px;
    border-radius: 5px 0px 0px 5px;
  }

  .next {
    width: 100%;
    background: linear-gradient(90deg, $dark-purple 0%, #e27cfc 100%);
    border-radius: 0px 5px 5px 0px;
    svg {
      margin-left: 5px;
    }
  }
  .showBackBtn .next {
    width: 85%;
  }
  .disabled {
    cursor: not-allowed;
  }
}
</style>
