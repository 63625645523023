<template>
  <div>
    <form action="#" @submit.prevent="updatePassword">
      <ul class="password-card">
        <li>
          <label for="current-password" class="password-label">
            {{
              user.user.hasPassword
                ? $t("account.password.hasPassword.currentPassword")
                : $t("account.password.hasNoPassword.setPassword")
            }}
          </label>
          <input
            id="current-password"
            v-model="oldPassword"
            type="password"
            class="password"
            maxlength="256"
            required
          />
        </li>
        <li>
          <label for{{
              user.user.hasPassword
                ? $t("account.password.hasPassword.newPassword")
                : $t("account.password.hasNoPassword.confirmPassword")
            }}="new-password" class="password-label">
            
          </label>
          <input
            id="new-password"
            v-model="newPassword"
            type="password"
            class="password"
            maxlength="256"
            required
          />
        </li>
      </ul>
      <input
        type="submit"
        :value="
          user.user.hasPassword
            ? $t('account.password.hasPassword.button')
            : $t('account.password.hasNoPassword.button')
        "
        class="submit-password"
      />
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      oldPassword: "",
      newPassword: "",
    };
  },

  computed: {
    ...mapState({ user: "user" }),
  },

  methods: {
    // for stripe users
    async updatePassword() {
      if (this.user.user.hasPassword) {
        try {
          const data = {
            newPassword: this.newPassword,
            oldPassword: this.oldPassword,
            userId: this.user.user._id,
          };

          await this.$store.dispatch("account/updatePassword", { data });
          this.$notify({
            title: this.$t("shared.toastMessage.passwordUpdated"),
            type: "success",
          });
        } catch (err) {
          this.$notify({
            title: this.$t("shared.toastMessage.error"),
            text: this.$t("shared.toastMessage.wrongPassword"),
            type: "error",
          });
        }
      } else {
        //newPassword can also be the confirmation password if no password as been set before
        if (this.oldPassword !== this.newPassword) {
          return this.$notify({
            title: this.$t("shared.toastMessage.error"),
            text: this.$t("shared.toastMessage.passwordDoNotMatch"),
            type: "error",
          });
        } else {
          const data = {
            password: this.newPassword,
            userId: this.user.user._id,
          };

          await this.$store.dispatch("account/setPassword", { data });
          this.$notify({
            title: this.$t("shared.toastMessage.newPasswordSet"),
            type: "success",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.password-card {
  height: 90px;
  padding: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
}

.password-label {
  @include font-small;
  margin-left: 24px;
}

#current-password,
#new-password {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3px;
  width: 50%;
  margin-right: 2vw;
  float: right;
  height: 34px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 1px rgba(226, 124, 252, 0.7);
  }
}

.password {
  @include font-small;
  color: black;
  border: none;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  width: 80%;
}

.submit-password {
  @include base-font;
  height: 25px;
  width: 160px;
  background: #ffffff;
  box-shadow: 2px 2px 15px rgba(141, 22, 193, 0.25);
  border-radius: 20px;
  border: none;
  position: absolute;
  right: 5%;
  bottom: 7%;
}

.submit-password:hover {
  background: $light-pink;
}

@include media(">tablet", "<=desktop") {
  #current-password,
  #new-password {
    width: 45%;
  }
}

@include media(">phone", "<=tablet") {
  .password-label {
    @include font-smaller;
    margin-left: 16px;
  }

  #current-password,
  #new-password {
    width: 50%;
  }

  .submit-password {
    @include font-small;
  }
}

@include media("<=phone") {
  #current-password,
  #new-password {
    width: 45%;
  }
  .password {
    @include font-smaller;
    width: 100%;
  }
}
</style>
