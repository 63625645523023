<template>
  <sliding-modal
    :title="!isProductPages ? elementsTitle : ''"
    :feed-name="getModalTitle"
    :is-product-pages="isProductPages"
    :campaign="campaign"
    :is-bulk="isBulk"
    @close="close"
  >
    <div class="elements-sliding-container">
      <Step3
        :is-bulk="true"
        :is-edit-mode="true"
        :is-edit-bulk-format="isEditBulkFormat"
        :current-feed-edit-index="currentFeedEditIndex"
        :is-product-pages="isProductPages"
        :campaign-id-from-product-pages="campaignId"
        :selected-product-id="selectedProductId"
        :comes-from-step-one="comesFromStepOne"
        @update-current-feed="updateCurrentFeed"
        @update-campaign="updateCampaign"
        @close-elements-modal="close"
      />
    </div>
  </sliding-modal>
</template>

<script>
import SlidingModal from "@/components/shared/SlidingModal.vue";
import Step3 from "@/components/create-campaign-steps/Step3.vue";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    SlidingModal,
    Step3,
  },
  props: {
    currentFeedEditIndex: { type: Number, required: true },
    isEditBulkFormat: { type: Boolean, required: true },
    isProductPages: { type: Boolean, default: false },
    campaignId: { type: String, default: null },
    selectedCurrentFeedEdit: { type: Object, default: null },
    selectedProductId: { type: Number, default: null },
    isBulk: { type: Boolean, default: false },
    comesFromStepOne: { type: Boolean, default: false },
  },
  data() {
    return {
      isNewVideoAdded: false,
      campaign: null,
    };
  },
  computed: {
    ...mapState({
      products: (state) => state.product.products,
    }),
    ...mapGetters({
      currentFeedEdit: "campaign/currentFeedEdit",
      feedList: "campaign/feedList",
      getVideos: "video/getVideos",
      siteId: "account/getSiteId",
      singleCampaign: "campaign/getSingle",
    }),
    getModalTitle() {
      return !this.isEditBulkFormat
        ? this.currentFeedEdit.feedName
        : this.$t("create.bulk.playerModal.allFeeds");
    },
    elementsTitle() {
      if (this.isEditBulkFormat) {
        return this.$t("create.bulk.playerModal.title");
      }
      return "";
    },
  },
  async created() {
    await this.$store.dispatch("product/getProducts", {
      siteId: this.siteId,
      isProductListView: false,
    });
    this.campaign = {
      ...structuredClone(this.singleCampaign()),
      videos: !this.isEditBulkFormat
        ? [...this.currentFeedEdit.videos]
        : [...this.feedList[0].videos],
    };
  },
  methods: {
    close() {
      this.$emit("close-elements-modal");
    },
    updateCurrentFeed(campaignId, formats) {
      this.$store.commit("campaign/updateFeedList", {
        currentFeedEdit: this.currentFeedEdit,
        index: this.currentFeedEditIndex,
      });
      if (this.isProductPages) {
        this.saveCampaign(campaignId, formats);
      }

      this.close();
    },
    async saveCampaign(campaignId, formats) {
      await this.$store.dispatch("campaign/updateCampaign", {
        campaignId: campaignId,
        dataToUpdate: {
          formats,
          bulk: this.feedList,
        },
      });
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("create.bulk.notification.bulkSaved"),
        type: "success",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.elements-sliding-container {
  position: relative;
  height: 100%;
}
.subtitle {
  @include base-font;
  @include font-normal;
  font-weight: 400;
  text-align: center;
  width: 70%;
  margin: 0 auto 24px;
}

.buttons {
  width: calc(25% + 12px);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  position: absolute;
  bottom: 80px;
  left: 0;
  height: 56px;
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.15));
}

.flex-button {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
