<template>
  <fullscreen-modal :show-close-button="false">
    <div></div>
    <spinner-loader v-if="isLoading"></spinner-loader>
  </fullscreen-modal>
</template>
<script>
import FullscreenModal from "./shared/Fullscreen-modal.vue";
import SpinnerLoader from "./shared/SpinnerLoader.vue";

export default {
  name: "WixIntegration",
  components: { FullscreenModal, SpinnerLoader },
  beforeRouteEnter(t, f, next) {
    next(async (vm) => {
      vm.init();
    });
  },
  data() {
    return { isLoading: true };
  },
  methods: {
    async init() {
      try {
        const { instance } = this.$route.query

        //Retreive Vidjet Token based on the Wix one that will be decoded with JWT
        //And save it into the state
        await this.$store.dispatch('account/logWixUser', { instance })
          
        //add logs
        this.isLoading = false
        this.$router.push({ name: "dashboard" })
      } catch (err) {
        console.log(`Err Login Auth Wix: ${err}`)
      }
    },
  },
};
</script>

<style lang="scss"></style>
