<template>
  <small-modal class="downgrade-modal" :fit-content="true" @close="close">
    <div class="plan-limit">
      <h1 class="card-title">{{ $t("plan1.title") }}</h1>
      <h3 class="subtitle">
        {{
          userIsTrial
            ? $t("planRegistration.trialSubtitle") +
              daysLeftTrial +
              $t("planRegistration.days")
            : $t("plan1.subtitle1") + newPlan.name + "?"
        }}
      </h3>
      <banner-modal
        banner-type="warning"
        :title="$t('deleteCampaign.warning')"
        :text="`${$t('plan1.bannerInfo')} ${currentPlan} ${$t(
          'plan1.bannerInfo2'
        )}`"
      >
        <template #icon>
          <WarningIcon />
        </template>
      </banner-modal>
      <div class="card-plan">
        <p class="new-plan">{{ $t("plan1.newPlan") }}</p>
        <plan-card :subscription="newPlan"></plan-card>
      </div>
      <div class="buttons">
        <button class="button-primary btn-plan cancel-btn" @click="close">
          {{ $t("plan1.cancel") }}
        </button>
        <button
          class="button-primary btn-plan downgrade-btn"
          @click="userOnFreePlan"
        >
          {{ $t("plan1.downgrade") }}
        </button>
      </div>
    </div>
  </small-modal>
</template>
<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import SmallModal from "../shared/SmallModal.vue";
import PlanCard from "../shared/PlanCard.vue";
import BannerModal from "../shared/BannerModal.vue";
import WarningIcon from "@/assets/svg/banner-warning-icon.svg?inline";

export default {
  components: {
    SmallModal,
    PlanCard,
    BannerModal,
    WarningIcon,
  },
  props: {
    newPlan: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      plan: "plan",
      site: "site",
    }),
    ...mapGetters({
      currentPlan: "plan/currentPlan",
      siteIntegration: "site/siteIntegration",
      userIsTrial: "plan/userIsTrial",
      daysLeftTrial: "plan/daysLeftTrial",
    }),
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async userOnFreePlan() {
      const data = {
        siteId: this.site.site._id,
        chosenPlan: this.newPlan.name,
      };
      await this.$store.dispatch("plan/updatePlan", { data });
      this.close();
      this.$notify({
        title: this.$t("shared.toastMessage.planUpdated"),
        type: "success",
      });

      if (
        this.siteIntegration === "shopify" &&
        this.plan.plan.shopifyChargeConfirmationUrl &&
        this.currentPlan !== "free"
      ) {
        window.open(this.plan.plan.shopifyChargeConfirmationUrl, "_blank");
      }
    },
  },
};
</script>
<style lang="scss">
.downgrade-modal .small-modal .bigger {
  width: 440px;
}
</style>
<style lang="scss" scoped>
.plan-limit {
  @include flex-col-gap(24px);
  justify-content: center;
  @include base-font;
}

.card-title {
  @include font-big;
  @include base-font;
  font-weight: 600;
  text-align: center;
}
.subtitle {
  @include font-normal;
  font-weight: 400;
  text-align: center;
}

.new-plan {
  @include font-small;
  font-weight: 500;
}

.card-plan {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 6px;
  padding: 24px 0;
  position: relative;
  align-items: center;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.downgrade-btn {
  box-shadow: 2px 2px 15px 0px rgba(235, 0, 0, 0.25);
  &:hover {
    background-color: rgba(235, 0, 0, 0.25);
  }
}

.cancel-btn {
  box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.18);
  &:hover {
    background-color: rgba(0, 0, 0, 0.18);
  }
}

@include media(">phone", "<=tablet") {
  .plan-limit {
    background-size: 90%;
  }
  .top {
    .card-title {
      @include font-normal;
    }
    .subtitle {
      @include font-small;
      @include base-font;
      text-align: center;
    }
  }
  .subscription-card {
    width: 175px;
  }
}
@include media("<=phone") {
  .plan-limit {
    background-size: 95%;
    height: 115%;
  }
  .top {
    .card-title {
      @include font-normal;
      margin-top: 32px;
    }
    .subtitle {
      @include font-small;
      @include base-font;
      text-align: center;
    }
  }
  .subscription-card {
    width: 175px;
  }
}
</style>
