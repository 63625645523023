import api from "../api";
import VueCookies from "vue-cookies";
import router from "../router.js";

const state = {
  loggedIn: false,
  authToken: null,
  loggedAs: false,
  user: null,
  isShopifyEmbed: false
};

const getters = {
  getIsLoggedIn: (state) => {
    return state.loggedIn;
  },
  userEmail: (state) => {
    return state.user && state.user.email;
  },
  getIsAdmin: (state) => {
    return Boolean(state.user && state.user.isAdmin);
  },
  getIsAgency: (state) => {
    return Boolean(state.user && state.user.isAgency);
  },
  getSiteId: (state) => {
    return state.user && state.user.siteId;
  },
  getAuthToken: (state) => {
    return state.authToken;
  },
  getIsShopifyEmbed: (state) => {
    return state.isShopifyEmbed;
  },
};

const mutations = {
  loginSuccess(state, { authToken, user, isShopify }) {
    state.loggedIn = true;
    state.authToken = authToken;
    state.user = user;

    if (isShopify) {
      state.isShopifyEmbed = true;
    }

    //Save auth token in cookies
    VueCookies.set("vidjet_authtoken", authToken, Infinity);

    //Send login event to GTM
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "user_login",
      user_email: user.email,
      user_firstname: user.firstname || "",
      user_lastname: user.lastname || "",
      user_site_id: user.siteId,
      user_id: user._id,
      user_is_loggedin: "true",
    });
  },
  loginFailure(state) {
    state.loggedIn = false;
    state.authToken = null;
    state.user = null;

    //Delete auth token from cookies
    VueCookies.remove("vidjet_authtoken");
  },
  logout(state) {
    state.loggedIn = false;
    state.authToken = null;
    state.user = null;

    //Delete auth token from cookies
    VueCookies.remove("vidjet_authtoken");
  },
  setLogAsSiteId(state, { siteId }) {
    state.user.siteId = siteId;
  },
  toggleIsAgency(state) {
    state.user.isAgency = !state.user.isAgency
  }
};

const actions = {
  async signup({ dispatch, commit }, data) {
    try {
      const { authToken, user } = await api.signup(data);
      commit("loginSuccess", { authToken, user });
    } catch (err) {
      throw err;
    }
  },
  async appsumoSignup({ dispatch, commit }, data) {
    try {
      const { authToken, user } = await api.appsumoSignup(data);
      commit("loginSuccess", { authToken, user });
    } catch (err) {
      throw err;
    }
  },
  async login({ dispatch, commit }, data) {
    try {
      const { authToken, user } = await api.login(data);
      commit("loginSuccess", { authToken, user });
    } catch (err) {
      throw err;
    }
  },
  logout({ commit }) {
    commit("logout");
    //Send logout event to GTM
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "user_logout",
      user_is_loggedin: "false",
    });
  },

  async updatePassword({ commit }, { data }) {
    try {
      await api.updatePassword(data);
    } catch (err) {
      throw err;
    }
  },
  async setPassword({ commit }, { data }) {
    try {
      await api.setPassword(data);
    } catch (err) {
      throw err;
    }
  },
  async cancelSubscription({ commit }, { data }) {
    try {
      await api.cancelSubscription(data);
    } catch (err) {
      throw err;
    }
  },
  async logShopifyUser({ commit }, { sessionToken }) {
    try {
      //Call Backend to decode shopify sessionToken with JWT to knwo the shop
      const { authToken, user } = await api.logShopifyUser({ sessionToken });

      commit("loginSuccess", { authToken, user, isShopify: true });
    } catch (err) {
      throw err;
    }
  },
  async logWixUser({ commit }, { instance }) {
    try {
      //Call Backend to decode wix instance token with JWT to knwo the shop
      const { authToken, user } = await api.logWixUser({ instance });

      commit("loginSuccess", { authToken, user });
    } catch (err) {
      throw err;
    }
  },
  async logFromAuthToken({ commit }, { authToken }) {
    try {
      const { user } = await api.logFromAuthToken({ authToken });

      commit("loginSuccess", { authToken, user });
    } catch (err) {
      commit("loginFailure");
      throw err;
    }
  },
  async logAs({ commit }, { siteId }) {
    commit("setLogAsSiteId", { siteId });
  },
  async logFromLocalStorage({ commit }, localUser) {
    try {
      const { user, authToken } = await api.logFromLocalStorage({
        user: localUser,
      });

      commit("loginSuccess", { authToken, user });
    } catch (err) {
      commit("loginFailure");
      throw err;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
