<template>
  <div class="format-block">
    <h3 class="format-block-title">
      {{
        formatType === 1 && isMultiple
          ? $t("create.step3.textBubble")
          : $t("create.step3.text")
      }}
    </h3>
    <input
      class="text-input --with-borders"
      :class="{ 'input-wider': isMultiple }"
      type="text"
      maxlength="50"
      :value="campaignTitle"
      :placeholder="$t('create.step3.textPlaceholder')"
      @input="$emit('campaign-title', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  props: {
    campaignTitle: {
      type: String,
      default: "",
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    formatType: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.input-wider {
  width: 80%;
  margin-bottom: 10px;
}
</style>
