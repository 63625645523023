<template>
  <fullscreen-modal :show-close-button="false">
    <section class="mobile-wrapper">
      <img
        v-lazy="require('../../assets/no-mobile.png')"
        class="sorry-image"
        alt="Vidjet logo apologizing illustration"
      />
      <p class="meet-on-desktop">
        Let’s meet <strong>on desktop</strong><br />to create popup videos
      </p>
      <p class="not-support">We do not support video creation on mobile yet.</p>
      <div class="btn-container">
        <edit-footer
          class="btns-link"
          :is-small-font-on-mobile="true"
          prev-text="Back to website"
          next-text="Our Shopify App"
          @prev="goToWebsite"
          @next="goToShopify"
        ></edit-footer>
      </div>
    </section>
  </fullscreen-modal>
</template>

<script>
import FullscreenModal from "./Fullscreen-modal.vue";
import EditFooter from "../create-campaign-steps/EditFooter.vue";

export default {
  components: {
    FullscreenModal,
    EditFooter,
  },

  methods: {
    goToWebsite() {
      window.location.href = "https://vidjet.io";
    },
    goToShopify() {
      window.location.href = "https://apps.shopify.com/vidjet";
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-wrapper {
  text-align: center;
  max-width: 960px;
  margin: 70px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sorry-image {
  position: relative;
  right: 20px;
  margin-bottom: 50px;
  width: 500px;
  object-fit: contain;
}

.meet-on-desktop {
  @include font-giant;
  margin-bottom: 60px;
  white-space: pre-line;
}

.not-support {
  @include font-big;
  margin-bottom: 60px;
}

.btn-container {
  width: 100%;
  margin-bottom: 60px;
}

.btns-link {
  margin: auto auto 100px auto;
}

@include media("<tablet") {
  .meet-on-desktop {
    @include font-big;
    width: 90%;
    margin-bottom: 50px;
  }

  .sorry-image {
    width: 300px;
  }

  .use-cases {
    margin-bottom: 50px;
  }

  .not-support {
    @include font-smaller;
    font-weight: normal;
    margin-bottom: 50px;
    width: 90%;
  }

  .btns-link {
    width: 100%;
  }
}

@include media("<phone") {
  .sorry-image {
    width: 150px;
  }
}
</style>
