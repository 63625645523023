export default [
  { id: 4, name: "Afghanistan", code: "af", alpha3: "afg" },
  { id: 710, name: "Afrique du Sud", code: "za", alpha3: "zaf" },
  { id: 248, name: "Îles Åland", code: "ax", alpha3: "ala" },
  { id: 8, name: "Albanie", code: "al", alpha3: "alb" },
  { id: 12, name: "Algérie", code: "dz", alpha3: "dza" },
  { id: 276, name: "Allemagne", code: "de", alpha3: "deu" },
  { id: 20, name: "Andorre", code: "ad", alpha3: "and" },
  { id: 24, name: "Angola", code: "ao", alpha3: "ago" },
  { id: 660, name: "Anguilla", code: "ai", alpha3: "aia" },
  { id: 10, name: "Antarctique", code: "aq", alpha3: "ata" },
  { id: 28, name: "Antigua-et-Barbuda", code: "ag", alpha3: "atg" },
  { id: 682, name: "Arabie saoudite", code: "sa", alpha3: "sau" },
  { id: 32, name: "Argentine", code: "ar", alpha3: "arg" },
  { id: 51, name: "Arménie", code: "am", alpha3: "arm" },
  { id: 533, name: "Aruba", code: "aw", alpha3: "abw" },
  { id: 36, name: "Australie", code: "au", alpha3: "aus" },
  { id: 40, name: "Autriche", code: "at", alpha3: "aut" },
  { id: 31, name: "Azerbaïdjan", code: "az", alpha3: "aze" },
  { id: 44, name: "Bahamas", code: "bs", alpha3: "bhs" },
  { id: 48, name: "Bahreïn", code: "bh", alpha3: "bhr" },
  { id: 50, name: "Bangladesh", code: "bd", alpha3: "bgd" },
  { id: 52, name: "Barbade", code: "bb", alpha3: "brb" },
  { id: 112, name: "Biélorussie", code: "by", alpha3: "blr" },
  { id: 56, name: "Belgique", code: "be", alpha3: "bel" },
  { id: 84, name: "Belize", code: "bz", alpha3: "blz" },
  { id: 204, name: "Bénin", code: "bj", alpha3: "ben" },
  { id: 60, name: "Bermudes", code: "bm", alpha3: "bmu" },
  { id: 64, name: "Bhoutan", code: "bt", alpha3: "btn" },
  { id: 68, name: "Bolivie", code: "bo", alpha3: "bol" },
  { id: 535, name: "Pays-Bas caribéens", code: "bq", alpha3: "bes" },
  { id: 70, name: "Bosnie-Herzégovine", code: "ba", alpha3: "bih" },
  { id: 72, name: "Botswana", code: "bw", alpha3: "bwa" },
  { id: 74, name: "Île Bouvet", code: "bv", alpha3: "bvt" },
  { id: 76, name: "Brésil", code: "br", alpha3: "bra" },
  { id: 96, name: "Brunei", code: "bn", alpha3: "brn" },
  { id: 100, name: "Bulgarie", code: "bg", alpha3: "bgr" },
  { id: 854, name: "Burkina Faso", code: "bf", alpha3: "bfa" },
  { id: 108, name: "Burundi", code: "bi", alpha3: "bdi" },
  { id: 136, name: "Îles Caïmans", code: "ky", alpha3: "cym" },
  { id: 116, name: "Cambodge", code: "kh", alpha3: "khm" },
  { id: 120, name: "Cameroun", code: "cm", alpha3: "cmr" },
  { id: 124, name: "Canada", code: "ca", alpha3: "can" },
  { id: 132, name: "Cap-Vert", code: "cv", alpha3: "cpv" },
  { id: 140, name: "République centrafricaine", code: "cf", alpha3: "caf" },
  { id: 152, name: "Chili", code: "cl", alpha3: "chl" },
  { id: 156, name: "Chine", code: "cn", alpha3: "chn" },
  { id: 162, name: "Île Christmas", code: "cx", alpha3: "cxr" },
  { id: 196, name: "Chypre (pays)", code: "cy", alpha3: "cyp" },
  { id: 166, name: "Îles Cocos", code: "cc", alpha3: "cck" },
  { id: 170, name: "Colombie", code: "co", alpha3: "col" },
  { id: 174, name: "Comores (pays)", code: "km", alpha3: "com" },
  { id: 178, name: "République du Congo", code: "cg", alpha3: "cog" },
  {
    id: 180,
    name: "République démocratique du Congo",
    code: "cd",
    alpha3: "cod",
  },
  { id: 184, name: "Îles Cook", code: "ck", alpha3: "cok" },
  { id: 410, name: "Corée du Sud", code: "kr", alpha3: "kor" },
  { id: 408, name: "Corée du Nord", code: "kp", alpha3: "prk" },
  { id: 188, name: "Costa Rica", code: "cr", alpha3: "cri" },
  { id: 384, name: "Côte d'Ivoire", code: "ci", alpha3: "civ" },
  { id: 191, name: "Croatie", code: "hr", alpha3: "hrv" },
  { id: 192, name: "Cuba", code: "cu", alpha3: "cub" },
  { id: 531, name: "Curaçao", code: "cw", alpha3: "cuw" },
  { id: 208, name: "Danemark", code: "dk", alpha3: "dnk" },
  { id: 262, name: "Djibouti", code: "dj", alpha3: "dji" },
  { id: 214, name: "République dominicaine", code: "do", alpha3: "dom" },
  { id: 212, name: "Dominique", code: "dm", alpha3: "dma" },
  { id: 818, name: "Égypte", code: "eg", alpha3: "egy" },
  { id: 222, name: "Salvador", code: "sv", alpha3: "slv" },
  { id: 784, name: "Émirats arabes unis", code: "ae", alpha3: "are" },
  { id: 218, name: "Équateur (pays)", code: "ec", alpha3: "ecu" },
  { id: 232, name: "Érythrée", code: "er", alpha3: "eri" },
  { id: 724, name: "Espagne", code: "es", alpha3: "esp" },
  { id: 233, name: "Estonie", code: "ee", alpha3: "est" },
  { id: 840, name: "États-Unis", code: "us", alpha3: "usa" },
  { id: 231, name: "Éthiopie", code: "et", alpha3: "eth" },
  { id: 238, name: "Malouines", code: "fk", alpha3: "flk" },
  { id: 234, name: "Îles Féroé", code: "fo", alpha3: "fro" },
  { id: 242, name: "Fidji", code: "fj", alpha3: "fji" },
  { id: 246, name: "Finlande", code: "fi", alpha3: "fin" },
  { id: 250, name: "France", code: "fr", alpha3: "fra" },
  { id: 266, name: "Gabon", code: "ga", alpha3: "gab" },
  { id: 270, name: "Gambie", code: "gm", alpha3: "gmb" },
  { id: 268, name: "Géorgie (pays)", code: "ge", alpha3: "geo" },
  {
    id: 239,
    name: "Géorgie du Sud-et-les îles Sandwich du Sud",
    code: "gs",
    alpha3: "sgs",
  },
  { id: 288, name: "Ghana", code: "gh", alpha3: "gha" },
  { id: 292, name: "Gibraltar", code: "gi", alpha3: "gib" },
  { id: 300, name: "Grèce", code: "gr", alpha3: "grc" },
  { id: 308, name: "Grenade (pays)", code: "gd", alpha3: "grd" },
  { id: 304, name: "Groenland", code: "gl", alpha3: "grl" },
  { id: 312, name: "Guadeloupe", code: "gp", alpha3: "glp" },
  { id: 316, name: "Guam", code: "gu", alpha3: "gum" },
  { id: 320, name: "Guatemala", code: "gt", alpha3: "gtm" },
  { id: 831, name: "Guernesey", code: "gg", alpha3: "ggy" },
  { id: 324, name: "Guinée", code: "gn", alpha3: "gin" },
  { id: 624, name: "Guinée-Bissau", code: "gw", alpha3: "gnb" },
  { id: 226, name: "Guinée équatoriale", code: "gq", alpha3: "gnq" },
  { id: 328, name: "Guyana", code: "gy", alpha3: "guy" },
  { id: 254, name: "Guyane", code: "gf", alpha3: "guf" },
  { id: 332, name: "Haïti", code: "ht", alpha3: "hti" },
  { id: 334, name: "Îles Heard-et-MacDonald", code: "hm", alpha3: "hmd" },
  { id: 340, name: "Honduras", code: "hn", alpha3: "hnd" },
  { id: 344, name: "Hong Kong", code: "hk", alpha3: "hkg" },
  { id: 348, name: "Hongrie", code: "hu", alpha3: "hun" },
  { id: 833, name: "Île de Man", code: "im", alpha3: "imn" },
  {
    id: 581,
    name: "Îles mineures éloignées des États-Unis",
    code: "um",
    alpha3: "umi",
  },
  { id: 92, name: "Îles Vierges britanniques", code: "vg", alpha3: "vgb" },
  { id: 850, name: "Îles Vierges des États-Unis", code: "vi", alpha3: "vir" },
  { id: 356, name: "Inde", code: "in", alpha3: "ind" },
  { id: 360, name: "Indonésie", code: "id", alpha3: "idn" },
  { id: 364, name: "Iran", code: "ir", alpha3: "irn" },
  { id: 368, name: "Irak", code: "iq", alpha3: "irq" },
  { id: 372, name: "Irlande (pays)", code: "ie", alpha3: "irl" },
  { id: 352, name: "Islande", code: "is", alpha3: "isl" },
  { id: 376, name: "Israël", code: "il", alpha3: "isr" },
  { id: 380, name: "Italie", code: "it", alpha3: "ita" },
  { id: 388, name: "Jamaïque", code: "jm", alpha3: "jam" },
  { id: 392, name: "Japon", code: "jp", alpha3: "jpn" },
  { id: 832, name: "Jersey", code: "je", alpha3: "jey" },
  { id: 400, name: "Jordanie", code: "jo", alpha3: "jor" },
  { id: 398, name: "Kazakhstan", code: "kz", alpha3: "kaz" },
  { id: 404, name: "Kenya", code: "ke", alpha3: "ken" },
  { id: 417, name: "Kirghizistan", code: "kg", alpha3: "kgz" },
  { id: 296, name: "Kiribati", code: "ki", alpha3: "kir" },
  { id: 414, name: "Koweït", code: "kw", alpha3: "kwt" },
  { id: 418, name: "Laos", code: "la", alpha3: "lao" },
  { id: 426, name: "Lesotho", code: "ls", alpha3: "lso" },
  { id: 428, name: "Lettonie", code: "lv", alpha3: "lva" },
  { id: 422, name: "Liban", code: "lb", alpha3: "lbn" },
  { id: 430, name: "Liberia", code: "lr", alpha3: "lbr" },
  { id: 434, name: "Libye", code: "ly", alpha3: "lby" },
  { id: 438, name: "Liechtenstein", code: "li", alpha3: "lie" },
  { id: 440, name: "Lituanie", code: "lt", alpha3: "ltu" },
  { id: 442, name: "Luxembourg (pays)", code: "lu", alpha3: "lux" },
  { id: 446, name: "Macao", code: "mo", alpha3: "mac" },
  { id: 807, name: "Macédoine du Nord", code: "mk", alpha3: "mkd" },
  { id: 450, name: "Madagascar", code: "mg", alpha3: "mdg" },
  { id: 458, name: "Malaisie", code: "my", alpha3: "mys" },
  { id: 454, name: "Malawi", code: "mw", alpha3: "mwi" },
  { id: 462, name: "Maldives", code: "mv", alpha3: "mdv" },
  { id: 466, name: "Mali", code: "ml", alpha3: "mli" },
  { id: 470, name: "Malte", code: "mt", alpha3: "mlt" },
  { id: 580, name: "Îles Mariannes du Nord", code: "mp", alpha3: "mnp" },
  { id: 504, name: "Maroc", code: "ma", alpha3: "mar" },
  { id: 584, name: "Îles Marshall (pays)", code: "mh", alpha3: "mhl" },
  { id: 474, name: "Martinique", code: "mq", alpha3: "mtq" },
  { id: 480, name: "Maurice (pays)", code: "mu", alpha3: "mus" },
  { id: 478, name: "Mauritanie", code: "mr", alpha3: "mrt" },
  { id: 175, name: "Mayotte", code: "yt", alpha3: "myt" },
  { id: 484, name: "Mexique", code: "mx", alpha3: "mex" },
  {
    id: 583,
    name: "États fédérés de Micronésie (pays)",
    code: "fm",
    alpha3: "fsm",
  },
  { id: 498, name: "Moldavie", code: "md", alpha3: "mda" },
  { id: 492, name: "Monaco", code: "mc", alpha3: "mco" },
  { id: 496, name: "Mongolie", code: "mn", alpha3: "mng" },
  { id: 499, name: "Monténégro", code: "me", alpha3: "mne" },
  { id: 500, name: "Montserrat", code: "ms", alpha3: "msr" },
  { id: 508, name: "Mozambique", code: "mz", alpha3: "moz" },
  { id: 104, name: "Birmanie", code: "mm", alpha3: "mmr" },
  { id: 516, name: "Namibie", code: "na", alpha3: "nam" },
  { id: 520, name: "Nauru", code: "nr", alpha3: "nru" },
  { id: 524, name: "Népal", code: "np", alpha3: "npl" },
  { id: 558, name: "Nicaragua", code: "ni", alpha3: "nic" },
  { id: 562, name: "Niger", code: "ne", alpha3: "ner" },
  { id: 566, name: "Nigeria", code: "ng", alpha3: "nga" },
  { id: 570, name: "Niue", code: "nu", alpha3: "niu" },
  { id: 574, name: "Île Norfolk", code: "nf", alpha3: "nfk" },
  { id: 578, name: "Norvège", code: "no", alpha3: "nor" },
  { id: 540, name: "Nouvelle-Calédonie", code: "nc", alpha3: "ncl" },
  { id: 554, name: "Nouvelle-Zélande", code: "nz", alpha3: "nzl" },
  {
    id: 86,
    name: "Territoire britannique de l'océan Indien",
    code: "io",
    alpha3: "iot",
  },
  { id: 512, name: "Oman", code: "om", alpha3: "omn" },
  { id: 800, name: "Ouganda", code: "ug", alpha3: "uga" },
  { id: 860, name: "Ouzbékistan", code: "uz", alpha3: "uzb" },
  { id: 586, name: "Pakistan", code: "pk", alpha3: "pak" },
  { id: 585, name: "Palaos", code: "pw", alpha3: "plw" },
  { id: 275, name: "Palestine", code: "ps", alpha3: "pse" },
  { id: 591, name: "Panama", code: "pa", alpha3: "pan" },
  { id: 598, name: "Papouasie-Nouvelle-Guinée", code: "pg", alpha3: "png" },
  { id: 600, name: "Paraguay", code: "py", alpha3: "pry" },
  { id: 528, name: "Pays-Bas", code: "nl", alpha3: "nld" },
  { id: 604, name: "Pérou", code: "pe", alpha3: "per" },
  { id: 608, name: "Philippines", code: "ph", alpha3: "phl" },
  { id: 612, name: "Îles Pitcairn", code: "pn", alpha3: "pcn" },
  { id: 616, name: "Pologne", code: "pl", alpha3: "pol" },
  { id: 258, name: "Polynésie française", code: "pf", alpha3: "pyf" },
  { id: 630, name: "Porto Rico", code: "pr", alpha3: "pri" },
  { id: 620, name: "Portugal", code: "pt", alpha3: "prt" },
  { id: 634, name: "Qatar", code: "qa", alpha3: "qat" },
  { id: 638, name: "La Réunion", code: "re", alpha3: "reu" },
  { id: 642, name: "Roumanie", code: "ro", alpha3: "rou" },
  { id: 826, name: "Royaume-Uni", code: "gb", alpha3: "gbr" },
  { id: 643, name: "Russie", code: "ru", alpha3: "rus" },
  { id: 646, name: "Rwanda", code: "rw", alpha3: "rwa" },
  {
    id: 732,
    name: "République arabe sahraouie démocratique",
    code: "eh",
    alpha3: "esh",
  },
  { id: 652, name: "Saint-Barthélemy", code: "bl", alpha3: "blm" },
  { id: 659, name: "Saint-Christophe-et-Niévès", code: "kn", alpha3: "kna" },
  { id: 674, name: "Saint-Marin", code: "sm", alpha3: "smr" },
  { id: 663, name: "Saint-Martin", code: "mf", alpha3: "maf" },
  { id: 666, name: "Saint-Pierre-et-Miquelon", code: "pm", alpha3: "spm" },
  {
    id: 336,
    name: "Saint-Siège (État de la Cité du Vatican)",
    code: "va",
    alpha3: "vat",
  },
  {
    id: 670,
    name: "Saint-Vincent-et-les-Grenadines",
    code: "vc",
    alpha3: "vct",
  },
  {
    id: 654,
    name: "Sainte-Hélène, Ascension et Tristan da Cunha",
    code: "sh",
    alpha3: "shn",
  },
  { id: 662, name: "Sainte-Lucie", code: "lc", alpha3: "lca" },
  { id: 90, name: "Salomon", code: "sb", alpha3: "slb" },
  { id: 882, name: "Samoa", code: "ws", alpha3: "wsm" },
  { id: 16, name: "Samoa américaines", code: "as", alpha3: "asm" },
  { id: 678, name: "Sao Tomé-et-Principe", code: "st", alpha3: "stp" },
  { id: 686, name: "Sénégal", code: "sn", alpha3: "sen" },
  { id: 688, name: "Serbie", code: "rs", alpha3: "srb" },
  { id: 690, name: "Seychelles", code: "sc", alpha3: "syc" },
  { id: 694, name: "Sierra Leone", code: "sl", alpha3: "sle" },
  { id: 702, name: "Singapour", code: "sg", alpha3: "sgp" },
  { id: 703, name: "Slovaquie", code: "sk", alpha3: "svk" },
  { id: 705, name: "Slovénie", code: "si", alpha3: "svn" },
  { id: 706, name: "Somalie", code: "so", alpha3: "som" },
  { id: 729, name: "Soudan", code: "sd", alpha3: "sdn" },
  { id: 728, name: "Soudan du Sud", code: "ss", alpha3: "ssd" },
  { id: 144, name: "Sri Lanka", code: "lk", alpha3: "lka" },
  { id: 752, name: "Suède", code: "se", alpha3: "swe" },
  { id: 756, name: "Suisse", code: "ch", alpha3: "che" },
  { id: 740, name: "Suriname", code: "sr", alpha3: "sur" },
  { id: 744, name: "Svalbard et ile Jan Mayen", code: "sj", alpha3: "sjm" },
  { id: 748, name: "Eswatini", code: "sz", alpha3: "swz" },
  { id: 760, name: "Syrie", code: "sy", alpha3: "syr" },
  { id: 762, name: "Tadjikistan", code: "tj", alpha3: "tjk" },
  {
    id: 158,
    name: "Taïwan / (République de Chine (Taïwan))",
    code: "tw",
    alpha3: "twn",
  },
  { id: 834, name: "Tanzanie", code: "tz", alpha3: "tza" },
  { id: 148, name: "Tchad", code: "td", alpha3: "tcd" },
  { id: 203, name: "Tchéquie", code: "cz", alpha3: "cze" },
  {
    id: 260,
    name: "Terres australes et antarctiques françaises",
    code: "tf",
    alpha3: "atf",
  },
  { id: 764, name: "Thaïlande", code: "th", alpha3: "tha" },
  { id: 626, name: "Timor oriental", code: "tl", alpha3: "tls" },
  { id: 768, name: "Togo", code: "tg", alpha3: "tgo" },
  { id: 772, name: "Tokelau", code: "tk", alpha3: "tkl" },
  { id: 776, name: "Tonga", code: "to", alpha3: "ton" },
  { id: 780, name: "Trinité-et-Tobago", code: "tt", alpha3: "tto" },
  { id: 788, name: "Tunisie", code: "tn", alpha3: "tun" },
  { id: 795, name: "Turkménistan", code: "tm", alpha3: "tkm" },
  { id: 796, name: "Îles Turques-et-Caïques", code: "tc", alpha3: "tca" },
  { id: 792, name: "Turquie", code: "tr", alpha3: "tur" },
  { id: 798, name: "Tuvalu", code: "tv", alpha3: "tuv" },
  { id: 804, name: "Ukraine", code: "ua", alpha3: "ukr" },
  { id: 858, name: "Uruguay", code: "uy", alpha3: "ury" },
  { id: 548, name: "Vanuatu", code: "vu", alpha3: "vut" },
  { id: 862, name: "Venezuela", code: "ve", alpha3: "ven" },
  { id: 704, name: "Viêt Nam", code: "vn", alpha3: "vnm" },
  { id: 876, name: "Wallis-et-Futuna", code: "wf", alpha3: "wlf" },
  { id: 887, name: "Yémen", code: "ye", alpha3: "yem" },
  { id: 894, name: "Zambie", code: "zm", alpha3: "zmb" },
  { id: 716, name: "Zimbabwe", code: "zw", alpha3: "zwe" },
];
