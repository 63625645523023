import axios from "axios";

async function sendLog(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };

  const res = await axios(
    `${process.env.VUE_APP_API_URL}/frontend-logs`,
    requestOptions
  );
  return res.data;
}

export default {
  sendLog,
};
