<template>
  <div class="banner">
    <div class="left">
      <WarningIcon v-if="warningIcon" />
      <slot :reason="reason" name="text"></slot>
    </div>
    <div class="right">
      <button class="button-primary" @click="actionForLockedReason">
        {{ buttonText }}
      </button>
    </div>
    <!-- if payment failed -->
    <CreditCardModal
      v-if="showCreditCardModal"
      @close="toggleCreditCardModal"
    />
    <!-- if plan limit reached -->
    <UpgradePlan
      v-if="showUpgradePlan"
      :plan-chosen="planChosen"
      @closeUpgradePlan="showUpgradePlan = false"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import CreditCardModal from "../settings/settings-components/CreditCardModal.vue";
import UpgradePlan from "../upgrade-plan/UpgradePlan.vue";
import enums from "../../enums";
import WarningIcon from "@/assets/svg/settings/warning-icon-banner.svg?inline";

const { LockReason } = enums;

export default {
  name: "LockedBanner",
  components: {
    WarningIcon,
    CreditCardModal,
    UpgradePlan,
  },
  props: {
    warningIcon: { type: Boolean, default: false },
  },
  data() {
    return {
      showCreditCardModal: false,
      reason: "",
      buttonText: "",
      showUpgradePlan: false,
      planChosen: "",
    };
  },

  computed: {
    ...mapState(["plan", "site"]),
    ...mapGetters({
      plan: "plan/plan",
      siteId: "account/getSiteId",
      siteIntegration: "site/siteIntegration"
    }),
    locale() {
      return this.$i18n.locale;
    },
  },

  watch: {
    locale: function () {
      this.textAndButtonToDisplay();
    },
  },
  created() {
    this.textAndButtonToDisplay();
  },

  methods: {
    textAndButtonToDisplay() {
      if (
        !this.plan.shopifyChargeAccepted &&
        this.plan.currentPlan !== "free" &&
        !this.plan.isLocked
      ) {
        this.reason = this.$t("bannerLocked.reason.shopifyWarning");
        this.buttonText = this.$t("shared.buttons.shopify");
      }
      if (
        this.plan.isLocked === true &&
        this.plan.lockReason !== ""
      ) {
        switch(this.plan.lockReason) {
          case (LockReason.videoViewLimitReached):
            this.reason = this.$t("bannerLocked.reason.limit");
            this.buttonText = this.$t("shared.buttons.seePlans");
            break;
          case (LockReason.paymentFailure):
            this.reason = this.$t("bannerLocked.reason.failure");
            this.buttonText = this.$t("shared.buttons.creditCard");
            break;
          case (LockReason.subscriptionCanceled):
            this.reason = this.$t("bannerLocked.reason.canceled");
            this.buttonText = this.$t("shared.buttons.reactivate");
            break;
          case (LockReason.shopifyChargeNotAccepted):
            this.reason = this.$t("bannerLocked.reason.shopifyLocked");
            this.buttonText = this.$t("shared.buttons.shopify");
          case (LockReason.freeTrialEnded):
            this.reason = this.$t("bannerLocked.reason.ftEnded");
            this.buttonText = this.$t("shared.buttons.seePlans");
        }
      }
    },
    actionForLockedReason() {
      if (
        (this.siteIntegration === 'shopify' && !this.plan.shopifyChargeAccepted && this.plan.currentPlan && this.plan.currentPlan !== "free") ||
        this.plan.lockReason === LockReason.shopifyChargeNotAccepted
      ) {
        return window.open(this.plan.shopifyChargeConfirmationUrl, "_blank");
      }
      
      switch(this.plan.lockReason) {
        case (LockReason.videoViewLimitReached):
        case (LockReason.freeTrialEnded):
          this.$router.push({ name: "billing" });
          break;
        case (LockReason.paymentFailure):
          this.toggleCreditCardModal();
          break;
        case (LockReason.subscriptionCanceled):
          this.reactivateSubscription();
          break;
        }
    },
    toggleCreditCardModal() {
      this.showCreditCardModal = !this.showCreditCardModal;
    },
    //  get proposed plan if trial if not current plan
    mailTo() {
      window.open(`mailto:contact@vidjet.io?subject=${this.$t('billing.mailTo')}`);
    },
    async reactivateSubscription() {
      const data = {
        siteId: this.siteId,
      };
      try {
        await this.$store.dispatch("plan/reactivateSubscription", data);

        this.$notify({
          title: this.$t("shared.toastMessage.subscriptionReactivated"),
          type: "success",
        });

        if (this.plan.shopifyChargeConfirmationUrl && this.currentPlan !== 'free') {
          window.open(this.plan.shopifyChargeConfirmationUrl, "_blank");
        }
      } catch (err) {
        console.log(err);
        this.$notify({
          title: "Failed",
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.banner {
  @include font-small;
  line-height: 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: map-get($zindex, bannerLocked);
  width: 100vw;
  height: 55px;
  background-color: $dark-grey;
  @include flex-centered;
  justify-content: space-evenly;
  h3 {
    color: white;
    align-self: center;
    margin-left: 16px;
  }
  .left {
    display: flex;
  }
  .button-primary {
    font-size: 14px;
    padding: 5px 20px;
  }
}
</style>
