<template>
  <div class="logged-as-card">
    <div class="log-as-text">
      <p>Logged as</p>
      <p>{{ websiteUrl }}</p>
    </div>
    <Logout class="log-out-icon" @click="logOut" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Logout from "@/assets/svg/sidebar/logout.svg?inline";
import api from "../../api";

export default {
  components: {
    Logout,
  },
  props: {
    websiteUrl: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapGetters({
      isAdmin: "account/getIsAdmin",
    }),
  },

  methods: {
    async logOut() {
      try {
        const originalUser = await api.getUser(
          this.$store.state.account.user._id
        );

        await this.$store.dispatch("account/logAs", {
          siteId: originalUser.siteId,
        });

        await this.$store.dispatch("site/getSite", {
          siteId: this.$store.state.account.user.siteId,
        });
        this.$store.dispatch("site/isLoggedAs", false);

        this.$notify({
          title: this.$t("shared.toastMessage.loggedOut"),
          type: "success",
        });
      } catch (err) {
        this.$notify({
          title: "Error",
          text: err,
          type: "success",
        });
      }
      this.isAdmin
        ? this.$router.push({ name: "adminPage" })
        : this.$router.push({ name: "agency" });
    },
  },
};
</script>

<style lang="scss" scoped>
$card-offset-top: 5px;

.logged-as-card {
  display: flex;
  height: 40px;
  width: 200px;
  background: linear-gradient(
    93.17deg,
    $dark-purple 0%,
    #be62dc 48.27%,
    #f9baff 100%
  );
  color: white;
  border-radius: $card-border-radius;
  @include flex-centered;
  @include font-smaller;
  position: absolute;
  right: 50%;
  top: $card-offset-top;
  transform: translateX(50%);
  width: fit-content;
  padding: 0 10px;
}

.log-out-icon {
  transform: scale(0.8);
  margin-left: 1rem;
  cursor: pointer;
}
</style>
