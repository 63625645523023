import Vue from "vue";
import Vuex from "vuex";

import campaign from "./campaign";
import account from "./account";
import site from "./site";
import dashboard from "./dashboard";
import invoices from "./invoices";
import plan from "./plan";
import user from "./user";
import usage from "./usage";
import issue from "./issue";
import logger from "./logger";
import suggestion from "./suggestion";
import video from "./video";
import tag from "./tag";
import product from "./product";

import { playerStore } from "player";

Vue.use(Vuex);

const modules = {
  campaign,
  account,
  site,
  dashboard,
  invoices,
  plan,
  user,
  usage,
  issue,
  logger,
  suggestion,
  video,
  tag,
  ...playerStore,
  product,
};

const store = new Vuex.Store({
  modules,
  namespaced: true,
  strict: process.env.NODE_ENV !== "production",
});

export default store;
