import api from "../api";

const state = {
  usage: {},
};

const mutations = {
  GET(state, usage) {
    state.usage = usage;
  },
};

const actions = {
  async getUsage({ commit }, data) {
    try {
      const usage = await api.getUsage(data);
      commit("GET", usage);
    } catch (err) {
      throw err;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
