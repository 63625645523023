<template>
  <div class="button-pen">
    <Pen />
  </div>
</template>

<script>
import Pen from "@/assets/svg/settings/pen.svg?inline";

export default {
  components: {
    Pen,
  },
};
</script>

<style lang="scss" scoped>
.button-pen {
  appearance: none;
  outline: none;
  border: none;
  cursor: pointer;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 7px 7px 2px 7px;
  width: fit-content;
  height: 20px;
  &:active {
    background: $dark-purple;
  }
}

.button-pen:hover {
  transform: scale(1.15) perspective(1px);
}
</style>
