<template>
  <base-card class="billing-history">
    <template #icon>
      <list-icon></list-icon>
    </template>
    <template #title>{{ $t("billing.paymentHistory.title") }}</template>
    <list-items
      class="payment-list-items"
      :header-elements="listHeaders"
      :centered-text="true"
    >
    </list-items>
    <div class="list-container">
      <!-- TODO: refactor this by using @show tooltip -->
      <div
        v-for="(invoice, index) in invoices.invoices"
        :key="invoice.id"
        class="list-elements"
      >
        <p>
          {{ invoice.currency === "USD" ? "$" : "€"
          }}{{ invoiceAmount(invoice) }}
        </p>
        <!-- If the user is shopify then display with a question mark and tooltip for the user
      to validate on the platform -->
        <div v-if="invoice.billingMethod === 'shopify'">
          <section
            v-if="invoice.stateFlag === InvoiceState.waiting"
            class="payment-status"
            style="background: #fcf3db; color: #f6c146"
          >
            <tippy
              placement="right"
              arrow="true"
              interactive="true"
              :show-on-init="index < 3 ? true : false"
              touch="true"
              a11y="false"
            >
              <div class="tooltip-content">
                <p>
                  {{ $t("billing.paymentHistory.tooltip.content") }}
                </p>
                <a
                  class="shopify-link"
                  @click="validateInvoice(invoice.confirmationUrl)"
                >
                  {{ $t("billing.paymentHistory.tooltip.validate") }}
                </a>
              </div>
              <template #trigger>
                <div class="trigger">
                  <p>{{ $t("billing.invoiceStatus.pending") }}</p>
                  <interrogation-mark
                    style="stroke: #f6c146"
                  ></interrogation-mark>
                </div>
              </template>
            </tippy>
          </section>

          <section
            v-else-if="invoice.stateFlag === InvoiceState.paid"
            class="payment-status"
            style="background: #e3f4f5; color: #5cc9bf"
          >
            <p>{{ $t("billing.invoiceStatus.paid") }}</p>
          </section>

          <section
            v-else-if="invoice.stateFlag === InvoiceState.refunded"
            class="payment-status"
            style="background: #8ecae6; color: #134074"
          >
            <p>{{ $t("billing.invoiceStatus.refunded") }}</p>
          </section>

          <section
            v-else
            class="payment-status"
            style="background: #fce3db; color: #ff5d53"
          >
            <!-- tooltip  -->
            <tippy
              placement="right"
              interactive="true"
              :show-on-init="index < 3 ? true : false"
              arrow="true"
              touch="true"
              a11y="false"
            >
              <div class="tooltip-content">
                <p>{{ $t("billing.paymentHistory.tooltip.content") }}</p>
                <a
                  class="shopify-link"
                  @click="validateInvoice(invoice.confirmationUrl)"
                >
                  {{ $t("billing.paymentHistory.tooltip.validate") }}
                </a>
              </div>
              <template #trigger>
                <div class="trigger">
                  <p>{{ $t("billing.invoiceStatus.failure") }}</p>
                  <interrogation-mark
                    style="stroke: #ff5d53"
                  ></interrogation-mark>
                </div>
              </template>
            </tippy>
          </section>
        </div>
        <!-- display for stripe users -->
        <div v-else class="regular-user">
          <section
            v-if="invoice.stateFlag === InvoiceState.waiting"
            class="payment-status"
            style="background: #fcf3db; color: #f6c146"
          >
            <p>{{ $t("billing.invoiceStatus.pending") }}</p>
          </section>
          <section
            v-else-if="invoice.stateFlag === InvoiceState.paid"
            class="payment-status"
            style="background: #e3f4f5; color: #5cc9bf"
          >
            <p>{{ $t("billing.invoiceStatus.paid") }}</p>
          </section>
          <section
            v-else-if="invoice.stateFlag === InvoiceState.refunded"
            class="payment-status"
            style="background: #8ecae6; color: #134074"
          >
            <p>{{ $t("billing.invoiceStatus.refunded") }}</p>
          </section>
          <section
            v-else
            class="payment-status"
            style="background: #fce3db; color: #ff5d53"
          >
            <p>{{ $t("billing.invoiceStatus.failure") }}</p>
          </section>
        </div>
        <p>{{ formatedDates(invoice.creationDate) }}</p>
        <a class="download-button" :href="invoice.pdfUrl" target="_blank">
          <DownloadInvoice />
        </a>
      </div>
    </div>
  </base-card>
</template>

<script>
import ListIcon from "@/assets/svg/list-icon.svg?inline";
import InterrogationMark from "@/assets/svg/settings/interrogation-mark.svg?inline";
import DownloadInvoice from "@/assets/svg/settings/download-invoice.svg?inline";

import BaseCard from "../../shared/BaseCard.vue";
import ListItems from "../../shared/ListItems.vue";
import { mapState } from "vuex";
import enums from "../../../enums";

const { InvoiceState } = enums;
export default {
  components: {
    ListItems,
    BaseCard,
    DownloadInvoice,
    InterrogationMark,
    ListIcon,
  },

  data() {
    return {
      index: 0,
      InvoiceState,
      listHeaders: [
        this.$t("billing.paymentHistory.paymentHeader.amount"),
        this.$t("billing.paymentHistory.paymentHeader.status"),
        this.$t("billing.paymentHistory.paymentHeader.invoice"),
        "Download",
      ],
    };
  },

  computed: {
    ...mapState({ invoices: "invoices" }),
  },

  methods: {
    // if user pays taxes display amount ATI otherwise display amount w/o taxes
    invoiceAmount(invoice) {
      return invoice.totalATI
        ? Math.round(invoice.totalATI * 100) / 100
        : invoice.amount;
    },

    // render date in readable format
    formatedDates(date) {
      const invoicingDate = new Date(date);
      const month = invoicingDate.toLocaleString("default", { month: "short" });
      return `${month}  ${date.substring(8, 10)},  ${date.substring(0, 4)} `;
    },
    validateInvoice(url) {
      window.top.location.href = url;
    },
  },
};
</script>
<style lang="scss">
// tool-tip styling
.trigger {
  display: flex !important;
}

.tooltip {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  p {
    padding: 5px;
    color: white;
  }
}

.shopify-link {
  cursor: pointer;
  color: black;
  padding: 6px;
  margin-right: 5px;
  align-self: center;
  font-size: 10px;
  background-color: white;
  border-radius: 20px;
  white-space: nowrap;
  text-align: center;
  height: 50%;
}

.tooltip-content {
  width: 200px;
  display: flex;
  @include font-smaller;
  font-weight: 400;
}

.billing-history {
  max-height: 260px;
  overflow-y: scroll;
}

.billing-history::-webkit-scrollbar {
  width: 0;
}

.billing-history::-webkit-scrollbar-track {
  background-color: transparent;
}

.billing-history::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.payment-list-items .list-header {
  margin-bottom: 12px;
}
</style>

<style lang="scss" scoped>
.list-elements {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  p {
    @include font-smaller;
  }
}

.list-container {
  overflow: auto;
}

.payment-status {
  border-radius: 4px;
  font-weight: 600;
  margin: auto;
  width: fit-content;
  padding: 4px;
  display: flex;
  svg {
    align-self: center;
    margin-left: 4px;
  }
}

.download-button {
  cursor: pointer;
  margin: auto;
  background: none;
}

@include media(">tablet", "<=desktop") {
  .list-elements {
    @include font-smaller;
  }
}

@include media(">phone", "<=tablet") {
  .list-elements {
    @include font-smaller;
  }

  .payment-status {
    p {
      align-self: center;
    }
    svg {
      margin-left: 1px;
    }
  }
}

@include media("<=phone") {
  .list-elements {
    @include font-smaller;
  }

  .payment-status {
    @include font-smallest;
    p {
      align-self: center;
    }
    svg {
      margin-left: 1px;
    }
  }
}
</style>
