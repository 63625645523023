]
<template>
  <div class="edit-footer">
    <button
      :disabled="disabledPrev"
      :class="[
        'button-primary --grey',
        { 'small-font': isSmallFontOnMobile, disabled: disabledPrev },
      ]"
      @click="handleClickPrev"
    >
      {{ prevText }}
    </button>
    <button
      :disabled="disabledNext"
      :class="[
        'button-primary',
        { 'small-font': isSmallFontOnMobile, disabled: disabledNext },
      ]"
      @click="handleClickNext"
    >
      {{ nextText }}
    </button>
  </div>
</template>

<script>
export default {
  name: "EditFooter",
  props: {
    prevText: {
      type: String,
      default: function () {
        return this.$t("shared.buttons.back");
      },
    },
    nextText: {
      type: String,
      default: function () {
        return this.$t("shared.buttons.nextStep");
      },
    },
    isSmallFontOnMobile: {
      type: Boolean,
      default: false,
    },
    disabledPrev: {
      type: Boolean,
      default: false,
    },
    disabledNext: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClickPrev() {
      this.$emit("prev");
    },
    handleClickNext() {
      this.$emit("next");
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-footer {
  display: flex;
  justify-content: space-evenly;
}

.disabled {
  cursor: not-allowed;
}

@include media("<tablet") {
  .small-font {
    @include font-smallest;
  }
}
</style>
