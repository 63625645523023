<template>
  <div class="device-selector">
    <input
      id="all-devices"
      v-model="selectedDevice"
      type="radio"
      class="button-radio"
      name="device-selector"
      value="allDevices"
      @change="$emit('update:selected-device', selectedDevice)"
    />
    <label for="all-devices" class="radio-label">{{
      $t("dashboard.filters.deviceSelector.all")
    }}</label>

    <input
      id="desktop"
      v-model="selectedDevice"
      type="radio"
      class="button-radio"
      name="device-selector"
      value="desktop"
      @change="$emit('update:selected-device', selectedDevice)"
    />
    <label for="desktop" class="radio-label">{{
      $t("dashboard.filters.deviceSelector.desktop")
    }}</label>
    <input
      id="mobile"
      v-model="selectedDevice"
      type="radio"
      class="button-radio"
      name="device-selector"
      value="mobile"
      @change="$emit('update:selected-device', selectedDevice)"
    />
    <label class="radio-label" for="mobile">{{
      $t("dashboard.filters.deviceSelector.mobile")
    }}</label>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedDevice: "allDevices",
    };
  },
};
</script>

<style lang="scss" scoped>
.device-selector {
  margin-top: 8px;
  label:nth-of-type(2) {
    margin: 0 20px;
  }
}
</style>
