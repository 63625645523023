<template>
  <DeselectIcon />
</template>

<script>
import DeselectIcon from "@/assets/svg/dashboard/off_outline_close.svg?inline";
export default {
  name: "Deselect",
  components: { DeselectIcon },
};
</script>
