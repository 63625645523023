<template>
  <small-modal
    class="integration-modal"
    :fit-content="true"
    :title="modalTitle"
    @close="close"
  >
    <div class="integration-steps-modal">
      <google-sheet v-if="toolChosen === 'google'"></google-sheet>
      <klaviyo v-else-if="toolChosen === 'klaviyo'"></klaviyo>
    </div>
  </small-modal>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import GoogleSheet from "../integration-modals/GoogleSheet.vue";
import Klaviyo from "../integration-modals/Klaviyo.vue";
import SmallModal from "../../shared/SmallModal";

export default {
  components: {
    GoogleSheet,
    Klaviyo,
    SmallModal,
  },

  props: ["toolChosen"],

  computed: {
    ...mapState({ site: "site" }),
    ...mapGetters({
      siteId: "site/getSiteId",
      authToken: "account/getAuthToken",
    }),
    modalTitle() {
      if (this.toolChosen === "google" && this.googleIntegration) {
        return this.$t("integration.modal.tool.gsheet.title2");
      } else if (this.toolChosen === "google") {
        return this.$t("integration.modal.tool.gsheet.title");
      } else if (this.toolChosen === "klaviyo") {
        return this.$t("integration.modal.tool.klaviyo.title");
      } else {
        return "Integration";
      }
    },
    googleIntegration() {
      return this.$store.state.site.toolIntegrations.find(
        (integration) => integration.type == "google"
      );
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
    clipboardSuccessHandler() {
      this.$notify({
        title: this.$t("shared.toastMessage.copied"),
        type: "success",
      });
    },
    clipboardErrorHandler() {
      this.$notify({
        title: this.$t("shared.toastMessage.failedToCopy"),
        type: "error",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  height: 100%;
  background-color: transparent;
}

.close-btn {
  float: right;
  margin-top: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  position: sticky;
  top: 10px;
}

.pink-square {
  width: 70vw;
  height: 120px;
  margin: auto;
  background: linear-gradient(
    93.39deg,
    rgba(123, 1, 181, 0.2) 0%,
    rgba(226, 124, 252, 0.2) 100%
  );
  border: 1px solid $dark-purple;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  margin-bottom: 32px;
  max-width: 800px;
}

.pink-square-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 90%;
  height: 90%;
  margin: auto;
  align-items: center;
}

.steplist {
  display: grid;
  grid-column: 1fr;
  grid-gap: 16px;
  width: 70%;
  margin: auto;
  max-width: 800px;
}
.step {
  @include font-small;
  padding: 8px;
}

.link-to-shopify {
  color: $dark-grey;
  text-decoration: underline;
}

.script-to-copy {
  position: relative;
  width: 90%;
  height: 110px;
  border: 1px solid $dark-purple;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: $card-border-radius;
  margin: 10px auto;
  overflow: auto;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  @include font-small;
}

.display-for-manual {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
}

.copy-to-clipboard {
  @include base-font;
  height: fit-content;
  padding: 8px;
  background-color: white;
  box-shadow: 1px 1px 12px -1px rgba(141, 22, 193, 0.25);
  border-radius: 20px;
  margin: auto;
  position: absolute;
  top: 35%;
  right: 12%;
}
.script {
  width: 60%;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.image-steps {
  height: 300px;
  padding: 8px;
}

@include media(">tablet", "<=desktop") {
  .copy-to-clipboard {
    right: 8%;
  }
}
@include media(">phone", "<=tablet") {
  .integration-steps-modal {
    width: 80vw;
    min-width: 320px;
    height: 400px;
    align-items: center;
  }

  .pink-square {
    width: 60vw;
  }

  .pink-square-grid {
    grid-template-columns: 1fr;
    h2 {
      @include font-smaller;
    }
  }
}

@include media("<=phone") {
  .integration-steps-modal {
    width: 300px;
    height: 350px;
    align-items: center;
  }
  .pink-square {
    width: 280px;
  }

  .pink-square-grid {
    grid-template-columns: 1fr;
    h2 {
      @include font-smaller;
    }
  }
}
</style>
